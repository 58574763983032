import { NewRecipe, Recipe } from "../../model/Recipe";
import { RecipesActions, NewRecipeActions, RecipesState, RecipeActions } from "../reducers/recipeReducer";

export const updateRecipes = (recipes: RecipesState, timeStamp: number): RecipesActions => ({
	type: "UPDATE_RECIPES",
	recipes,
	timeStamp
});

export const clearRecipesList = (): RecipesActions => ({
	type: "CLEAR_RECIPES"
});

export const updateNewRecipe = (recipe: {content: Recipe}): NewRecipeActions => ({
	type: "UPDATE_NEW_RECIPES",
	recipe: recipe.content
});

export const failNewRecipe = (): NewRecipeActions => ({
	type: "FAIL_NEW_RECIPE"
});

export const clearNewRecipe = (): NewRecipeActions => ({
	type: "CLEAR_NEW_RECIPES"
});

export const updateRecipe = (recipe: {content: Recipe}): RecipeActions => ({
	type: "UPDATE_RECIPE",
	recipe: recipe.content
});

export const failRecipe = (): RecipeActions => ({
	type: "FAIL_RECIPE"
});

export const clearRecipe = (): RecipeActions => ({
	type: "CLEAR_RECIPE"
});

export const clearErrorRecipe = (): RecipeActions => ({
	type: "CLEAR_ERROR"
});


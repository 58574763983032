import {Reducer} from "redux";
import {FullFile} from "../../model/FullMediaComponents";

export interface FullMediaDataState {
    fullFile?: FullFile;
    preview?: FullFile;
    thumbnail?: FullFile;
}

const defaultState: FullMediaDataState = {
    fullFile: undefined,
    preview: undefined,
    thumbnail: undefined
};

export const fullMediaDataReducer: Reducer<FullMediaDataState, FullMediaDataActions> = (
    state: FullMediaDataState = defaultState,
    action: FullMediaDataActions
) => {
    switch (action.type) {
        case "ADD_FULL_FILE_DATA":
            return {...state, fullFile: action.fullFile};
        case "ADD_THUMBNAIL_DATA":
            return {...state, thumbnail: action.thumbnail};
        case "ADD_PREVIEW_DATA":
            return {...state, preview: action.preview};
        case "REMOVE_FULL_FILE_DATA":
            return {...state, fullFile: undefined};
        case "REMOVE_PREVIEW_DATA":
            return {...state, preview: undefined};
        case "REMOVE_THUMBNAIL_DATA":
            return {...state, thumbnail: undefined};
        case "CLEAR_ALL_FULL_MEDIA_DATA":
            return {fullFile: undefined, preview: undefined, thumbnail: undefined};
        default:
            return state;
    }
}

export type FullMediaDataActions =
    | {
    type: 'ADD_FULL_FILE_DATA',
    fullFile: FullFile
} | {
    type: 'ADD_THUMBNAIL_DATA',
    thumbnail: FullFile
} | {
    type: 'ADD_PREVIEW_DATA',
    preview: FullFile
} | {
    type: 'REMOVE_FULL_FILE_DATA'
} | {
    type: 'REMOVE_PREVIEW_DATA'
} | {
    type: 'REMOVE_THUMBNAIL_DATA'
} | {
    type: 'CLEAR_ALL_FULL_MEDIA_DATA'
};

import { Reducer } from "redux";
import { ImportRecipe } from "../../model/ImportRecipe";

export interface ImportRecipesState extends ImportRecipe {
    error: boolean;
}

const defaultState: ImportRecipesState = {
    rows: [],
    id: '',
    running: false,
    error: false
};

export const ImportRecipesReducer: Reducer<ImportRecipesState, ImportRecipesActions> = (
    state: ImportRecipesState = defaultState,
    action: ImportRecipesActions
) => {
    switch (action.type) {
        case "IMPORT_RECIPES":
            return {rows: [], running: action.importRecipe.running, id: action.importRecipe.id, error: false};
        case "UPDATE_IMPORT_RECIPES":
            return {rows: action.importRecipe.rows, running: action.importRecipe.running, id: action.importRecipe.id, error: false};
        case "ERROR_IMPORT_RECIPES":
            return {rows: [], running: false, id: '', error: true}
        case "CLEAR_IMPORT_RECIPES":
            return defaultState;
        default:
            return state;
    }
};

export type ImportRecipesActions = 
| {
    type: "IMPORT_RECIPES";
    importRecipe: ImportRecipe;
}
| {
    type: "UPDATE_IMPORT_RECIPES";
    importRecipe: ImportRecipe;
}
| {
    type: "ERROR_IMPORT_RECIPES";
}
| {
    type: "CLEAR_IMPORT_RECIPES"
};
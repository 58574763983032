import {ContentModel} from "../../model/ContentModel";
import {ContentModelActions} from "../reducers/ContentModelReducer";

export const loadContentModel = (contentModel: ContentModel): ContentModelActions => ({
  type: "LOAD_CONTENT_MODEL",
  contentModel
});

export const clearContentModel = (): ContentModelActions => ({
  type: "CLEAR_CONTENT_MODEL"
});

import React from "react";
import LinkIcon from "@material-ui/icons/Link";
import {IconButton} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import styled from "styled-components";
import {FullFile} from "../../../../model/FullMediaComponents";
import {useSelector} from "react-redux";

interface Props {
    fullFile: FullFile,
    removeFullFile: () => void
}

export const FullFileLink = ({fullFile, removeFullFile} : Props) => {

    // const { contentId } = useSelector(state => state.transient.fullMediaUploadingReducer);

    const openInNewTab = () => {
        const url = fullFile.id ? process.env.REACT_APP_API_URL + '/media/stream/' + fullFile.id + "?fullMedia=true" : fullFile.url;

        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) {
            newWindow.opener = null;
        }
    }

    return (
        <DataContainer>
            <LinkContainer onClick={() => openInNewTab()}>
                <LinkIcon style={{color: '#2196f3'}}/>
                <FileName>{fullFile.name}</FileName>
            </LinkContainer>
            <Delete>
                <IconButton
                    color="secondary"
                    onClick={() => removeFullFile()}
                >
                    <DeleteForeverIcon />
                </IconButton>
            </Delete>

        </DataContainer>
    )
}

const DataContainer = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Delete = styled.div`
  cursor: pointer;
  margin-left: 25px;
`;

const LinkContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
`;

const FileName = styled.span`
  color: #2196f3;
  cursor: pointer;
  margin-left: 15px;
`;


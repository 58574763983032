import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import {ResultRowsImported} from "../../../../model/ImportResults";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

interface Props {
    resultImport: ResultRowsImported;
    open: boolean;
    handleClose: () => void;
}

export const ImportRecipeResultModal = ({resultImport, handleClose, open}: Props) => {

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={() => handleClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">Import Result</DialogTitle>
                    <DialogContent>
                        <div style={{minWidth: "80%"}}>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell># Row</TableCell>
                                            <TableCell align="left">Name</TableCell>
                                            <TableCell align="left">Status</TableCell>
                                            <TableCell align="left">Comments</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            !resultImport.rows ?
                                                null
                                            :
                                                resultImport.rows.map(row => (
                                                    <TableRow key={row.row_number}>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            style={{width: "17%"}}
                                                        >
                                                            {row.row_number}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            style={{width: "29%"}}
                                                        >
                                                            {row.row_name}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            style={{width: "5%"}}
                                                        >
                                                            {
                                                                row.row_status_ok ?
                                                                    <CheckIcon />
                                                                    :
                                                                    <ErrorIcon />
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            style={{width: "49%"}}
                                                        >
                                                            {row.row_message}
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()} color="primary" autoFocus>
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )

}

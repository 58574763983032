import { failDCHImages, updateDCHImages } from "../../store/actions/DCHImageAction";
import { errorDrinkShot, errorLifeStyle, updateDrinkShotFromJanus, updateLifeStyleFromJanus, updateUploadingDrinkShotFromJanus, updateUploadingLifeStyleFromJanus } from "../../store/actions/fileActions";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { DCHImagesResponse, DCHImagesResponseCodec } from "../types/DCHImagesResponseCodec";
import { ImageResponse, ImageResponseCodec, UploadingImage, uploadingImageResponse } from "../types/ImageResponseCodec";

export const getDCHImages = (text: string | null, page: number): ServiceCallAction<DCHImagesResponse> => ({
	method: "GET",
	endpoint: `diageo-content-hub/retrieve/recipe`,
    serviceKey: "getDCHImages",
    header: { qtext: text, page: page, limit: 12},
	responseDecoder: DCHImagesResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateDCHImages,
	onFailure: failDCHImages
});

export const importImage = (id: number, formKey: "drinkShot" | "lifeStyle", maxSize: string): ServiceCallAction<uploadingImageResponse> => ({
	method: "POST",
	endpoint: `recipe/import/${id}`,
	body: {type: 'application/json', value: {maxSize}},
    serviceKey: "importImage",
	responseDecoder: UploadingImage,
	type: "SERVICE_CALL",
	onSuccess: formKey === "drinkShot" ? updateUploadingDrinkShotFromJanus : updateUploadingLifeStyleFromJanus,
	onFailure: formKey === "drinkShot" ? errorDrinkShot : errorLifeStyle,
});

export const getJanusImage = (id: string, formKey: string): ServiceCallAction<ImageResponse> => ({
	method: "GET",
	endpoint: `recipe/janus-image/${id}`,
    serviceKey: "getDCHImage",
	responseDecoder: ImageResponseCodec,
	type: "SERVICE_CALL",
	// onSuccess: (data: ImageResponse) => {
	// 	if (!data.asset) {
	// 		return formKey === "drinkShot" ? updateUploadingDrinkShotFromJanus({id: data.id}) : updateUploadingLifeStyleFromJanus({id: data.id});
	// 	} else {
	// 		return formKey === "drinkShot" && data.asset ? updateDrinkShotFromJanus({asset: data.asset}) : updateLifeStyleFromJanus({asset: data.asset})
	// 	}
	//
	// },
	// onFailure: formKey === "drinkShot" ? errorDrinkShot : errorLifeStyle,
});


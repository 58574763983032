import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PreviewFileButton } from "./PreviewFileButton";
import { FullFileData } from "../../../../model/FullMediaComponents";
import { _postFullMediaMultiPart } from "../../../../services/actions/FullMediaService";
import { isRight } from "fp-ts/These";
import { addErrorUploadingFullMediaAction, addPreviewAction } from "../../../../store/actions/FullMediaUploadingActions";
import { ProgressPreviewFile } from "./ProgressPreviewFile";
import { ProgressFromOriginalFile } from "../UploadFile/ProgressFromOriginalFile";
import { FullFileLink } from "../UploadFile/FullFileLink";
import { removePreviewDataAction } from "../../../../store/actions/FullMediaDataActions";
import {AutogeneratePreviewButton} from "./AutogeneratePreviewButton";
import styled from "styled-components";
import {ProgressAutogeneratePreview} from "./ProgressAutogeneratePreview";

export const UploadPreviewFile = () => {

    const dispatch = useDispatch();
    const { canAddPreview, addPreview, clear }  = useSelector(state => state.transient.fullMediaUploadingReducer);
    const { preview } = useSelector(state => state.transient.fullMediaDataReducer);
    const [ fullFileData, setFullFileData ] = useState<FullFileData>({} as FullFileData);
    const [ file, setFile ] = useState<File>();
    const [ previewId, setPreviewId ] = useState('');
    const [ autogenerate, setAutogenerate ] = useState({
        autogenerate: false,
        startTime: '00:00:00.000',
        duration: 1
    });

    const uploadFile = async () => {

        const resp = await dispatch(_postFullMediaMultiPart(fullFileData));
        if (!isRight(resp)) {
            setFullFileData({} as FullFileData);
            removePreview();
            dispatch(addErrorUploadingFullMediaAction('Error uploading file'));
            return;
        }

        setPreviewId(resp.right.id);

    }

    const renderProgress = () => {
        if (addPreview) {
            return <ProgressFromOriginalFile typeFile={'preview'}/>;
        } else if (autogenerate.autogenerate) {
            return <ProgressAutogeneratePreview startTime={autogenerate.startTime} duration={autogenerate.duration} handleError={removePreview} />
        } else if (file) {
            return <ProgressPreviewFile file={file} previewId={previewId} />;
        }
    }

    const renderAutogenerateButton = () => {
        if (canAddPreview) {
            return (
                <AutoGenerateContainer>
                    <AutogeneratePreviewButton setAutogenerate={setAutogenerate}/>
                </AutoGenerateContainer>
            )
        }
        return null;
    }

    const isUploadingPreview = (): boolean => {
        return !!file || addPreview || autogenerate.autogenerate;
    }

    const removePreview = () => {
        dispatch(removePreviewDataAction());
        dispatch(addPreviewAction(false));
        setFile(undefined);
        setAutogenerate({
            autogenerate: false,
            startTime: '00:00:00.000',
            duration: 1
        });
    }


    useEffect(() => {
        if ( fullFileData.filename ) {
            uploadFile();
        }
    }, [fullFileData])

    useEffect(() => {
        if (clear) {
            setFile(undefined);
        }
    }, [clear]);


    return (
        <>
            {
                preview
                    ? <FullFileLink fullFile={preview} removeFullFile={removePreview} />
                    : (
                        <>
                            {
                                isUploadingPreview()
                                    ? renderProgress()
                                    : (
                                        <ButtonContainer>
                                            <PreviewFileButton setFullFileData={setFullFileData} setFile={setFile} />
                                            { renderAutogenerateButton() }
                                        </ButtonContainer>
                                      )
                            }
                        </>
                    )
            }
        </>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const AutoGenerateContainer = styled.div`
    margin-left: 10px;
`;

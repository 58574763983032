import { LoginActions, AuthActions } from "../reducers/AuthReducer";


export const sendEmail = (resp: any): LoginActions => ({
	type: "SEND_EMAIL"
});

export const updateToken = (token: {token: string}): AuthActions => ({
	type: "UPDATE_TOKEN",
	token: token.token
});

export const updateUid = (data: {email: string, firstName: string, lastName: string, apps: any}): AuthActions => {
	const role = data.apps && data.apps.recipes && data.apps.recipes['nam-tsp'] && data.apps.recipes['nam-tsp']['role'] ? 
					data.apps.recipes['nam-tsp']['role'] : undefined;
	const superAdmin = data.apps && data.apps.recipes && data.apps.recipes.superadmin;
	return ({
		type: "UPDATE_UID",
		uid: data.email,
		user: data.lastName.trim() + ', ' + data.firstName.trim(),
		admin: superAdmin || (role && role == 'admin')
	})
};

export const failLogin = (res: any): AuthActions => ({
	type: "FAIL_LOGIN"
});

export const clearLogin = (): AuthActions => ({
	type: "CLEAR_LOGIN"
});
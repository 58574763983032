import { Box, Checkbox, FormControlLabel, Grid, IconButton, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

interface NewTagsProps {
    formName: string;
    label: string;
};

const renderNewCheckbox = ({ input}: any) => {
    return (
        <Grid item xs={4}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={input.value.checked}
                        onChange={(event) => {
                            const newValue = {
                                name: input.value.name,
                                checked: event.target.checked
                            };
                            return input.onChange(newValue);
                        }}
                    />
                }
                label={input.value.name}
            />
        </Grid>
    )
}

const renderCheckboxGroup = ({ fields, meta: { error }, description, setDescription, addLabel, formName}: any) => {
    const addNewTag = (fields: any[]) => {
        if (description.length > 0 ) {
            fields.push({name: description, checked: true});
            setDescription('');
        }  
    };

    return (
        <Grid container item xs={12} spacing={3}>

            <Grid item xs={12}>
                    <Box display="flex" flexDirection="row">
                        <TextField
                            id="ingredient-description"
                            label={addLabel}
                            color="primary"
                            value={description}
                            fullWidth
                            onChange={e => setDescription(e.target.value)}
                        />
                        <IconButton aria-label="add" color="primary" onClick={() => addNewTag(fields)}>
                            <AddCircleOutlineIcon fontSize="large" />
                        </IconButton>
                    </Box>
            </Grid>
            
            {fields.map((newTag: any, index: number) => (
                <Field
                    key={`${formName}-${index}`}
                    name={newTag}
                    component={renderNewCheckbox}
                />
            ))}

        </Grid>
    )
}

const NewTag = ({formName, label}: NewTagsProps) => {
    
    const [description, setDescription] = useState('');
    
    return (
        <div style={{width: "100%"}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FieldArray name={formName} component={renderCheckboxGroup} description={description} setDescription={setDescription} addLabel={label} formName={formName}/>
                </Grid>
            </Grid>
        </div>
    );
    
};

export default NewTag;
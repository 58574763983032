import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { FIELD_DELIMITER, FILE_TYPE } from '../../../services/types/util/Constants';
import RecipeActions from '../basicComponents/RecipeActions';
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import { ElementRecipe } from '../../../model/ElementRecipe';
import { removeTag, updateFilterName } from '../../../store/actions/FilterTagActions';
import CloseIcon from '@material-ui/icons/Close';

interface DashboardFilterProps {
    showAdminOptions: boolean;
    handleBlukDraft: () => void;
    handleBlukArchive: () => void;
    handleExportRecipes: (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => void,
    handleExportAllRecipes?: (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => void,
    sortOptions: any[];
    handleChangeSortOption: (option: number) => void;
}

const DashboardFilter = ({showAdminOptions, handleBlukDraft, handleBlukArchive, handleExportRecipes, handleExportAllRecipes, sortOptions, handleChangeSortOption}: DashboardFilterProps) => {
    const [sortOption, setSortOption] = useState(0);
    const { tags, filterName } = useSelector(state => state.transient.filterTags);
    const dispatch = useDispatch();

    const handlerCloseFilter = (tag: ElementRecipe, tagKey: string) => {
        dispatch(removeTag(tag, tagKey));
    }

    const handlerClearRecipeNameFilter = () => {
        dispatch(updateFilterName(''));
    }

    const renderTags = () => {
        const selectedTags = [];
        for (let tagKey in tags) {
            for (let tagId in tags[tagKey]) {
                selectedTags.push((
                    <Tag key={tagId}>
                        <span style={{whiteSpace: "nowrap"}}>
                            {tags[tagKey][tagId].name}
                        </span>
                        <IconButton
                            aria-label="delete" 
                            size="small" 
                            onClick={e => handlerCloseFilter(tags[tagKey][tagId], tagKey)}
                        >
                            <CloseIcon fontSize="small" style={{color: 'white'}}/>
                        </IconButton>
                    </Tag>
                ));
            }
        }
        if (filterName && filterName.length > 0) {
            selectedTags.push((
                <Tag key={filterName}>
                    <span style={{whiteSpace: "nowrap"}}>
                        {filterName}
                    </span>
                    <IconButton 
                        aria-label="delete" 
                        size="small" 
                        onClick={e => handlerClearRecipeNameFilter()}
                    >
                        <CloseIcon fontSize="small" style={{color: 'white'}}/>
                    </IconButton>
                </Tag>
            ));
        }
        return selectedTags;
    }

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSortOption(event.target.value as number);        
        handleChangeSortOption(event.target.value as number);
    };

    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mt={4} mb={4}>
            <Box display="flex" flexDirection="row" alignItems="baseline">
                <TitleFilter>Filtered by:</TitleFilter>
                <TagContainer>
                    {renderTags()}
                </TagContainer>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <FormControl variant="outlined" style={{width: '322px', margin: '0 20px'}} size="small">
                    <InputLabel id="demo-simple-select-outlined-label">SORT BY</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={sortOption}
                        onChange={handleChange}
                        label="SORT BY"
                        fullWidth
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                            }}
                    >
                        {
                            sortOptions.map(option => {
                                return <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                <RecipeActions
                    showAdminOptions={showAdminOptions} 
                    showExportOptions={true}
                    showImportOptions={false}
                    showPublishOption={false}
                    showExportAllOptions={true}
                    handlerMoveToDraft={handleBlukDraft}
                    handlerArchiveSelected={handleBlukArchive}
                    handleExportRecipes={handleExportRecipes}
                    handleExportAllRecipes={handleExportAllRecipes}
                />
            </Box>
        </Box>
    )
};

const TitleFilter = styled.span`
    padding-right: 5px;
    white-space: nowrap;
`;

const TagContainer = styled.div`
    overflow-x: auto;
    display: flex;
    max-width: 800px;
`

const Tag = styled.div`
    background-color: #0c5d8e;
    color: white;
    padding: 2px 10px 2px 10px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    margin-left: 5px;
`;

export default DashboardFilter
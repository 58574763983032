import { Checkbox, FormControlLabel, Box } from '@material-ui/core';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ElementRecipe } from '../../../model/ElementRecipe';
import { removeTag, updateTags } from '../../../store/actions/FilterTagActions';

interface TagMenuProps {
    tag: ElementRecipe;
    tagKey: string;
}

const TagMenu = ({tag, tagKey} : TagMenuProps) => {
    const [selected, setSelected] = useState(false);
    const dispatch = useDispatch();
    const { tags } = useSelector(state => state.transient.filterTags);

    useEffect(() => {
        const isSelected = tags[tagKey] && tags[tagKey][tag._id];
        setSelected(!!isSelected);
    }, [tags]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelected(event.target.checked);
        if (event.target.checked) {
            dispatch(updateTags(tag, tagKey));
        } else {
            dispatch(removeTag(tag, tagKey));
        }
    }

    return (
        <Box mt={1} mb={1}>
            <FormControlLabel
                control={<Checkbox checked={selected} onChange={handleChange} name={tag.name} />}
                label={tag.name}
            />
        </Box>

    );
};

export default TagMenu;
import queryString from "query-string";

interface SearchParam {
	[param: string]: any;
}
export const parseSearch = <T extends SearchParam>(search: string): T => {
	let result: SearchParam = {};
	const parsed = queryString.parse(search, { parseNumbers: true });

	Object.keys(parsed).forEach(key => {
		const value = parsed[key];
		result[key] = typeof value === "string" || typeof value === "number" ? value : null;
	});
	return result as T;
};
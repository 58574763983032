import {FileImage, JanusFile} from '../../model/FileImage';
import { FileImagesActions } from '../reducers/fileReducer';

export const updateLifeStyleFromJanus = (fileImage: {asset: JanusFile | null}): FileImagesActions => ({
	type: "UPDATE_LIFESTYLE",
	fileImage: {
		file: null,
		DCHImage: null,
		janusFile: fileImage.asset ? fileImage.asset : null
	}
});

export const updateDrinkShotFromJanus = (fileImage: {asset: JanusFile | null}): FileImagesActions => ({
	type: "UPDATE_DRINKSHOT",
	fileImage: {
		file: null,
		DCHImage: null,
		janusFile: fileImage.asset ? fileImage.asset : null
	}
});

export const updateUploadingDrinkShotFromJanus = (result: {id: string}): FileImagesActions => ({
	type: "UPDATE_UPLOADING_DRINKSHOT",
	id: result.id
});

export const updateUploadingLifeStyleFromJanus = (result: {id: string}): FileImagesActions => ({
	type: "UPDATE_UPLOADING_LIFESTYLE",
	id: result.id
});

export const updateLifeStyle = (fileImage: FileImage): FileImagesActions => ({
	type: "UPDATE_LIFESTYLE",
	fileImage
});

export const removeLifeStyle = (): FileImagesActions => ({
	type: "REMOVE_LIFESTYLE"
});

export const updateDrinkShot = (fileImage: FileImage): FileImagesActions => ({
	type: "UPDATE_DRINKSHOT",
	fileImage
});

export const removeDrinkShot = (): FileImagesActions => ({
	type: "REMOVE_DRINKSHOT"
});

export const errorDrinkShot = (): FileImagesActions => ({
	type: "ERROR_DRINKSHOT"
});

export const errorLifeStyle = (): FileImagesActions => ({
	type: "ERROR_LIFESTYLE"
});

export const clearFiles = (): FileImagesActions => ({
	type: "CLEAR_IMAGES_FILES"
});
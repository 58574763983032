import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useAutogenerateFile} from "../../../hooks/useAutogenerateFile";
import { LinearProgress} from "@material-ui/core";
import styled from "styled-components";

interface Props {
    typeFile: 'preview' | 'thumbnail'
}

export const ProgressFromOriginalFile = ( { typeFile }: Props ) => {

    const { fullFile }  = useSelector(state => state.transient.fullMediaDataReducer);

    const { checkAutogenerateFile } = useAutogenerateFile(typeFile);


    useEffect(() => {

        if (fullFile) {
            checkAutogenerateFile(fullFile.id);
        }

    }, [fullFile])

    return (
        <>
            <PercentText>Generating...</PercentText>
            <LinearProgress />
        </>
    )
}


const PercentText = styled.span`
    margin: 10px 0;
    font-weight: bold;
`;

import {ContentModel} from "../../model/ContentModel";
import {Reducer} from "redux";
import {FullMedia, getDefaultFullMedia} from "../../model/FullMedia";

export interface ContentState {
  content: FullMedia;
  errorToUpdate: boolean;
  contentSaved: boolean
  existingContent: boolean;
}

const defaultState: ContentState = {
  content: getDefaultFullMedia(),
  errorToUpdate: false,
  contentSaved: false,
  existingContent: false
};

export const ContentReducer: Reducer<ContentState, ContentActions> = (
  state: ContentState = defaultState,
  action: ContentActions
) => {
  switch (action.type) {
    case "SAVE_CONTENT":
      return {content: action.content, errorToUpdate: false, contentSaved: true, existingContent: false};
    case "REPLACE_CONTENT":
      return {content: action.content, errorToUpdate: false, contentSaved: true, existingContent: true};
    case "CLEAR_CONTENT":
      return defaultState;
    case "UPDATE_CONTENT":
      return {content: action.content, errorToUpdate: false, contentSaved: false, existingContent: true};
    case "SAVE_IN_LIVE":
      if (action.updatedContentsCount > 0) {
        let contentInLive = {...state.content};
        contentInLive.status = 'Live';
        return {...state, content: contentInLive, contentSaved: true};
      } else {
        return {...state, errorToUpdate: true};
      }
    default:
      return state;
  }
};

export type ContentActions =
  | {
  type: "SAVE_CONTENT";
  content: FullMedia;
} | {
  type: "REPLACE_CONTENT";
  content: FullMedia;
} | {
  type: "UPDATE_CONTENT";
  content: FullMedia;
} | {
  type: "CLEAR_CONTENT"
} | {
  type: "SAVE_IN_LIVE";
  updatedContentsCount: number;
  rejectedContentsCount: number;
  uuid: string;
}

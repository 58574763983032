import { Grid } from '@material-ui/core';
import React from 'react';
import { ElementRecipe } from '../../../model/ElementRecipe';
import styled from "styled-components";

interface TagsProps {
    tags: string[];
    allTags: ElementRecipe[];
    customTags: {name: string, checked: boolean}[];
    width?: 1 | 2 | 4 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const Tags = ({tags, allTags, customTags, width = 4}: TagsProps) => {

    const renderTags = () => {
        return tags.map(tag => {
            const selectedTag = allTags.find(selectedTag => selectedTag._id === tag);
            return (
                <Grid item xs={width} key={tag}>
                    <Tag>{selectedTag?.name}</Tag>
                </Grid>
            );
        });
    }

    const renderCustomTags = () => {
        return customTags.filter(tag => tag.checked).map((tag, index) => {
            return (
                <Grid item xs={width} key={index}>
                    <Tag>{tag.name}</Tag>
                </Grid>
            );
        });
    }

    const renderAllTags = () => {
        if ((tags && tags.length > 0 ) || (customTags && customTags.length > 0)) {
            return (
                <Grid container item xs={12} spacing={3}>
                    {renderTags()}
                    {renderCustomTags()}
                </Grid>
            )
        }
        return <Text>None</Text>;
    }

    return (
        <div>
            {renderAllTags()}
        </div>
    );

}

const Text = styled.span`
    color: #a2a2a2;
    font-size: 18px;
    padding-right: 5px;
    font-family: sans-serif;
`;

const Tag = styled.span`
    background-color: #0c5d8e;
    color: white;
    padding: 10px 29px 10px 10px;
    border-radius: 9px;
    display: -webkit-inline-box;
`;

export default Tags;
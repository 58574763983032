import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import styled from "styled-components";
import CancelSharpIcon from '@material-ui/icons/CancelSharp';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ImageModal = ({open, onClose, url}: any) => {

    return (
      <div>
        <Dialog 
            fullScreen 
            open={open} 
            onClose={onClose} 
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              }}
        >
        
          <ImageContainer src={url}/>

          <CloseButton >
            <IconButton color="primary" onClick={onClose}>
                <CancelSharpIcon style={{ fontSize: 60 }} />
            </IconButton>
          </CloseButton>
        </Dialog>
      </div>
    );  
};

const CloseButton = styled.div`
    position: fixed;
    top: 64px;
    right: 50px;
`;

const ImageContainer = styled.img`
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  vertical-align: middle;
`;

export default ImageModal;
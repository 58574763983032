import * as t from "io-ts";

export const ContentCodec = t.strict({
  content: t.strict({
    _id: t.string,
    name: t.string,
    slug: t.string,
    status: t.string,
    uuid: t.string,
    version: t.number,
    createdByUser: t.string,
    author: t.string,
    createdAt: t.string,
    updatedAt: t.strict({
      title: t.string,
      textToShow: t.string,
      raw: t.string
    }),
    elements: t.array(t.strict({
      _id: t.string,
      name: t.string,
      datatype: t.string,
      data: t.strict({
        value: t.unknown,
        image: t.union([
          t.null,
          t.undefined,
          t.strict({
            url: t.string,
            name: t.string,
            mimeType: t.union([t.undefined, t.string])
          })
        ])
      })
    }))
  })
});

export const ContentUpdateCodec = t.strict({
  updatedContentsCount: t.number,
  rejectedContentsCount: t.number
});

export type ContentResponse = t.OutputOf<typeof ContentCodec>;
export type ContentUpdateResponse = t.OutputOf<typeof ContentUpdateCodec>;

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface BasicDialogProps {
    open: boolean;
    handleClose: (_: boolean) => void;
    title?: string;
    description?: string[];
    okButton: string | null;
    cancelButton: string | null;
};

const BasicDialog = ({open, handleClose, title, description, okButton, cancelButton }: BasicDialogProps) => {
    return (
        <div>
          <Dialog
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>

              {
                description ?
                  description.map((desc, index) => {
                    return (
                      <DialogContentText id="alert-dialog-description" key={index}>
                        {desc}
                      </DialogContentText>
                    );
                  })
                :
                  null
              }
            </DialogContent>
            <DialogActions>
              {
                cancelButton ?
                  <Button onClick={() => handleClose(false)} color="primary">
                    {cancelButton}
                  </Button>
                :
                  null
              }
              <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                {okButton}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );

};

export default BasicDialog;

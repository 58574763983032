import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, TablePagination} from '@material-ui/core';
import { RecipeFirstTitle } from '../RecipeCreate/RecipeTitles';
import { useDispatch, useSelector } from 'react-redux';
import { clearAllTags } from '../../../store/actions/FilterTagActions';
import { getMedia } from '../../../services/actions/MediaService';
import { buildMediaQuery } from './BuildMediaQuery';
import { FILE_TYPE, FIELD_DELIMITER, SORT_BY, SORT_BY_ELEMENT, SORT_ORDER } from '../../../services/types/util/Constants';
import MediaComponent from './MediaComponent';
import { JanusFile } from '../../../model/FileImage';
import MediaDetail from './MediaDetail';
import { getRecipe } from '../../../services/actions/RecipesService';
import { LoadRecipeDetail, RecipeDetail } from '../../../model/Recipe';
import RecipeDetailForm from '../Recipe/RecipeDetailForm';
import { clearRecipe } from '../../../store/actions/recipeActions';
import { clearMedia } from '../../../store/actions/MediaActions';
import styled from "styled-components";
import { Pagination, Skeleton } from '@material-ui/lab';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import DashboardFilter from '../Dashboard/DashboardFilter';
import { getBrands, getVariants } from '../../../services/actions/ElementsRecipeService';
import { exportMedia, exportRecipes } from '../../../services/actions/ImportExportService';

const sortOptions = [
    {value: 0, name: "None"},
    {value: 1, name: "Image Name (A - Z)"},
    {value: 2, name: "Image Name (Z - A)"},
    {value: 3, name: "Brand (A - Z)"},
    {value: 4, name: "Brand (Z - A)"}
]

const MediaLibrary = () => {
    const [rowsPerPage, setRowsPerPage] = useState(12);
    const [imagesToExport, setImagesToExport] = useState<string[]>([]);
    const { filterTags, media } = useSelector(state => state.transient);
    const [ sortBy, setSortBy ] = useState<SORT_BY>();
    const [ sortOrder, setSortOrder ] = useState<SORT_ORDER>('asc');
    const [ mediaComponents, setMediaComponents] = useState<JanusFile[]>([]);
    const [ mediaSelected, setMediaSelected ] = useState<JanusFile>();
    const [selectedRecipe, setSelectedRecipe] = useState<string | null>(null);
    const [ recipeDetail, setRecipeDetail ] = useState<RecipeDetail | null>(null);
    const { recipe, error } = useSelector(state => state.transient.recipe);
    const [ loading, setLoading ] = useState(false);
    const dispatch = useDispatch();

    const getQuery = () => {
        return buildMediaQuery(filterTags, sortBy, undefined, sortOrder);
    }

    const loadingData = () => {
        setMediaSelected(undefined);
        setImagesToExport([]);
        setLoading(true);
    }

    const onChangePage = (event: unknown, newPage: number) => {
        dispatch(getMedia(newPage + 1, rowsPerPage, getQuery()));
        loadingData();
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        dispatch(getMedia(1, parseInt(event.target.value), getQuery()));
        loadingData();
    };

    useEffect(() => {
        dispatch(getMedia(1, rowsPerPage, getQuery()));
        loadingData();
    }, [filterTags]);

    useEffect(() => {
        dispatch(getMedia(media.currentpage, rowsPerPage, getQuery()));
        loadingData();
    }, [sortOrder, sortBy]);

    useEffect(() => {
        if (recipe) {
            const recipeData: RecipeDetail = LoadRecipeDetail(recipe);
            setRecipeDetail(recipeData);
        }
        if (error) {
            setSelectedRecipe(null);
        }
    }, [recipe, error]);

    useEffect(() => {
        if (selectedRecipe) {
            dispatch(getRecipe(selectedRecipe));
        }
    }, [selectedRecipe])

    useEffect(() => {
        setMediaComponents(media.contents);
        setLoading(false);
    }, [media])

    useEffect(() => {
        dispatch(getMedia(1, rowsPerPage, getQuery()));
        dispatch(getBrands());
        dispatch(getVariants());
        loadingData();
        return () => {
            dispatch(clearAllTags());
            dispatch(clearMedia())
        };
    }, [])

    const openDetail = (media: JanusFile) => {
        setMediaSelected(media);
    }

    const closeDetail = () => {
        setMediaSelected(undefined);
    }

    const closeRecipeDetail = () => {
        setSelectedRecipe(null);
        dispatch(clearRecipe());
        setRecipeDetail(null);
    };

    const getLoadingMedia = () => {
        const loadingMedia = [];
        for (let i=0; i<rowsPerPage ; i++) {
            loadingMedia.push(
                <Grid item xs={3} key={i}>
                    <Skeleton variant="rect" width="100%" height={150} />
                </Grid>
            )
        }    
        return loadingMedia;
    }

    const handleChangeSortOption = (option: number) => {
        switch (option) {
            case 1:
                setSortOrder('asc');
                setSortBy('name');
                break;
            case 2:
                setSortOrder('desc');
                setSortBy('name');
                break;
            case 3:
                setSortOrder('asc');
                setSortBy('brand');
                break;
            case 4:
                setSortOrder('desc');
                setSortBy('variant');
                break;
            default:
                setSortOrder('asc');
                setSortBy(undefined);
                break;
        }
    }

    const handleExportMedia = (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => {
        if (imagesToExport.length === 0) {
            return;
        }
        return  dispatch(exportMedia(imagesToExport, file_type, field_delimiter));
    }

    const handlerImagesToExport = (add: boolean, id: string) => {

        if (!add) {
            const newImages = imagesToExport.filter(idImage => idImage !== id);
            setImagesToExport(newImages);
        } else {
            setImagesToExport([...imagesToExport, id]);
        }
        
    }

    return (
        <Card>
            <CardContent>
                <Box mx="auto" p={4}>

                    {
                        !selectedRecipe || !recipeDetail ?
                            <div>
                                <RecipeFirstTitle title={"Media Library"} />
                                <DashboardFilter
                                    showAdminOptions={false}
                                    handleBlukDraft={() => {}}
                                    handleBlukArchive={() => {}}
                                    handleExportRecipes={handleExportMedia}
                                    sortOptions={sortOptions}
                                    handleChangeSortOption={handleChangeSortOption}
                                />
                                <Box display="flex" flexDirection="row" width="100%">
                                    <Box flex={!!mediaSelected ? "1 0 75%" : "1 0 100%"}>
                                        <Grid container spacing={3}>
                                            {
                                                loading ?
                                                    getLoadingMedia()
                                                :
                                                    mediaComponents.map(component => {
                                                        return (
                                                            <Grid item xs={3} key={component._id}>
                                                                <MediaComponent 
                                                                    media={component}
                                                                    setMediaSelected={openDetail}
                                                                    imageSelected={mediaSelected}
                                                                    handlerImagesToExport={handlerImagesToExport}
                                                                />
                                                            </Grid>
                                                        );
                                                    })
                                            }
                                        </Grid>
                                        <TablePagination
                                            rowsPerPageOptions={[12, 24, 48]}
                                            component="div"
                                            count={media.totalcount}
                                            rowsPerPage={rowsPerPage}
                                            labelRowsPerPage={"Items per page:"}
                                            page={media.currentpage - 1}
                                            onChangePage={onChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </Box>
                                    {
                                        !!mediaSelected ? 
                                            <Box flex="1 0 75%">
                                                <MediaDetail 
                                                    mediaSelected={mediaSelected}
                                                    closeDetail={closeDetail}
                                                    setSelectedRecipe={setSelectedRecipe}
                                                />
                                            </Box>
                                            
                                        :
                                            null
                                    }   
                                </Box>
                            </div>
                        :
                            <RecipeDetailForm 
                                showHeader={true} 
                                recipeInformation={recipeDetail} 
                                goBack={closeRecipeDetail}
                                canPublish={false}
                            />
                    }
                </Box>
            </CardContent>
        </Card>
    );
};


const PaginationContainer = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    :& > *: {
        margin-top: 2px;
    }
`;

export default MediaLibrary;
import React from 'react';
import { JanusFile } from '../../../model/FileImage';
import styled from "styled-components";
import { getIconFromURL } from '../../../services/types/util/IconImage';
import { Box, Checkbox } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface MediaComponentProps {
    media: JanusFile;
    imageSelected?: JanusFile;
    setMediaSelected: (media: JanusFile) => void;
    handlerImagesToExport: (add: boolean, id: string) => void;
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const MediaComponent = ({media, setMediaSelected, imageSelected, handlerImagesToExport}: MediaComponentProps) => {

    const Selected = imageSelected && imageSelected._id === media._id ? ImageContainerSelected : ImageContainer2;
    
    const getURL = (): string => {
        return process.env.REACT_APP_API_URL + '/media/stream/' + media._id;
        //return media.metadata.thumbnail ? media.metadata.thumbnail : media.url;
    }

    const addDefaultSrc = (ev: any) => {
        ev.target.src = '/assets/extensions/_blank.png'
    }

    const getStatus = () => {
        switch (media.status) {
            case 'broken':
                return <Broken />
            case 'dch': 
                return <DCH />
            default: 
                return <Local />
        }
    }

    const getMediaName = () => {
        if (media.name.length === 0) {
            return 'no name'
        }
        return media.name.length > 15 ? media.name.slice(0, 15) + '...' : media.name;
    }

    const getUploadDate = () => {
        let uploadDate = new Date(media.uploadDate);
        let month = monthNames[uploadDate.getMonth()];
        return month + ' ' + uploadDate.getDate().toString() + ', ' + uploadDate.getFullYear().toString();
    }

    const [selectedValue, setSelectedValue] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handlerImagesToExport(event.target.checked, media._id);
        setSelectedValue(!selectedValue);
    };


    return (
        <Box display="flex" flexDirection="column">
            <ImageContainer1>
                <Selected >
                    <Status>
                        <Checkbox 
                            icon={<RadioButtonUncheckedIcon />} 
                            checkedIcon={<FiberManualRecordIcon />} 
                            name="checkedH" 
                            color="primary"
                            onChange={handleChange}
                            checked={selectedValue}
                        />
                    </Status>
                    <ImageContainer3>
                        <ImageContainer4>
                            <Image onError={addDefaultSrc} src={getURL()} alt="recipe" onClick={e => setMediaSelected(media)}/>                       
                            <IconImage src={getIconFromURL(media.name)}/>
                        </ImageContainer4>
                    </ImageContainer3>
                </Selected>
            </ImageContainer1>
            <Box display="flex" flexDirection="column" alignItems="center" mt={1.5}>
                <MediaInfo>
                    <Title>Name:</Title>
                    <Text>{getMediaName()}</Text>
                </MediaInfo>
                <MediaInfo>
                    <Title>Uploaded:</Title>
                    <Text>{getUploadDate()}</Text>
                </MediaInfo>
            </Box>
        </Box>
    );
};

const MediaInfo = styled.div`
    display: flex;
    margin-top: 5px;
`;

const Title = styled.span`
    font-size: 12px;
    width: 50%;
    text-align: right;
`;

const Text = styled.span`
    font-size: 12px;
    width: 50%;
    text-align: left;
    margin-left: 5px;
    color: #536171;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Image = styled.img`
    cursor: pointer;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

const Status = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    padding-top: 10px;
    position: absolute;
    z-index: 1;
    right: 0;
`;

const ImageContainer4 = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
`;

const ImageContainer1 = styled.div`
    position: relative;
    margin: 0 auto 0 auto;
    width: 100%;
    max-width: 80%;
`;

const ImageContainer2 = styled.div`
    border: 2px solid #34495e;
    border-radius: 5px;
    position: relative;
`;

const ImageContainerSelected = styled(ImageContainer2)`
    border: 2px solid #47a8ec;
`;

const ImageContainer3 = styled.div`
    height: 150px;
`;

const ImageName = styled.span`
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    color: #536171;
    display: block;
    height: 37px;
    margin-top: 10px;
`;
const IconImage = styled.img`
    position: absolute;
    z-index: 1;
    width: 100px;
    right: -25px;
    bottom: -15px;
`;

const DCH = styled.span`
    height: 21px;
    width: 21px;
    background-color: #93f362;
    border-radius: 50%;
    display: inline-block;
    border:1px solid black;
`;

const Broken = styled.span`
    height: 21px;
    width: 21px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    border:1px solid black;
`;

const Local = styled.span`
    height: 21px;
    width: 21px;
    background-color: #ece8e8;
    border-radius: 50%;
    display: inline-block;
    border:1px solid black;
`;

export default MediaComponent;
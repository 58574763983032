import { StatusUpdateResponse } from "../../services/types/StatusRecipeCodec";
import { StatusUpdateActions } from "../reducers/StatusReducer";

export const updateStatusAction = (status: StatusUpdateResponse): StatusUpdateActions => ({
	type: "UPDATE_STATUS",
	rejectedContentsCount: status.rejectedContentsCount
});

export const failUpdateStatus = (): StatusUpdateActions => ({
	type: "FAIL_UPDATE_STATUS"
});

export const clearStatusError = (): StatusUpdateActions => ({
	type: "CLEAR_STATUS_ERROR"
});


import {FullMediaUploadingActions} from "../reducers/FullMediaUploadingReducer";

export const addPreviewAction = (add: boolean): FullMediaUploadingActions => ({
    type: "ADD_PREVIEW",
    add
});

export const addThumbnailAction = (add: boolean): FullMediaUploadingActions => ({
    type: "ADD_THUMBNAIL",
    add
});

export const addThumbnailFromImageAction = (add: boolean): FullMediaUploadingActions => ({
    type: "ADD_THUMBNAIL_FROM_IMAGE",
    add
});

export const addErrorUploadingFullMediaAction = (message: string): FullMediaUploadingActions => ({
    type: "UPLOADING_FULL_MEDIA_ERROR",
    message
});

export const clearFullMediaError = (): FullMediaUploadingActions => ({
    type: "CLEAR_FULL_MEDIA_ERROR"
});

export const addFullMediaIdAction = (id: string): FullMediaUploadingActions => ({
    type: "ADD_FULL_MEDIA_ID",
    id
});

export const canAddPreviewAndThumbnailAction = (canAddThumbnail: boolean, canAddThumbnailFromImage: boolean, canAddPreview: boolean): FullMediaUploadingActions => ({
    type: 'CAN_ADD_PREVIEW_AND_THUMBNAIL',
    canAddThumbnail,
    canAddThumbnailFromImage,
    canAddPreview
});

export const clearAllFullMediaUploading = (): FullMediaUploadingActions => ({
    type: 'CLEAR_ALL_FULL_MEDIA_UPLOADING'
});

export const resetClearAllFullMediaUploading = (): FullMediaUploadingActions => ({
    type: 'RESET_CLEAR_FULL_MEDIA_UPLOADING'
});

export const addContentIdFullMediaUploading = (contentId: string): FullMediaUploadingActions => ({
    type: 'ADD_CONTENT_ID_FULL_MEDIA_UPLOADING',
    contentId
});

export const addVideoMaxTime = (maxTime: string): FullMediaUploadingActions => ({
    type: 'ADD_VIDEO_MAX_TIME',
    maxTime
});

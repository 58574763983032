import * as t from "io-ts";

export const ImageCodec = t.strict({
    _id: t.string,
    url: t.string,
    originalFilename: t.string,
    name: t.string,
    space: t.string,
    metadata: t.type({
        ownerID: t.union([t.string, t.undefined]),
        originalOwner: t.union([t.string, t.undefined]),
        usagerights: t.union([t.string, t.undefined]),
        size: t.union([t.number, t.null, t.undefined]),
        thumbnail: t.union([t.string, t.undefined]),
        height: t.union([t.number, t.undefined]),
        width: t.union([t.number, t.undefined]),
    }),
    uploadDate: t.string,
    broken: t.union([t.boolean, t.undefined]),
    status: t.union([t.string, t.undefined]),
    usedBy: t.union([
        t.array(t.type({
            name: t.string,
            status: t.string,
            _id: t.string
        })),
        t.undefined
    ])
});

export const UploadingImage = t.strict({
    id: t.string
});

export const ImageUpdateResponseCodec = t.strict({
    asset: ImageCodec
});

export const ImageResponseCodec = t.strict({
    id: t.string,
    asset: t.union([ImageCodec, t.null])
});

export const MediaCodec = t.strict({
    contents: t.array(ImageCodec),
    resultlimit: t.number,
    currentpage: t.number,
    totalcount: t.number,
    pagecount: t.number
});

export type MediaResponse = t.OutputOf<typeof MediaCodec>;
export type ImageResponse = t.OutputOf<typeof ImageResponseCodec>;
export type ImageUpdateResponse = t.OutputOf<typeof ImageUpdateResponseCodec>;
export type uploadingImageResponse = t.OutputOf<typeof UploadingImage>;
import { Reducer } from "redux";
import { FileImage } from "../../model/FileImage";

export interface FileImageState {
    drinkShotId: string;
    lifeStyleId: string;
    drinkShot: FileImage | null;
    lifeStyle: FileImage | null;
    errorDrinkshot: string | null,
    errorLifeStyle: string | null,
};

const defaultState: FileImageState = {
    drinkShotId: '',
    lifeStyleId: '',
    drinkShot: null,
    lifeStyle: null,
    errorDrinkshot: null,
    errorLifeStyle: null
};

export const fileImageReducer: Reducer<FileImageState, FileImagesActions> = (
    state: FileImageState = defaultState,
    action: FileImagesActions
) => {
    switch (action.type) {
        case "UPDATE_DRINKSHOT":
            const drinkShot = {
                file: state.drinkShot?.file || action.fileImage.file,
                DCHImage: action.fileImage.DCHImage,
                janusFile: action.fileImage.janusFile
            };
            if (action.fileImage.janusFile) {
                //drinkShot.file = null;
                drinkShot.DCHImage = null;
            }
            return {...state, drinkShot: drinkShot, errorDrinkshot: null};
        case "UPDATE_UPLOADING_DRINKSHOT":
            return {...state, drinkShotId: action.id};
        case "UPDATE_UPLOADING_LIFESTYLE":
            return {...state, lifeStyleId: action.id};
        case "UPDATE_LIFESTYLE":
            const lifeStyle = {
                file: state.lifeStyle?.file || action.fileImage.file,
                DCHImage: action.fileImage.DCHImage,
                janusFile: action.fileImage.janusFile
            };
            if (action.fileImage.janusFile) {
                //lifeStyle.file = null;
                lifeStyle.DCHImage = null;
            }
            return {...state, lifeStyle: lifeStyle, errorLifeStyle: null};
        case "REMOVE_LIFESTYLE":
            return {...state, lifeStyle: null};
        case "REMOVE_DRINKSHOT":
            return {...state, drinkShot: null};
        case "ERROR_DRINKSHOT":
            return {...state, errorDrinkshot: 'error loading drinkshot'};
        case "ERROR_LIFESTYLE":
            return {...state, errorLifeStyle: 'error loading life style'};
        case "CLEAR_IMAGES_FILES":
            return defaultState;
        default:
            return state;
    }
};

export type FileImagesActions = 
| {
    type: "UPDATE_DRINKSHOT";
    fileImage: FileImage;
}
| {
    type: "UPDATE_UPLOADING_DRINKSHOT";
    id: string
}
| {
    type: "UPDATE_LIFESTYLE";
    fileImage: FileImage;
}
| {
    type: "UPDATE_UPLOADING_LIFESTYLE",
    id: string
}
| {
    type: "REMOVE_LIFESTYLE";
}
| {
    type: "REMOVE_DRINKSHOT";
}
| {
    type: "ERROR_DRINKSHOT";
}
| {
    type: "ERROR_LIFESTYLE";
}
| {
    type: "CLEAR_IMAGES_FILES"
};
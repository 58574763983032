import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderTextField } from '../../basicComponents/LocalFields';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import validate from '../Validate';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import UploadImage from '../../basicComponents/uploadImage/UploadImage';
import styled from "styled-components";
import BrandsAndVaiants from './BrandsAndVariants';
import { RecipeTitle } from '../RecipeTitles';
import { Checkbox, Divider, FormControlLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

const renderCheckbox = ({ tags, input}: any) => {
    return tags.map((tag: any, index: number) => {
        return (
            <Grid item xs={2} key={tag._id}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={input.value.indexOf(tag._id) !== -1}
                            onChange={(event) => {
                                const newValue = [...input.value] || [];
                                if (event.target.checked) {
                                    newValue.push(tag._id);
                                } else {
                                    newValue.splice(newValue.indexOf(tag._id), 1);
                                }
                                return input.onChange(newValue);
                            }}
                        />
                    }
                    label={tag.name}
                />
            </Grid>
        )
    });
};

const RecipeCreateFistStep: any = (props: any) => {
    const { handleSubmit } = props;
    const { serveStrategies } = useSelector(state => state.transient);
    const { newRecipe } = useSelector(state => state.transient.newRecipe);


    const renderServeStrategy = () => {
        if (serveStrategies.elementRecipe.length > 0) {
            return <Field name="servinStrategy" component={renderCheckbox} tags={serveStrategies.elementRecipe} />;
        } else {
            return <CircularProgress size={24}/>;
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>

                    <RecipeTitle title={"Recipe Name"} />

                    <BrandsAndVaiants />

                    <Box mt={5}>
                        <Box mb={1} pt={2} pb={2} pl={3} {...defaultProps}>
                            <Grid container>
                                <Grid item xs={12} sm={10}>
                                    <Field
                                        name="recipeName"
                                        component={renderTextField}
                                        label="Recipe Name *"
                                        placeholder=""
                                        fullWidth
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={5}>
                        <Box mb={1} p={1} pb={1} pl={3} {...defaultProps}>
                            <SubTitle>Serving Strategy</SubTitle>
                            <Box display="flex" flexDirection="row">
                                {renderServeStrategy()}
                            </Box>
                        </Box>
                    </Box>

                    <Box mt={5}>

                        <RecipeTitle title={"Media Selection"} />

                        <Box mb={1} p={1} pb={1} pl={3} {...defaultProps}>
                            <SubTitle>Drink Image *</SubTitle>
                            <UploadImage change={props.change} formKey={'drinkShot'} formId={'drinkShotId'} id={newRecipe?._id}/>
                            <SubTitle>Lifestyle Image *</SubTitle>
                            <UploadImage change={props.change} formKey={'lifeStyle'}  formId={'lifeStyleId'} id={newRecipe?._id}/>
                        </Box>

                    </Box>

                </div>

                <Box pt={3}>
                    <Divider />
                </Box>

                <Box display="flex" justifyContent="flex-end" pt={5}>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                        >
                            Next: Ingredients, Serving Size
                        </Button>

                </Box>

            </form>
        </div>
    );
};

const defaultProps = {
    borderLeft: "8px solid #bfbfbfcc",
};

const SubTitle = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;


export default reduxForm({
    form: 'createRecipe',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    initialValues: {servingSize: "1"},
    validate
})(RecipeCreateFistStep);

export interface ElementRecipe {
    _id: string;
    name: string;
    slug: string;
    contentModel_id: string;
    status: string;
    createdByUser: string;
    createdAt: string;
    updatedAt: {
        title: string;
        textToShow: string;
        raw: string;
    };
    elements: any[];
    appid: string;
    uuid: string;
    version: number;
};

export const buildFakeElementRecipe = (id: string, name: string): ElementRecipe =>{
    return {
        _id: id,
        name: name,
        slug: '',
        contentModel_id: '',
        status: '',
        createdByUser: '',
        createdAt: '',
        updatedAt: {
            title: '',
            textToShow: '',
            raw: ''
        },
        elements: [],
        appid: '',
        uuid: '',
        version: 0
    }
}
import { createStore, combineReducers, applyMiddleware, Reducer, CombinedState } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import localStorage from "redux-persist/es/storage";
import { reducer as ReducerForm } from 'redux-form';

import { composeEnhancers } from "./enhancer/devToolsEnhancer";
import { serviceCallMiddleware } from "./middleware/serviceCallMiddleware";
import { newRecipeReducer, recipeReducer, recipesReducer } from "./reducers/recipeReducer";
import { brandReducer, mixerReducer, serveStrategyReducer, variantReducer, occasionReducer, glasswareReducer, complexityReducer, drinkTypesReducer, variantsWithBrandReducer } from "./reducers/elementsRecipeReducer";
import { FilterTagsReducer } from "./reducers/filterTagsReducer"
import { fileImageReducer } from "./reducers/fileReducer";
import { DCHImageReducer } from "./reducers/DCHImageReducer";
import { AuthReducer, LogingReducer } from "./reducers/AuthReducer";
import { LoadFormReducer } from "./reducers/LoadFormReducer";
import { reloadRecipeFormReducer } from "./reducers/RecipeFormReducer";
import { statusUpdateReducer } from "./reducers/StatusReducer";
import { ImportRecipesReducer } from "./reducers/ImportRecipesReducer";
import { CopyRecipeReducer } from "./reducers/CopyRecipeReducer";
import { VariantsTagsReducer } from "./reducers/VariantsTagsReducer";
import { mediaReducer } from "./reducers/MediaReducer";
import { restoreReducer } from "./reducers/RestoreReducer";
import { CreateRecipeReducer } from "./reducers/CreateRecipeReducer";
import {fullMediaReducer} from "./reducers/FullMediaReducer";
import {ContentModelReducer} from "./reducers/ContentModelReducer";
import {ContentReducer} from "./reducers/ContentReducer";
import {fullMediaListReducer} from "./reducers/FullMediaListReducer";
import {segmentFileReducer} from "./reducers/SegmentActionReducer";
import {utilsReducer} from "./reducers/UtilsReducer";
import {fullMediaUploadingReducer} from "./reducers/FullMediaUploadingReducer";
import {fullMediaDataReducer} from "./reducers/FullMediaDataReducer";

declare module "react-redux" {
	interface DefaultRootState extends StoreContent {}
}

type StateOf<T> = T extends Reducer<CombinedState<infer C>, infer A> ? C : never;

const transientReducer = combineReducers({
	recipeCreated: CreateRecipeReducer,
	restore: restoreReducer,
	media: mediaReducer,
	reloadRecipeForm: reloadRecipeFormReducer,
	login: LogingReducer,
	newRecipe: newRecipeReducer,
	copyRecipe: CopyRecipeReducer,
	recipes: recipesReducer,
	recipe: recipeReducer,
	brands: brandReducer,
	variants: variantReducer,
	variantsWithBrand: variantsWithBrandReducer,
	serveStrategies: serveStrategyReducer,
	mixers: mixerReducer,
	occasions: occasionReducer,
	glasswares: glasswareReducer,
	complexities: complexityReducer,
	drinkTypes: drinkTypesReducer,
	fileImages: fileImageReducer,
	dchImages: DCHImageReducer,
	loadFrom: LoadFormReducer,
	filterTags: FilterTagsReducer,
	statusUpdate: statusUpdateReducer,
	recipesImported: ImportRecipesReducer,
	variantsTagsFilter: VariantsTagsReducer,
	fullMedia: fullMediaReducer,
	contentModel: ContentModelReducer,
	contentFullMedia: ContentReducer,
	fullMediaList: fullMediaListReducer,
	segmentFile: segmentFileReducer,
	utils: utilsReducer,
	fullMediaUploadingReducer: fullMediaUploadingReducer,
	fullMediaDataReducer: fullMediaDataReducer
});

const persistentReducer = persistReducer(
	{
		key: "root",
		keyPrefix: "",
		storage: localStorage
	},
	combineReducers({
		auth: AuthReducer,
	})
);

const storeReducer = combineReducers({
	transient: transientReducer,
	persistent: persistentReducer,
	form: ReducerForm
});

const enhancers = composeEnhancers(applyMiddleware(serviceCallMiddleware));

export const store = createStore(storeReducer, undefined, enhancers);

export type StoreContent = StateOf<typeof storeReducer>;

export const persistor = persistStore(store);

import {Reducer} from "redux";

export interface FullMediaUploadingState {
    addPreview: boolean;
    addThumbnail: boolean;
    addThumbnailFromImage: boolean;
    fullMediaIdUploading: string;
    fullMediaLoading: boolean;
    canAddThumbnail: boolean;
    canAddPreview: boolean;
    canAddThumbnailFromImage: boolean;
    error: boolean;
    message: string;
    clear: boolean;
    contentId?: string;
    videoMaxTime: string;
}

const defaultState: FullMediaUploadingState = {
    addPreview: false,
    addThumbnail: false,
    addThumbnailFromImage: false,
    fullMediaIdUploading: '',
    fullMediaLoading: false,
    canAddThumbnail: false,
    canAddPreview: false,
    canAddThumbnailFromImage: false,
    error: false,
    message: '',
    clear: false,
    contentId: undefined,
    videoMaxTime: '00:00:00.000'
};

export const fullMediaUploadingReducer: Reducer<FullMediaUploadingState, FullMediaUploadingActions> = (
    state: FullMediaUploadingState = defaultState,
    action: FullMediaUploadingActions
) => {
    switch (action.type) {
        case "ADD_PREVIEW":
            return {...state, addPreview: action.add};
        case "ADD_THUMBNAIL":
            return {...state, addThumbnail: action.add};
        case "ADD_THUMBNAIL_FROM_IMAGE":
            return {...state, addThumbnailFromImage: action.add};
        case "UPLOADING_FULL_MEDIA_ERROR":
            return {...state, error: true, message: action.message};
        case "CLEAR_FULL_MEDIA_ERROR":
            return {...state, error: false, message: ''};
        case "ADD_FULL_MEDIA_ID":
            return {...state, fullMediaIdUploading: action.id, fullMediaLoading: true};
        case "CAN_ADD_PREVIEW_AND_THUMBNAIL":
            return {...state, canAddPreview: action.canAddPreview, canAddThumbnail: action.canAddThumbnail, canAddThumbnailFromImage: action.canAddThumbnailFromImage};
        case "CLEAR_ALL_FULL_MEDIA_UPLOADING":
            return {
                addPreview: false,
                addThumbnail: false,
                addThumbnailFromImage: false,
                fullMediaIdUploading: '',
                fullMediaLoading: false,
                canAddThumbnail: false,
                canAddPreview: false,
                canAddThumbnailFromImage: false,
                error: false,
                message: '',
                clear: true,
                contentId: undefined,
                videoMaxTime: '00:00:00.000'
            };
        case "RESET_CLEAR_FULL_MEDIA_UPLOADING":
            return {...state, clear: false};
        case "ADD_CONTENT_ID_FULL_MEDIA_UPLOADING":
            return {...state, contentId: action.contentId};
        case "ADD_VIDEO_MAX_TIME":
            return {...state, videoMaxTime: action.maxTime}
        default:
            return state;
    }
}

export type FullMediaUploadingActions =
| {
    type: 'ADD_PREVIEW',
    add: boolean
} | {
    type: 'ADD_THUMBNAIL',
    add: boolean
} | {
    type: 'ADD_THUMBNAIL_FROM_IMAGE',
    add: boolean
} | {
    type: 'UPLOADING_FULL_MEDIA_ERROR',
    message: string
} | {
    type: 'CLEAR_FULL_MEDIA_ERROR',
} | {
    type: 'ADD_FULL_MEDIA_ID',
    id: string
} | {
    type: 'CAN_ADD_PREVIEW_AND_THUMBNAIL',
    canAddThumbnail: boolean,
    canAddThumbnailFromImage: boolean,
    canAddPreview: boolean,
} | {
    type: 'CLEAR_ALL_FULL_MEDIA_UPLOADING'
} | {
    type: 'RESET_CLEAR_FULL_MEDIA_UPLOADING'
} | {
    type: 'ADD_CONTENT_ID_FULL_MEDIA_UPLOADING',
    contentId: string
} | {
    type: 'ADD_VIDEO_MAX_TIME',
    maxTime: string
};

import { Reducer } from "redux";
import { ElementRecipe } from "../../model/ElementRecipe";

interface VariantsTagsState {
    elementRecipe: ElementRecipe[];
}

const defaultState: VariantsTagsState = {
    elementRecipe: []
};

export const VariantsTagsReducer: Reducer<VariantsTagsState, VariantsTagsActions> = (
    state: VariantsTagsState = defaultState,
    action: VariantsTagsActions
) => {
    switch (action.type) {
        case "UPDATE_FILTER_TAG_VARIANTS":
            return {elementRecipe: action.elementRecipe};
        case "CLEAR_FILTER_TAG_VARIANTS":
            return defaultState;
        default:
            return state;
    }
};

export type VariantsTagsActions = 
    | {
        type: "UPDATE_FILTER_TAG_VARIANTS";
        elementRecipe: ElementRecipe[];
    }
    | {
        type: "CLEAR_FILTER_TAG_VARIANTS";
    };
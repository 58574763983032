import {FullFile} from "../../model/FullMediaComponents";
import {FullMediaDataActions} from "../reducers/FullMediaDataReducer";

export const addFullFileDataAction = (fullFile: FullFile): FullMediaDataActions => ({
    type: "ADD_FULL_FILE_DATA",
    fullFile
});

export const addPreviewDataAction = (preview: FullFile): FullMediaDataActions => ({
    type: "ADD_PREVIEW_DATA",
    preview
});

export const addThumbnailDataAction = (thumbnail: FullFile): FullMediaDataActions => ({
    type: "ADD_THUMBNAIL_DATA",
    thumbnail
});

export const removeFullFileDataAction = (): FullMediaDataActions => ({
    type: "REMOVE_FULL_FILE_DATA"
});

export const removePreviewDataAction = (): FullMediaDataActions => ({
    type: "REMOVE_PREVIEW_DATA"
});

export const removeThumbnailDataAction = (): FullMediaDataActions => ({
    type: "REMOVE_THUMBNAIL_DATA"
});


export const clearAllFullMediaDataAction = (): FullMediaDataActions => ({
    type: 'CLEAR_ALL_FULL_MEDIA_DATA'
});


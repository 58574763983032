import React, {useEffect, useState} from "react";
import {FullFileData} from "../../../../model/FullMediaComponents";
import {useDispatch, useSelector} from "react-redux";
import {_postFullMediaMultiPart} from "../../../../services/actions/FullMediaService";
import {isRight} from "fp-ts/These";
import {FullFileButton} from "./FullFileButton";
import {ProgressFullFile} from "./ProgressFullFile";
import {
    addErrorUploadingFullMediaAction,
    addFullMediaIdAction
} from "../../../../store/actions/FullMediaUploadingActions";
import {FullFileLink} from "../UploadFile/FullFileLink";
import {removeFullFileDataAction} from "../../../../store/actions/FullMediaDataActions";


export const UploadFullFile = () => {

    const dispatch = useDispatch();
    const { clear } = useSelector(state => state.transient.fullMediaUploadingReducer);
    const { fullFile } = useSelector(state => state.transient.fullMediaDataReducer);
    const [ fullFileData, setFullFileData ] = useState<FullFileData>({} as FullFileData);
    const [ file, setFile ] = useState<File>();

    const uploadFile = async () => {

        const resp = await dispatch(_postFullMediaMultiPart(fullFileData));

        if (!isRight(resp)) {
            setFullFileData({} as FullFileData);
            removeFullFile();
            dispatch(addErrorUploadingFullMediaAction('Error uploading file'));
            return;
        }

        dispatch(addFullMediaIdAction(resp.right.id));

    }

    const removeFullFile = () => {
        dispatch(removeFullFileDataAction());
        setFile(undefined);
    }

    useEffect(() => {
        if ( fullFileData.filename ) {
            uploadFile();
        }
    }, [fullFileData])

    useEffect(() => {
        if (clear) {
            setFile(undefined);
        }
    }, [clear]);

    return (
        <>
            {
                fullFile
                    ? <FullFileLink fullFile={fullFile} removeFullFile={removeFullFile}/>
                    : (
                        <>
                            {
                                file
                                    ? <ProgressFullFile file={ file } />
                                    : <FullFileButton setFullFileData={ setFullFileData } setFile={ setFile } />
                            }
                        </>
                    )
            }
        </>
    )
}

import { FilterTagsState } from '../../../store/reducers/filterTagsReducer';
import { FILTER_STATUS, SORT_BY, SORT_BY_ELEMENT, SORT_ORDER } from '../../../services/types/util/Constants';
import { QuerySearchRecipe } from '../../../model/QuerySearchRecipes';

export const buildQuery = (tags: FilterTagsState, sortBy: SORT_BY, sortByElement: SORT_BY_ELEMENT, sortOrder: SORT_ORDER): QuerySearchRecipe => {
    let text = tags.filterName !== '' ? tags.filterName : undefined;
    let attributes = [];
    let status = [];
    const selectedTags = tags.tags;
    for (let tagKey in selectedTags) {
        for (let tagId in selectedTags[tagKey]) {
            if (tagKey === FILTER_STATUS) {
                status.push(selectedTags[tagKey][tagId]._id);
            } else {
                attributes.push( tagKey + ':' + selectedTags[tagKey][tagId].name);
            }
            
        }
    }
    let statusFilter = status.join('|');
    let attributesFilter = attributes.join('|');
    let sortByElementFilter: string = '';
    if (sortByElement) {
        sortByElementFilter = sortByElement + ':' + sortOrder;
    }
    let sortByFilter: string = '';
    if (sortBy) {
        sortByFilter = sortBy + ':' + sortOrder;
    }

    return {
        attributes: attributesFilter.length > 0 ? attributesFilter : undefined, 
        text,
        sortByElement: sortByElementFilter.length > 0 ? sortByElementFilter : undefined,
        sort: sortByFilter.length > 0 ? sortByFilter : undefined,
        status: statusFilter.length > 0 ? statusFilter : undefined,
    };
}
import * as t from "io-ts";

export const SegmentFileResponseCodec = t.strict({
  segmentFinished: t.boolean,
  segment: t.number,
  percent: t.number,
  progress: t.string
});

export const anyCodec = t.unknown;

export type SegmentFileResponse = t.OutputOf<typeof SegmentFileResponseCodec>;
export type anyResponse = t.OutputOf<typeof anyCodec>;

import { Reducer } from "redux";
import { DCHImages } from "../../model/DCHImages";


export interface DCHImageState {
    assets: DCHImages[];
    totalcount: number;
    resultlimit: number;
    currentpage: number;
    pagecount: number;
    error?: boolean;
};

const defaultState: DCHImageState = {
    assets: [],
    totalcount: 0,
    resultlimit: 0,
    currentpage: 0,
    pagecount: 0,
    error: false
};

export const DCHImageReducer: Reducer<DCHImageState, DCHImagesActions> = (
    state: DCHImageState = defaultState,
    action: DCHImagesActions
) => {
    switch (action.type) {
        case "UPDATE_DCH_IMAGES":
            return {...action.payload, error: false};
        case "CLEAR_DCH_IMAGES":
            return defaultState;
        case "FAIL_DCH_IMAGES":
            return {...defaultState, error: true};
        default:
            return state;
    }
};

export type DCHImagesActions = 
    | {
        type: "UPDATE_DCH_IMAGES";
        payload: {
            assets: DCHImages[];
            totalcount: number;
            resultlimit: number;
            currentpage: number;
            pagecount: number;
        }
    }
    | {
        type: "FAIL_DCH_IMAGES"
    }
    | {
        type: "CLEAR_DCH_IMAGES"
    };
import { Box } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from "styled-components";

const defaultProps = {
    borderLeft: "8px solid #bfbfbfcc",
};

export const RecipeName = () => {
    const [recipeName, setRecipeName] = useState('');
    const formState = useSelector(state => state.form);

    useEffect(()=> {
        const name = formState && formState.createRecipe && formState.createRecipe.values && formState.createRecipe.values.recipeName ? formState.createRecipe.values.recipeName : '';
        setRecipeName(name);
    }, [formState]);

    return (
        <Box mt={5}>
            <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                <SubTitle>Recipe Name</SubTitle>
                <RecipeNameText>{recipeName}</RecipeNameText>
            </Box>
        </Box>
    );
}

export const RecipeTitle = ({title} : {title: string}) => (
    <Box mt={1}>
        <Title>{title}</Title>
    </Box>
)

export const RecipeFirstTitle = ({title} : {title: string}) => (
    <Box mt={1}>
        <FirstTitle>{title}</FirstTitle>
    </Box>
)

const Title = styled.h3`
    font-size: 20px;
    color: #616184;
    font-weight: 700;
    font-family: sans-serif;
    margin: 20px 0;
`;

const FirstTitle = styled(Title)`
    font-size: 30px;
`


const SubTitle = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const RecipeNameText = styled.div`
    color: #a2a2a2;
    font-size: 21px;
    font-family: sans-serif;
`;

import { FileImage, JanusFile } from "./FileImage";

export interface Recipe {
    _id: string;
    enviroment: number;
    contentModel_id: string;
    author: string;
    updatedAt: {
        title: string;
        textToShow: string;
        raw: string;
    };
    name: string;
    uuid: string;
    version: number;
    uploadDate: string;
    status: string;
    drinkTypes: string[];
    occasions: string[];
    mixers: string[];
    glassware: string[];
    foodPairing: string[];
    complexity: string;
    preparationSteps: string;
    menuCopy: string;
    ingredients: {
        ingredient: string | undefined;
    }[];
    servingSize: string;
    serveStrategy: string[];
    secondaryVariant: string;
    secondaryBrand: string;
    primaryVariant: string;
    brand: string;
    thumbnail: {
        url: string | null;
    };
    thumbnail_Drinkshot: {
        url: string | null;
    };
    lifeStyle: {
        url: string | null;
        asset: string | null;
        media: JanusFile | null;
    };
    drinkShot: {
        url: string | null;
        asset: string | null;
        media: JanusFile | null;
    };
};

export interface NewRecipe {
    _id: string;
}

export interface RecipeDetail {
    _id?: string;
    enviroment?: number;
    contentModel_id?: string;
    name: string;
    version?: number;
    uploadDate?: string;
    uuid?: string;
    status?: string;
    updatedAt?: {
        title: string;
        textToShow: string;
        raw: string;
    };
    author?: string;
    drinkTypes?: string[];
    newDrinkTypes: {name: string, checked: boolean}[];
    occasions?: string[];
    newOccasions: {name: string, checked: boolean}[];
    mixers?: string[];
    newMixers: {name: string, checked: boolean}[];
    glassware?: string[];
    newGlassware: {name: string, checked: boolean}[];
    foodPairing?: {name: string, checked: boolean}[];
    complexity?: string;
    preparationSteps?: string;
    menuCopy?: string;
    ingredients: {
        ingredient: string | undefined;
    }[];
    servingSize?: string;
    serveStrategy?: string[];
    secondaryVariant?: string;
    secondaryBrand?: string;
    primaryVariant: string;
    brand: string;
    lifeStyle: {
        fileImage: FileImage | null;
        error: string | null;
    };
    drinkShot: {
        fileImage: FileImage | null;
        error: string | null;
    };
}

export const LoadRecipeDetail = (recipe: Recipe): RecipeDetail => {

    const foodPairing = recipe.foodPairing.map(fp => {return {name: fp, checked: true}});
    const recipeData: RecipeDetail = {
        _id: recipe._id,
        enviroment: recipe.enviroment,
        contentModel_id: recipe.contentModel_id,
        name: recipe.name,
        updatedAt: recipe.updatedAt,
        version: recipe.version,
        uploadDate: recipe.uploadDate,
        author: recipe.author,
        uuid: recipe.uuid,
        status: recipe.status,
        drinkTypes: recipe.drinkTypes,
        newDrinkTypes: [],
        occasions: recipe.occasions,
        newOccasions: [],
        mixers: recipe.mixers,
        newMixers: [],
        glassware: recipe.glassware,
        newGlassware: [],
        foodPairing: foodPairing,
        complexity: recipe.complexity,
        preparationSteps: recipe.preparationSteps,
        menuCopy: recipe.menuCopy,
        ingredients: recipe.ingredients,
        servingSize: recipe.servingSize,
        serveStrategy: recipe.serveStrategy,
        secondaryVariant: recipe.secondaryVariant,
        secondaryBrand: recipe.secondaryBrand,
        primaryVariant: recipe.primaryVariant,
        brand: recipe.brand,
        lifeStyle: {
            fileImage: {file: null, DCHImage: null, janusFile: recipe.lifeStyle.media},
            error: null
        },
        drinkShot: {
            fileImage: {file: null, DCHImage: null, janusFile: recipe.drinkShot.media},
            error: null
        }
    };

    return recipeData;
}
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

interface AlertMessageProps {
    text: string;
    open: boolean;
    type: 'error' | 'warning' | 'info' | 'success';
    setOpen: (open: boolean) => void;
};

export const AlertMessage = ({text, open, type, setOpen }: AlertMessageProps) => {
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type}>
                {text}
            </Alert>
        </Snackbar>
    );
}
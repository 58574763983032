import { useEffect, useRef, useState } from "react";
import { CHUNK_SIZE } from "../../services/types/util/Constants";
import { useDispatch } from "react-redux";
import { FullFile } from "../../model/FullMediaComponents";
import { _checkFullMedia, _checkSegment, postFullMediaSegment } from "../../services/actions/FullMediaService";
import { isRight } from "fp-ts/These";

const UPDATE_TIME = 2000;

export const useUploadFullFile = (file: File) => {

    const dispatch = useDispatch();
    const [ checkProgress, setCheckProgress ] = useState(false);
    const [ checkFullFile, setCheckFullFile ] = useState(false);
    const [ uploadingInfo, setUploadingInfo ] = useState({progress: '', percent: 0});
    const [ fullFile, setFullFile ] = useState<FullFile>({} as FullFile);
    const timerId = useRef(0);
    const timerCheckFullFileId = useRef(0);
    const uploadingPart = useRef(1);
    const id = useRef('');

    const runCheckProgressTimeout = () => {

        timerId.current = setTimeout(() => {
            setCheckProgress(true);
        }, UPDATE_TIME);

    }

    const runCheckFullFileTimeout = () => {

        timerCheckFullFileId.current = setTimeout(() => {
            setCheckFullFile(true);
        }, UPDATE_TIME);

    }

    const checkUploadingProgress = async () => {

        const resp = await dispatch(_checkSegment(id.current, uploadingPart.current.toString()));

        if (!isRight(resp)) {
            //TODO: agregar manejo del error
            return ;
        }

        setUploadingInfo({progress: resp.right.progress, percent: resp.right.percent});

        if ( resp.right.segmentFinished ) {
            uploadingPart.current += 1;
            uploadFile();
        } else {
            runCheckProgressTimeout();
        }
        setCheckProgress(false);

    }

    const checkIfAllPartsWereUploaded = () => {
        const totalParts = file.size % CHUNK_SIZE === 0 ? file.size / CHUNK_SIZE : Math.floor(file.size / CHUNK_SIZE) + 1;
        return totalParts < uploadingPart.current;
    }


    const checkFullFileUploaded = async () => {

        setCheckFullFile(false);
        const resp = await dispatch(_checkFullMedia(id.current));

        if ( !isRight(resp) ) {
            //TODO: Manejar el error
            return;
        }

        if ( !resp.right.result ) {
            runCheckFullFileTimeout();
        } else {
            setFullFile(resp.right.result);
        }

    }

    const uploadNextChunkFile = async () => {

        const start = CHUNK_SIZE * (uploadingPart.current - 1);
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const segment = file.slice(start, end);

        const data = new FormData();
        data.append("file", segment);

        await dispatch(postFullMediaSegment(data, id.current, uploadingPart.current));

    }

    const uploadFile = async () => {
        if ( checkIfAllPartsWereUploaded() ) {
            checkFullFileUploaded();
            // TODO: Ver caso del thumbnail
            return;
        }

        await uploadNextChunkFile();
        runCheckProgressTimeout();

    }

    useEffect( () => {

        if ( checkFullFile ) {
            checkFullFileUploaded();
        }

    }, [ checkFullFile ])

    useEffect(() => {

        if ( checkProgress ) {
            checkUploadingProgress();
        }

    }, [ checkProgress ])

    const startUploadFile = async (uploadingId: string) => {

        if (uploadingId.length === 0) {
            return;
        }

        id.current = uploadingId;

        uploadFile();

    }

    useEffect(() => {
        return () => {
            if ( timerId.current !== 0 ) {
                clearTimeout(timerId.current);
            }
            if ( timerCheckFullFileId.current !== 0 ) {
                clearTimeout(timerCheckFullFileId.current);
            }
        }
    }, [])

    return {
        startUploadFile,
        uploadingInfo,
        fullFile
    }
}

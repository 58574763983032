const validate = (values: any) => {
    const errors: any = {};
    if (values.auxiliarName === values.recipeName) {
      errors.recipeName = 'Required';
    }
    if (!values.brand) {
      errors.brand = 'Required';
    }
    if (!values.primaryVariant || !values.primaryVariant) {
      errors.primaryVariant = 'Required';
      errors.primaryVariantEmpty = 'Required';
    }
    if (!values.recipeName) {
      errors.recipeName = 'Required';
    }

    return errors;
};

export default validate;

import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { exportImportCodec, exportImportResponse, importCodec, importResponse } from "../types/ImportExportCodec";
import { FIELD_DELIMITER, FILE_TYPE } from "../types/util/Constants";
import FileSaver from 'file-saver';
import { ImportRecipesAction, ErrorImportRecipesAction, UpdateImportRecipesAction } from "../../store/actions/ImportActions";
import { QuerySearchRecipe } from "../../model/QuerySearchRecipes";

export const exportRecipes = (ids: string[], contentModelId: string, file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER): ServiceCallAction<exportImportResponse> => ({
	method: "POST",
	endpoint: `contents/export/model/${contentModelId}`,
	body: {type: 'application/json', value: {ids}},
	param: {file_type, field_delimiter, exportContent: true, selected: true},
	serviceKey: "exportRecipes",
	responseDecoder: exportImportCodec,
	type: "SERVICE_CALL",
	blob: true,
	onDownloadFile: (data: Blob) => {
		FileSaver.saveAs(data, `recipes.${file_type}`);
	}
});

export const exportAllRecipes = (params: QuerySearchRecipe, file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER): ServiceCallAction<exportImportResponse> => ({
	method: "GET",
	endpoint: `recipe/exportAll`,
  param: {file_type, field_delimiter, ...params},
	serviceKey: "exportRecipes",
	responseDecoder: exportImportCodec,
	type: "SERVICE_CALL",
	blob: true,
	onDownloadFile: (data: Blob) => {
		FileSaver.saveAs(data, `recipes.${file_type}`);
	}
});

export const exportAllRecipesWithIssues = (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER): ServiceCallAction<exportImportResponse> => ({
	method: "GET",
	endpoint: `recipe/exportAllIssues`,
    param: {file_type, field_delimiter},
	serviceKey: "exportRecipes",
	responseDecoder: exportImportCodec,
	type: "SERVICE_CALL",
	blob: true,
	onDownloadFile: (data: Blob) => {
		FileSaver.saveAs(data, `recipes.${file_type}`);
	}
});

export const exportMedia = (mediaIds: string[], file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER): ServiceCallAction<exportImportResponse> => ({
	method: "POST",
	endpoint: `contents/export/media`,
	param: {file_type, field_delimiter},
	body: {type: 'application/json', value: {ids: mediaIds}},
	serviceKey: "exportRecipes",
	responseDecoder: exportImportCodec,
	type: "SERVICE_CALL",
	blob: true,
	onDownloadFile: (data: Blob) => {
		FileSaver.saveAs(data, `media.${file_type}`);
	}
});

export const importRecipes = (data: FormData, contentModelId: string): ServiceCallAction<importResponse> => ({
	method: "POST",
	endpoint: `recipe/import/model/${contentModelId}`,
	body: {type: 'multipart', value: data},
	serviceKey: "importRecipes",
	responseDecoder: importCodec,
	type: "SERVICE_CALL",
	onSuccess: ImportRecipesAction,
	onFailure: ErrorImportRecipesAction
});

export const getImportRecipes = (id: string): ServiceCallAction<importResponse> => ({
	method: "GET",
	endpoint: `recipe/import-record/${id}`,
	serviceKey: "GetImportRecipes",
	responseDecoder: importCodec,
	type: "SERVICE_CALL",
	onSuccess: UpdateImportRecipesAction,
	onFailure: ErrorImportRecipesAction
});

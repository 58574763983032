import { FilterTagsState } from '../../../store/reducers/filterTagsReducer';
import { FILTER_BRANDS, FILTER_LIBRARY, FILTER_STATUS, FILTER_VARIANTS, SORT_BY, SORT_BY_ELEMENT, SORT_ORDER } from '../../../services/types/util/Constants';
import { QuerySearchMedia } from '../../../model/QuerySearchMedia';

export const buildMediaQuery = (tags: FilterTagsState, sortBy: SORT_BY, sortByElement: SORT_BY_ELEMENT, sortOrder: SORT_ORDER): QuerySearchMedia => {
    let mediaFilter = tags.filterName !== '' ? tags.filterName : undefined;
    let brands = [];
    let variants = [];
    let status = [];
    let library = [];
    const selectedTags = tags.tags;
    for (let tagKey in selectedTags) {
        for (let tagId in selectedTags[tagKey]) {
            if (tagKey === FILTER_STATUS) {
                status.push(selectedTags[tagKey][tagId]._id);
            } else if (tagKey === FILTER_BRANDS) {
                brands.push(selectedTags[tagKey][tagId].name);
            } else if (tagKey === FILTER_VARIANTS) {
                variants.push(selectedTags[tagKey][tagId].name)
            } else if (tagKey === FILTER_LIBRARY) {
                library.push(selectedTags[tagKey][tagId].name)
            }
            
        }
    }
    let statusFilter = status.join('|');
    let sortByFilter: string = '';
    if (sortBy) {
        sortByFilter = sortBy + ':' + sortOrder;
    }

    return {
        brands: brands.length > 0 ? brands.join('|') : undefined, 
        variants: variants.length > 0 ? variants.join('|') : undefined,
        library: library.length > 0 ? library.join('|') : undefined,
        mediaFilter,
        status: statusFilter.length > 0 ? statusFilter : undefined,
        sort: sortByFilter.length > 0 ? sortByFilter : undefined,
    };
}
import React, {useEffect, useState} from 'react';
import { Link, useHistory } from "react-router-dom";
import { createStyles, Theme, makeStyles, createMuiTheme } from '@material-ui/core/styles';
import styled from "styled-components";
import { Button, Divider, Drawer, ThemeProvider, Toolbar } from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { recipeReloadForm } from '../store/actions/RecipeFormActions';
import FilterTags from './components/FilterTags/FilterTags';
import { useLocation } from 'react-router-dom'
import {openModalAction, redirectAction} from "../store/actions/UtilsAction";

const drawerWidth = 280;

const DashboardButton = withStyles((theme: Theme) => ({
    root: {
      backgroundColor: '#ec808d',
      '&:hover': {
        backgroundColor: '#b76872',
      },
    },
}))(Button);

const MediaGaleryButton = withStyles((theme: Theme) => ({
    root: {
      backgroundColor: '#c280ff',
      '&:hover': {
        backgroundColor: '#9862ca',
      },
    },
}))(Button);

const MediaCreateButton = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#cc8010',
        '&:hover': {
            backgroundColor: '#b5710d',
        },
    },
}))(Button);

const MediaListButton = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#105bcc',
        '&:hover': {
            backgroundColor: '#10499e',
        },
    },
}))(Button);



const SideBar = (props: any) => {
    let history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const pathsFilterTags = ['/recipe/list', '/dashboard'];
    const mediaLibraryTags = ['/media-library'];
    const location = useLocation();
    const {redirect} = useSelector(state => state.transient.utils);
    const [destination, setDestination] = useState('');

    const createFullMedia = () => {
        if (location.pathname === '/media/new') {
            dispatch(openModalAction(true));
        }
        history.push('/media/new');
    }

    const showFilterTags = (): boolean => {
        return pathsFilterTags.includes(history.location.pathname);
    }

    const showMediaLibraryTags = (): boolean => {
        return mediaLibraryTags.includes(history.location.pathname);
    }

    const handlerRedirect = (routeDestination: string) => {
        switch (routeDestination) {
            case '/recipe/new':
                if (location.pathname !== '/recipe/new') {
                    dispatch(recipeReloadForm());
                }
                break;
            default:
                break;
        }
        history.push(routeDestination);
    }

    useEffect(() => {
        if (!redirect.check || redirect.waiting) {
            return;
        }
        if (redirect.canRedirect) {
            handlerRedirect(destination);
        }
        dispatch(redirectAction(false, false, false));
    }, [redirect])

    const handlerLinks = (routeDestination: string) => {
        if (location.pathname === '/media/new') {
            setDestination(routeDestination);
            dispatch(redirectAction(true, false, true));
        } else {
            handlerRedirect(routeDestination);
        }
    }

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Toolbar />
            <div className={classes.drawerContainer}>

                <ThemeProvider theme={outerTheme}>
                    <ButtonContainer >
                        {/*<Link to={'/dashboard'} style={{ textDecoration: 'none' }}>*/}
                            <DashboardButton
                                variant="contained"
                                size="large"
                                className={classes.buttonCustom}
                                onClick={() => handlerLinks('/dashboard')}
                            >
                                DASHBOARD
                            </DashboardButton>
                        {/*</Link>*/}
                    </ButtonContainer>

                    <ButtonContainer >
                        {/*<Link to={'/recipe/list'} style={{ textDecoration: 'none' }}>*/}
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                className={classes.buttonCustom}
                                onClick={() => handlerLinks('/recipe/list')}
                            >
                                ALL RECIPES
                            </Button>
                        {/*</Link>*/}
                    </ButtonContainer>

                    <ButtonContainer >
                        {/*<Link to={'/media-library'} style={{ textDecoration: 'none' }}>*/}
                            <MediaGaleryButton
                                variant="contained"
                                size="large"
                                className={classes.buttonCustom}
                                onClick={() => handlerLinks('/media-library')}
                            >
                                MEDIA LIBRARY
                            </MediaGaleryButton>
                        {/*</Link>*/}
                    </ButtonContainer>

                    <ButtonContainer >
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.buttonCustom}
                            onClick={() => handlerLinks('/recipe/new')}
                        >
                            CREATE RECIPE +
                        </Button>
                    </ButtonContainer>

                    <ButtonContainer >
                        {/*<Link to={'/media/list'} style={{ textDecoration: 'none' }}>*/}
                            <MediaListButton
                              variant="contained"
                              size="large"
                              className={classes.buttonCustom}
                              onClick={() => handlerLinks('/media/list')}
                            >
                                FULL MEDIA LIST
                            </MediaListButton>
                        {/*</Link>*/}
                    </ButtonContainer>

                    <ButtonContainer >
                        {/*<Link to={'/media/new'} style={{ textDecoration: 'none' }}>*/}
                            <MediaCreateButton
                              variant="contained"
                              size="large"
                              className={classes.buttonCustom}
                              onClick={createFullMedia}
                            >
                                CREATE FULL MEDIA +
                            </MediaCreateButton>
                        {/*</Link>*/}
                    </ButtonContainer>
                </ThemeProvider>
                <Divider />
                {
                    showFilterTags() ?
                        <FilterTags
                            title="Recipes"
                            titleTextFilter="Filter Recipes"
                            placeholderTextFilter="Filter Recipes by Keyword"
                            filtersToShow={{libraryStatus: false, mixers: true, occasions: true, drinkTypes: true}}
                        />
                    :
                        null
                }
                {
                    showMediaLibraryTags() ?
                        <FilterTags
                            title="Media Library"
                            titleTextFilter="Filter Media"
                            placeholderTextFilter="Search by DCH ID or Keyword"
                            filtersToShow={{libraryStatus: true, mixers: false, occasions: false, drinkTypes: false}}
                        />
                    :
                        null
                }
            </div>
        </Drawer>
    );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
      paddingTop: theme.spacing(2),
    },
    buttonCustom: {
        width: '240px',
        color: 'white',
    }
  }),
);

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px 0;
`

const outerTheme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: {
            main: '#70b603',
        },
        info: {
            main: '#ec808d',
        }
    },
});

export default SideBar;

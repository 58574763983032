import React, {useState} from 'react';
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import styled from "styled-components";

interface FullMediaPanelProps {
  state: {
    preview: boolean,
    thumbnail: boolean
  };
  handleStateChange: any;
  preview: {
    time: string,
    duration: number
  };
  handleChangePreview: any,
  showPreview?: boolean,
  enabledThumbnail?: boolean
}

const VideoOptions = ({state, handleStateChange, preview, handleChangePreview, showPreview, enabledThumbnail}: FullMediaPanelProps) => {

  // const [state, setState] = useState({
  //   preview: false,
  //   thumbnail: false
  // });
  //
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  //
  // const [preview, setPreview] = React.useState({
  //   seconds: 0,
  //   minutes: 0,
  //   duration: 0
  // });
  //
  // const handleChangePreview = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPreview({ ...preview, [event.target.name]: event.target.value });
  // };

  const validStartTime = () => {
    let [hours, minutes, seconds] = preview.time.split(":");
    return /([0-9][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/g.test(preview.time) && /^\d+$/.test(hours) && /^\d+$/.test(seconds) && seconds.length === 2;
  }

  const validDuration = () => {
    return preview.duration > 0;
  }

  return (
    <Container>
      {
        showPreview ?
          <PreviewContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.preview}
                  onChange={handleStateChange}
                  name="preview"
                  color="primary"
                />
              }
              label="Generate Preview"
            />
            <OptionsContainer>
              <FieldContainer>
                <TextField
                  disabled={!state.preview}
                  label="Start Time"
                  name="time"
                  placeholder="00:00:00"
                  error={!validStartTime()}
                  helperText={!validStartTime() ? "Format time: HH:MM:SS" : ""}
                  value={preview.time}
                  onChange={handleChangePreview}
                />
              </FieldContainer>
              <FieldContainer>
                <TextField
                  disabled={!state.preview}
                  label="Duration in Seconds"
                  name="duration"
                  type="number"
                  error={!validDuration()}
                  helperText={!validDuration() ? "Invalid duration" : ""}
                  value={preview.duration}
                  onChange={handleChangePreview}
                />
              </FieldContainer>
            </OptionsContainer>
          </PreviewContainer>
         :
          null
      }
      <ThumbnailContainer>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!state.preview && !enabledThumbnail}
              checked={state.thumbnail}
              onChange={handleStateChange}
              name="thumbnail"
              color="primary"
            />
          }
          label="Generate Thumbnail"
        />
      </ThumbnailContainer>

    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;  
`;

const ThumbnailContainer = styled.div`
  width: fit-content;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  margin: 10px 0;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const FieldContainer = styled.div`
  margin-right: 5px;
`;


export default VideoOptions;

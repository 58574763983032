import {ServiceCallAction} from "../../store/middleware/serviceCallMiddleware";
import {ContentModelCodec, ContentModelResponse} from "../types/ContentModelCodec";
import {loadContentModel} from "../../store/actions/ContentModelActions";

export const getContentModel = (contentModelName: string, space: string): ServiceCallAction<ContentModelResponse> => ({
  method: "GET",
  endpoint: `contentmodels/${space}/retrieve/name/${contentModelName}`,
  serviceKey: "postFullMedia",
  responseDecoder: ContentModelCodec,
  type: "SERVICE_CALL",
  onSuccess: loadContentModel
});

export const _getContentModel = (contentModelName: string, space: string): ServiceCallAction<ContentModelResponse> => ({
  method: "GET",
  endpoint: `contentmodels/${space}/retrieve/name/${contentModelName}`,
  serviceKey: "postFullMedia",
  responseDecoder: ContentModelCodec,
  type: "SERVICE_CALL",
});

import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

interface ImportRecipeResult {
  file: File;
}

const MediaResultFile = ({file}: ImportRecipeResult) => {

  const getFileSize = () => {
    return file.size > 1000 ? Math.trunc(file.size / (1024*1024)).toString() + ' MB' : file.size.toString() + ' KB';
  }

  return (
    <div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow >
              <TableCell component="th" scope="row">
                {file?.name}
              </TableCell>
              <TableCell align="right">{getFileSize()}</TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>

    </div>
  )
}

export default MediaResultFile;

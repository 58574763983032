import { Backdrop, Box, Card, CardContent, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadRecipeDetail, RecipeDetail } from '../../../model/Recipe';
import { getBrands, getComplexities, getDrinkTypes, getGlasswares, getMixers, getOccasions, getServeStrategy, getVariants } from '../../../services/actions/ElementsRecipeService';
import { exportAllRecipes, exportRecipes } from '../../../services/actions/ImportExportService';
import {getPrevRecipeVersion, getRecipe, getRecipes} from '../../../services/actions/RecipesService';
import { bulkStatus } from '../../../services/actions/StatusService';
import { FIELD_DELIMITER, FILE_TYPE, SORT_ORDER, SORT_BY, SORT_BY_ELEMENT } from '../../../services/types/util/Constants';
import { clearRecipe, clearRecipesList } from '../../../store/actions/recipeActions';
import {clearAllTags, updateFilterName} from '../../../store/actions/FilterTagActions';
import { AlertMessage } from '../basicComponents/AlertMessage';
import RecipeDetailForm from '../Recipe/RecipeDetailForm';
import { RecipeFirstTitle } from '../RecipeCreate/RecipeTitles';
import RecipeListFilter from './RecipeListFilter';
import RecipeTable from './RecipeTable';
import { buildQuery } from './BuildQuery';
import { clearStatusError } from '../../../store/actions/StatusReducerActions';
import {isRight} from "fp-ts/These";


const RecipeList = (props: any) => {
    const { filterName } = (props.location && props.location.state) || {};
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [loading, setLoading] = React.useState(true);
    const [loadingRecipe, setLoadingRecipe] = React.useState(false);
    const [showPreviousVersion, setShowPreviousVersion] = React.useState(false);
    const [selectedRecipe, setSelectedRecipe] = React.useState<string | null>(null);
    const { recipe, error } = useSelector(state => state.transient.recipe);
    const { filterTags } = useSelector(state => state.transient);
    const { recipes, statusUpdate } = useSelector(state => state.transient);
    const [ recipeDetail, setRecipeDetail ] = useState<RecipeDetail | null>(null);
    const [ recipePreviousVersion, setRecipePreviousVersion ] = useState<RecipeDetail | null>(null);
    const [ selected, setSelected ] = useState<string[]>([]);
    const [ openError, setOpenError ] = useState<boolean>(false);
    const [ sortOrder, setSortOrder] = useState<SORT_ORDER>('asc');
    const [ sortBy, setSortBy] = useState<SORT_BY>('createdAt');
    const [ sortByElement, setSortByElement] = useState<SORT_BY_ELEMENT>(undefined);
    const [ messageStatusUpadate, setMessageStatusUpadate] = useState<{message: string, open: boolean}>({message: '', open: false});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRecipes(1, rowsPerPage, getQuery()));
        setSelected([]);
        setLoading(true);
    }, [filterTags]);

    useEffect(() => {
        setLoading(false);
        if (statusUpdate.error) {
            setLoading(false);
        } else if (statusUpdate.rejectedContentsCount > 0) {
            setMessageStatusUpadate({
                message: `${statusUpdate.rejectedContentsCount} Recipes rejected`,
                open: true
            });
        }
        dispatch(clearStatusError());
    }, [statusUpdate]);

    const getQuery = () => {
        return buildQuery(filterTags, sortBy, sortByElement, sortOrder);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        dispatch(getRecipes(newPage +1, rowsPerPage, getQuery()));
        setSelected([]);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        dispatch(getRecipes(1, parseInt(event.target.value), getQuery()));
        setSelected([]);
        setLoading(true);
    };

    const closeRecipeDetail = () => {
        setSelected([]);
        dispatch(getRecipes(recipes.currentpage, rowsPerPage, getQuery()));
        setLoading(true);
        setSelectedRecipe(null);
        dispatch(clearRecipe());
        setRecipeDetail(null);
    };

    useEffect(() => {
        dispatch(getRecipes(recipes.currentpage, rowsPerPage, getQuery()));
        setSelected([]);
        setLoading(true);
    }, [sortOrder, sortBy]);

    useEffect(() => {
        if (recipe) {
            const recipeData: RecipeDetail = LoadRecipeDetail(recipe);
            setRecipeDetail(recipeData);
        }
        if (error) {
            setSelectedRecipe(null);
        }
        setLoadingRecipe(false);
    }, [recipe, error]);

    useEffect(() => {
        if (selectedRecipe) {
            dispatch(getRecipe(selectedRecipe));
            setLoadingRecipe(true);
        }
    }, [selectedRecipe])


    useEffect(() => {
        dispatch(getBrands());
        dispatch(getVariants());
        dispatch(getServeStrategy());
        dispatch(getMixers());
        dispatch(getOccasions());
        dispatch(getGlasswares());
        dispatch(getComplexities());
        dispatch(getDrinkTypes());
        dispatch(getRecipes(1, rowsPerPage, getQuery()));
        if (filterName) {
            dispatch(updateFilterName(filterName));
        }
        return () => {
            dispatch(clearRecipesList());
            setSelected([]);
            dispatch(clearRecipe());
            dispatch(clearAllTags());
        };
    }, []);

    const handleBlukPublish = () => {
        if (selected.length === 0) {
            setOpenError(true);
            return;
        }
        dispatch(bulkStatus(selected, 'Live', recipes.currentpage, rowsPerPage, getQuery()));
        setSelected([]);
        setLoading(true);
    }

    const handleBlukDraft = () => {
        if (selected.length === 0) {
            setOpenError(true);
            return;
        }
        dispatch(bulkStatus(selected, 'Draft', recipes.currentpage, rowsPerPage, getQuery()));
        setSelected([]);
        setLoading(true);
    }

    const handleBlukArchive = () => {
        if (selected.length === 0) {
            setOpenError(true);
            return;
        }
        dispatch(bulkStatus(selected, 'Archived', recipes.currentpage, rowsPerPage, getQuery()));
        setSelected([]);
        setLoading(true);
    }

    const handleExportRecipes = (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => {
        if (selected.length === 0) {
            setOpenError(true);
            return;
        }
        let contentModelId = recipes.contents[0].contentModel_id;
        return  dispatch(exportRecipes(selected, contentModelId, file_type, field_delimiter));
    }

    const handleExportAllRecipes = (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => {
        return dispatch(exportAllRecipes(getQuery(), file_type, field_delimiter));
    }

    const getShowPreviousVersion = async (value: boolean) => {

        setLoadingRecipe(true);
        const resp = await dispatch(getPrevRecipeVersion(recipeDetail!.uuid!));
        if (isRight(resp)) {
            setRecipePreviousVersion(LoadRecipeDetail(resp.right.content));
            setShowPreviousVersion(value);
        } else {
            setMessageStatusUpadate({message: 'No previous version found', open: true});
        }
        setLoadingRecipe(false);

    }

    return (
        <Card>
            <CardContent>
                <Box mx="auto" p={4}>
                    {
                        !selectedRecipe || !recipeDetail ?
                            <div>
                                <RecipeFirstTitle title={"Recipe List"} />
                                <RecipeListFilter
                                    handleBlukPublish={handleBlukPublish}
                                    handleBlukDraft={handleBlukDraft}
                                    handleBlukArchive={handleBlukArchive}
                                    handleExportRecipes={handleExportRecipes}
                                    handleExportAllRecipes={handleExportAllRecipes}
                                    setSortOrder={setSortOrder}
                                    setSortBy={setSortBy}
                                    setSortByElement={setSortByElement}
                                />
                                <RecipeTable
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    rowsPerPage={rowsPerPage}
                                    loading={loading}
                                    setLoading={setLoading}
                                    setSelectedRecipe={setSelectedRecipe}
                                    selected={selected}
                                    setSelected={setSelected}
                                    sortOrder={sortOrder}
                                    sortBy={sortBy}
                                    sortByElement={sortByElement}
                                />
                            </div>
                        :
                            <>
                            {
                                showPreviousVersion && recipePreviousVersion
                                ?
                                    <RecipeDetailForm
                                        previousVersion
                                        showHeader={false}
                                        recipeInformation={recipePreviousVersion}
                                        goBack={() => {
                                            setRecipePreviousVersion(null);
                                            setShowPreviousVersion(false);
                                        }}
                                        canPublish={true}
                                    />
                                :
                                    <RecipeDetailForm
                                        setShowPreviousVersion={getShowPreviousVersion}
                                        showHeader={true}
                                        recipeInformation={recipeDetail}
                                        goBack={closeRecipeDetail}
                                        canPublish={true}
                                    />
                            }
                            </>
                    }
                    <Backdrop className={classes.backdrop} open={loadingRecipe}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <AlertMessage text="You must select a recipe" open={openError} type="error" setOpen={setOpenError}/>
                </Box>
                <AlertMessage
                    text={messageStatusUpadate.message}
                    open={messageStatusUpadate.open}
                    type='error'
                    setOpen={() => setMessageStatusUpadate({message: '', open: false})}
                />
            </CardContent>
        </Card>

    );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  }),
);

export default RecipeList;

import { CopyRecipeActions } from "../reducers/CopyRecipeReducer";
import { Recipe } from "../../model/Recipe";

export const updateCopyRecipe = (recipe: {content: Recipe}): CopyRecipeActions => ({
	type: "UPDATE_COPY_RECIPE",
	recipe: recipe.content
});

export const clearCopyRecipe = (): CopyRecipeActions => ({
	type: "CLEAR_COPY_RECIPE"
});
import React, { useCallback } from 'react';
import { Box, Grid, IconButton, TextField } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import styled from "styled-components";
import Ingredient from './Ingredient';

const Ingredients = ({ fields, meta: { error }, description, setDescription}: any) => {

    const moveIngredient = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            fields.move(hoverIndex, dragIndex);
        },
        [fields],
    )

    const addIngredient = (fields: any[]) => {
        if (description.length > 0) {
            fields.push({description: description, id: (fields.length).toString()});
            setDescription('');
        }
    };

    return (
        <div style={{width: "100%"}}>
            <Grid item xs={12}>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" flexDirection="row">
                        <TextField
                            id="ingredient-description"
                            label="Ingredient"
                            color="primary"
                            value={description}
                            fullWidth
                            onChange={e => setDescription(e.target.value)}
                        />
                        <IconButton aria-label="add" color="primary" onClick={() => addIngredient(fields)}>
                            <AddCircleOutlineIcon fontSize="large" />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            {fields.map((ingredient: string, index: number) => (
                <Ingredient 
                    name={ingredient}
                    ingredient={fields.get(index)}
                    index={index} 
                    removeIngredient={(idx: number) => fields.remove(idx)} 
                    key={fields.get(index).id}
                    moveIngredient={moveIngredient}
                />
            ))}
        </div>
    );
};

const StepNumber = styled.div`
    margin-right: 10px;
    border: 1px solid black;
    padding: 8px;
`;

export default Ingredients;
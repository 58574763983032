import {Reducer} from "redux";
import {FullMedia} from "../../model/FullMedia";

export interface FullMediaListState {
  contents: FullMedia[],
  resultlimit: number,
  currentpage: number,
  totalcount: number,
  pagecount: number
}

const defaultState: FullMediaListState = {
  contents: [],
  resultlimit: 25,
  currentpage: 1,
  totalcount: 0,
  pagecount: 0
};

export const fullMediaListReducer: Reducer<FullMediaListState, FullMediaListActions> = (
  state: FullMediaListState = defaultState,
  action: FullMediaListActions
) => {
  switch (action.type) {
    case "UPDATE_FULL_MEDIA_LIST":
      return {...action.mediaList};
    case "CLEAR_FULL_MEDIA_LIST":
      return defaultState;
    default:
      return state;
  }
};

export type FullMediaListActions =
  | {
  type: "UPDATE_FULL_MEDIA_LIST";
  mediaList: FullMediaListState;
} | {
  type: "CLEAR_FULL_MEDIA_LIST"
};

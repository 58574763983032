import { Box, Card, CardContent, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardStatistics } from '../../../services/actions/DashboardService';
import { RecipeFirstTitle } from '../RecipeCreate/RecipeTitles';
import { useDataService } from '../../hooks/useServiceCall';
import DashboardElement from './DashboardElement';
import DashboardFilter from './DashboardFilter';
import { FIELD_DELIMITER, FILE_TYPE, SORT_BY, SORT_BY_ELEMENT, SORT_ORDER } from '../../../services/types/util/Constants';
import RecipeIssues from './RecipeIssues';
import { exportAllRecipesWithIssues, exportRecipes } from '../../../services/actions/ImportExportService';
import { bulkStatus } from '../../../services/actions/StatusService';
import { buildQuery } from '../RecipeList/BuildQuery';
import {getRecipeError, getRecipesIssues} from '../../../services/actions/RecipeIssuesService';
import { clearRecipe, clearRecipesList } from '../../../store/actions/recipeActions';
import { getBrands, getServeStrategy, getVariants } from '../../../services/actions/ElementsRecipeService';
import { AlertMessage } from '../basicComponents/AlertMessage';
import { clearAllTags } from '../../../store/actions/FilterTagActions';
import RecipeDetailForm from '../Recipe/RecipeDetailForm';
import { LoadRecipeDetail, RecipeDetail } from '../../../model/Recipe';
import { getRecipe } from '../../../services/actions/RecipesService';
import { clearStatusError } from '../../../store/actions/StatusReducerActions';
import {RecipeErroInformation} from "./recipeErrors/RecipeErrorInformation";
import {ImportRecipeResultsTable} from "./importRecipeResults/ImportRecipeResultsTable";

const sortOptions = [
    {value: 0, name: "None"},
    {value: 1, name: "Recipe Name (A - Z)"},
    {value: 2, name: "Recipe Name (Z - A)"},
    {value: 3, name: "Brand (A - Z)"},
    {value: 4, name: "Brand (Z - A)"}
];


const Dashboard = () => {
    const classes = useStyles();
    let { result, isLoading } = useDataService(getDashboardStatistics,[],{});
    const [ sortOrder, setSortOrder] = useState<SORT_ORDER>('asc');
    const [ sortBy, setSortBy] = useState<SORT_BY>('createdAt');
    const [ sortByElement, setSortByElement] = useState<SORT_BY_ELEMENT>(undefined);
    const [ selected, setSelected ] = useState<string[]>([]);
    const [ openError, setOpenError ] = useState<boolean>(false);
    const { recipes } = useSelector(state => state.transient);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const { filterTags, statusUpdate } = useSelector(state => state.transient);
    const [loading, setLoading] = React.useState(true);
    const [selectedRecipe, setSelectedRecipe] = React.useState<string | null>(null);
    const [ recipeDetail, setRecipeDetail ] = useState<RecipeDetail | null>(null);
    const { recipe, error } = useSelector(state => state.transient.recipe);
    const [loadingRecipe, setLoadingRecipe] = React.useState(false);
    const [ messageStatusUpadate, setMessageStatusUpadate] = useState<{message: string, open: boolean}>({message: '', open: false});

    const dispatch = useDispatch();
    let live = result ? result.live : 0;
    let draft = result ? result.draft : 0;
    let archived = result ? result.archived : 0;
    let issues = result ? result.issues : 0;

    const getQuery = () => {
        return buildQuery(filterTags, sortBy, sortByElement, sortOrder);
    }


    useEffect(() => {
        setLoading(false);
        if (statusUpdate.error) {
            setLoading(false);
        } else if (statusUpdate.rejectedContentsCount > 0) {
            setMessageStatusUpadate({
                message: `${statusUpdate.rejectedContentsCount} Recipes rejected`,
                open: true
            });
        }
        dispatch(clearStatusError());
    }, [statusUpdate]);

    useEffect(() => {
        dispatch(getRecipesIssues(1, rowsPerPage, getQuery()));
        setSelected([]);
        setLoading(true);
    }, [filterTags]);

    useEffect(() => {
        dispatch(getRecipesIssues(recipes.currentpage, rowsPerPage, getQuery()));
        setSelected([]);
        setLoading(true);
    }, [sortOrder, sortBy]);

    useEffect(() => {
        dispatch(getRecipesIssues(1, rowsPerPage, getQuery()));
        dispatch(getBrands());
        dispatch(getVariants());
        dispatch(getServeStrategy());
        setSelected([]);
        setLoading(true);
        return () => {
            dispatch(clearRecipesList());
            setSelected([]);
            dispatch(clearAllTags());
            dispatch(clearRecipe());
        };
    }, []);

    useEffect(() => {
        if (recipe) {
            const recipeData: RecipeDetail = LoadRecipeDetail(recipe);
            setRecipeDetail(recipeData);
        }
        if (error) {
            setSelectedRecipe(null);
        }
        setLoadingRecipe(false);
    }, [recipe, error]);

    useEffect(() => {
        if (selectedRecipe) {
            dispatch(getRecipe(selectedRecipe));
            setLoadingRecipe(true);
        }
    }, [selectedRecipe])

    const handleChangePage = (event: unknown, newPage: number) => {
        dispatch(getRecipesIssues(newPage +1, rowsPerPage, getQuery()));
        setSelected([]);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        dispatch(getRecipesIssues(1, parseInt(event.target.value), getQuery()));
        setSelected([]);
        setLoading(true);
    };

    const handleBlukDraft = () => {
        if (selected.length === 0) {
            setOpenError(true);
            return;
        }
        dispatch(bulkStatus(selected, 'Draft', recipes.currentpage, rowsPerPage, getQuery(), true));
        setSelected([]);
        setLoading(true);
    }

    const handleBlukArchive = () => {
        if (selected.length === 0) {
            setOpenError(true);
            return;
        }
        dispatch(bulkStatus(selected, 'Archived', recipes.currentpage, rowsPerPage, getQuery(), true));
        setSelected([]);
        setLoading(true);
    }

    const closeRecipeDetail = () => {
        setSelected([]);
        dispatch(getRecipesIssues(recipes.currentpage, rowsPerPage, getQuery()));
        setLoading(true);
        setSelectedRecipe(null);
        dispatch(clearRecipe());
        setRecipeDetail(null);
    };

    const handleExportRecipes = (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => {
        if (selected.length === 0) {
            setOpenError(true);
            return;
        }
        let contentModelId = recipes.contents[0].contentModel_id;
        return  dispatch(exportRecipes(selected, contentModelId, file_type, field_delimiter));
    }

    const handleExportAllRecipes = (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => {
        return  dispatch(exportAllRecipesWithIssues(file_type, field_delimiter));
    }

    const handleChangeSortOption = (option: number) => {
        switch (option) {
            case 1:
                setSortOrder('asc');
                setSortBy('name');
                setSortByElement(undefined);
                break;
            case 2:
                setSortOrder('desc');
                setSortBy('name');
                setSortByElement(undefined);
                break;
            case 3:
                setSortByElement('Featured Brands');
                setSortOrder('asc');
                setSortBy(undefined);
                break;
            case 4:
                setSortByElement('Featured Brands');
                setSortOrder('desc');
                setSortBy(undefined);
                break;
            default:
                setSortByElement(undefined);
                setSortOrder('asc');
                setSortBy(undefined);
                break;
        }
    }

    return (
        <Card>
            <CardContent>
                <Box mx="auto" p={4}>
                    {
                        !selectedRecipe || !recipeDetail ?
                            <div>
                                <RecipeFirstTitle title={"Dashboard"} />
                                <Box display="flex" flexDirection="row" justifyContent="space-between" mt={4} mb={4}>
                                    <DashboardElement
                                        title="Total Live"
                                        value={live}
                                        isLoading={isLoading}
                                        color='#137d01'
                                        colorText='white'
                                    />
                                    <DashboardElement
                                        title="Total Draft"
                                        value={draft}
                                        isLoading={isLoading}
                                        color='#fbe616'
                                        colorText='black'
                                    />
                                    <DashboardElement
                                        title="Total Archived"
                                        value={archived}
                                        isLoading={isLoading}
                                        color='#797979'
                                        colorText='white'
                                    />
                                    <DashboardElement
                                        title="Recipes with Issues"
                                        value={issues}
                                        isLoading={isLoading}
                                        color='#d9001b'
                                        colorText='white'
                                    />
                                </Box>
                                <RecipeFirstTitle title={"DCH Errors"} />
                                <DashboardFilter
                                    showAdminOptions={true}
                                    handleBlukDraft={handleBlukDraft}
                                    handleBlukArchive={handleBlukArchive}
                                    handleExportRecipes={handleExportRecipes}
                                    sortOptions={sortOptions}
                                    handleChangeSortOption={handleChangeSortOption}
                                    handleExportAllRecipes={handleExportAllRecipes}
                                />
                                <RecipeIssues
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    rowsPerPage={rowsPerPage}
                                    loading={loading}
                                    setLoading={setLoading}
                                    setSelectedRecipe={setSelectedRecipe}
                                    selected={selected}
                                    setSelected={setSelected}
                                    sortOrder={sortOrder}
                                    sortBy={sortBy}
                                    sortByElement={sortByElement}
                                />

                                <RecipeFirstTitle title={"Recipe Issues"} />

                                <RecipeErroInformation setIsLoading={setLoading} setSelectedRecipe={setSelectedRecipe}/>

                                <RecipeFirstTitle title={"Recipe Import"} />

                                <ImportRecipeResultsTable />
                            </div>
                            :
                            <RecipeDetailForm
                                showHeader={true}
                                recipeInformation={recipeDetail}
                                goBack={closeRecipeDetail}
                                canPublish={false}
                            />
                    }
                    <AlertMessage text="You must select a recipe" open={openError} type="error" setOpen={setOpenError}/>
                </Box>
                <AlertMessage
                    text={messageStatusUpadate.message}
                    open={messageStatusUpadate.open}
                    type='error'
                    setOpen={() => setMessageStatusUpadate({message: '', open: false})}
                />
            </CardContent>
        </Card>

    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);


export default Dashboard;

import * as t from "io-ts";

export const ResponseContainerCodec = t.intersection([
	t.strict({
		/* success: t.boolean, */
		status: t.union([t.literal("success"), t.literal("error")])
	}),
	t.union([
		t.strict({
			data: t.null,
			error: t.type({
				message: t.string
			})
		}),
		t.strict({
			data: t.unknown
		})
	])
]);

export type ResponseContainer = t.OutputOf<typeof ResponseContainerCodec>;


/*

t.partial({
	status: t.union([t.literal("success"), t.literal("error")]),
	data: t.union([t.unknown, t.null]),
	error: t.type({
		message: t.string
	})
})


*/
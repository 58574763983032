import React, { useState } from 'react';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import styled from "styled-components";
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { ElementRecipe } from '../../../model/ElementRecipe';
import { removeTag, updateFilterName } from '../../../store/actions/FilterTagActions';
import RecipeActions from '../basicComponents/RecipeActions';
import { FIELD_DELIMITER, FILE_TYPE, SORT_ORDER, SORT_BY, SORT_BY_ELEMENT } from '../../../services/types/util/Constants';

interface RecipeListFilterProps {
    handleBlukPublish: () => void;
    handleBlukDraft: () => void;
    handleBlukArchive: () => void;
    handleExportRecipes: (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => void,
    handleExportAllRecipes: (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => void,
    setSortOrder: (_: SORT_ORDER) => void,
    setSortBy: (_: SORT_BY) => void;
    setSortByElement: (_: SORT_BY_ELEMENT) => void;
}

const RecipeListFilter = ({handleBlukPublish, handleBlukDraft, handleBlukArchive, handleExportRecipes, handleExportAllRecipes, setSortOrder, setSortBy, setSortByElement}: RecipeListFilterProps) => {
    const [sortOption, setSortOption] = useState(0);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSortOption(event.target.value as number);
        switch (event.target.value) {
            case 0:
                setSortOrder('asc');
                setSortBy('createdAt');
                setSortByElement(undefined);
                break;
            case 1:
                setSortOrder('asc');
                setSortBy('name');
                setSortByElement(undefined);
                break;
            case 2:
                setSortOrder('desc');
                setSortBy('name');
                setSortByElement(undefined);
                break;
            case 3:
                setSortByElement('Featured Brands');
                setSortOrder('asc');
                setSortBy(undefined);
                break;
            case 4:
                setSortByElement('Featured Brands');
                setSortOrder('desc');
                setSortBy(undefined);
                break;
            case 5:
                setSortOrder('desc');
                setSortBy('status');
                setSortByElement(undefined);
                break;
            default:
                break;
        }
    };

    const { tags, filterName } = useSelector(state => state.transient.filterTags);
    const dispatch = useDispatch();

    const handlerCloseFilter = (tag: ElementRecipe, tagKey: string) => {
        dispatch(removeTag(tag, tagKey));
    }

    const handlerClearRecipeNameFilter = () => {
        dispatch(updateFilterName(''));
    }

    const renderTags = () => {
        const selectedTags = [];
        for (let tagKey in tags) {
            for (let tagId in tags[tagKey]) {
                selectedTags.push((
                    <Tag key={tagId}>
                        <span style={{whiteSpace: "nowrap"}}>
                            {tags[tagKey][tagId].name}
                        </span>
                        <IconButton 
                            aria-label="delete" 
                            size="small" 
                            onClick={e => handlerCloseFilter(tags[tagKey][tagId], tagKey)}
                        >
                            <CloseIcon fontSize="small" style={{color: 'white'}}/>
                        </IconButton>
                    </Tag>
                ));
            }
        }
        if (filterName && filterName.length > 0) {
            selectedTags.push((
                <Tag key={filterName}>
                    <span style={{whiteSpace: "nowrap"}}>
                        {filterName}
                    </span>
                    <IconButton 
                        aria-label="delete" 
                        size="small" 
                        onClick={e => handlerClearRecipeNameFilter()}
                    >
                        <CloseIcon fontSize="small" style={{color: 'white'}}/>
                    </IconButton>
                </Tag>
            ));
        }
        return selectedTags;
    }

    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mt={4} mb={4}>
            <Box display="flex" flexDirection="row" alignItems="baseline">
                <TitleFilter>Filtered by:</TitleFilter>
                <TagContainer>
                    {renderTags()}
                </TagContainer>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <FormControl variant="outlined" style={{width: '322px', margin: '0 20px'}} size="small">
                    <InputLabel id="demo-simple-select-outlined-label">SORT BY</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={sortOption}
                        onChange={handleChange}
                        label="SORT BY"
                        fullWidth
                        MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                    >
                        <MenuItem value={0}>
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>Recipe Name (A - Z)</MenuItem>
                        <MenuItem value={2}>Recipe Name (Z - A)</MenuItem>
                        <MenuItem value={3}>Brand (A - Z)</MenuItem>
                        <MenuItem value={4}>Brand (Z - A)</MenuItem>
                        <MenuItem value={5}>Publish Status</MenuItem>
                    </Select>
                </FormControl>
                <RecipeActions 
                    showAdminOptions={true} 
                    showExportOptions={true}
                    showImportOptions={true}
                    showExportAllOptions={true}
                    handlerPublish={handleBlukPublish}
                    handlerMoveToDraft={handleBlukDraft}
                    handlerArchiveSelected={handleBlukArchive}
                    handleExportRecipes={handleExportRecipes}
                    handleExportAllRecipes={handleExportAllRecipes}
                />
            </Box>
       </Box>
    );
};

const TitleFilter = styled.span`
    padding-right: 5px;
    white-space: nowrap;
`;

const TagContainer = styled.div`
    overflow-x: auto;
    display: flex;
    max-width: 800px;
`

const Tag = styled.div`
    background-color: #0c5d8e;
    color: white;
    padding: 2px 10px 2px 10px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    margin-left: 5px;
`;


export default RecipeListFilter;
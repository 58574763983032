import { DCHImagesActions, DCHImageState } from "../reducers/DCHImageReducer";

export const updateDCHImages = (dCHImages: DCHImageState): DCHImagesActions => ({
	type: "UPDATE_DCH_IMAGES",
	payload: dCHImages
});

export const clearDCHImages = (): DCHImagesActions => ({
	type: "CLEAR_DCH_IMAGES"
});

export const failDCHImages = (): DCHImagesActions => ({
	type: "FAIL_DCH_IMAGES"
});
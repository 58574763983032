import { Reducer } from "redux";

interface StatusUpdateState {
    rejectedContentsCount: number;
    error: boolean;
}

const defaultNewRecipeState: StatusUpdateState = {
    rejectedContentsCount: 0,
    error: false
};

export const statusUpdateReducer: Reducer<StatusUpdateState, StatusUpdateActions> = (
    state: StatusUpdateState = defaultNewRecipeState,
    action: StatusUpdateActions
) => {
    switch (action.type) {
        case "UPDATE_STATUS":
            return {rejectedContentsCount: action.rejectedContentsCount, error: false}
        case "FAIL_UPDATE_STATUS":
            return {rejectedContentsCount: 0, error: true};
        case "CLEAR_STATUS_ERROR":
            return defaultNewRecipeState;
        default:
            return state;
    }
};

export type StatusUpdateActions = 
    | {
        type: "UPDATE_STATUS",
        rejectedContentsCount: number;
    }
    | {
        type: "FAIL_UPDATE_STATUS"
    }
    | {
        type: "CLEAR_STATUS_ERROR"
    };
import { errorDrinkShot, errorLifeStyle, updateDrinkShotFromJanus, updateLifeStyleFromJanus, updateUploadingDrinkShotFromJanus, updateUploadingLifeStyleFromJanus } from "../../store/actions/fileActions";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { ImageResponse, ImageResponseCodec, UploadingImage, uploadingImageResponse } from "../types/ImageResponseCodec";

export const postImage = (data: FormData, formKey: "drinkShot" | "lifeStyle"): ServiceCallAction<uploadingImageResponse> => ({
	method: "POST",
	endpoint: `recipe/media/create`,
  serviceKey: "postImage",
  body: {type: 'multipart', value: data},
	responseDecoder: UploadingImage,
	type: "SERVICE_CALL",
	onSuccess: formKey === "drinkShot" ? updateUploadingDrinkShotFromJanus : updateUploadingLifeStyleFromJanus,
	onFailure: formKey === "drinkShot" ? errorDrinkShot : errorLifeStyle,
});

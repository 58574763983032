import { Reducer } from "redux";

export interface RecipeFormState {
    recipeName?: string;
    _id?: string;
    brand?: string;
    primaryVariant?: string;
    secondaryBrand?: string;
    secondaryVariant?: string;
    serveStrategy?: string[];
    servingSize?: string;
    preparationSteps?: string;
    menuCopy?: string;
    complexity?: string;
    drinkTypes?: string[];
    newdrinkTypes?: {name: string, checked: boolean}[];
    occassions?: string[];
    newoccassions?: {name: string, checked: boolean}[];
    mixers?: string[];
    newmixers?: {name: string, checked: boolean}[];
    glassware?: string[];
    newglassware?: {name: string, checked: boolean}[];
    foodPairings?: {name: string, checked: boolean}[];
    ingredients?: {description: string, id: string}[];
    servinStrategy?: string[];
    auxiliarName?: string
}

const defaultState: RecipeFormState = {
    servingSize: "1"
};

export const LoadFormReducer: Reducer<RecipeFormState, LoadFormActions> = (
    state: RecipeFormState = defaultState,
    action: LoadFormActions
) => {
    switch (action.type) {
        case "LOAD":
            return action.recipeForm;
        case "CLEAR":
            return defaultState;
        default:
            return state;
    }
};

export type LoadFormActions = 
| {
    type: "LOAD";
    recipeForm: RecipeFormState;
}
| {
    type: "CLEAR"
};
import { ElementRecipe } from '../../model/ElementRecipe';
import { ElementsRecipeActions } from '../reducers/elementsRecipeReducer';

interface elementsContent {
    contents: ElementRecipe[];
};

const compareNames = (a: ElementRecipe, b: ElementRecipe) => {
	var nameA = a.name.trim().toUpperCase();
	var nameB = b.name.trim().toUpperCase();
	if (nameA < nameB) {
	  return -1;
	}
	if (nameA > nameB) {
	  return 1;
	}
	return 0;
}

export const updateBrands = (elementsContent: elementsContent): ElementsRecipeActions => ({
	type: "UPDATE_BRANDS",
	elementRecipe: elementsContent.contents.sort(compareNames)
});

export const updateVariants = (elementsContent: elementsContent): ElementsRecipeActions => ({
	type: "UPDATE_VARIANTS",
	elementRecipe: elementsContent.contents.sort(compareNames)
});

export const updatePrimaryVariants = (elementsContent: elementsContent): ElementsRecipeActions => ({
	type: "UPDATE_PRYMARY_VARIANTS",
	elementRecipe: elementsContent.contents.sort(compareNames)
});

export const updateSecondaryVariants = (elementsContent: elementsContent): ElementsRecipeActions => ({
	type: "UPDATE_SECONDARY_VARIANTS",
	elementRecipe: elementsContent.contents.sort(compareNames)
});

export const clearVariantsAssociatedWithBrands = (): ElementsRecipeActions => ({
	type: "CLEAR_VARIANTS"
});

export const updateServeStrategy = (elementsContent: elementsContent): ElementsRecipeActions => ({
	type: "UPDATE_SERVE_STRATEGY",
	elementRecipe: elementsContent.contents.sort(compareNames)
});

export const updateMixers = (elementsContent: elementsContent): ElementsRecipeActions => ({
	type: "UPDATE_MIXERS",
	elementRecipe: elementsContent.contents.sort(compareNames)
});

export const updateOccasions = (elementsContent: elementsContent): ElementsRecipeActions => ({
	type: "UPDATE_OCCASIONS",
	elementRecipe: elementsContent.contents.sort(compareNames)
});

export const updateGlasswares = (elementsContent: elementsContent): ElementsRecipeActions => ({
	type: "UPDATE_GLASSWARE",
	elementRecipe: elementsContent.contents.sort(compareNames)
});

export const updateComplexity = (elementsContent: elementsContent): ElementsRecipeActions => ({
	type: "UPDATE_COMPLEXITY",
	elementRecipe: elementsContent.contents.sort(compareNames)
});

export const updateDrinkTypes = (elementsContent: elementsContent): ElementsRecipeActions => ({
	type: "UPDATE_DRINK_TYPES",
	elementRecipe: elementsContent.contents.sort(compareNames)
});
import React, { useEffect, useState } from 'react';
import RecipeCreateFifthStep from './fifthStep/RecipeCreateFifthStep';
import RecipeCreateFistStep from './firsStep/RecipeCreateFirstStep';
import RecipeCreateFourthStep from './fourthStep/RecipeCreateFourthStep';
import RecipeCreateHeader from './RecipeCreateHeader';
import RecipeCreateSecondStep from './secondStep/RecipeCreateSecondStep';
import RecipeCreateThirdStep from './thirdStep/RecipeCreateThirdStep';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getBrands, getComplexities, getDrinkTypes, getGlasswares, getMixers, getOccasions, getServeStrategy, getVariants, getVariantsAssociatedWithBrands } from '../../../services/actions/ElementsRecipeService';
import { FileImage } from '../../../model/FileImage';
import { createRecipe, updateRecipe } from '../../../services/actions/RecipesService';
import { clearNewRecipe } from '../../../store/actions/recipeActions';
import { NewRecipe, Recipe } from '../../../model/Recipe';
import { Card, CardContent, Snackbar } from '@material-ui/core';
import { RecipeFormState } from '../../../store/reducers/LoadFormReducer';
import { initialize, reset } from 'redux-form';
import { Alert } from '@material-ui/lab';
import {
    clearFiles,
    updateLifeStyleFromJanus,
    updateDrinkShotFromJanus,
    errorDrinkShot, errorLifeStyle
} from '../../../store/actions/fileActions';
import { recipeReloadedForm } from '../../../store/actions/RecipeFormActions';
import { clearCopyRecipe } from '../../../store/actions/CopyRecipeAction';
import { clearVariantsAssociatedWithBrands } from '../../../store/actions/elementsRecipeAction';
import { useHistory } from 'react-router-dom';
import { clearCreateRecipe } from '../../../store/actions/CreateRecipeActions';
import { getJanusImage } from '../../../services/actions/DCHImagesService';
import { isRight } from "fp-ts/These";

const steps = [
    {
        header: "Step 1:",
        description: "Name, Serve Strategy, Images",
        id: 0,
        isFirst: true
    },
    {
        header: "Step 2:",
        description: "Ingredients, Serving Size",
        id: 1,
        isFirst: false
    },
    {
        header: "Step 3:",
        description: "Prep Steps, Menu Copy",
        id: 2,
        isFirst: false
    },
    {
        header: "Step 4:",
        description: "Drink Types & Tags",
        id: 3,
        isFirst: false
    },
    {
        header: "Step 5:",
        description: "Review & Submit",
        id: 4,
        isFirst: false
    }
];

const saveTags = (body: any, field: string, newField: string, tags: string[] | null, newTags: {name: string, checked: boolean}[] | null) => {
    if (tags && tags.length > 0) {
        body[field] = tags;
    }
    if (newTags && newTags.length > 0) {
        body[newField] = [];
        newTags.forEach((tag: {name: string, checked: boolean}) => {
            if (tag.checked) {
                body[newField].push(tag.name);
            }
        });
    }
}

const parseForm = (formData: any, enviroment: 'live' | 'draft', drinkShot: FileImage | null, lifeStyle: FileImage | null, recipe: NewRecipe | null, copyRecipe?: Recipe | null) => {
    const body: any = {};

    body.name = formData.recipeName;
    body.brand = formData.brand;
    body.primaryVariant = formData.primaryVariant;
    body.enviroment = enviroment;

    if (recipe && recipe._id.length > 0) {
        body._id = recipe._id;
    }

    if (formData.secondaryBrand) {
        body.secondaryBrand = formData.secondaryBrand;
    }
    if (formData.secondaryVariant) {
        body.secondaryVariant = formData.secondaryVariant;
    }
    if (formData.servinStrategy && formData.servinStrategy.length > 0) {
        body.serveStrategy = formData.servinStrategy;
    }
    if (formData.servingSize) {
        body.servingSize = formData.servingSize;
    }
    if (drinkShot && drinkShot.janusFile) {
        const thumbnail = {url: drinkShot.janusFile.metadata.thumbnail};
        body.drinkShot = {url: drinkShot.janusFile.url, asset: drinkShot.janusFile._id, thumbnail: thumbnail};
    }
    if (lifeStyle && lifeStyle.janusFile) {
        const thumbnail = {url: lifeStyle.janusFile.metadata.thumbnail};
        body.lifeStyle = {url: lifeStyle.janusFile.url, asset: lifeStyle.janusFile._id, thumbnail: thumbnail};
    }

    if (formData.ingredients && formData.ingredients.length > 0) {
        body.ingredients = [];
        formData.ingredients.forEach((ingredient: {description: string, id: string}) => {
            body.ingredients.push(ingredient.description);
        });
    }
    if (formData.preparationSteps) {
        body.preparationSteps = formData.preparationSteps;
    }
    if (formData.menuCopy) {
        body.menuCopy = formData.menuCopy;
    }
    if (formData.complexity) {
        body.complexity = formData.complexity;
    }
    saveTags(body, 'drinkTypes', 'newDrinkTypes', formData.drinkTypes || null, formData.newdrinkTypes || null);
    saveTags(body, 'occassions', 'newOccassions', formData.occassions || null, formData.newoccassions || null);
    saveTags(body, 'mixers', 'newMixers', formData.mixers || null, formData.newmixers || null);
    saveTags(body, 'glassware', 'newGlassware', formData.glassware || null, formData.newglassware || null);
    saveTags(body, '', 'foodPairings', null, formData.foodPairings || null);

    return body;
}

const RecipeCreate = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedStep, setSelectedStep] = useState(steps[0].id);
    const [submittingForm, setsubmittingForm] = useState(false);
    const formState = useSelector(state => state.form);
    const { drinkShot, lifeStyle, drinkShotId, lifeStyleId } = useSelector(state => state.transient.fileImages);
    const { newRecipe, error } = useSelector(state => state.transient.newRecipe);
    const { copyRecipe, recipeCreated } = useSelector(state => state.transient);
    const { reload } = useSelector(state => state.transient.reloadRecipeForm);
    const [openError, setOpenError] = useState(false);
    const [ checkLifeStyle, setCheckLifeStyle ] = useState(false);
    const [ checkDrinkShot, setCheckDrinkShot ] = useState(false);
    const UPDATE_TIME = 6 * 1000;


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const runCheckProgressTimeout = (formKey: string) => {
        if (formKey === 'drinkShot') {
            setCheckDrinkShot(false);
            setTimeout(() => {
                setCheckDrinkShot(true);
            }, UPDATE_TIME);
        } else {
            setCheckLifeStyle(false);
            setTimeout(() => {
                setCheckLifeStyle(true);
            }, UPDATE_TIME);
        }
    }

    const checkProgress = async (id: string, formKey: string) => {
        if (formKey === 'drinkShot') {
            setCheckDrinkShot(false);
        } else {
            setCheckLifeStyle(false);
        }
        const resp = await dispatch(getJanusImage(id, formKey));
        if (!isRight(resp)) {
            if (formKey === "lifeStyle") {
                dispatch(errorDrinkShot());
            } else {
                dispatch(errorLifeStyle())
            }
            return;
        }

        if ( !resp.right.asset) {
            return runCheckProgressTimeout(formKey);
        }

        if (formKey === "lifeStyle") {
            dispatch(updateLifeStyleFromJanus({asset: resp.right.asset}));
        } else {
            dispatch(updateDrinkShotFromJanus({asset: resp.right.asset}));
        }

    }

    useEffect(() => {
        if (checkLifeStyle) {
            checkProgress(lifeStyleId, "lifeStyle");
        }
    }, [checkLifeStyle])

    useEffect(() => {
        if (checkDrinkShot) {
            checkProgress(drinkShotId, "drinkShot");
        }
    }, [ checkDrinkShot])

    useEffect(()=> {
        if (drinkShotId.length > 0) {
            setCheckDrinkShot(true);
        }
    }, [drinkShotId])

    useEffect(()=> {
        if (lifeStyleId.length > 0) {
            setCheckLifeStyle(true);
        }
    }, [lifeStyleId])

    const reloadForm = (recipeToLoad: Recipe, recipeCreation: boolean) => {

        const initForm: RecipeFormState = {
            servingSize: recipeToLoad.servingSize,
            serveStrategy: recipeToLoad.serveStrategy,
            preparationSteps: recipeToLoad.preparationSteps,
            menuCopy: recipeToLoad.menuCopy,
            complexity: recipeToLoad.complexity,
            drinkTypes: recipeToLoad.drinkTypes,
            newdrinkTypes: [],
            occassions: recipeToLoad.occasions,
            newoccassions: [],
            mixers: recipeToLoad.mixers,
            newmixers: [],
            glassware: recipeToLoad.glassware,
            newglassware: [],
            foodPairings: recipeToLoad.foodPairing.map(fp => {
                return {
                    name: fp,
                    checked: true
                }
            }),
            ingredients: recipeToLoad.ingredients.map((ing, index) => {
                return {
                    description: ing.ingredient ? ing.ingredient: '',
                    id: index.toString()
                }
            }),
            brand: recipeToLoad.brand,
            secondaryBrand: recipeToLoad.secondaryBrand,
            primaryVariant: recipeToLoad.primaryVariant,
            secondaryVariant: recipeToLoad.secondaryVariant,
            recipeName: recipeToLoad.name,
            servinStrategy: recipeToLoad.serveStrategy,
            auxiliarName: !recipeCreation ? recipeToLoad.name : undefined
        };
        dispatch(initialize('createRecipe', initForm));
    }

    const fetchRecipeFields = () => {
        const recipeBrand = newRecipe || copyRecipe.recipe;
        if (recipeBrand && recipeBrand.brand) {
            dispatch(getVariantsAssociatedWithBrands(recipeBrand.brand, true));
        }
        const recipeSecondaryBrand = newRecipe || copyRecipe.recipe;
        if (recipeSecondaryBrand && recipeSecondaryBrand.secondaryBrand) {
            dispatch(getVariantsAssociatedWithBrands(recipeSecondaryBrand.secondaryBrand, false));
        }
        dispatch(getBrands());
        dispatch(getVariants());
        dispatch(getServeStrategy());
        dispatch(getMixers());
        dispatch(getOccasions());
        dispatch(getGlasswares());
        dispatch(getComplexities());
        dispatch(getDrinkTypes());
    }

    useEffect(() => {
        if (newRecipe) {
            fetchRecipeFields();
            reloadForm(newRecipe, true);
            if (newRecipe.lifeStyle.media) {
                const lifeStyleImage = {asset: newRecipe.lifeStyle.media};
                dispatch(updateLifeStyleFromJanus(lifeStyleImage))
            }
            if (newRecipe.drinkShot.media) {
                const drinkShotImage = {asset: newRecipe.drinkShot.media};
                dispatch(updateDrinkShotFromJanus(drinkShotImage))
            }
        }
        if (error) {
            setOpenError(true);
        }
        setsubmittingForm(false);
    }, [newRecipe, error]);

    useEffect(() => {
        if (reload) {
            dispatch(clearNewRecipe());
            dispatch(clearFiles());
            fetchRecipeFields();
            setSelectedStep(steps[0].id);
            dispatch(recipeReloadedForm());
        }
    }, [reload]);

    useEffect(() => {
        if (recipeCreated.created) {
            history.push('/recipe/list');
        }
    }, [recipeCreated]);

    useEffect(() => {
        if (copyRecipe.recipe) {
            fetchRecipeFields();
            reloadForm(copyRecipe.recipe, false);
            if (copyRecipe.recipe.lifeStyle.media) {
                const lifeStyleImage = {asset: copyRecipe.recipe.lifeStyle.media};
                dispatch(updateLifeStyleFromJanus(lifeStyleImage))
            }
            if (copyRecipe.recipe.drinkShot.media) {
                const drinkShotImage = {asset: copyRecipe.recipe.drinkShot.media};
                dispatch(updateDrinkShotFromJanus(drinkShotImage))
            }
        }
    }, [copyRecipe]);

    useEffect(() => {
        dispatch(clearNewRecipe());
        fetchRecipeFields();
        return () => {

            dispatch(clearCreateRecipe());
            dispatch(reset('createRecipe'));
            dispatch(clearCopyRecipe());
            dispatch(clearNewRecipe());
            dispatch(clearFiles());
            dispatch(clearVariantsAssociatedWithBrands());
            dispatch(initialize('createRecipe', {servingSize: "1"}));
            // clearTimeout(timerIdDrinkShot);
            // setTimerIdDrinkShot(undefined);
            // clearTimeout(timerIdLifeStyle);
            // setTimerIdLifeStyle(undefined);
        }
    }, []);

    const next = () => {
        setSelectedStep(selectedStep + 1);
    }

    const prev = () => {
        setSelectedStep(selectedStep - 1);
    }

    const sendRecipe = (enviroment: 'live' | 'draft', data: any) => {
        const body = parseForm(data, enviroment, drinkShot, lifeStyle, newRecipe, copyRecipe.recipe);
        setsubmittingForm(true);
        if (newRecipe?._id) {
            dispatch(updateRecipe(body, newRecipe._id));
        } else {
            dispatch(createRecipe(body));
        }
    }

    const onSubmit = (data: any) => {
        sendRecipe('draft', data);

    };

    const saveAsLive = () => {
        sendRecipe('live', formState.createRecipe.values);
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }

        setOpenError(false);
    };

    return (
        <Card>
            <CardContent>
                <Box mx="auto" p={4}>
                    <RecipeCreateHeader steps={steps} selectedStep={selectedStep} onSelectedChange={setSelectedStep}/>
                    <Box mx="auto" p={1}>
                        {selectedStep === 0 && <RecipeCreateFistStep  onSubmit={next}/>}
                        {selectedStep === 1 && <RecipeCreateSecondStep  previousPage={prev} onSubmit={next} />}
                        {selectedStep === 2 && <RecipeCreateThirdStep  previousPage={prev} onSubmit={next} />}
                        {selectedStep === 3 && <RecipeCreateFourthStep  previousPage={prev} onSubmit={next} />}
                        {selectedStep === 4 && <RecipeCreateFifthStep  previousPage={prev} saveAsLive={saveAsLive} onSubmit={onSubmit} submittingForm={submittingForm}/>}
                    </Box>
                </Box>
            </CardContent>
            <Snackbar open={openError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Error saving recipe!
                </Alert>
            </Snackbar>
        </Card>
    );
};

export default RecipeCreate;

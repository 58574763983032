import { Reducer } from "redux";
import { Recipe } from "../../model/Recipe";

export interface CopyRecipeState {
    recipe: Recipe | null;
};

const defaultState: CopyRecipeState = {
    recipe: null
};

export const CopyRecipeReducer: Reducer<CopyRecipeState, CopyRecipeActions> = (
    state: CopyRecipeState = defaultState,
    action: CopyRecipeActions
) => {
    switch (action.type) {
        case "UPDATE_COPY_RECIPE":
            return {recipe: action.recipe};
        case "CLEAR_COPY_RECIPE":
            return defaultState;
        default:
            return state;
    }
};

export type CopyRecipeActions = 
    | {
        type: "UPDATE_COPY_RECIPE";
        recipe: Recipe;
    }
    | {
        type: "CLEAR_COPY_RECIPE"
    };
import React, { useEffect, useState } from 'react';
import { JanusFile } from '../../../model/FileImage';
import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Backdrop, Box, Button, CircularProgress, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { LoadRecipeDetail } from '../../../model/Recipe';
import ImageModal from '../basicComponents/uploadImage/ImageModal';
import { useDispatch, useSelector } from 'react-redux';
import { updateMediaName } from '../../../services/actions/MediaService';

interface MediaDetailProps {
    mediaSelected: JanusFile;
    closeDetail: () => void;
    setSelectedRecipe: (_id: string) => void;
}

const MediaDetail = ({mediaSelected, closeDetail, setSelectedRecipe}: MediaDetailProps) => {
    const classes = useStyles();
    const [name, setName] = useState(mediaSelected.name);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const {media} = useSelector(state => state.transient);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(false);
    }, [media]);

    useEffect(() => {
        setName(mediaSelected.name);
    }, [mediaSelected]);

    const getDimensions = () => {
        if (mediaSelected.metadata.width && mediaSelected.metadata.height) {
            return mediaSelected.metadata.width.toString() + ' X ' + mediaSelected.metadata.height.toString();
        }
        return 'Unknown';
    }

    const getURL = (): string => {
        return process.env.REACT_APP_API_URL + '/media/stream/' + mediaSelected._id;
        //return mediaSelected.metadata.thumbnail ? mediaSelected.metadata.thumbnail : mediaSelected.url;
    }

    const addDefaultSrc = (ev: any) => {
        ev.target.src = '/assets/extensions/_blank.png'
    }

    const loadRecipeDetail = (_id: string) => {
        setSelectedRecipe(_id);
    }

    const getUsedBy = () => {
        if (mediaSelected.usedBy && mediaSelected.usedBy.length > 0) {
            return mediaSelected.usedBy.map( item =>{
                let name = item.name.length > 20 ? item.name.slice(0,20) + '...' : item.name;
                return (
                    <Grid container key={item._id}>
                        <Grid item xs={10}>
                            <RecipeName onClick={e => loadRecipeDetail(item._id)}>
                                {name}
                            </RecipeName>
                        </Grid>
                        <Grid item xs={2}>
                            <span>{item.status}</span>
                        </Grid>
                    </Grid>
                )
            })
        }
        return <div></div>;
    }

    const updateMedia = () => {
        setLoading(true);
        dispatch(updateMediaName(mediaSelected._id, name));
    }

    return (
        <DetailContainer>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <div style={{fontSize: '18px'}}>
                    Asset Information
                </div>
                <IconButton onClick={closeDetail}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DetailElement>
                <span>Name:</span>
            </DetailElement>
            <form noValidate autoComplete="off">
                <TextField 
                    fullWidth 
                    variant="outlined"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    inputProps={{style: { backgroundColor: 'white', padding: '10px', borderRadius: '4px' }}}
                />
            </form>
            <DetailElement>
                <Button 
                    variant="contained"
                    color="primary" 
                    onClick={updateMedia}
                    style={{textTransform: 'none'}} 
                >
                    Update
                </Button>
            </DetailElement>

            <DetailSection>
                <DetailElement>
                    <span>DCH ID:</span>
                </DetailElement>
                <DetailElement>
                    {mediaSelected.metadata.ownerID ? mediaSelected.metadata.ownerID : 'NA'}
                </DetailElement>
            </DetailSection>

            <DetailSection>
                <DetailElement>
                    <span>Dimensions: {getDimensions()}</span>
                </DetailElement>
            </DetailSection>

            <DetailSection>
                <DetailElement>
                    <span>Used by:</span>
                </DetailElement>
                <DetailElement>
                    <UsedByContainer>
                        {getUsedBy()}
                    </UsedByContainer>
                </DetailElement>
            </DetailSection>

            <ImageContainer1 >
                    <ImageContainer3>
                        <ImageContainer4>
                        <Image 
                            onError={addDefaultSrc} 
                            src={getURL()} alt="recipe"
                            onClick={e => setOpenModal(true)}
                        />
                        <ImageModal
                            open={openModal} 
                            onClose={() => setOpenModal(false)} 
                            url={mediaSelected.url}
                        />
                        </ImageContainer4>
                    </ImageContainer3>
            </ImageContainer1>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </DetailContainer>
    )
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  }),
);

const ImageContainer4 = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
`;

const ImageContainer1 = styled.div`
    position: relative;
    margin: 0 auto 0 auto;
    width: 100%;
    max-width: 90%;
`;

const ImageContainer2 = styled.div`
    cursor: pointer;
    position: relative;
`;

const ImageContainerSelected = styled(ImageContainer2)`
    border: 2px solid #47a8ec;
`;

const ImageContainer3 = styled.div`
    height: 150px;
`;

const DetailContainer = styled.div`
    padding: 10px;
    width: 400px;
    border-radius: 5px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
`
const DetailSection = styled.div`
    margin: 20px 0px;
`
const DetailElement = styled.div`
    margin: 10px 0px;
`

const ImageContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
`;

const Image = styled.img`
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    cursor: pointer;
`;

const UsedByContainer = styled.div`
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    max-height: 150px;
    overflow-y: auto;
`
const RecipeName = styled.span`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

export default MediaDetail;
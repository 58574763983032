import React from 'react';
import styled from "styled-components";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';

interface ImportRecipeResult {
    file: File;
    importing: boolean;
}

const ImportRecipeResult = ({file, importing}: ImportRecipeResult) => {

    const { rows, running, id, error} = useSelector(state => state.transient.recipesImported)

    const getFileSize = () => {
        return file.size > 1000 ? Math.trunc(file.size / 1024).toString() + ' MB' : file.size.toString() + ' KB';
    }

    const renderImportingStatus = () => {
        if (importing) {
            return <CircularProgress size={24}/>;
        }
        if (error) {
            return <ErrorIcon />;
        }
        if (rows.length > 0) {
            return <CheckIcon />;
        }

    };

    const renderResult = () => {
        if (!error && rows.length > 0) {
            return (
                <div>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell># Row</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Comments</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rows.map(row => (
                                        <TableRow key={row.row_number}>
                                            <TableCell 
                                                component="th" 
                                                scope="row" 
                                                style={{width: "17%"}}
                                            >
                                                {row.row_number}
                                            </TableCell>
                                            <TableCell 
                                                align="left"
                                                style={{width: "29%"}}
                                            >
                                                {row.row_name}
                                            </TableCell>
                                            <TableCell 
                                                align="left"
                                                style={{width: "5%"}}
                                            >
                                                {
                                                    row.row_status_ok ?
                                                        <CheckIcon />
                                                    :
                                                        <ErrorIcon />
                                                }
                                            </TableCell>
                                            <TableCell 
                                                align="left"
                                                style={{width: "49%"}}
                                            >
                                                {row.row_message}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )
        } else if (error) {
            return <Message>Error importing recipes</Message>
        }
    }

    return (
        <div>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Size</TableCell>
                        <TableCell align="right">Status</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    
                        <TableRow >
                        <TableCell component="th" scope="row">
                            {file?.name}
                        </TableCell>
                        <TableCell align="right">{getFileSize()}</TableCell>
                        <TableCell align="right">
                            {renderImportingStatus()}
                        </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
            <Box pt={3}>
                <Message>
                    <Important>
                    IMPORTANT: 
                    </Important>
                     All records will be processed together if all have the correct data. This may take a while based on the amount of records.
                </Message>
            </Box>
            <Box pt={3}>
                {renderResult()}
            </Box>
            
        </div>
    )
}

const Message = styled.span`
    font-family: sans-serif;
    font-size: 15px;
`;

const Important = styled.span`
    font-weight: bold;
`;

export default ImportRecipeResult;
import React, {useCallback} from 'react';
import { Box } from '@material-ui/core';
import styled from "styled-components";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DropTargetMonitor } from 'react-dnd'
import TargetBox from "../../basicComponents/uploadFile/TargetBox";
import {useDispatch} from "react-redux";
import {addVideoMaxTime} from "../../../../store/actions/FullMediaUploadingActions";

function MenuIcon(props: any) {
  return (
    <div>
      <img src={props.src} style={props.style}/>
    </div>
  );
}

interface UploadFullMediaFileProps {
  setFile: (file: File) => void;
  setShowErrorFile: (value: boolean) => void;
}

const prohibitedExtensions = ['image/vnd.adobe.photoshop', 'application/vnd.adobe.photoshop']
window.URL = window.URL || window.webkitURL;
// let myVideos: any[] = [];


const UploadFullMediaFile = ({setFile, setShowErrorFile}: UploadFullMediaFileProps) => {

  const dispatch = useDispatch();

  const onChange = (e: any) => {
    console.log( e.target.files[0] )

    let video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = function() {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration;
      dispatch(addVideoMaxTime(new Date(duration * 1000).toISOString().substr(11, 12)));
    }
    video.src = URL.createObjectURL(e.target.files[0]);


    const extesion = e.target.files[0].name.split('.').pop().toLowerCase();
    const type = e.target.files[0].type;
    if (extesion === 'psd' || prohibitedExtensions.includes(type)) {
      setShowErrorFile(true);
      return;
    }

    setFile(e.target.files[0]);
  }

  const handleFileDrop = useCallback(
    (item: any, monitor: DropTargetMonitor) => {
      if (monitor) {
        setFile(monitor.getItem().files[0])
      }
    },
    [],
  )

  const renderImportFile = () => {
    return (
      <div style={{width: "100%"}}>
        <input
          id="importFile"
          style={{display: 'none'}}
          type="file"
          onChange={onChange}
        />
        <label htmlFor="importFile">
          <DropZone style={{fontFamily: 'sans-serif'}}>
            <div style={{textAlign: "center", marginTop: "20px", fontSize: "16px"}}>
              Select a file from disk or drag & drop here to upload
            </div>
          </DropZone>
        </label>
      </div>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <DndProvider backend={HTML5Backend}>
        <TargetBox onDrop={handleFileDrop}>
          {renderImportFile()}
        </TargetBox>

      </DndProvider>
    </Box>
  );
};

const DropZone = styled.div`
    margin: 0;
    display: flex;
    min-height: 200px;
    border: dotted 3px lightgray;
    background-color: #f5f5f5;
    cursor: pointer;
    font-family: 'sans-serif';
    flex-direction: column;
    width: 100%;
    justify-content: center;
`;


export default UploadFullMediaFile;

import React, { useState } from 'react';
import { Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch } from 'react-redux';
import { updateFilterName } from '../../../store/actions/FilterTagActions';
import { string } from 'io-ts';

interface FilterNameProps {
    title: string;
    placeholder: string;
}

const FilterName = ({title, placeholder}: FilterNameProps) => {
    const [filter, setFilter] = useState('');
    const [searched, setSearched] = useState(false);
    const dispatch = useDispatch();

    const handleClick = () => {
        if (filter.length === 0) {
            return;
        }
        dispatch(updateFilterName(filter));
        setFilter('');
        setSearched(true);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (searched && filter.length > 0 && event.target.value.length === 0) {
            dispatch(updateFilterName(''));
            setSearched(false);
        }
        setFilter(event.target.value);
    };

    return (
        <Box mt={1} mb={1} display="flex" flexDirection="row" justifyContent="center">
            <FormControl variant="outlined" size="small" style={{width: "95%"}}>
                <InputLabel htmlFor="filter-recipe-by-keyboard">{title}</InputLabel>
                <OutlinedInput
                    id="filter-recipe-by-keyboard"
                    type='text'
                    placeholder={placeholder}
                    value={filter}
                    onChange={handleChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClick}
                                edge="end"
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                    labelWidth={100}
                />
            </FormControl>
        </Box>
    );
};

export default FilterName;
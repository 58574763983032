import * as t from "io-ts";

export const ElementRecipeCodec = t.strict({
    _id: t.string,
    name: t.string,
    slug: t.string,
    contentModel_id: t.string,
    status: t.string,
    createdByUser: t.string,
    createdAt: t.string,
    updatedAt: t.type({
        title: t.string,
        textToShow: t.string,
        raw: t.string,
    }),
    elements: t.array(t.unknown),
    appid: t.string,
    uuid: t.string,
    version: t.number,
});

export const ElementsRecipeCodec = t.array(ElementRecipeCodec);

export const ElementsRecipeCodecResponseCodec = t.strict({
    contents: ElementsRecipeCodec
});

export type ElementsRecipeResponse = t.OutputOf<typeof ElementsRecipeCodecResponseCodec>;
import React from "react";
import {RecipeData, RecipesWithExcludedVariantsAndBrand, WarningMessage} from "../../../../model/RecipeErrors";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {RecipeEnvironment} from "./RecipeEnvironment";
import SearchIcon from "@material-ui/icons/Search";
import styled from "styled-components";
import {FixButton} from "./FixButton";
import {getRecipeDetail} from "../../../../services/actions/RecipeIssuesService";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

interface Props {
    recipes: RecipeData[];
    typeError: 'thumbnail' | 'brand' | 'variant'
    type: string;
    error: string;
    showDialog: (messages: string[]) => void;
    setWarningMessage: (value: WarningMessage) => void;
    setSelectedRecipe: (_id: string) => void;
}

export const RecipeDataRows = ({ recipes, typeError, type, error, showDialog, setWarningMessage, setSelectedRecipe }: Props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const fixIssue = (recipe: RecipeData) => {
        dispatch(getRecipeDetail(recipe._id));
        history.push('/recipe/new');
    }

    const showWaring = (recipe: RecipeData) => {
        let messages: string[] = [];
        switch (typeError) {
            case "variant":
                messages = ['To fix this you need to add the variant in the recipe'];
                break;
            case "thumbnail":
                messages = [
                    '- You can reload an image into the recipe for the thumbnail to be generated',
                    '- You can upload the thumbnail manually in Janus'
                ];
                break;
            case "brand":
                messages = ['To fix this you need to add the brand in the recipe'];
                break;
        }
        setWarningMessage({
            showWaring: true,
            messages: [...messages, 'Are you sure to continue?'],
            callback: () => fixIssue(recipe),
            ok: 'Edit Recipe'
        })
    }

    const render = (item: RecipeData) => {
        return (
            <TableRow key={item._id}>
                <TableCell component="th" scope="row">
                    {type}
                </TableCell>
                <TableCell
                    component="th"
                    scope="row"
                    style={{ cursor: 'pointer'}}
                    onClick={e => setSelectedRecipe(item._id)}
                >
                    <RecipeName>{item.name}</RecipeName>
                </TableCell>
                <TableCell component="th" scope="row">
                    <RecipeEnvironment environment={item.status} />
                </TableCell>
                <TableCell component="th" scope="row">
                    <InfoCell onClick={() => showDialog( item.messages ? item.messages : [] )}>
                        <SearchIcon style={{marginRight: '10px'}}/>
                        <span>{error}</span>
                    </InfoCell>
                </TableCell>
                <TableCell component="th" scope="row">
                    <FixButton onClick={ () => showWaring(item) } />
                </TableCell>
            </TableRow>
        )
    }

    return (
        <>
            {
                recipes && recipes.length > 0 &&
                recipes.map( render )
            }
        </>
    )
}

const InfoCell = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RecipeName = styled.span`
  color: blue;
  text-decoration: underline;
`;

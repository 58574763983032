import React, {useEffect, useState} from "react";
import {RecipeErrorsParams} from "../../../../model/RecipeErrors";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import styled from "styled-components";

interface Props {
    open: boolean;
    handleClose: (_: boolean) => void;
    setOptions: (options: RecipeErrorsParams) => void;
    options: RecipeErrorsParams;
}

export const FilterErrorsOptions = ({open, handleClose, setOptions, options }: Props) => {

    const [ auxOptions, setAuxOptions ] = useState<RecipeErrorsParams>(options);

    const [state, setState] = React.useState({
        live: options.environments.includes('live'),
        draft: options.environments.includes('draft'),
        archived: options.environments.includes('archived')
    });


    useEffect(() => {
        if (open) {
            setAuxOptions({...options});
            setState({
                live: options.environments.includes('live'),
                draft: options.environments.includes('draft'),
                archived: options.environments.includes('archived')
            })
        }
    }, [open])

    const handleEnvironment = (event: React.ChangeEvent<HTMLInputElement>) => {
        let environments = auxOptions.environments.split('|');
        if (event.target.checked) {
            environments.push(event.target.name)
        } else {
            environments = environments.filter(e => e !== event.target.name)
        }
        setAuxOptions({
            ...auxOptions,
            environments: environments.join('|')
        });
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAuxOptions({
            ...auxOptions,
            [event.target.name]: event.target.checked
        });
    }

    const filter = () => {
        setOptions({...auxOptions});
        handleClose(false);
    }


    return (
        <div>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Filter Options</DialogTitle>
                <DialogContent>

                    <Title>Environments</Title>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.archived}
                                onChange={handleEnvironment}
                                name="archived"
                                color="primary"
                            />
                        }
                        label="Archived"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.draft}
                                onChange={handleEnvironment}
                                name="draft"
                                color="primary"
                            />
                        }
                        label="Draft"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.live}
                                onChange={handleEnvironment}
                                name="live"
                                color="primary"
                            />
                        }
                        label="Live"
                    />

                    <Title>Options</Title>

                    <OptionsContainer>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={auxOptions.findVariantsAreNotInBrands}
                                    onChange={handleOptions}
                                    name="findVariantsAreNotInBrands"
                                    color="primary"
                                />
                            }
                            label="Show Invalid Variants"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={auxOptions.findRecipesWithExcludedVariantsAndBrands}
                                    onChange={handleOptions}
                                    name="findRecipesWithExcludedVariantsAndBrands"
                                    color="primary"
                                />
                            }
                            label="Show excluded brands and variants from recipes"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={auxOptions.findRecipesWithoutBrands}
                                    onChange={handleOptions}
                                    name="findRecipesWithoutBrands"
                                    color="primary"
                                />
                            }
                            label="Show recipes without brands"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={auxOptions.findRecipesWithoutVariants}
                                    onChange={handleOptions}
                                    name="findRecipesWithoutVariants"
                                    color="primary"
                                />
                            }
                            label="Show recipes without variants"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={auxOptions.findRecipesWithoutThumbnail}
                                    onChange={handleOptions}
                                    name="findRecipesWithoutThumbnail"
                                    color="primary"
                                />
                            }
                            label="Show recipes without thumbnails"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={auxOptions.findDuplicatedTags}
                                    onChange={handleOptions}
                                    name="findDuplicatedTags"
                                    color="primary"
                                />
                            }
                            label="Show recipes with duplicated tags"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={auxOptions.findDuplicatedNames}
                                    onChange={handleOptions}
                                    name="findDuplicatedNames"
                                    color="primary"
                                />
                            }
                            label="Show recipes with duplicated names"
                        />

                    </OptionsContainer>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="primary">
                        cancel
                    </Button>
                    <Button onClick={() => filter()} color="primary" autoFocus>
                        filter
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-weight: bold;
  font-family: sans-serif;
  margin-bottom: 10px;
  margin-top: 10px;
`;

import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FullMediaPanel from "../FullMediaPanel/FullMediaPanel";
import {FullFileData} from "../../../../model/FullMediaComponents";
import {useDispatch} from "react-redux";
import {
    addErrorUploadingFullMediaAction,
    addPreviewAction,
    addThumbnailAction,
    addThumbnailFromImageAction,
    canAddPreviewAndThumbnailAction
} from "../../../../store/actions/FullMediaUploadingActions";
import {CHUNK_SIZE} from "../../../../services/types/util/Constants";

interface Props {
    setFullFileData: (data: FullFileData) => void;
    setFile: (file: File) => void;
}

export const FullFileButton = ({ setFullFileData, setFile }: Props) => {

    const [openImport, setOpenImport] = useState(false);
    const dispatch = useDispatch();

    const canAddThumbnailAndPreview = (isVideo: boolean, isImage: boolean ) => {
        return isVideo || isImage;
    }

    const uploadFile = (data: {file: File, preview: boolean, startTime: string, duration: string, thumbnail: boolean, thumbnailFromImage: boolean, isVideo: boolean, isImage: boolean}) => {

        const totalParts = data.file.size % CHUNK_SIZE === 0 ? data.file.size / CHUNK_SIZE : Math.floor(data.file.size / CHUNK_SIZE) + 1;

        let body: FullFileData = {
            contentType: data.file.type,
            filename: data.file.name,
            totalParts: totalParts,
            fileSize: data.file.size,
            previewStart: data.preview ? data.startTime : undefined,
            previewDuration: data.preview ? data.duration : undefined,
            thumbnail: data.preview && data.thumbnail,
            thumbnailFromImage: data.thumbnailFromImage,
        }

        if (data.preview) {
            dispatch(addPreviewAction(true));
        }

        if (data.preview && data.thumbnail) {
            dispatch(addThumbnailAction(true));
        }

        if (data.thumbnailFromImage) {
            dispatch(addThumbnailFromImageAction(true));
        }

        if (canAddThumbnailAndPreview(data.isVideo, data.isImage)) {

            const canAddThumbnail =  data.isImage || data.isVideo;
            const canAddThumbnailFromImage = data.isImage;
            const canAddPreview = data.isVideo;

            dispatch(canAddPreviewAndThumbnailAction(canAddThumbnail, canAddThumbnailFromImage, canAddPreview));

        }

        setFullFileData(body);
        setFile(data.file);

    }

    const handleError = () => {
        dispatch(addErrorUploadingFullMediaAction('PSD file isn\'t valid'));
    }


    return (
        <div>
            <Button
                size="large"
                variant="outlined"
                color="primary"
                component="span"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => setOpenImport(true)}
            >
                UPLOAD FULL MEDIA
            </Button>
            <FullMediaPanel open={openImport} setOpen={setOpenImport} uploadFile={uploadFile} setShowErrorFile={handleError}/>
        </div>
    );
}

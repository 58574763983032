import {ServiceCallAction} from "../../store/middleware/serviceCallMiddleware";
import {ContentCodec, ContentResponse, ContentUpdateCodec, ContentUpdateResponse} from "../types/ContentCodec";
import {
  createNewContent,
  publishContentInLiveAction,
  replaceContentAction
} from "../../store/actions/ContentAction";
import {JSONObject} from "../types/util/BasicTypes";
import {failUpdateUtilStatus, updateStatusUtilAction} from "../../store/actions/UtilsAction";

export const getContent = (id: string, space: string): ServiceCallAction<ContentResponse> => ({
  method: "GET",
  endpoint: `contents/${space}/retrieve/${id}`,
  serviceKey: "postFullMedia",
  responseDecoder: ContentCodec,
  type: "SERVICE_CALL",
})

export const createContent = (body: any): ServiceCallAction<ContentResponse> => ({
  method: "POST",
  endpoint: `contents/create`,
  body: {type: 'application/json', value: body},
  serviceKey: "postFullMedia",
  responseDecoder: ContentCodec,
  type: "SERVICE_CALL",
  onSuccess: createNewContent
});

export const _createContent = (body: any): ServiceCallAction<ContentResponse> => ({
  method: "POST",
  endpoint: `contents/create`,
  body: {type: 'application/json', value: body},
  serviceKey: "postFullMedia",
  responseDecoder: ContentCodec,
  type: "SERVICE_CALL",
});

export const publishContentInLive = (id: string): ServiceCallAction<ContentUpdateResponse> => ({
  method: "PUT",
  endpoint: `status/change/${id}`,
  body: {type: 'application/json', value: {newStatus: 'Live'}},
  serviceKey: "publishContentInLive",
  responseDecoder: ContentUpdateCodec,
  type: "SERVICE_CALL",
  onSuccess: (data: ContentUpdateResponse ) => publishContentInLiveAction(data, id)
});

export const _publishContentInLive = (id: string): ServiceCallAction<ContentUpdateResponse> => ({
  method: "PUT",
  endpoint: `status/change/${id}`,
  body: {type: 'application/json', value: {newStatus: 'Live'}},
  serviceKey: "publishContentInLive",
  responseDecoder: ContentUpdateCodec,
  type: "SERVICE_CALL"
});

export const changeContentStatus = (_id: string, newStatus: 'Live' | 'Draft' | 'Archived'): ServiceCallAction<ContentUpdateResponse> => ({
  method: "PUT",
  endpoint: `status/change/${_id}`,
  body: {type: 'application/json', value: {newStatus: newStatus}},
  serviceKey: "changeContentStatus",
  responseDecoder: ContentUpdateCodec,
  type: "SERVICE_CALL",
  onSuccess: updateStatusUtilAction,
  onFailure: failUpdateUtilStatus
});

export const changeBulkContentStatus = (body: JSONObject): ServiceCallAction<ContentUpdateResponse> => ({
  method: "PUT",
  endpoint: `status/bulk/change`,
  body: {type: 'application/json', value: body},
  serviceKey: "changeBulkContentStatus",
  responseDecoder: ContentUpdateCodec,
  type: "SERVICE_CALL",
  onSuccess: updateStatusUtilAction,
  onFailure: failUpdateUtilStatus
});


export const replaceContent = (id: string, body: any): ServiceCallAction<ContentResponse> => ({
  method: "PUT",
  endpoint: `contents/replace/${id}`,
  body: {type: 'application/json', value: body},
  serviceKey: "replaceContent",
  responseDecoder: ContentCodec,
  type: "SERVICE_CALL",
  onSuccess: replaceContentAction
});

export const _replaceContent = (id: string, body: any): ServiceCallAction<ContentResponse> => ({
  method: "PUT",
  endpoint: `contents/replace/${id}`,
  body: {type: 'application/json', value: body},
  serviceKey: "replaceContent",
  responseDecoder: ContentCodec,
  type: "SERVICE_CALL",
});

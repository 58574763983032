import { Reducer } from "redux";
import { Recipe } from "../../model/Recipe";

export interface RecipesState {
    contents: Recipe[];
    resultlimit: number;
    currentpage: number;
    totalcount: number;
    pagecount: number;
    firstLoad?: boolean;
    timeStamp: number;
}

const defaultState: RecipesState = {
    contents: [],
    resultlimit: 0,
    currentpage: 1,
    totalcount: 0,
    pagecount: 0,
    firstLoad: true,
    timeStamp: 0
};

interface NewRecipesState {
    newRecipe: Recipe | null;
    error: boolean;
}

const defaultNewRecipeState: NewRecipesState = {
    newRecipe: null,
    error: false
};

interface RecipeState {
    recipe: Recipe | null;
    error: boolean;
}

const defaultRecipeState: RecipeState = {
    recipe: null,
    error: false
};

export const recipesReducer: Reducer<RecipesState, RecipesActions> = (
    state: RecipesState = defaultState,
    action: RecipesActions
) => {
    switch (action.type) {
        case "UPDATE_RECIPES":
            if (action.timeStamp > state.timeStamp) {
                return {...action.recipes, timeStamp: action.timeStamp, firstLoad: false};
            } else {
                return state;
            }
        case "CLEAR_RECIPES":
            return defaultState;
        default:
            return state;
    }
};

export const recipeReducer: Reducer<RecipeState, RecipeActions> = (
    state: RecipeState = defaultRecipeState,
    action: RecipeActions
) => {
    switch (action.type) {
        case "UPDATE_RECIPE":
            return {recipe: action.recipe, error: false};
        case "CLEAR_RECIPE":
            return {recipe: null, error: false};
        case "FAIL_RECIPE":
            return {recipe: null, error: true};
        case "CLEAR_ERROR":
            return {...state, error: true};
        default:
            return state;
    }
};

export const newRecipeReducer: Reducer<NewRecipesState, NewRecipeActions> = (
    state: NewRecipesState = defaultNewRecipeState,
    action: NewRecipeActions
) => {
    switch (action.type) {
        case "UPDATE_NEW_RECIPES":
            return {newRecipe: action.recipe, error: false};
        case "CLEAR_NEW_RECIPES":
            return {newRecipe: null, error: false};
        case "FAIL_NEW_RECIPE":
            return {newRecipe: null, error: true};
        default:
            return state;
    }
}

export type RecipesActions = 
    | {
        type: "UPDATE_RECIPES";
        recipes: RecipesState;
        timeStamp: number;
    }
    | {
        type: "CLEAR_RECIPES";
    };

export type NewRecipeActions = 
    | {
        type: "UPDATE_NEW_RECIPES";
        recipe: Recipe;
    }
    | {
        type: "FAIL_NEW_RECIPE";
    }
    | {
        type: "CLEAR_NEW_RECIPES"
    };

export type RecipeActions = 
    | {
        type: "UPDATE_RECIPE";
        recipe: Recipe;
    }
    | {
        type: "FAIL_RECIPE"
    }
    | {
        type: "CLEAR_RECIPE"
    }
    | {
        type: "CLEAR_ERROR"
    };
import { failRestoreAction, restoreRecipeAction } from "../../store/actions/RestoreActions";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { RestoreCodec, RestoreResponse } from "../types/RestoreCodec";

export const restoreRecipe = (_id: string): ServiceCallAction<RestoreResponse> => ({
	method: "POST",
	endpoint: `recipe/restoretodraft/${_id}`,
	serviceKey: "restoreRecipe",
	responseDecoder: RestoreCodec,
	type: "SERVICE_CALL",
	onSuccess: restoreRecipeAction,
	onFailure: failRestoreAction
});
import { isLeft } from "fp-ts/lib/Either";
import { useCallback, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { ServiceCallAction, ServiceCallError } from "../../store/middleware/serviceCallMiddleware";

interface ServiceCallOptions {
	skip?: boolean;
}

interface ServiceCallState<T> {
	state: "idle" | "pending";
	requestNumber: number;
	error: ServiceCallError | null;
	result: T | null;
}

interface ServiceCallResult<T> {
	isLoading: boolean;
	isSkipped: boolean;
	result: T | null;
	error: ServiceCallError | null;
	retry: () => void;
	dismissError: () => void;
}

export const useDataService = <In extends any[], Out>(
	service: (...args: In) => ServiceCallAction<Out>,
	parameters: In,
	options: ServiceCallOptions
): ServiceCallResult<Out> => {
	const [state, setState] = useReducer(
		(state: ServiceCallState<Out>, update: Partial<ServiceCallState<Out>>) => {
			return {
				...state,
				...update
			};
		},
		{ state: "idle", requestNumber: 0, error: null, result: null },
		e => e
	);
	const dispatch = useDispatch();

	useEffect(() => {
		if (options.skip) return;

		const initialParameters = parameters;
		setState({ state: "pending" });
		dispatch(service(...parameters)).then(result => {
			if (initialParameters.some((old, index) => old !== parameters[index])) {
				// Cancelled
				return;
			} else if (isLeft(result)) {
				setState({ state: "idle", error: result.left });
			} else {
				setState({ state: "idle", result: result.right });
			}
		});
	}, [state.requestNumber, ...parameters]);

	const retry = useCallback(() => {
		setState({ requestNumber: state.requestNumber + 1 });
	}, [setState, state.requestNumber]);

	const dismissError = useCallback(() => {
		setState({ error: null });
	}, [setState]);

	return {
		isLoading: state.state === "pending",
		isSkipped: options.skip ?? false,
		result: state.result,
		error: state.error,
		retry,
		dismissError
	};
};

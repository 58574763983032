import { Reducer } from "redux";
import { ElementRecipe } from "../../model/ElementRecipe";

export interface FilterTagsState {
    tags: {
        [key: string]: {
            [key: string]: ElementRecipe
        }
    };
    filterName: string;
}

const defaultState: FilterTagsState = {
    tags: {},
    filterName: ''
};

export const FilterTagsReducer: Reducer<FilterTagsState, FilterTagsActions> = (
    state: FilterTagsState = defaultState,
    action: FilterTagsActions
) => {
    switch (action.type) {
        case "UPDATE_FILTER_TAGS":
            const tagToAdd = {...state.tags[action.key]};
            tagToAdd[action.tag._id] = action.tag;
            const collectionToAdd: any = {};
            collectionToAdd[action.key] = tagToAdd
            return {tags: {...state.tags, ...collectionToAdd}, filterName: state.filterName};
        case "REMOVE_SPECIFIC_TAG":
            const tagToRemove = {...state.tags[action.key]};
            action.ids.forEach(id => delete tagToRemove[id]);
            const collectionWithTagsRemoved: any = {};
            collectionWithTagsRemoved[action.key] = tagToRemove;
            return {tags: {...state.tags, ...collectionWithTagsRemoved}, filterName: state.filterName};
        case "REMOVE_FILTER_TAG":
            const tags = {...state.tags[action.key]};
            delete tags[action.tag._id];
            const collectionToRemove: any = {};
            collectionToRemove[action.key] = tags;
            return {tags: {...state.tags, ...collectionToRemove}, filterName: state.filterName};
        case "UPDATE_FILTER_NAME":
            return {tags: {...state.tags}, filterName: action.filterName};
        case "CLEAR_ALL_TAGS":
            return defaultState;
        default:
            return state;
    }
};

export type FilterTagsActions = 
    | {
        type: "UPDATE_FILTER_TAGS";
        key: string;
        tag: ElementRecipe;
    }
    | {
        type: "REMOVE_FILTER_TAG";
        key: string;
        tag: ElementRecipe;
    }
    | {
        type: "REMOVE_SPECIFIC_TAG";
        key: string;
        ids: string[];
    }
    | {
        type: "UPDATE_FILTER_NAME";
        filterName: string;
    }
    | {
        type: "CLEAR_ALL_TAGS"
    };
import { updateBrands, updateVariants, updateServeStrategy, updateMixers, updateOccasions, updateGlasswares, updateComplexity, updateDrinkTypes, updatePrimaryVariants, updateSecondaryVariants } from "../../store/actions/elementsRecipeAction";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { ElementsRecipeCodecResponseCodec, ElementsRecipeResponse } from "../types/ElementsRecipeResponseCodec";

export const getBrands = (): ServiceCallAction<ElementsRecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/brands`,
	serviceKey: "getBrands",
	responseDecoder: ElementsRecipeCodecResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateBrands
});

export const getVariants = (): ServiceCallAction<ElementsRecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/variants`,
	serviceKey: "getVariants",
	responseDecoder: ElementsRecipeCodecResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateVariants
});

export const getVariantsAssociatedWithBrands = (ids: string, primary: boolean): ServiceCallAction<ElementsRecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/variants-from-brands`,
	param: {ids},
	serviceKey: "getVariants",
	responseDecoder: ElementsRecipeCodecResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: primary ? updatePrimaryVariants : updateSecondaryVariants
});

export const getServeStrategy = (): ServiceCallAction<ElementsRecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/serve-strategy`,
	serviceKey: "getServeStrategy",
	responseDecoder: ElementsRecipeCodecResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateServeStrategy
});

export const getMixers = (): ServiceCallAction<ElementsRecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/mixers`,
	serviceKey: "getServeMixers",
	responseDecoder: ElementsRecipeCodecResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateMixers
});

export const getOccasions = (): ServiceCallAction<ElementsRecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/occasions`,
	serviceKey: "getOccasions",
	responseDecoder: ElementsRecipeCodecResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateOccasions
});

export const getGlasswares = (): ServiceCallAction<ElementsRecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/glassware`,
	serviceKey: "getGlassware",
	responseDecoder: ElementsRecipeCodecResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateGlasswares
});

export const getComplexities = (): ServiceCallAction<ElementsRecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/complexity`,
	serviceKey: "getComplexity",
	responseDecoder: ElementsRecipeCodecResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateComplexity
});

export const getDrinkTypes = (): ServiceCallAction<ElementsRecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/drink-types`,
	serviceKey: "getDrinkTypes",
	responseDecoder: ElementsRecipeCodecResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateDrinkTypes
});

export const FILTER_STATUS = 'status';
export const FILTER_BRANDS = 'Featured Brands';
export const FILTER_VARIANTS = 'Featured Variants';
export const FILTER_LIBRARY = 'Library';
export const CHUNK_SIZE = 1024 * 1024 * 20; //20mb
export const CONTENT_MODEL = 'Full Media';
export const SPACE = 'nam-tsp';
export const MIME_PNG = 'image/png';
export const MIME_MP4 = 'application/mp4';

export type DrinkShot = 'drinkShot';
export type LifeStyle = 'lifeStyle';

export type FILE_TYPE = 'csv' | 'xlsx' | 'json';
export type FIELD_DELIMITER = 'COMMA' | 'SEMICOLON';

export type SORT_ORDER =  'asc' | 'desc';
export type SORT_BY = 'status' | 'name' | 'createdAt' | 'brand' | 'variant' | undefined;
export type SORT_BY_ELEMENT = 'Featured Brands' | undefined;
/* export type FILTER_STATUS = 'status';
export type FILTER_BRANDS = 'Featured Brands';
export type FILTER_VARIANTS = 'Featured Variants'; */

import React from "react";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {postFullMedia} from "../../../../services/actions/FullMediaService";
import {addErrorUploadingFullMediaAction} from "../../../../store/actions/FullMediaUploadingActions";
import {useDispatch} from "react-redux";

interface Props {
    setFile: (file: File) => void;
    label: string;
}

const prohibitedExtensions = ['image/vnd.adobe.photoshop', 'application/vnd.adobe.photoshop']


export const FileButton = ({ setFile, label }: Props ) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const canUploadFileType = (extension: string, type: string): boolean => {
        return extension !== 'psd' && !prohibitedExtensions.includes(type);
    }

    const onChange = (e: any) => {

        const extension = e.target.files[0].name.split('.').pop().toLowerCase();
        const type = e.target.files[0].type;

        if ( !canUploadFileType(extension, type) ) {
            dispatch(addErrorUploadingFullMediaAction('PSD files aren\'t valid'));
            return;
        }

        setFile(e.target.files[0]);

    }

    return (
        <div>
            <input
                accept={"image/*"}
                className={classes.input}
                id='fileInput'
                type="file"
                onChange={onChange}
            />
            <label htmlFor='fileInput'>
                <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    component="span"
                    startIcon={<AddCircleOutlineIcon />}
                >
                    {label}
                </Button>
            </label>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '5px',
            marginBottom: '5px',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
    }),
);

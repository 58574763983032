import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Box} from "@material-ui/core";
import UploadFile from "../../basicComponents/uploadFile/UploadFile";
import ImportRecipeResult from "../../RecipeList/ImportRecipeResult";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import UploadFullMediaFile from "./UploadFullMediaFile";
import MediaResultFile from "./MediaResultFile";
import VideoOptions from "./VideoOptions";
import {clearImportRecipesAction} from "../../../../store/actions/ImportActions";

interface FullMediaPanelProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  uploadFile: (data: {file: File, preview: boolean, startTime: string, duration: string, thumbnail: boolean, thumbnailFromImage: boolean, isVideo: boolean, isImage: boolean}) => void;
  setShowErrorFile: (value: boolean) => void;
}

const FullMediaPanel = ({open, setOpen, uploadFile, setShowErrorFile}: FullMediaPanelProps) => {
  const [file, setFile] = useState<File>();
  const [state, setState] = useState({
    preview: false,
    thumbnail: false
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [preview, setPreview] = React.useState({
    time: '00:00:00',
    duration: 1
  });

  const handleChangePreview = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreview({ ...preview, [event.target.name]: event.target.value });
  };

  const isVideo = () => {
    if (file) {
      return !!file.type.match('video.*');
    }
    return false;
  }

  const isImage = () => {
    if (file) {
      return !!file.type.match('image.*');
    }
    return false;
  }

  const renderVideoOptions = () => {
    if (isVideo()) {
      return <VideoOptions state={state} handleStateChange={handleChange} preview={preview} handleChangePreview={handleChangePreview} showPreview={true}/>
    }
    if (isImage())  {
      return <VideoOptions state={state} handleStateChange={handleChange} preview={preview} handleChangePreview={handleChangePreview} showPreview={false} enabledThumbnail={true}/>
    }
    return null;
  }

  const clear = () => {
    setFile(undefined);
  }

  const handleClose = () => {
    setOpen(false);
    clear();
  }

  const validStartTime = () => {
    let [hours, minutes, seconds] = preview.time.split(":");
    return /([0-9][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/g.test(preview.time) && /^\d+$/.test(hours) && /^\d+$/.test(seconds) && seconds.length === 2;
  }

  const validDuration = () => {
    return preview.duration > 0;
  }

  const validToUpload = () => {
    return !state.preview || (state.preview && validStartTime() && validDuration());
  }

  const upload = () => {
    if (file) {
      const data = {
        file: file,
        preview: state.preview,
        startTime: preview.time,
        duration: preview.duration.toString(),
        thumbnail: state.thumbnail,
        thumbnailFromImage: state.thumbnail && !!file.type.match('image.*'),
        isVideo: isVideo(),
        isImage: isImage()
      }
      uploadFile(data);
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        maxWidth='md'
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Upload new File
        </DialogTitle>
        <DialogContent>

          <Box display="flex" flexDirection="column" alignItems="inherit" width="100%">
            {
              !file ?
                <UploadFullMediaFile setFile={setFile} setShowErrorFile={setShowErrorFile} />
                :
                <MediaResultFile file={file}/>
            }

          </Box>

          {/*{renderVideoOptions()}*/}

        </DialogContent>
        <DialogActions>
          {
            !!file ?
              <Button onClick={clear} color="primary">
                Clear
              </Button>
              :
              null
          }
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => upload()}
            color="primary"
            autoFocus
            disabled={!file || !validToUpload()}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}

export default FullMediaPanel;

import React, { useRef } from 'react';
import { Box, Grid, IconButton, TextField } from '@material-ui/core';
import styled from "styled-components";
import { Field } from 'redux-form';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { XYCoord } from 'dnd-core'

interface IngredientProps {
    name: string;
    index: number;
    ingredient: {description: string; id: string}
    removeIngredient: (_: number) => void;
    moveIngredient: (dragIndex: number, hoverIndex: number) => void;
}

const renderField = ({ input, meta: { touched, error } }: any) => (
    <TextField
        color="primary"
        onChange={(event) => {
            const newValue = {
                description: event.target.value,
                id: input.value.id
            };
            return input.onChange(newValue);
        }}
        value={input.value.description}
        fullWidth
    />
)

interface DragItem {
    index: number
    id: string
    type: string
}

export const ItemTypes = {
    CARD: 'card',
}

const style = {
    cursor: 'move',
}

const Ingredient = ({name, index, ingredient, removeIngredient, moveIngredient}: IngredientProps ) => {
    const ref = useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item: DragItem, monitor: DropTargetMonitor) {
          if (!ref.current) {
            return
          }
          const dragIndex = item.index
          const hoverIndex = index
    
          // Don't replace items with themselves
          if (dragIndex === hoverIndex) {
            return
          }
    
          // Determine rectangle on screen
          const hoverBoundingRect = ref.current?.getBoundingClientRect()
    
          // Get vertical middle
          const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    
          // Determine mouse position
          const clientOffset = monitor.getClientOffset()
    
          // Get pixels to the top
          const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
    
          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%
    
          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return
          }
    
          // Dragging upwards
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return
          }
    
          // Time to actually perform the action
          moveIngredient(dragIndex, hoverIndex);
    
          // Note: we're mutating the monitor item here!
          // Generally it's better to avoid mutations,
          // but it's good here for the sake of performance
          // to avoid expensive index searches.
          item.index = hoverIndex
        },
      })

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, id: ingredient.id, index },
        collect: (monitor: any) => ({
          isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    
    return (
        <div ref={ref} style={{opacity}}>
            <Grid item xs={12}>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <DragIndicatorIcon style={{ ...style }}/>
                        <StepNumber>{ index + 1 }</StepNumber>
                        <Field
                            name={name}
                            component={renderField}
                        />
                        <IconButton
                            color="secondary" 
                            onClick={() => removeIngredient(index)}
                        >
                            <DeleteForeverIcon fontSize="large" />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

const StepNumber = styled.div`
    margin-right: 10px;
    font-weight: bold;
    padding: 8px;
`;

export default Ingredient;
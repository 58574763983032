import { Reducer } from "redux";
import { Recipe } from "../../model/Recipe";

export interface RestoreState {
    recipe: Recipe | null;
    message: string;
    restored: boolean;
    error: boolean;
}

const defaultState: RestoreState = {
    recipe: null,
    message: '',
    restored: false,
    error: false,
};

export const restoreReducer: Reducer<RestoreState, RestoreActions> = (
    state: RestoreState = defaultState,
    action: RestoreActions
) => {
    switch (action.type) {
        case "UPDATE_RESTORE":
            return {recipe: action.recipe, message: action.message, restored: action.restored, error: false};
        case "FAIL_RESTORE":
            return {...defaultState, error: true};
        case "CLEAR_RESTORE":
            return defaultState;
        default:
            return state;
    }
}

export type RestoreActions = 
    | {
        type: "UPDATE_RESTORE";
        recipe: Recipe;
        message: string;
        restored: boolean;
    }
    | {
        type: "FAIL_RESTORE"
    }
    | {
        type: "CLEAR_RESTORE"
    };
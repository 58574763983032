const extensions = ['acc', 'avi', 'bmp', 'c', 'cpp', 'css', 'csv',
                    'dat', 'dmg', 'doc', 'docx', 'exe', 'flv', 'jpg', 'jpeg', 'gif', 'png',
                    'h', 'hpp', 'html', 'iso', 'java', 'js', 'json', 'less',
                    'mid', 'mp3', 'mp4', 'mpg', 'odf', 'ods', 'odt', 'otp', 'ots', 'ott',
                    'pdf', 'php', 'ppt', 'pptx', 'psd', 'py', 'qt', 'rar', 'rb', 'rtf',
                    'sass', 'scss', 'sql', 'tga', 'tgz', 'tiff', 'txt', 'wav', 'xls', 'xlsx', 'xml', 'yml', 'zip','mov', 'eps' , 'ai', 'tif', 'tiff'];


export const getIconFromURL = (originalUrl: string, alt?: string): string => {
	let newUrl = '/assets/extensions/';
    let splitUrl = originalUrl ? originalUrl.split('.') : [];
    let extension = '';
    let extensionFound = false;
    if (splitUrl.length > 1) {
        extension = splitUrl[splitUrl.length - 1].toLocaleLowerCase();
        extensionFound = extensions.indexOf(extension) > -1;
    }
    if (!extensionFound && alt) {
        let splitAlt = alt.split('.');
        if (splitAlt.length > 1) {
            extension = splitAlt[splitAlt.length - 1].toLocaleLowerCase();
            extensionFound = extensions.indexOf(extension) > -1;
        }
    }
    newUrl += extensionFound ? (extension + '.png') : '_blank.png';
    return newUrl;
};
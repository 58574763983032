import { ImageResponse, ImageUpdateResponse } from "../../services/types/ImageResponseCodec";
import { MediaActions, MediaState } from "../reducers/MediaReducer";

export const updateMedia = (media: MediaState, timeStamp: number): MediaActions => ({
	type: "UPDATE_MEDIA",
	media,
	timeStamp
});

export const clearMedia = (): MediaActions => ({
	type: "CLEAR_MEDIA"
});

export const updateMediaNameAction = (data: ImageUpdateResponse): MediaActions => ({
	type: "UPDATE_MEDIA_NAME",
	media: data.asset
});
import { Reducer } from "redux";

interface CreateRecipeState {
    created: boolean;
}

const defaultCreateRecipeState : CreateRecipeState = {
    created: false
};

export const CreateRecipeReducer: Reducer<CreateRecipeState, CreateRecipeActions> = (
    state: CreateRecipeState = defaultCreateRecipeState,
    action: CreateRecipeActions
) => {
    switch (action.type) {
        case "CREATE_RECIPE":
            return {created: true};
        case "CLEAR_CREATE_RECIPE":
            return defaultCreateRecipeState;
        default:
            return state;
    }
}

export type CreateRecipeActions = 
    | {
        type: "CREATE_RECIPE";
    }
    | {
        type: "CLEAR_CREATE_RECIPE"
    };
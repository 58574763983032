import {ServiceCallAction} from "../../store/middleware/serviceCallMiddleware";
import {FullMediaListCodec, FullMediaListResponse} from "../types/FullMediaListCodec";
import {updateFullMediaList} from "../../store/actions/FullMediaListAction";
import {QuerySearchRecipe} from "../../model/QuerySearchRecipes";
import {FIELD_DELIMITER, FILE_TYPE} from "../types/util/Constants";
import {exportImportCodec, exportImportResponse} from "../types/ImportExportCodec";
import FileSaver from "file-saver";

export const getFullMedias = (page: number, limit: number, contentModelFilter: string ): ServiceCallAction<FullMediaListResponse> => ({
    method: "POST",
    endpoint: `contents/retrieve`,
    header: {page, limit, contentModelFilter},
    serviceKey: "getFullMedias",
    responseDecoder: FullMediaListCodec,
    type: "SERVICE_CALL",
    onSuccess: updateFullMediaList
})

export const exportFullMedia = (ids: string[], contentModelId: string, file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER): ServiceCallAction<exportImportResponse> => ({
    method: "POST",
    endpoint: `contents/export/model/${contentModelId}`,
    body: {type: 'application/json', value: {ids}},
    param: {file_type, field_delimiter, exportContent: true, selected: true},
    serviceKey: "exportFullMedia",
    responseDecoder: exportImportCodec,
    type: "SERVICE_CALL",
    blob: true,
    onDownloadFile: (data: Blob) => {
        FileSaver.saveAs(data, `fullMedia.${file_type}`);
    }
});

import * as t from "io-ts";

export const FullMediaResponseCodec = t.strict({
  id: t.string,
  running: t.boolean,
  percent: t.number,
  progress: t.string,
  result: t.union([
    t.null,
    t.undefined,
    t.strict(
      {
        id: t.string,
        url: t.string,
        name: t.string,
        mimeType: t.string
      }
    )
  ])
});

export const CheckPreviewAndThumbnailResponseCodec = t.strict({
  finished: t.boolean,
  preview: t.union([
    t.undefined,
    t.strict(
      {
        id: t.string,
        url: t.string,
        name: t.string,
        mimeType: t.string
      }
    )
  ]),
  thumbnail: t.union([
    t.undefined,
    t.strict(
      {
        id: t.string,
        url: t.string,
        name: t.string,
        mimeType: t.string
      }
    )
  ]),
});

export const CheckThumbnailResponseCodec = t.strict({
  id: t.string,
  url: t.string,
  name: t.string,
  mimeType: t.string
})

export type FullMediaResponse = t.OutputOf<typeof FullMediaResponseCodec>;
export type CheckPreviewAndThumbnailResponse = t.OutputOf<typeof CheckPreviewAndThumbnailResponseCodec>;
export type CheckThumbnailResponseResponse = t.OutputOf<typeof CheckThumbnailResponseCodec>;


import React from 'react';
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue, green, yellow } from '@material-ui/core/colors';
import './App.css';
import { HashRouter } from 'react-router-dom';
import { Routes } from './presentation/Routes';
import { PersistGate } from 'redux-persist/integration/react';

const theme = createMuiTheme({
  palette: {
      primary: blue,
      success: {
        main: green['A700'],
      },
      info: {
        main: yellow['A700'],
      },
  },
});

const App = () => {

  return (
    <div className="App">
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <HashRouter>
        <ThemeProvider theme={theme}>
          {/* <RecipeCreacte /> */}
          {/* <Auth /> */}
          <Routes />
        </ThemeProvider>

      </HashRouter>
    </PersistGate>


    </Provider>
    </div>
  );
}

export default App;

import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import {ImportRecipeResultCodec, ImportRecipeResultResponse} from "../types/ImportRecipeResultCodec";
import {FilterOptions} from "../../model/ImportResults";

export const getImportRecipeResult = (option: string, page: number, limit: number ): ServiceCallAction<ImportRecipeResultResponse> => ({
    method: "GET",
    endpoint: `recipe/import-record/all`,
    param: {page, limit, option},
    serviceKey: "getImportRecipeResult",
    responseDecoder: ImportRecipeResultCodec,
    type: "SERVICE_CALL",
});

import React, {useEffect, useState} from 'react';
import {openModalAction, redirectAction} from "../../../../store/actions/UtilsAction";
import BasicDialog from "../../basicComponents/BasicDialog";
import {useDispatch, useSelector} from "react-redux";

interface FullMediaWarningProps {
  restartFullMedia: () => void,
  changes: boolean
}

const WARNING_MESSAGE = ["Your changes will be lost if you don't save them.", "Do you want to continue?"];

const FullMediaWarning = ({restartFullMedia, changes}: FullMediaWarningProps) => {
  const [openWarningMessage, setOpenWarningMessage] = useState(false);
  const { openModal, redirect }  = useSelector(state => state.transient.utils);
  const dispatch = useDispatch();


  useEffect(() => {
    if (!redirect.check) {
      return;
    }
    if (redirect.waiting && changes) {
      setOpenWarningMessage(true);
    } else if (redirect.waiting) {
      dispatch(redirectAction(false, true, true));
    }
  }, [redirect]);

  useEffect(() => {
    if (openModal && changes) {
      setOpenWarningMessage(true);
    } else if (openModal) {
      dispatch(openModalAction(false));
      restartFullMedia();
    }
  }, [openModal]);

  const handleClose = (action: boolean) => {
    setOpenWarningMessage(false);
    dispatch(openModalAction(false));

    if (redirect.waiting) {
      dispatch(redirectAction(false, action, true));
    } else if (action) {
      restartFullMedia();
    }

  }

  return (
    <BasicDialog
      open={openWarningMessage}
      handleClose={handleClose}
      cancelButton="CANCEL"
      okButton="OK"
      description={WARNING_MESSAGE}
    />
  );
}

export default FullMediaWarning;

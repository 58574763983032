import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { change, Field } from 'redux-form';
import { RenderAutocompleteField } from '../../basicComponents/LocalFields';
import { useDispatch, useSelector } from 'react-redux';
import { ElementRecipe } from '../../../../model/ElementRecipe';
import { getVariantsAssociatedWithBrands } from '../../../../services/actions/ElementsRecipeService';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from "styled-components";
import {isRight} from "fp-ts/These";

const defaultProps = {
    borderLeft: "8px solid #bfbfbfcc",
};

const invalidProps = {
    borderLeft: "8px solid #f44336cc",
};

const BrandsAndVaiants = () => {
    const { brands, variantsWithBrand } = useSelector(state => state.transient);
    const formState = useSelector(state => state.form);
    const dispatch = useDispatch();
    const [validFields, setValidFields] = useState(true);

    const renderBrandField = (options: ElementRecipe[], name: string, label: string, optionalHandler: (_:string, __: boolean) => void, handlerStatus: boolean) => {
         if (options.length > 0) {
            return (
                <Field
                    name={name}
                    component={RenderAutocompleteField}
                    label={label}
                    options={options}
                    customField="name"
                    optionalHandler={optionalHandler}
                    handlerStatus={handlerStatus}
                />
            );
        } else {
            return <CircularProgress size={24}/>
        }
    }

    const extraValidation = (options: ElementRecipe[], fieldName: string) => {
        const fieldValue = formState && formState.createRecipe && formState.createRecipe.values && formState.createRecipe.values[fieldName] ? formState.createRecipe.values[fieldName] : undefined;
        if (!fieldValue) {
            return false;
        }
        const haveOptions = options.length > 0;
        const validField = options.map(opt => opt._id).includes(fieldValue);
        setValidFields(haveOptions && validField);
        return !haveOptions || !validField;
    }

    const renderVariantField = (options: ElementRecipe[], name: string, label: string) => {
        if (options.length > 0) {
            return (
                <Field
                    name={name}
                    component={RenderAutocompleteField}
                    extraValidation={() => extraValidation(options, name)}
                    label={label}
                    options={options ? options : []}
                    customField="name"
                />
            );
        } else {
            return (
                <Field
                    name={`${name}Empty`}
                    component={RenderAutocompleteField}
                    extraValidation={() => extraValidation(options, name)}
                    label={label}
                    options={[]}
                    customField="name"
                />
            );
        }
    }

    const getTags = (tags: ElementRecipe[], tagName: string): ElementRecipe[] => {
        const tag = formState && formState.createRecipe && formState.createRecipe.values && formState.createRecipe.values[tagName] ? formState.createRecipe.values[tagName] : null;
        if (tag) {
            return tags.filter(elem => elem._id !== tag);
        } else {
            return tags && tags.length > 0 ? tags: [];
        }
    }

    const searchVariants = (id: string, primary: boolean) => {
        const field = primary ? 'primaryVariant' : "secondaryVariant";
        dispatch(change('createRecipe', field, ''));
        dispatch(getVariantsAssociatedWithBrands(id, primary));
    }

    const getProps = () => {
        return validFields ? defaultProps.borderLeft : invalidProps.borderLeft;
    }

    return (
        <Box mb={3} pb={3} pt={2} pl={2} borderLeft={getProps()}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Grid item md={12} lg={6}>
                        {renderBrandField(brands.elementRecipe, "brand", "Brand *", searchVariants, true)}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid item md={12} lg={6}>
                        {renderBrandField(brands.elementRecipe, "secondaryBrand", "Secondary Brand", searchVariants, false)}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid item md={12} lg={10}>
                        {renderVariantField(getTags(variantsWithBrand.primaryVariants, "secondaryVariant"), "primaryVariant", "Primary Variant *")}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid item md={12} lg={10}>
                        {renderVariantField(getTags(variantsWithBrand.secondaryVariants, "primaryVariant"), "secondaryVariant", "Secondary Variant")}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid item md={12} lg={10}>
                        <Text>If the brand or variant is not available please contact your administrator</Text>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
};

const Text = styled.span`
  color: #616184;
  font-weight: 700;
`;


export default BrandsAndVaiants;

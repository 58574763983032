import * as t from "io-ts";

const Duplicated = t.strict({
    count:    t.number,
    _id:      t.string,
    name:     t.string,
    uuid:     t.string,
    status:   t.number,
    tag:      t.string,
    tagName:  t.string,
    tagType:  t.string,
    messages: t.string
});

const RecipeData = t.strict({
    _id:       t.string,
    name:      t.string,
    uuid:      t.string,
    status:    t.number,
    messages: t.union([t.undefined, t.array(t.string)])
});

const RecipesWithExcludedVariantsAndBrand = t.strict({
    _id:             t.string,
    name:            t.string,
    uuid:            t.string,
    status:          t.number,
    brand_1_name:    t.string,
    brand_2_name:    t.union([t.string, t.undefined]),
    variant_1_name:  t.string,
    variant_2_name:  t.union([t.string, t.undefined]),
    brand_1_id:      t.union([t.string, t.undefined]),
    brand_2_id:      t.union([t.string, t.undefined]),
    variant_1_id:    t.union([t.string, t.undefined]),
    variant_2_id:    t.union([t.string, t.undefined]),
    brand_1:         t.boolean,
    brand_2:         t.union([ t.undefined, t.boolean ]),
    variant_1:       t.boolean,
    variant_2:       t.union([ t.undefined, t.boolean ]),
    messages:        t.array(t.string)
})

const VariantsAreNotInBrand = t.strict({
    _id:             t.string,
    name:            t.string,
    uuid:            t.string,
    status:          t.number,
    brand_1_name:    t.union([t.string, t.undefined]),
    brand_2_name:    t.union([t.string, t.undefined]),
    brand_1_id:      t.union([t.string, t.undefined]),
    brand_2_id:      t.union([t.string, t.undefined]),
    variant_1_name:  t.string,
    variant_2_name:  t.union([t.string, t.undefined]),
    variant_1_id:    t.union([t.string, t.undefined]),
    variant_2_id:    t.union([t.string, t.undefined]),
    validVariant1:   t.boolean,
    validVariant2:   t.boolean,
    messages:        t.array(t.string)
});

const DuplicatedName = t.strict({
    name:     t.string,
    recipes:  t.array(RecipeData),
    count:    t.number,
    messages: t.string,
});

const RecipeErrorsCodec = t.strict({
    variantsAreNotInBrands:               t.array(VariantsAreNotInBrand),
    recipesWithExcludedVariantsAndBrands: t.array(RecipesWithExcludedVariantsAndBrand),
    recipesWithoutVariants:               t.array(RecipeData),
    recipesWithoutBrands:                 t.array(RecipeData),
    recipesWithoutThumbnail:              t.array(RecipeData),
    duplicatedDrinkTypes:                 t.array(Duplicated),
    duplicatedOccasions:                  t.array(Duplicated),
    duplicatedMixers:                     t.array(Duplicated),
    duplicatedGlassware:                  t.array(Duplicated),
    duplicatedNames:                      t.array(DuplicatedName)
});

export const RecipeErrorsDataCodec = t.strict({
    page: t.number,
    currentpage: t.number,
    totalcount: t.number,
    pagecount: t.number,
    data: RecipeErrorsCodec
})

export const FixRecipeCodec = t.strict({
    message: t.string
})

export type RecipeErrorsResponseCodec = t.OutputOf<typeof RecipeErrorsDataCodec>;
export type FixRecipeResponseCodec = t.OutputOf<typeof FixRecipeCodec>;

import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import { Box, CircularProgress, Snackbar } from '@material-ui/core';
import MediaPicker from './MediaPicker';
import { useDispatch, useSelector } from 'react-redux';
import { getDCHImages } from '../../../../services/actions/DCHImagesService';
import { DCHImages } from '../../../../model/DCHImages';
import { clearDCHImages } from '../../../../store/actions/DCHImageAction';
import { Alert, Skeleton } from '@material-ui/lab';


interface UploadDCHImageProps {
    open: boolean;
    handleClickOpen: () => void;
    handleClose: any;
}

const UploadDCHImage = ({open, handleClickOpen, handleClose}: UploadDCHImageProps) => {
    const [dchId, setDCHId] = useState('');
    const [loading, setLoading] = useState(false);
    const [textToSearch, setTextToSearch] = useState('');
    const [page, setPage] = useState(1);
    const [image, setImage] = useState<DCHImages | null>(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { dchImages } = useSelector(state => state.transient);
    const [error, setError] = useState(false);

    const _handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            onClickSearch();
        }
    }

    const onClickSearch = () => {
        setPage(1);
        dispatch(getDCHImages(textToSearch, page));
        setLoading(true);
    }

    useEffect(() => {
        setLoading(false);
        if (dchImages.error) {
            setError(true);
        }
    }, [dchImages])

    useEffect(() => {
        if (open) {
            dispatch(getDCHImages(textToSearch, page));
            setLoading(true); 
        } else {
            setPage(1);
            dispatch(clearDCHImages());
        }
    }, [open, page]);

    const renderImages = () => {
        if (loading) {
            return (
                <Box display="flex" flexDirection="column" alignItems="center" p={3}>
                    <CircularProgress />
                    <Box p={2}>
                        <span>Loading assets...</span>
                    </Box>
                </Box>
            );
        } else if (dchImages.assets.length > 0) {
            return <MediaPicker DCHImages={dchImages} onSelectImage={setImage} imageSelected={image} setPage={setPage} />;
        } else {
            return <div>No results found</div>
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                maxWidth='xl'
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Media Picker
                </DialogTitle>
                <DialogContent>
                
                    <Box display="flex" flexDirection="column" alignItems="center">

                        <FormControl variant="outlined" fullWidth className={classes.search}>
                            <InputLabel htmlFor="outlined-adornment-password">DCH ID</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type='text'
                                value={textToSearch}
                                onChange={e => setTextToSearch(e.target.value)}
                                onKeyDown={_handleKeyDown}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton 
                                            onClick={() => onClickSearch()}
                                        >
                                            <SearchIcon /> 
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                        
                        <Box p={2} width={1}>
                            {renderImages()}
                        </Box>
                        
                    </Box>
                    
                
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                            setError(false);
                            handleClose(null);
                        }} color="primary">
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => {
                            setError(false);
                            handleClose(image);
                        }} 
                        color="primary" 
                        autoFocus
                        disabled={image === null}
                    >
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={error} autoHideDuration={3000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(false)} severity="error">
                    An error occurred while searching for images
                </Alert>
            </Snackbar>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
        maxWidth: "1000px",
    },
    root: {
        display: 'flex',
        '& > * + *': {
          margin: theme.spacing(2),
        },
      },
  }),
);

export default UploadDCHImage;
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';
import UploadFile from '../basicComponents/uploadFile/UploadFile';
import ImportRecipeResult from './ImportRecipeResult';
import { useDispatch, useSelector } from 'react-redux';
import { importRecipes, getImportRecipes } from '../../../services/actions/ImportExportService';
import { clearImportRecipesAction } from '../../../store/actions/ImportActions';


interface ImportRecipeProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const ImportRecipe = ({open, setOpen}: ImportRecipeProps) => {
    const [file, setFile] = useState<File>();
    const [importing, setImporting] = useState(false);
    const [callImport, setCallImport] = useState(false);
    const [timerId, setTimerId] = useState<number>();
    const dispatch = useDispatch();
    const { recipes } = useSelector(state => state.transient);
    const { rows, running, id, error} = useSelector(state => state.transient.recipesImported);
    const UPDATE_TIME = 6 * 1000;

    const getImportResult = () => {
        dispatch(getImportRecipes(id));
        const timeId = setTimeout(() => {
            getImportResult()
        }, UPDATE_TIME);
        setTimerId(timeId);
    }

    useEffect(() => {
        setImporting(running);
        if (running) {
            getImportResult();
        } else if (timerId) {
            clearTimeout(timerId);
            setTimerId(undefined);
        }
    }, [running])

    const importFile = () => {
        if (file) {
            var data = new FormData();
            data.append("file", file);
            data.append("drinkshotMaxSize", "9");
            data.append("lifestyleMaxSize", "19");
            let contentModelId = recipes.contents[0].contentModel_id;
            dispatch(importRecipes(data, contentModelId));
            setImporting(true);
            setCallImport(true);
        }
    }

    const importDisabled = () => {
        return callImport || !file || importing;
    }


    const clear = () => {
        setCallImport(false);
        setFile(undefined);
        if (timerId) {
            clearTimeout(timerId);
            setTimerId(undefined);
        }
        dispatch(clearImportRecipesAction());
    }

    const handleClose = () => {
        setOpen(false);
        clear();
    }

    return (
        <div>
            <Dialog
                open={open}
                maxWidth='md'
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Import Content From File
                </DialogTitle>
                <DialogContent>
                
                    <Box display="flex" flexDirection="column" alignItems="inherit" width="100%">
                        {
                            !file ?
                                <UploadFile setFile={setFile} />
                            :
                                <ImportRecipeResult file={file} importing={importing}/>
                        }
                        
                    </Box>
                    
                
                </DialogContent>
                <DialogActions>
                    {
                        !!file ?
                            <Button onClick={clear} color="primary">
                                Clear
                            </Button>
                        :
                            null
                    }
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => importFile()} 
                        color="primary" 
                        autoFocus
                        disabled={importDisabled()}
                    >
                        Import
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ImportRecipe;
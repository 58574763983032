import * as t from "io-ts";

export interface FullMedia {
  _id: string;
  name: string;
  slug: string;
  status: string;
  uuid: string;
  version: number;
  createdByUser: string;
  author: string;
  createdAt: string;
  updatedAt: {
    "title": string,
    "textToShow": string,
    "raw": string
  };
  elements: {
    _id: string;
    name: string;
    datatype: string;
    data: {
      value: any;
      image?: {
        url: string;
        name: string;
      } | null;
    };
  }[];
}

export const getDefaultFullMedia = (): FullMedia => {
  return {
    _id: '',
    name: '',
    slug: '',
    status: '',
    uuid: '',
    version: 0,
    createdByUser: '',
    author: '',
    createdAt: '',
    updatedAt: {
      title: '',
      textToShow: '',
      raw: ''
    },
    elements: []
  }
}

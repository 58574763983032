import { Box, Collapse, createStyles, Divider, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagListMenu from './TagListMenu';
import FilterName from './FilterName';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { buildFakeElementRecipe, ElementRecipe } from '../../../model/ElementRecipe';
import { FILTER_STATUS, FILTER_VARIANTS, FILTER_BRANDS, FILTER_LIBRARY } from '../../../services/types/util/Constants';
import { getVariantFromBrands } from '../../../services/actions/FilterTagsService';
import { clearFilterTagVariants } from '../../../store/actions/VariantTagsActions';
import { removeSpecificTags } from '../../../store/actions/FilterTagActions';

const publishStatus = [
    buildFakeElementRecipe('1', 'Live'),
    buildFakeElementRecipe('3', 'Draft'),
    buildFakeElementRecipe('4', 'Archived')
];

const libraryStatus = [
    buildFakeElementRecipe('5', 'DCH'),
    buildFakeElementRecipe('6', 'Recipe Library')
];

interface FilterTagsProps {
    title: string;
    titleTextFilter: string;
    placeholderTextFilter: string;
    filtersToShow: {
        libraryStatus: boolean;
        mixers: boolean;
        occasions: boolean;
        drinkTypes: boolean;
    };
}

const FilterTags = ({title, titleTextFilter, placeholderTextFilter, filtersToShow}: FilterTagsProps) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);
    const { brands, variants, mixers, occasions, drinkTypes, filterTags, variantsTagsFilter } = useSelector(state => state.transient);
    const [preBrands, setPreBrands] = useState(0);
    const [variantsTags, setVariantsTags] = useState<ElementRecipe[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setVariantsTags(variantsTagsFilter.elementRecipe);
        const varintsTagSelected = filterTags.tags['Featured Variants'];
        let selectedVariants = varintsTagSelected ? Object.keys(varintsTagSelected) : [];
        const ids = variantsTagsFilter.elementRecipe.map(vt => vt._id);
        let variantsToRemove = selectedVariants.filter(sv => !ids.includes(sv));
        dispatch(removeSpecificTags('Featured Variants', variantsToRemove));
    }, [variantsTagsFilter]);

    useEffect(() => {
        const brandTags = filterTags.tags['Featured Brands'];
        let selectedBrands = brandTags ? Object.keys(brandTags).length : 0;
        if (selectedBrands === 0) {
            setPreBrands(selectedBrands);
            dispatch(clearFilterTagVariants());
        } else if (preBrands !== selectedBrands) {
            setPreBrands(selectedBrands);
            const ids = Object.keys(brandTags).map(key => key.toString()).join('|');
            dispatch(getVariantFromBrands(ids));
        }
    }, [filterTags]);

    return (
        <div>
            <Box 
                display="flex"
                flexDirection="row"
                alignItems="center"
                mt={1}
                mb={1}
                onClick={() => setExpanded(!expanded)} className={classes.root}
            >
                <Box pl={2}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
                <Box pl={1}>
                    <span>{title}</span>
                </Box>
            </Box>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box display="flex" flexDirection="column">
                    <Divider />
                    <FilterName title={titleTextFilter} placeholder={placeholderTextFilter}/>
                    {
                        filtersToShow.libraryStatus ?
                            <div>
                                <Divider />
                                <TagListMenu title="Library" tags={libraryStatus} tagKey={FILTER_LIBRARY} />
                            </div>
                        :
                            null
                    }
                    <Divider />
                    <TagListMenu title="Publish Status" tags={publishStatus} tagKey={FILTER_STATUS} />
                    <Divider />
                    <TagListMenu title="Brands" tags={brands.elementRecipe} tagKey={FILTER_BRANDS} />
                    {
                        variantsTags.length > 0 ?
                            <div>
                                <Divider />
                                <TagListMenu title="Variants" tags={variantsTags} tagKey={FILTER_VARIANTS} />
                            </div>
                        : 
                            null
                    }
                    {
                        filtersToShow.mixers ?
                            <div>
                                <Divider />
                                <TagListMenu title="Mixers" tags={mixers.elementRecipe} tagKey='Mixers' />
                            </div>
                        :
                            null
                    }
                    {
                        filtersToShow.occasions ?
                            <div>
                                <Divider />
                                <TagListMenu title="Occasions" tags={occasions.elementRecipe} tagKey='Occasions' />
                            </div>
                        :
                            null
                    }
                    {
                        filtersToShow.drinkTypes ?
                            <div>
                                <Divider />
                                <TagListMenu title="Drink Types" tags={drinkTypes.elementRecipe} tagKey='Drink Types' />
                            </div>
                        :
                            null
                    }
                </Box>
            </Collapse>
            <Divider />

        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: 'fit-content',
        cursor: 'pointer',
    }
  }),
);

export default FilterTags;
import { Box, TextField } from '@material-ui/core';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import styled from "styled-components";
import { createMuiTheme, createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import { grey, blue } from '@material-ui/core/colors';
import login_bg from "../../resources/images/login-bg.jpg";
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmailService } from '../../../services/actions/AuthService';
import CircularProgress from '@material-ui/core/CircularProgress';

import { clearLogin } from '../../../store/actions/AuthActions';



const Auth = ({location}: any) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const { login } = useSelector(state => state.transient);
    const [loading, setLoading] = React.useState(false);
    const classes = useStyles();

    useEffect(()=> {
        dispatch(clearLogin());
    },[])

    useEffect(() => {
        if (login.response) {
            setLoading(false);
        }
    }, [login])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setError(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value));
        setEmail(event.target.value);
    };

    const onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!error && email.length > 0) {
            setLoading(true);
            dispatch(sendEmailService(email));
        }
    }

    const allowedButton = () => {
        return error || email.length === 0 ? {cursor: 'not-allowed'} : {};
    }

    const renderLoginForm = () => {
        if (!login.response) {
            return (
                <form noValidate autoComplete="off" onSubmit={onSubmitHandler}>
                    <Box mt={3}>
                        <TextField
                            fullWidth
                            error={error}
                            placeholder="Email"
                            value={email}
                            onChange={handleChange}
                            variant="outlined"
                            inputProps={{style: { textAlign: 'center', backgroundColor: 'white', padding: '10px', borderRadius: '4px' }}}
                        />

                    </Box>

                    <Box mt={3}>
                        <ThemeProvider theme={outerTheme}>
                            <div className={classes.wrapper}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    fullWidth 
                                    disabled={loading}
                                    className={classes.button}
                                    style={{...{textTransform: 'none'}, ...allowedButton()}} 
                                    type="submit"
                                >
                                    Request access link
                                </Button>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                           
                        </ThemeProvider>
                    </Box>
                </form>
            );
        }

        return (
            <Text>If you are a registered user, you'll receive a login email shortly</Text>
        );
    }

    return (
        <AuthContainer image={login_bg}>
            <LoginBlock>

                <TitleContainer>
                    <Title>
                        Janus Recipes&nbsp;&nbsp;|&nbsp;&nbsp;
                    </Title>
                    <span>
                        <Logo src={require("../../resources/images/css.png")}/>
                    </span>
                </TitleContainer>

                {renderLoginForm()}
            </LoginBlock>
        </AuthContainer>
    );
};


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: blue[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    button: {
        "&:disabled": {
            backgroundColor: "#fafafa"
        }
    }
  }),
);


const outerTheme = createMuiTheme({
    palette: {
        primary: {
            main: grey['300'],
        }
    },
});

const AuthContainer = styled.div<{ image: string }>`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    background-size: cover;
    opacity: 0.99;
    background-position: center;
    background-image: url("${props => props.image}");
`

const LoginBlock = styled.div`
    background-color: #34495e;
    width: 540px;
    margin: 0 auto;
    border-radius: 5px;
    color: #bbdefb !important;
    padding: 32px;
    font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
`
const TitleContainer = styled.h1`
    font-weight: 300;
    text-align: center;
`

const Title = styled.span`
    color: white;
    font-size: 30px;
    font-weight: lighter;
    font-family: sans-serif;
`

const Logo = styled.img`
    max-width: 150px;
    vertical-align: middle;
`

const Text = styled.p`
    margin-top: 20px;
    place-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    font-size: 16px;
`

export default Auth;
import { failUpdateStatus, updateStatusAction } from "../../store/actions/StatusReducerActions";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { StatusUpdateResponse, StatusUpdateResponseCodec } from "../types/StatusRecipeCodec";
import { getRecipe, getRecipes } from "./RecipesService";
import { QuerySearchRecipe } from "../../model/QuerySearchRecipes";
import { getRecipesIssues } from "./RecipeIssuesService";


export const updateStatusAndFetchRecipes = (uuid: string, newStatus: string, page: number, limit: number, query: QuerySearchRecipe, isIssue?: boolean ): ServiceCallAction<StatusUpdateResponse> => ({
	method: "PUT",
	endpoint: `recipe/change/${uuid}`,
	body: {type: 'application/json', value: {newStatus}},
	serviceKey: "updateRecipe",
	responseDecoder: StatusUpdateResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: (data: StatusUpdateResponse) => isIssue? getRecipesIssues(page, limit, query) : getRecipes(page, limit, query ),
	auxiliarAction: updateStatusAction,
	onFailure: failUpdateStatus
});

export const updateStatus = (uuid: string, newStatus: string, _id: string ): ServiceCallAction<StatusUpdateResponse> => ({
	method: "PUT",
	endpoint: `recipe/change/${uuid}`,
	body: {type: 'application/json', value: {newStatus}},
	serviceKey: "updateRecipe",
	responseDecoder: StatusUpdateResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: (data: StatusUpdateResponse) => getRecipe(_id),
	auxiliarAction: updateStatusAction,
	onFailure: failUpdateStatus
});

export const republishAndFetchRecipes = (uuid: string, page: number, limit: number, query: QuerySearchRecipe ): ServiceCallAction<StatusUpdateResponse> => ({
	method: "PUT",
	endpoint: `recipe/republish/${uuid}`,
	body: {type: 'application/json', value: {newStatus: 'Live'}},
	serviceKey: "updateRecipe",
	responseDecoder: StatusUpdateResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: (data: StatusUpdateResponse) => getRecipes(page, limit, query ),
	auxiliarAction: updateStatusAction,
	onFailure: failUpdateStatus
});

export const republish = (uuid: string, _id: string ): ServiceCallAction<StatusUpdateResponse> => ({
	method: "PUT",
	endpoint: `recipe/republish/${uuid}`,
	body: {type: 'application/json', value: {newStatus: 'Live'}},
	serviceKey: "updateRecipe",
	responseDecoder: StatusUpdateResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: (data: StatusUpdateResponse) => getRecipe(_id),
	auxiliarAction: updateStatusAction,
	onFailure: failUpdateStatus
});

export const bulkStatus = (uuids: string[], newStatus: string, page: number, limit: number, query: QuerySearchRecipe, isIssue?: boolean ): ServiceCallAction<StatusUpdateResponse> => ({
	method: "PUT",
	endpoint: `recipe/bulk/change/`,
	body: {type: 'application/json', value: {newStatus: newStatus, uuids: uuids, allContents: false}},
	serviceKey: "updateRecipe",
	responseDecoder: StatusUpdateResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: (data: StatusUpdateResponse) => isIssue? getRecipesIssues(page, limit, query) : getRecipes(page, limit, query ),
	auxiliarAction: updateStatusAction,
	onFailure: failUpdateStatus
});
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createMuiTheme, createStyles, makeStyles, Theme, ThemeProvider } from "@material-ui/core/styles";
import { blue, green, yellow } from "@material-ui/core/colors";
import styled from "styled-components";
import { Card, CardContent, CircularProgress, Snackbar, TextField } from "@material-ui/core";
import BasicDialog from "../basicComponents/BasicDialog";
import { RecipeFirstTitle } from "../RecipeCreate/RecipeTitles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { UploadFullFile } from "./UploadFullFile/UploadFullFile";
import { UploadPreviewFile } from "./UploadPreviewFile/UploadPreviewFile";
import { UploadThumbnailFile } from "./UploadThumbnailFile/UploadThumbnailFile";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useFullMedia } from "../../hooks/useFullMedia";
import {
    clearAllFullMediaUploading,
    clearFullMediaError,
    resetClearAllFullMediaUploading
} from "../../../store/actions/FullMediaUploadingActions";
import {clearAllFullMediaDataAction} from "../../../store/actions/FullMediaDataActions";
import FullMediaWarning from "./FullMediaMessages/FullMediaWarning";

export const _MediaCreate = () => {

    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { error, message, clear, contentId } = useSelector(state => state.transient.fullMediaUploadingReducer);
    const [ errorUploading, setErrorUploading ] = useState(false);

    const {
        closeError,
        showError,
        saving,
        content,
        name,
        setName,
        publishErrors,
        publish,
        publishLive,
        change
    } = useFullMedia();

    const saveInLiveContent = async () => {
        const valid = await publishLive();
        if (valid) {
            history.push('/media/list');
        }

    };

    const saveContent = async () => {
        const valid = await publish();
        if (valid) {
            history.push('/media/list');
        }
    }

    const getTitle = () => {
        if (contentId) {
            return "Edit Full Media"
        } else {
            return "Create Full Media"
        }
    }

    const handleCloseError = () => {
        dispatch(clearFullMediaError());
        setErrorUploading(false);
    }

    const clearAllData = () => {
        dispatch(clearAllFullMediaUploading());
        dispatch(clearAllFullMediaDataAction());
    }

    const restartForm = () => {
        clearAllData();
        setName('');
        history.replace('/media/new', null);
    }

    const onUnload = (ev: BeforeUnloadEvent) => {
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';
    };

    useEffect(() => {
        if (error) {
            setErrorUploading(true);
        }
    }, [error])

    useEffect(() => {
        if (change) {
            window.addEventListener("beforeunload", onUnload);
        }
    }, [change])

    useEffect(() => {
        if (clear) {
            dispatch(resetClearAllFullMediaUploading());
        }
    }, [clear])

    useEffect(() => {
        return () => {
            clearAllData();
            window.removeEventListener("beforeunload", onUnload)
        }
    }, [])

    return (
        <Card>
            <CardContent>

                <BasicDialog
                    open={showError}
                    handleClose={ closeError }
                    okButton="OK"
                    cancelButton={null}
                    description={ publishErrors }
                />

                <FullMediaWarning restartFullMedia={restartForm} changes={change}/>

                <RecipeFirstTitle title={getTitle()} />

                {
                    contentId && (
                        <Box pt={0} pb={4}>
                            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" pt={3}>
                                <UpdatedAt>Updated {content.updatedAt?.textToShow} by {content.author}</UpdatedAt>
                                <Separator />
                            </Box>
                        </Box>
                    )
                }


                <Box mb={3} pb={3} pt={2} pl={2} {...defaultProps}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Full Media Name *"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </Box>

                <Box mb={3} pb={3} pt={2} pl={2} {...defaultProps}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Grid item md={12} lg={10}>
                                <SubTitle>UPLOAD FULL MEDIA *</SubTitle>
                                <UploadFullFile />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid item md={12} lg={10}>
                                <SubTitle>UPLOAD PREVIEW</SubTitle>
                                <UploadPreviewFile />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid item md={12} lg={10}>
                                <SubTitle>UPLOAD THUMBNAIL</SubTitle>
                                <UploadThumbnailFile />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Box display="flex" justifyContent="flex-end">

                    <ThemeProvider theme={outerTheme}>

                        <div className={classes.wrapper}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.buttonWhite}
                                endIcon={<ArrowForwardIosIcon />}
                                onClick={saveContent}
                                disabled={ saving }
                            >
                                SAVE AS DRAFT
                            </Button>
                            { saving && <CircularProgress size={24} className={classes.buttonProgress} /> }
                        </div>
                        <div className={classes.wrapper}>
                            <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={saveInLiveContent}
                                endIcon={<ArrowForwardIosIcon />}
                                disabled={saving}
                            >
                                PUBLISH LIVE
                            </Button>
                            { saving && <CircularProgress size={24} className={classes.buttonProgress} /> }
                        </div>

                    </ThemeProvider>

                </Box>

                <Snackbar open={errorUploading} autoHideDuration={3000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="error">
                        { message }
                    </Alert>
                </Snackbar>

            </CardContent>
        </Card>
    )
}

const defaultProps = {
    borderLeft: "8px solid #bfbfbfcc",
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: 'relative',
        },
        buttonProgress: {
            color: blue[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        buttonWhite: {
            marginRight: theme.spacing(1),
        }
    }),
);

const outerTheme = createMuiTheme({
    palette: {
        primary: {
            main: yellow['A700'],
        },
        secondary: {
            main: green['A700'],
        }
    },
});


const SubTitle = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const Separator = styled.span`
    flex: 1 1 auto;
`;

const UpdatedAt = styled.p`
    margin-left: 15px;
    color: #858383de;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const AutoGenerateContainer = styled.div`
    margin-left: 10px;
`;

import {RecipesActions, RecipesState} from "../reducers/recipeReducer";
import {FullMedia} from "../../model/FullMedia";
import {FullMediaListActions} from "../reducers/FullMediaListReducer";

export const updateFullMediaList = (contents: {contents: FullMedia[], resultlimit: number, currentpage: number, totalcount: number, pagecount: number}): FullMediaListActions => ({
  type: "UPDATE_FULL_MEDIA_LIST",
  mediaList: contents
});

export const clearFullMediasList = (): FullMediaListActions => ({
  type: "CLEAR_FULL_MEDIA_LIST"
});

import { updateNewRecipe, updateRecipes, failNewRecipe } from "../../store/actions/recipeActions";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import {  RecipeResponse, RecipesResponse, RecipesCodec, RecipeResponseCodec } from "../types/CategoriesResponseCodec";
import { QuerySearchRecipe } from "../../model/QuerySearchRecipes";
import {RecipeErrorsParams} from "../../model/RecipeErrors";
import {
	FixRecipeCodec,
	FixRecipeResponseCodec,
	RecipeErrorsDataCodec,
	RecipeErrorsResponseCodec
} from "../types/RecipeErrorCodec";

export const getRecipesIssues = (page: number, limit: number, params: QuerySearchRecipe ): ServiceCallAction<RecipesResponse> => {
	const timeStamp = new Date().getTime();
	return ({
		method: "GET",
		endpoint: `recipe/issues`,
		param: {page, limit, ...params},
		serviceKey: "getRecipes",
		responseDecoder: RecipesCodec,
		type: "SERVICE_CALL",
		onSuccess: (data: any) => updateRecipes(data, timeStamp)
	})
};

export const getRecipeDetail = (_id: string): ServiceCallAction<RecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/${_id}`,
	serviceKey: "getRecipe",
	responseDecoder: RecipeResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateNewRecipe,
	onFailure: failNewRecipe
});

export const getRecipeError = (params: RecipeErrorsParams, page: number, limit: number): ServiceCallAction<RecipeErrorsResponseCodec> => ({
	method: "GET",
	endpoint: `recipe/recipeErrors`,
	param: {page, limit, ...params},
	serviceKey: "getRecipeError",
	responseDecoder: RecipeErrorsDataCodec,
	type: "SERVICE_CALL",
});

export const fixBrandsAndVariants = (brandId: string, variantId: string, secondBrandId?: string, SecondVariantId?: string): ServiceCallAction<FixRecipeResponseCodec> => ({
	method: "PUT",
	endpoint: `recipe/fix/variant-and-brands`,
	body: {type: "application/json", value: { brandId, variantId, secondBrandId, SecondVariantId }},
	serviceKey: "fixBrandsAndVariants",
	responseDecoder: FixRecipeCodec,
	type: "SERVICE_CALL",
});

export const fixEnableContents = (ids: string[]): ServiceCallAction<FixRecipeResponseCodec> => ({
	method: "PUT",
	endpoint: `recipe/fix/enable-contents`,
	body: {type: "application/json", value: { ids }},
	serviceKey: "fixEnableContents",
	responseDecoder: FixRecipeCodec,
	type: "SERVICE_CALL",
});


export const fixRemoveDuplicatedTags = (id: string, tagType: string, tagId: string): ServiceCallAction<FixRecipeResponseCodec> => ({
	method: "PUT",
	endpoint: `recipe/fix/remove-duplicated-tag`,
	body: {type: "application/json", value: { id, tagType, tagId }},
	serviceKey: "fixRemoveDuplicatedTags",
	responseDecoder: FixRecipeCodec,
	type: "SERVICE_CALL",
});

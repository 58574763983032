import { sendEmail, updateToken, updateUid, failLogin, clearLogin } from "../../store/actions/AuthActions";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { AuthResponse, AuthResponseCodec, TokenResponse, TokenResponseCodec, UserInformactionResponse, UserInformactionResponseCodec } from "../types/AuthCodec";

export const sendEmailService = (email: string): ServiceCallAction<AuthResponse> => ({
	method: "POST",
	endpoint: `auth/sendtoken`,
    serviceKey: "getSendEmail",
    header: { user: email, delivery: 'recipes' },
	responseDecoder: AuthResponseCodec,
	type: "SERVICE_CALL_AUTH",
    onSuccess: sendEmail,
    onFailure: sendEmail
});

export const getToken = (uid: string, token: string): ServiceCallAction<TokenResponse> => ({
	method: "POST",
	endpoint: `auth/accesslink`,
    serviceKey: "updateToken",
    header: { uid: uid, token: token, app: 'recipes' },
	responseDecoder: TokenResponseCodec,
	type: "SERVICE_CALL_AUTH",
    onSuccess: updateToken,
    onFailure: failLogin
});

export const getUserInformation = (): ServiceCallAction<UserInformactionResponse> => ({
	method: "POST",
	endpoint: `auth/me`,
    serviceKey: "getUserInformation",
	responseDecoder: UserInformactionResponseCodec,
	type: "SERVICE_CALL",
    onSuccess: updateUid,
    onFailure: failLogin
});

export const logOut = (): ServiceCallAction<UserInformactionResponse> => ({
	method: "POST",
	endpoint: `auth/logout`,
    serviceKey: "logOut",
	responseDecoder: UserInformactionResponseCodec,
	type: "SERVICE_CALL",
    onSuccess: clearLogin,
    onFailure: clearLogin
});

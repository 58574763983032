import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from "styled-components";

interface DashboardElementProps {
    title: string;
    value: number;
    isLoading: boolean;
    color: string;
    colorText: string;
}

const DashboardElement = ({title, value, isLoading, color, colorText}: DashboardElementProps) => {

    const getValue = () => {
        if (isLoading) {
            return <CircularProgress />
        } else {
            return (
                <Element style={{color: colorText}}>
                    {value.toString()}
                </Element>  
            );
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <BoxTitle>{title}</BoxTitle>
            <BoxDashboard style={{backgroundColor: color}}>
                {getValue()}              
            </BoxDashboard>
        </Box>
    )
};

const BoxTitle = styled.span`
    font-size: 16px;
    margin-bottom: 10px;
`;

const BoxDashboard = styled.div`
    width: 214px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Element = styled.span`
    font-size: 30px;
    font-family: 'Arial', sans-serif;
    font-weight: 400;
    font-style: normal;
`;

export default DashboardElement;
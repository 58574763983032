import React from "react";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";

interface Props {
    onClick: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginLeft: '10px',
            width: '100px',
            color: 'white'
        },
    }),
);

export const FixButton = ( { onClick }: Props) => {
    const classes = useStyles();

    return (
        <IssuesButtonStyle
            aria-controls="archived-button"
            aria-haspopup="true"
            variant="contained"
            style={{textTransform: 'none'}}
            size="small"
            className={classes.button}
            onClick={ onClick }
        >
            Fix Issue
        </IssuesButtonStyle>
    )
}

const IssuesButtonStyle = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#d9001b',
        '&:hover': {
            backgroundColor: '#af0217',
        },
    },
}))(Button);

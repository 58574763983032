import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {_checkPreviewAndThumbnail, checkPreviewAndThumbnail} from "../../services/actions/FullMediaService";
import {isRight} from "fp-ts/These";
import {FullFile} from "../../model/FullMediaComponents";
import {
    addPreviewDataAction,
    addThumbnailDataAction, removePreviewDataAction,
    removeThumbnailDataAction
} from "../../store/actions/FullMediaDataActions";
import {
    addPreviewAction,
    addThumbnailAction,
    addThumbnailFromImageAction
} from "../../store/actions/FullMediaUploadingActions";

const UPDATE_TIME = 2000;

export const useAutogenerateFile = ( typeFile: 'thumbnail' | 'preview' ) => {

    const dispatch = useDispatch();
    const [ checkFile, setCheckFile ] = useState(false);
    const [ autogenerateFile, setAutogenerateFile ] = useState<FullFile>();
    const timerId = useRef(0);
    const id = useRef('');

    const removeAutogeneratedFile = () => {
        if (typeFile === 'thumbnail') {
            dispatch(removeThumbnailDataAction());
            dispatch(addThumbnailAction(false));
            dispatch(addThumbnailFromImageAction(false));
        } else if (typeFile === 'preview') {
            dispatch(removePreviewDataAction());
            dispatch(addPreviewAction(false));
        }

    }

    const runCheckProgressTimeout = () => {
        setCheckFile(false);
        timerId.current = setTimeout(() => {
            setCheckFile(true);
        }, UPDATE_TIME);

    }

    const loadAutogenerateFile = (data: { finished: boolean, preview?: FullFile, thumbnail?: FullFile }) => {
        if (data[typeFile]) {
            setAutogenerateFile(data[typeFile]);
        }

        if ( typeFile === 'thumbnail' && !!data.thumbnail ) {

            dispatch(addThumbnailDataAction(data.thumbnail));

        } else if (typeFile === 'preview' && data.preview) {

            dispatch(addPreviewDataAction(data.preview));

        }

    }

    const updateProgressFile = async () => {
        const resp = await dispatch(_checkPreviewAndThumbnail(id.current));

        if (!isRight(resp)) {
            //TODO: Manejar error
            return;
        }

        if ( resp.right.finished) {
            loadAutogenerateFile(resp.right);
        } else {
            runCheckProgressTimeout();
        }

    }

    useEffect(() => {

        if ( checkFile ) {
            updateProgressFile();
        }

    }, [checkFile])

    const checkAutogenerateFile = (uploadingId: string) => {

        if (uploadingId.length === 0) {
            return;
        }

        id.current = uploadingId;

        updateProgressFile();

    }

    useEffect(() => {
        return () => {
            clearTimeout(timerId.current);
        }
    }, [])

    return {
        autogenerateFile,
        checkAutogenerateFile,
        timerId,
        removeAutogeneratedFile
    }

}

import React from "react";
import {DuplicatedName, RecipeData, WarningMessage} from "../../../../model/RecipeErrors";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SearchIcon from "@material-ui/icons/Search";
import {FixButton} from "./FixButton";
import styled from "styled-components";
// import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
// import {updateFilterName} from "../../../../store/actions/FilterTagActions";

interface Props {
    recipes: DuplicatedName[];
    showDialog: (messages: string[]) => void;
    setWarningMessage: (value: WarningMessage) => void;
}

export const DuplicatedNameRows = ({ recipes, showDialog, setWarningMessage }: Props) => {

    // const dispatch = useDispatch();
    const history = useHistory();

    const fixIssue = (recipe: DuplicatedName) => {
        // dispatch(updateFilterName(recipe.name));
        history.push('/recipe/list',  {filterName: recipe.name});
    }

    const showWaring = (recipe: DuplicatedName) => {

        setWarningMessage({
            showWaring: true,
            messages: [`To fix this, you need to go recipes list, search by name '${recipe.name}' and modify the name of the recipes`, 'Are you sure to continue?'],
            callback: () => fixIssue(recipe),
            ok: 'Go To Recipe List'
        })
    }

    const render = (item: DuplicatedName) => {
        return (
            <TableRow key={item.name}>
                <TableCell component="th" scope="row">
                    Duplicated Name
                </TableCell>
                <TableCell
                    component="th"
                    scope="row"
                    style={{ cursor: 'pointer'}}
                    onClick={() => showWaring(item)}
                >
                    <RecipeName>{item.name}</RecipeName>
                </TableCell>
                <TableCell component="th" scope="row">
                    -
                </TableCell>
                <TableCell component="th" scope="row">
                    <InfoCell onClick={ () => showDialog([item.messages]) }>
                        <SearchIcon style={{marginRight: '10px'}}/>
                        <span>There are recipes with the same name</span>
                    </InfoCell>
                </TableCell>
                <TableCell component="th" scope="row">
                    <FixButton onClick={() => showWaring(item) } />
                </TableCell>
            </TableRow>
        )
    }

    return (
        <>
            {
                recipes && recipes.length > 0 &&
                recipes.map( render )
            }
        </>
    )
}

const InfoCell = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RecipeName = styled.span`
  color: blue;
  text-decoration: underline;
`;

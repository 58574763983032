import React, { useState } from 'react';
import { Field, reduxForm, InjectedFormProps, FieldArray } from 'redux-form';
import { renderTextField } from '../../basicComponents/LocalFields';
import validate from '../Validate';
import { createMuiTheme, createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import styled from "styled-components";
import Ingredients from "./Ingredients";
import { Divider, Grid } from '@material-ui/core';
import { RecipeName, RecipeTitle } from '../RecipeTitles';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';

const theme = createMuiTheme({
    palette: {
        primary: blue,
    },
});

const defaultProps = {
    borderLeft: "8px solid #bfbfbfcc",
};

interface CustomProps {
    previousPage: () => void;
};

let RecipeCreateSecondStep = (props: CustomProps & InjectedFormProps<{}, CustomProps>) => {
    const { handleSubmit, previousPage } = props;
    const [description, setDescription] = useState('');
    const formState = useSelector(state => state.form);

    const onKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        } 
    }

    const clearEmptyIngredients = () => {
        const ingredients = formState && formState.createRecipe && formState.createRecipe.values && formState.createRecipe.values.ingredients ? formState.createRecipe.values.ingredients : null;
        if (ingredients) {
            let newIngredientes = ingredients
                                    .filter((ing: {description: string, id: string}) => ing.description !== '')
                                    .map((ing: {description: string, id: string}, index: number) => {
                                        return {description: ing.description, id: index.toString()}
                                    });
            props.change("ingredients", newIngredientes);
        }
    };

    return (
        <div>
             <form 
                onSubmit={e => {
                    clearEmptyIngredients();
                    handleSubmit(e);
                }} 
                onKeyPress={onKeyPress}
            >
                
                <RecipeName />

                <RecipeTitle title={"Ingredients"} />

                <Box mt={5}>
                    <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                            <Grid container spacing={1}>
                                <p>
                                    <OrderText>
                                        Order #
                                    </OrderText>
                                </p>
                                <DndProvider backend={HTML5Backend}>
                                    <FieldArray name="ingredients" component={Ingredients} description={description} setDescription={setDescription} />
                                </DndProvider>
                            </Grid>
                    </Box>
                </Box>

                <Box mt={5}>
                    <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Field
                                    name="servingSize"
                                    component={renderTextField}
                                    label="Serving Size"
                                    fullWidth
                                    required
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Box pt={3}>
                    <Divider />
                </Box>

                <Box display="flex" justifyContent="space-between" pt={5}>
                    <ThemeProvider theme={theme}>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            startIcon={<ArrowBackIosIcon />}
                            onClick={previousPage}
                        >
                            Previous
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                        >
                            Next: Prep Steps, Menu Copy
                        </Button>
                    </ThemeProvider>
                </Box>
            
            </form>
        </div>
    );
};

const Title = styled.h3`
    font-size: 20px;
    color: #616184;
    font-weight: 700;
    font-family: sans-serif;
    margin: 20px 0;
`;


const SubTitle = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const OrderText = styled.span`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.2px;
    color: #A58BAF;
    line-height: 21px;
`;

export default reduxForm<{}, CustomProps>({
    form: 'createRecipe',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    
  })(RecipeCreateSecondStep);

import {Reducer} from "redux";

export interface FullMediaState {
  id: string;
  running: boolean;
  percent: number;
  result?: { id: string, url: string, name: string, mimeType: string } | null;
  progress: string;
  origin?: string;
}

export interface FullMediasState {
  fullMedia: FullMediaState;
  thumbnail: FullMediaState;
  preview: FullMediaState;
  error: {
    fullMedia: boolean;
    thumbnail: boolean;
    preview: boolean;
  };
  errorMessage: string;
}

const defaultState: FullMediasState = {
  fullMedia: {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined},
  thumbnail: {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined},
  preview: {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined},
  error: {
    fullMedia: false,
    thumbnail: false,
    preview: false,
  },
  errorMessage: ''
};

export const fullMediaReducer: Reducer<FullMediasState, FullMediaActions> = (
  state: FullMediasState = defaultState,
  action: FullMediaActions
) => {
  switch (action.type) {
    case "UPLOAD_FULL_MEDIA":
      let uploadState = {...state};
      uploadState[action.origin] = {...action.file, origin: action.origin};
      return uploadState;
    case "CLEAR_FULL_MEDIA":
      let newState = {...state};
      switch (action.origin) {
        case "fullMedia":
          newState.fullMedia = {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined};
          break;
        case "preview":
          newState.preview = {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined};
          break;
        case "thumbnail":
          newState.thumbnail = {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined};
          break;
      }
      return newState;
    case "CLEAR_ALL_FULL_MEDIA":
      let clearAllState = {...state};
      clearAllState.fullMedia = {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined};
      clearAllState.preview = {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined};
      clearAllState.thumbnail = {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined};
      return clearAllState;
    case "UPDATE_THUMBNAIL_FULL_MEDIA":
      let contentsThumb = {...state};
      contentsThumb.thumbnail.id = '';
      contentsThumb.thumbnail.running = false;
      contentsThumb.thumbnail.percent = 100;
      contentsThumb.thumbnail.origin = 'thumbnail';
      contentsThumb.thumbnail.result = {id: action.thumbnail.id, url: action.thumbnail.url, name: action.thumbnail.name, mimeType: action.thumbnail.mimeType};
      return contentsThumb;
    case "UPDATE_PREVIEW_AND_THUMBNAIL":
      let contentsPAT = {...state};
      if ( action.preview ) {
        contentsPAT.preview.id = '';
        contentsPAT.preview.running = false;
        contentsPAT.preview.percent = 100;
        contentsPAT.preview.origin = 'preview';
        contentsPAT.preview.result = {id: action.preview.id, url: action.preview.url, name: action.preview.name, mimeType: action.preview.mimeType};
      }
      if ( action.thumbnail ) {
        contentsPAT.thumbnail.id = '';
        contentsPAT.thumbnail.running = false;
        contentsPAT.thumbnail.percent = 100;
        contentsPAT.thumbnail.origin = 'thumbnail';
        contentsPAT.thumbnail.result = {id: action.thumbnail.id, url: action.thumbnail.url, name: action.thumbnail.name, mimeType: action.thumbnail.mimeType};
      }
      return contentsPAT;
    case "FAIL_UPLOAD_FULL_MEDIA":
      let stateWithError = {...state};
      stateWithError.errorMessage = 'Error to upload ' + action.origin;
      switch (action.origin) {
        case "thumbnail":
          stateWithError.thumbnail = {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined};
          stateWithError.error.thumbnail = action.origin === "thumbnail";
          break;
        case "preview":
          stateWithError.preview = {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined};
          stateWithError.error.preview = action.origin === "preview";
          break;
        case "fullMedia":
          stateWithError.fullMedia = {id: '', running: false, percent: 0, progress: '', result: undefined, origin: undefined};
          stateWithError.error.fullMedia = action.origin === "fullMedia";
          break;
      }
      return stateWithError
    case "CLEAR_ERRORS":
      let stateWithOutError = {...state};
      stateWithOutError.errorMessage = '';
      stateWithOutError.error = {
        fullMedia: false,
        thumbnail: false,
        preview: false,
      };
      return stateWithOutError;
    default:
      return state;
  }
};

export type FullMediaActions =
  | {
  type: "UPLOAD_FULL_MEDIA";
  file: FullMediaState;
  origin: 'fullMedia' | 'thumbnail' | 'preview';
} | {
  type: "FAIL_UPLOAD_FULL_MEDIA";
  origin: 'fullMedia' | 'thumbnail' | 'preview';
} | {
  type: "CLEAR_ERRORS"
} | {
  type: "UPDATE_THUMBNAIL_FULL_MEDIA";
  thumbnail: { id: string, url: string, name: string, mimeType: string };
} | {
  type: "CLEAR_FULL_MEDIA",
  origin: 'fullMedia' | 'thumbnail' | 'preview';
} | {
  type: "CLEAR_ALL_FULL_MEDIA"
} | {
  type: "UPDATE_PREVIEW_AND_THUMBNAIL";
  finished: boolean;
  thumbnail?: {
    id: string;
    url: string;
    name: string;
    mimeType: string;
  };
  preview?: {
    id: string;
    url: string;
    name: string
    mimeType: string;
  };
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getUserInformation } from '../services/actions/AuthService';
import { parseSearch } from '../services/types/util/parseSearch';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from "styled-components";
import { store } from '../store/store';
import { clearLogin } from '../store/actions/AuthActions';


interface CategorySearchParams {
    token: string | null;
    uid: string | null;
}

export const LoadingPage = (props: any) => {

    const query = parseSearch<CategorySearchParams>(props.location.search || props.location.hash.slice(2));
    const history = props.history;
    const dispatch = useDispatch();
    const { auth } = useSelector(state => state.persistent);

    useEffect(() => {
        const token = store.getState().persistent.auth.token;
        if (token) {
            history.push('/dashboard');
        } else if (query.token && query.uid) {
            dispatch(getToken(query.uid, query.token));
        } else {
            history.push('/login');
        }
    }, []);


    useEffect(() => {
        if (auth.error) {
            dispatch(clearLogin());
            history.push('/login');
        } else if (auth.token && !auth.uid) {
            dispatch(getUserInformation());
        } else if (auth.token && auth.uid) {
            history.push('/dashboard');
        }
    }, [auth])

    return (
        <LoadingContainer>
            <CircularProgress size={150}/>
        </LoadingContainer>
    );

};

const LoadingContainer = styled.div`
    position: absolute;
    top: 35%;
    left: 50%;
`
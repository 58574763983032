import { VariantsTagsActions } from "../reducers/VariantsTagsReducer";
import { ElementRecipe } from '../../model/ElementRecipe';

interface elementsContent {
    contents: ElementRecipe[];
};

export const updateFilterTagVariantsStatus = (elementsContent: elementsContent): VariantsTagsActions => ({
    type: "UPDATE_FILTER_TAG_VARIANTS",
    elementRecipe: elementsContent.contents
});

export const clearFilterTagVariants = (): VariantsTagsActions => ({
	type: "CLEAR_FILTER_TAG_VARIANTS"
});

import React, { useState } from 'react';
import { Box, Button, Divider, Icon, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PublishIcon from '@material-ui/icons/Publish';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ImportRecipe from '../RecipeList/ImportRecipe';
import { withStyles } from '@material-ui/styles';
import { FIELD_DELIMITER, FILE_TYPE } from '../../../services/types/util/Constants';

function MenuIcon(props: any) {
    return (
        <Icon>
            <img src={props.src} style={props.style}/>
        </Icon>
    );
}

interface RecipeActionsProps {
    handlerPublish?: () => void;
    handlerMoveToDraft?: () => void;
    handlerArchiveSelected?: () => void;
    handlerImportFromFile?: () => void;
    handleExportRecipes?: (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => void;
    handleExportAllRecipes?: (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => void;
    showPublishOption?: boolean;
    showAdminOptions: boolean;
    showImportOptions: boolean;
    showExportOptions: boolean;
    showExportAllOptions?: boolean;
}

const RecipeActions = ({
        handlerPublish,
        handlerMoveToDraft,
        handlerArchiveSelected,
        handleExportRecipes,
        handleExportAllRecipes,
        showPublishOption,
        showAdminOptions,
        showImportOptions,
        showExportOptions,
        showExportAllOptions}: RecipeActionsProps) => {
            
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showExportData, setShowExportData] = useState(false);
    const [showExportAllData, setShowExportAllData] = useState(false);
    const [showExportCSVData, setShowExportCSVData] = useState(false);
    const [openImport, setOpenImport] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setShowExportCSVData(false);
        setShowExportData(false);
        setShowExportAllData(false);
    };

    const handleClose = (funtionToCall?: () => void) => {
        setAnchorEl(null);
        if (funtionToCall) {
            funtionToCall();
        }
    };

    const handleExport = (file_type: FILE_TYPE, all: boolean, field_delimiter?: FIELD_DELIMITER) => {
        if (!all && handleExportRecipes) {
            handleExportRecipes(file_type, field_delimiter);
        }
        if (all && handleExportAllRecipes) {
            handleExportAllRecipes(file_type, field_delimiter);
        }
        handleClose();
    };

    const openImportModal = () => {
        handleClose();
        setOpenImport(true);
    }

    const renderAdminOptions = () => {
        return (
            <div>
                {
                    showPublishOption || showPublishOption === undefined ?
                        <MenuItem onClick={e => handleClose(handlerPublish)}>
                            <StyledMenuItem>
                                <PublishIcon fontSize="small" />
                            </StyledMenuItem>
                            <ListItemText primary="Publish Selected" />
                        </MenuItem>
                    :
                        null
                }
                <MenuItem onClick={e => handleClose(handlerMoveToDraft)}>
                    <StyledMenuItem>
                        <EditIcon fontSize="small" />
                    </StyledMenuItem>
                    <ListItemText primary="Move to Draft" />
                </MenuItem>
                <MenuItem onClick={e => handleClose(handlerArchiveSelected)}>
                    <StyledMenuItem>
                        <RemoveCircleIcon fontSize="small" />
                    </StyledMenuItem>
                    <ListItemText primary="Archive Selected" />
                </MenuItem>
                <Divider />
            </div>
        )
    }

    const renderExportOptions = (showExportData: boolean, all: boolean) => {
        if (showExportData) {
            return (
                <div>
                    <SubMenuitem onClick={e => setShowExportCSVData(!showExportCSVData)}>
                        <StyledMenuItem>
                            <MenuIcon src={"/assets/icons/csv.svg"} style={{ height: '28px', marginLeft: '-5px'}}/>
                        </StyledMenuItem>
                        <ListItemText primary="CSV File" />
                        <StyledMenuItem>
                            <ArrowDropDownIcon fontSize="small" />
                        </StyledMenuItem>
                    </SubMenuitem>

                    {
                        showExportCSVData ? 
                            (
                                <div>
                                    <SubMenuCSVitem onClick={e => handleExport('csv', all, 'COMMA')}>
                                        - Comma Delimited
                                    </SubMenuCSVitem>
                                    <SubMenuCSVitem onClick={e => handleExport('csv', all, 'SEMICOLON')}>
                                        - Semicolon Delimited
                                    </SubMenuCSVitem>
                                </div>
                            )
                        :
                            null
                    }

                    <SubMenuitem onClick={e => handleExport('xlsx', all)}>
                        <Box display="flex" flexDirection="row" alignItems="end">
                            <StyledMenuItem>
                                <MenuIcon src={"/assets/icons/excel.svg"} style={{ height: '21px'}}/>
                            </StyledMenuItem>
                            <ListItemText primary="Excel File" />
                        </Box>
                    </SubMenuitem>
                    <SubMenuitem onClick={e => handleExport('json', all)}>
                        <Box display="flex" flexDirection="row" alignItems="end">
                            <MenuIcon src={"/assets/icons/json.svg"} style={{ height: '21px'}}/>
                            <ListItemText primary="JSON File" />
                        </Box>
                    </SubMenuitem>
                </div>
                
                
            );
        }
        return null;
    }

    const renderImportExportOptions = () => {
        return (
            <div>
                {
                    showImportOptions ? 
                        <MenuItem onClick={e => openImportModal()}>
                            <StyledMenuItem>
                                <PublishIcon fontSize="small" />
                            </StyledMenuItem>
                            <ListItemText primary="Import from File" />
                        </MenuItem>
                    : 
                        null
                }
                
                <MenuItem onClick={e => setShowExportData(!showExportData)}>
                    <StyledMenuItem>
                        <GetAppIcon fontSize="small" />
                    </StyledMenuItem>
                    <ListItemText primary="Export Data to File" />
                    <StyledMenuItem>
                        <ArrowDropDownIcon fontSize="small" />
                    </StyledMenuItem>
                </MenuItem>
                {renderExportOptions(showExportData, false)}
                
                {
                    showExportAllOptions ? 
                        <div>
                            <MenuItem onClick={e => setShowExportAllData(!showExportAllData)}>
                                <StyledMenuItem>
                                    <GetAppIcon fontSize="small" />
                                </StyledMenuItem>
                                <ListItemText primary="Export All Data to File" />
                                <StyledMenuItem>
                                    <ArrowDropDownIcon fontSize="small" />
                                </StyledMenuItem>
                            </MenuItem>
                            {renderExportOptions(showExportAllData, true)}
                        </div>
                    :
                        null
                }
                
            </div>
        );
    }

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                endIcon={<ImportExportIcon />}
                onClick={handleClick}
                aria-controls="simple-menu" 
                aria-haspopup="true"
            >
                Actions
            </Button>
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={e => handleClose()}
            >
                {
                    showAdminOptions ?
                        
                        (renderAdminOptions())
                        
                    : 
                        null
                }
                {
                    showExportOptions ?
                        (renderImportExportOptions())
                    :
                        null
                }
            </StyledMenu>
            <ImportRecipe open={openImport} setOpen={setOpenImport}/>
        </div>
    );
};

const SubMenuitem = withStyles((theme) => ({
    root: {
        paddingLeft: '40px'
    },
}))(MenuItem);

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        minWidth: '27px',
    },
}))(ListItemIcon);

const SubMenuCSVitem = withStyles((theme) => ({
    root: {
        paddingLeft: '50px'
    },
}))(MenuItem);

export default RecipeActions;
import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, CircularProgress, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import { FileImage } from '../../../../model/FileImage';
import styled from "styled-components";
import BasicDialog from '../BasicDialog';
import { LifeStyle, DrinkShot } from '../../../../services/types/util/Constants';

interface recipeImageProps {
    file: FileImage | null;
    deleteFile: () => void;
    editFile: any;
    showButtons: boolean;
    error?: string | null;
    type?: LifeStyle | DrinkShot | null;
    id?: string | null
}

const RecipeImage = ({file, deleteFile, editFile, showButtons, error, type, id}: recipeImageProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClose = (ok: boolean) => {
        setOpen(false);
        if (ok) {
            deleteFile();
        }
    }

    const rederButtons = () => {
        if (showButtons) {
            const disabled = !file || (!file.janusFile && !error)
            return (
                <div>
                    <Box display="flex" flexDirection="row">
                        <IconButton color="primary" aria-label="edit" onClick={() => editFile()} disabled={disabled}>
                            <EditIcon />
                        </IconButton>
                        <IconButton color="secondary" aria-label="delete" onClick={() => setOpen(true)} disabled={disabled}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </Box>
                </div>
            )
        }
    }

    const getSize = (): string => {
        if (file && file.DCHImage) {
            return file.DCHImage.filesize ? file.DCHImage.filesize.toString() + ' MB' : '';
        } else if (file && file.file) {
            const sizeKB = Math.trunc(file.file.size / 1024);
            return sizeKB > 1000 ? Math.trunc(sizeKB / 1024).toString() + ' MB' : sizeKB.toString() + ' KB';
        } else if (file && file.janusFile){
            return file.janusFile.metadata.size ? file.janusFile.metadata.size.toString() + ' MB' : '';
        } else {
            return "";
        }
    };

    const getDCHID = (): string => {
        if (file && file.DCHImage) {
            return file.DCHImage.id ? 'DCH ID: ' + file.DCHImage.id.toString() : '';
        } else if (file && file.janusFile){
            return file.janusFile.metadata.ownerID ? 'DCH ID: ' + file.janusFile.metadata.ownerID.toString() : '';
        } else {
            return "";
        }
    }

    const getSrc = () => {
        if (file && file.DCHImage) {
            const url = file.DCHImage.renditions.thumbnail ? file.DCHImage.renditions.thumbnail.url : '/assets/extensions/_blank.png';
            return url;
        } else if (file && file.file) {
            return URL.createObjectURL(file.file);
        } else if (file && file.janusFile){
            const imageType = type === 'lifeStyle' ? (file.janusFile.metadata.thumbnail ? 'thumbnail' : 'lifeStyle') :  type === 'drinkShot' && file.janusFile.metadata.thumbnail ? 'thumbnail_Drinkshot' : 'drinkShot';
            const thumbnail = file.janusFile.metadata.thumbnail;
            console.log(process.env.REACT_APP_API_URL + '/contents/stream/' + id + '?type=' + imageType);
            
            return process.env.REACT_APP_API_URL + '/contents/stream/' + id + '?type=' + imageType;
            //return process.env.REACT_APP_API_URL + '/contents/stream/' + id + '?type=' + imageType;
            //return thumbnail ? thumbnail : file.janusFile.url;
        } else {
            return "";
        }
    };

    const getFileName = () => {
        let fileName = '';
        if (file && file.DCHImage) {
            fileName = file.DCHImage.name;
        } else if (file && file.file) {
            fileName = file.file.name;
        } else if (file && file.janusFile){
            fileName = file.janusFile.name;
        }
        return fileName;
    };

    const getShortFileName = () => {
        let fileName = getFileName();
        return fileName !== '' && fileName.trim().length > 30? fileName.trim().slice(0,30) + '...' : fileName;
    }

    const renderIcon = () => {
        if (error) {
            return <Broken />;
        }

        const dchImage = file && file.janusFile && file.janusFile.metadata && file.janusFile.metadata.ownerID;

        if (file && file.janusFile && file.janusFile.broken) {
            return <Broken />;
        }

        if (file && !file.janusFile) {
            return <CircularProgress size={15} color="secondary"/>
        } else if (dchImage) {
            return <OK />;
        } else if (file && file.DCHImage) {
            return file.DCHImage.enableToDownload.valid ? <OK /> : <Broken />;
        }
        return <Local />;
    };

    const addDefaultSrc = (ev: any) => {
        ev.target.src = '/assets/extensions/_blank.png'
    }

    return (
        <div>
            <Box display="flex" flexDirection="row" justifyContent="space-between" className={classes.imageContainer}>
                <Box
                    display="flex"
                    flexDirection="row"
                >
                    <img
                        className={classes.image}
                        onError={addDefaultSrc}
                        alt={getFileName()}
                        src={getSrc()}
                    />
                    <Box display="flex" flexDirection="column">
                        <Tooltip disableFocusListener title={getFileName()} placement="top">
                            <span className={classes.fileName}>
                                {getShortFileName()}
                            </span>
                        </Tooltip>
                        <span className={classes.text}>
                            {getSize()}
                        </span>
                        <span className={classes.text}>
                            {getDCHID()}
                        </span>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={ showButtons ? "space-between" : "flex-end" }
                    alignItems="flex-end"
                >
                    {rederButtons()}
                    <div className={classes.dchStatus}>
                            <span className={classes.dchStatusText}>DCH Status</span>
                            {renderIcon()}
                    </div>
                </Box>
            </Box>
            <BasicDialog
                open={open}
                handleClose={handleClose}
                okButton="delete"
                cancelButton="cancel"
                description={["Are you sure to delete the image?"]}
            />
        </div>

    );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
        padding: "5px",
        maxWidth: "150px",
        maxHeight: "150px",
    },
    imageContainer: {
        backgroundColor: '#dededeb5',
        maxWidth: "700px",
        padding: "10px 20px"
    },
    dchStatus: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "20px",
    },
    text: {
        color: "gray",
        padding: "20px 0 0 10px",
    },
    fileName: {
        padding: "20px 0 0 10px",
    },
    dchStatusText: {
        paddingRight: "10px",
    },
  }),
);

const OK = styled.span`
    height: 21px;
    width: 21px;
    background-color: #93f362;
    border-radius: 50%;
    display: inline-block;
    border:1px solid black;
`;

const Broken = styled.span`
    height: 21px;
    width: 21px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    border:1px solid black;
`;

const Local = styled.span`
    height: 21px;
    width: 21px;
    background-color: #ece8e8;
    border-radius: 50%;
    display: inline-block;
    border:1px solid black;
`;



export default RecipeImage;

import { Box, Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import styled, {css} from "styled-components";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ImageModal from './ImageModal';
import { DCHImageState } from '../../../../store/reducers/DCHImageReducer';
import { DCHImages } from '../../../../model/DCHImages';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
  }),
);

const extensions = ['acc', 'avi', 'bmp', 'c', 'cpp', 'css', 'csv',
                    'dat', 'dmg', 'doc', 'docx', 'exe', 'flv', 'jpg', 'jpeg', 'gif', 'png',
                    'h', 'hpp', 'html', 'iso', 'java', 'js', 'json', 'less',
                    'mid', 'mp3', 'mp4', 'mpg', 'odf', 'ods', 'odt', 'otp', 'ots', 'ott',
                    'pdf', 'php', 'ppt', 'pptx', 'psd', 'py', 'qt', 'rar', 'rb', 'rtf',
                    'sass', 'scss', 'sql', 'tga', 'tgz', 'tiff', 'txt', 'wav', 'xls', 'xlsx', 'xml', 'yml', 'zip','mov', 'eps' , 'ai', 'tif', 'tiff'];

interface MediaPickerProps {
    DCHImages: DCHImageState,
    imageSelected: DCHImages | null;
    onSelectImage: (_: DCHImages | null) => void;
    setPage: (_: number) => void
}

const MediaPicker = ({DCHImages, imageSelected, onSelectImage, setPage}: MediaPickerProps) => {
    const classes = useStyles();

    const onChangePage = (event: object, page: number) => {
        setPage(page);
    }

    const getTotalPages = (total: number) => {
        let maxDCHValue = 833;
        return total > maxDCHValue ? maxDCHValue : total
    }

    const addImage = (image: DCHImages) => {
        if (!imageSelected) {
            return onSelectImage(image);
        }
        image.id === imageSelected.id ? onSelectImage(null) : onSelectImage(image);
    }

    const getIconForUrl = (originalUrl: string, alt?: string) => {
        let newUrl = '/assets/extensions/';
        let splitUrl = originalUrl ? originalUrl.split('.') : [];
        let extension = '';
        let extensionFound = false;
        if (splitUrl.length > 1) {
            extension = splitUrl[splitUrl.length - 1].toLocaleLowerCase();
            extensionFound = extensions.indexOf(extension) > -1;
        }
        if (!extensionFound && alt) {
            let splitAlt = alt.split('.');
            if (splitAlt.length > 1) {
                extension = splitAlt[splitAlt.length - 1].toLocaleLowerCase();
                extensionFound = extensions.indexOf(extension) > -1;
            }
        }
        newUrl += extensionFound ? (extension + '.png') : '_blank.png';
        return newUrl;
    };
    const [open, setOpen] = React.useState(false);
    const [imageOpen, setImageOpen] = React.useState(0);
  
    const handleClickOpen = (id: number) => {
        setImageOpen(id);
        setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const imageEnableToDownload = (image: DCHImages) => {
        return image.enableToDownload.valid && image.mimetype?.includes('image') && !image.retired;
    }

    const getTooltipText = (image: DCHImages) => {
        if (!image.enableToDownload.valid) {
            return image.enableToDownload.message;
        } else if (!image.mimetype?.includes('image')){
            return "Can only select images";
        } else if (image.retired) {
            return "The asset is Retired";
        } else {
            return '';
        }            
    }

    const getSrc = (image: DCHImages) => {
        return image.renditions.thumbnail ? image.renditions.thumbnail.url : '/assets/extensions/_blank.png';
    }

    const images = DCHImages.assets.map(image => {
        const Selected = imageSelected && imageSelected.id === image.id ? ImageContainerSelected : ImageContainer2;
        const title = image.title.length > 0 ? image.title : 'no name';
        const tooltipText = getTooltipText(image);
        const validToDowload = imageEnableToDownload(image);
        return (
            <Grid item xs={2} key={image.id}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Tooltip disableFocusListener title={tooltipText} placement="top">
                        <ImageContainer1 onClick={() => validToDowload && addImage(image)}>
                            <Selected>
                                <ImageContainer3>
                                    <ImageContainer4>
                                        <Image src={getSrc(image)} alt={image.name} />
                                        <IconImage src={getIconForUrl(image.name)}/>
                                        <IconVisibilty>
                                            <div>
                                                <VisibilityIcon style={{ fontSize: 40 }} onClick={() => handleClickOpen(image.id)}/>
                                                <ImageModal 
                                                    open={imageOpen==image.id && open} 
                                                    onClose={handleClose} 
                                                    url={image.renditions.preview ? image.renditions.preview.url : ''}
                                                />
                                            </div>
                                        </IconVisibilty>
                                    </ImageContainer4>
                                </ImageContainer3>
                            </Selected>
                        </ImageContainer1>
                    </Tooltip>
                    
                    <ImageName>{title}</ImageName>
                </Box>
                
            </Grid>
        );
    });

    return (
        <div>
            <Grid container spacing={3}>
                {images}
            </Grid>
            <PaginationContainer>
                <Pagination page={DCHImages.currentpage} count={getTotalPages(DCHImages.pagecount)} color="primary" onChange={onChangePage}/>
            </PaginationContainer>
        </div>
    );
};

const PaginationContainer = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    :& > *: {
        margin-top: 2px;
    }
`;

const Image = styled.img`
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

const IconVisibilty = styled.div`
    display: none;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    div {
        font-size: 50px;
        width: 50px;
        height: 50px;
        color: #a2a2a2;
    }
    &:hover {
        div {
            color: #000;
        }
    }
`;

const ImageContainer4 = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    &:hover {
        ${IconVisibilty} {
            display: block;
        }
    }
`;

const ImageContainer1 = styled.div`
    position: relative;
    margin: 0 auto 0 auto;
    width: 100%;
    max-width: 80%;
`;

const ImageContainer2 = styled.div`
    border: 2px solid #34495e;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
`;

const ImageContainerSelected = styled(ImageContainer2)`
    border: 2px solid #47a8ec;
`;

const ImageContainer3 = styled.div`
    height: 150px;
`;

const ImageName = styled.span`
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    color: #536171;
    display: block;
    height: 37px;
    margin-top: 10px;
`;
const IconImage = styled.img`
    position: absolute;
    z-index: 1;
    width: 100px;
    right: -25px;
    bottom: -15px;
`;

export default MediaPicker;
import { Reducer } from "redux";
import { ElementRecipe } from '../../model/ElementRecipe';

interface ElementRecipeState {
    elementRecipe: ElementRecipe[];
}

interface VariantsWithBrandsState {
    primaryVariants: ElementRecipe[];
    secondaryVariants: ElementRecipe[];
}

const defaultVariantsWithBrandsState: VariantsWithBrandsState = {
    primaryVariants: [],
    secondaryVariants: []
};

const defaultState: ElementRecipeState = {
    elementRecipe: []
};

export const brandReducer: Reducer<ElementRecipeState, ElementsRecipeActions> = (
    state: ElementRecipeState = defaultState,
    action: ElementsRecipeActions
) => {
    switch (action.type) {
        case "UPDATE_BRANDS":
            return {elementRecipe: action.elementRecipe};
        default:
            return state;
    }
};

export const variantReducer: Reducer<ElementRecipeState, ElementsRecipeActions> = (
    state: ElementRecipeState = defaultState,
    action: ElementsRecipeActions
) => {
    switch (action.type) {
        case "UPDATE_VARIANTS":
            return {elementRecipe: action.elementRecipe};
        default:
            return state;
    }
};

export const variantsWithBrandReducer: Reducer<VariantsWithBrandsState, ElementsRecipeActions> = (
    state: VariantsWithBrandsState = defaultVariantsWithBrandsState,
    action: ElementsRecipeActions
) => {
    switch (action.type) {
        case "UPDATE_PRYMARY_VARIANTS":
            return {...state, primaryVariants: action.elementRecipe};
        case "UPDATE_SECONDARY_VARIANTS":
            return {...state, secondaryVariants: action.elementRecipe};
        case "CLEAR_VARIANTS":
            return defaultVariantsWithBrandsState;
        default:
            return state;
    }
};

export const serveStrategyReducer: Reducer<ElementRecipeState, ElementsRecipeActions> = (
    state: ElementRecipeState = defaultState,
    action: ElementsRecipeActions
) => {
    switch (action.type) {
        case "UPDATE_SERVE_STRATEGY":
            return {elementRecipe: action.elementRecipe};
        default:
            return state;
    }
};

export const mixerReducer: Reducer<ElementRecipeState, ElementsRecipeActions> = (
    state: ElementRecipeState = defaultState,
    action: ElementsRecipeActions
) => {
    switch (action.type) {
        case "UPDATE_MIXERS":
            return {elementRecipe: action.elementRecipe};
        default:
            return state;
    }
};

export const occasionReducer: Reducer<ElementRecipeState, ElementsRecipeActions> = (
    state: ElementRecipeState = defaultState,
    action: ElementsRecipeActions
) => {
    switch (action.type) {
        case "UPDATE_OCCASIONS":
            return {elementRecipe: action.elementRecipe};
        default:
            return state;
    }
};

export const glasswareReducer: Reducer<ElementRecipeState, ElementsRecipeActions> = (
    state: ElementRecipeState = defaultState,
    action: ElementsRecipeActions
) => {
    switch (action.type) {
        case "UPDATE_GLASSWARE":
            return {elementRecipe: action.elementRecipe};
        default:
            return state;
    }
};

export const complexityReducer: Reducer<ElementRecipeState, ElementsRecipeActions> = (
    state: ElementRecipeState = defaultState,
    action: ElementsRecipeActions
) => {
    switch (action.type) {
        case "UPDATE_COMPLEXITY":
            return {elementRecipe: action.elementRecipe};
        default:
            return state;
    }
};

export const drinkTypesReducer: Reducer<ElementRecipeState, ElementsRecipeActions> = (
    state: ElementRecipeState = defaultState,
    action: ElementsRecipeActions
) => {
    switch (action.type) {
        case "UPDATE_DRINK_TYPES":
            return {elementRecipe: action.elementRecipe};
        default:
            return state;
    }
};


export type ElementsRecipeActions = 
    | {
        type: "UPDATE_BRANDS";
        elementRecipe: ElementRecipe[];
    }
    | {
        type: "UPDATE_VARIANTS";
        elementRecipe: ElementRecipe[];
    }
    | {
        type: "UPDATE_PRYMARY_VARIANTS";
        elementRecipe: ElementRecipe[];
    }
    | {
        type: "UPDATE_SECONDARY_VARIANTS";
        elementRecipe: ElementRecipe[];
    }
    | {
        type: "CLEAR_VARIANTS";
    }
    | {
        type: "UPDATE_SERVE_STRATEGY";
        elementRecipe: ElementRecipe[];
    }
    | {
        type: "UPDATE_MIXERS";
        elementRecipe: ElementRecipe[];
    }
    | {
        type: "UPDATE_OCCASIONS";
        elementRecipe: ElementRecipe[];
    }
    | {
        type: "UPDATE_GLASSWARE";
        elementRecipe: ElementRecipe[];
    }
    | {
        type: "UPDATE_DRINK_TYPES";
        elementRecipe: ElementRecipe[];
    }
    | {
        type: "UPDATE_COMPLEXITY";
        elementRecipe: ElementRecipe[];
    };
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {getImportRecipeResult} from "../../../../services/actions/ImportRecipeResult";
import {DataImportRecipeResult, FilterOptions, ResultRowsImported} from "../../../../model/ImportResults";
import {isRight} from "fp-ts/These";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {VariantsAreNotInBrandRows} from "../recipeErrors/variantsAreNotInBrandsRows";
import {RecipesWithExcludedVariantsAndBrandsRows} from "../recipeErrors/RecipesWithExcludedVariantsAndBrandsRows";
import {RecipeDataRows} from "../recipeErrors/RecipeDataRows";
import {DuplicatedRows} from "../recipeErrors/DuplicatedRows";
import {DuplicatedNameRows} from "../recipeErrors/DuplicatedNameRows";
import {Button, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps, TablePagination} from "@material-ui/core";
import TablePaginationActions from "../../basicComponents/TablePaginationActions";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Avatar from "@material-ui/core/Avatar";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ErrorIcon from '@material-ui/icons/Error';
import SearchIcon from "@material-ui/icons/Search";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import styled from "styled-components";
import {ImportRecipeResultModal} from "./ImportRecipeResultModal";
import {FilterImportOptions} from "./FilterImportOptions";
import EditIcon from "@material-ui/icons/Edit";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";


export const ImportRecipeResultsTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [results, setResults] = useState<DataImportRecipeResult[]>([]);
    const [resultImport, setResultImport] = useState<ResultRowsImported>({} as ResultRowsImported);
    const [openDetail, setOpenDetail] = useState(false);
    const [ showFilterOptions, setShowFiltersOptions] = useState(false);
    const [filterOption, setFilterOption] = useState('all');
    const page = useRef(1);
    const limit = useRef(25);
    const count = useRef(1);

    const loadImportRecipeResults = async () => {
        const resp = await dispatch(getImportRecipeResult(filterOption, page.current, limit.current));
        if (isRight(resp)) {
            setResults(resp.right.data);
            page.current = resp.right.page;
            limit.current = resp.right.limit;
            count.current = resp.right.total;
        }

    }

    const changePage = (event: unknown, newPage: number) => {
        page.current = newPage +1;
        loadImportRecipeResults();
    }

    const changeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        limit.current = parseInt(event.target.value);
        loadImportRecipeResults();
    }

    const getIcon = (result: DataImportRecipeResult) => {
        const error = result.result?.rows.filter( row => !row.row_status_ok);
        if (!error || error.length > 0) {
            return <ErrorIcon />
        } else {
            return <CheckCircleOutlineOutlinedIcon />
        }
    }

    const viewDetail = (result?: ResultRowsImported) => {
        if (!result) {
            return;
        }
        setResultImport(result);
        setOpenDetail(true);
    }


    useEffect(() => {
        page.current = 1;
        loadImportRecipeResults();
    }, [filterOption]);

    useEffect(() => {
        loadImportRecipeResults();
    }, []);

    return (
        <div>

            <FilterImportOptions
                setOption={setFilterOption}
            />

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell>Author</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            results.map((row, index) => {
                                return (
                                    <TableRow
                                        key={row._id}
                                    >
                                        <TableCell align="left">
                                            {
                                                row.running
                                                    ? <AutorenewIcon style={{marginRight: '10px'}}/>
                                                    : getIcon(row)
                                            }
                                        </TableCell>
                                        <TableCell align="left">
                                            <span>{row.createdAt ? row.createdAt.slice(0, 10) : '-' }</span>
                                        </TableCell>
                                        <TableCell align="left">
                                            <span>{row.createdByUser ? row.createdByUser : '-' }</span>
                                        </TableCell>
                                        <TableCell align="left">
                                            {
                                                !row.running
                                                ? (
                                                    <InfoCell onClick={() => viewDetail(row.result)}>
                                                        <SearchIcon style={{marginRight: '10px'}}/>
                                                        <span>Details</span>
                                                    </InfoCell>
                                                  )
                                                : null
                                            }

                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={ count.current }
                rowsPerPage={ limit.current }
                labelRowsPerPage={"Items per page:"}
                page={ page.current - 1}
                onChangePage={ changePage }
                onChangeRowsPerPage={ changeRowsPerPage }
                ActionsComponent={ TablePaginationActions }
            />

            <ImportRecipeResultModal
                open={openDetail}
                handleClose={() => setOpenDetail(false)}
                resultImport={resultImport}
            />
        </div>
    )
}

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#f2f2f2',
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);



const InfoCell = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;


import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FileButton} from "../UploadFile/FileButton";
import {_postFullMedia} from "../../../../services/actions/FullMediaService";
import {isRight} from "fp-ts/These";
import {ProgressFromOriginalFile} from "../UploadFile/ProgressFromOriginalFile";
import {ProgressFile} from "../UploadFile/ProgressFile";
import {FullFile} from "../../../../model/FullMediaComponents";
import {
    addThumbnailDataAction,
    removePreviewDataAction,
    removeThumbnailDataAction
} from "../../../../store/actions/FullMediaDataActions";
import {
    addErrorUploadingFullMediaAction,
    addThumbnailAction,
    addThumbnailFromImageAction
} from "../../../../store/actions/FullMediaUploadingActions";
import {FullFileLink} from "../UploadFile/FullFileLink";
import {PreviewFileButton} from "../UploadPreviewFile/PreviewFileButton";
import {ProgressThumbnailFromImage} from "./ProgressThumbnailFromImage";
import {AutogeneratePreviewButton} from "../UploadPreviewFile/AutogeneratePreviewButton";
import styled from "styled-components";
import {ProgressAutogenerateThumbnail} from "./ProgressAutogenerateThumbnail";
import {ProgressAutogenerateThumbnailFromImage} from "./ProgressAutogenerateThumbnailFromImage";
import {AutogenerateThumbnailButton} from "./AutogenerateThumbnailButton";

export const UploadThumbnailFile = () => {

    const dispatch = useDispatch();
    const { addThumbnail, addThumbnailFromImage, canAddThumbnailFromImage, canAddThumbnail, clear }  = useSelector(state => state.transient.fullMediaUploadingReducer);
    const { thumbnail } = useSelector(state => state.transient.fullMediaDataReducer);
    const [ file, setFile ] = useState<File>();
    const [ thumbnailId, setThumbnailId ] = useState('');
    const [ autogenerate, setAutogenerate ] = useState({
        autogenerate: false,
        fromImage: false,
        startTime: '00:00:00.000'
    });

    const uploadFile = async () => {

        const data = new FormData();
        data.append("file", file!);

        const resp = await dispatch(_postFullMedia(data));

        if (!isRight(resp)) {
            handleError();
            return;
        }

        setThumbnailId(resp.right.id);

    }

    const handleError = () => {
        removeThumbnail();
        dispatch(addErrorUploadingFullMediaAction('Error uploading file'));
    }

    const handleAddFullFile = (fullFile: FullFile) => {
        dispatch(addThumbnailDataAction(fullFile));
    }

    const renderProgress = () => {
        debugger;
        if (addThumbnailFromImage) {
            return <ProgressThumbnailFromImage handleError={handleError} />;
        } else if (addThumbnail) {
            return <ProgressFromOriginalFile typeFile={'thumbnail'} />;
        } else if (autogenerate.autogenerate && autogenerate.fromImage) {
            return <ProgressAutogenerateThumbnailFromImage handleError={handleError}/>
        } else if (autogenerate.autogenerate) {
            return <ProgressAutogenerateThumbnail startTime={autogenerate.startTime} handleError={handleError} />
        } else if (file) {
            return <ProgressFile id={thumbnailId} handleAddFullFile={handleAddFullFile} handleError={handleError} />
        }
    }

    const renderAutogenerateButton = () => {
        if (canAddThumbnailFromImage || canAddThumbnail) {
            return (
                <AutoGenerateContainer>
                    <AutogenerateThumbnailButton setAutogenerate={setAutogenerate} />
                </AutoGenerateContainer>
            )
        }
        return null;
    }

    const isUploadingThumbnail = (): boolean => {
        return !!file || addThumbnail || addThumbnailFromImage || autogenerate.autogenerate;
    }

    const removeThumbnail = () => {
        setFile(undefined);
        dispatch(removeThumbnailDataAction());
        dispatch(addThumbnailAction(false));
        dispatch(addThumbnailFromImageAction(false));
        setAutogenerate({
            autogenerate: false,
            fromImage: false,
            startTime: '00:00:00.000'
        });
    }

    useEffect(() => {
        if (!!file) {
            uploadFile();
        }
    }, [file])

    useEffect(() => {
        if (clear) {
            setFile(undefined);
        }
    }, [clear]);


    return (
        <>
            {
                thumbnail
                    ? <FullFileLink fullFile={thumbnail} removeFullFile={removeThumbnail} />
                    : (
                        <>
                            {
                                isUploadingThumbnail()
                                    ? renderProgress()
                                    : (
                                        <ButtonContainer>
                                            <FileButton setFile={setFile} label='UPLOAD THUMBNAIL' />
                                            { renderAutogenerateButton() }
                                        </ButtonContainer>
                                    )
                            }
                        </>
                    )
            }
        </>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const AutoGenerateContainer = styled.div`
    margin-left: 10px;
`;

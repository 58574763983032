import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import RadioGroup from '@material-ui/core/RadioGroup'
import Autocomplete from '@material-ui/lab/Autocomplete';


export const RenderAutocompleteField = ({
  label,
  input,
  meta: { touched, invalid, error },
  options,
  customField,
  defaultValue,
  optionalHandler,
  handlerStatus,
  extraValidation,
  ...custom
}: any) => {
  const [valueAutocomplete, setValueAutocomplete] = useState<any>(null);

  useEffect(() => {
    if (input.value) {
      setValueAutocomplete(options.find((option: any) => option._id === input.value));
    } else if (input.value === '') {
      setValueAutocomplete(null);
    }
  }, [input.value])

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option: any) => {
        return option[customField] ? option[customField] : "";
      }}
      fullWidth
      value={valueAutocomplete}
      onChange={(_, value) => {
        setValueAutocomplete(value);
        input.onChange(value ? value._id : null);
        if (value && optionalHandler) {
          optionalHandler(value._id, !!handlerStatus);
        }
      }}
      defaultValue={defaultValue}
      renderInput={(params) => (
          <TextField
            {...params}
            {...custom}
            label={label}
            error={(touched && invalid) || (extraValidation && extraValidation())}
            variant="outlined"
          />
      )}
    />

  )
}

export const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error, asyncValidating },
  ...custom
}: any) => (
  <TextField
    variant="outlined"
    label={label}
    placeholder={label}
    error={touched && invalid}
    /* helperText={touched && error} */
    {...input}
    {...custom}
  />
)

export const renderCheckbox = ({ input, label }: any) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
  </div>
)

export const renderRadioButton = ({ input, children, ...rest }: any) => (
  <FormControl>
    <RadioGroup {...input} {...rest}>
      {children}
    </RadioGroup>
  </FormControl>
)


export const renderFromHelper = ({ touched, error }: any) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

export const renderSelectField = ({
    label,
    input,
    meta: { touched, invalid, error },
    children,
    ...custom
  }: any) =>  (
      <FormControl {...custom} fullWidth error={touched && error} variant="outlined">
        <InputLabel>{label}</InputLabel>
        <Select
          native
          label={label}
          {...input}
          {...custom}
          fullWidth
          children={children}
        >
        </Select>
      </FormControl>
  )

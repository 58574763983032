import React from "react";
import {Duplicated, RecipesWithExcludedVariantsAndBrand, WarningMessage} from "../../../../model/RecipeErrors";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import styled from "styled-components";
import {RecipeEnvironment} from "./RecipeEnvironment";
import SearchIcon from "@material-ui/icons/Search";
import {FixButton} from "./FixButton";
import {useDispatch} from "react-redux";
import {fixRemoveDuplicatedTags} from "../../../../services/actions/RecipeIssuesService";
import {isRight} from "fp-ts/These";

interface Props {
    recipes: Duplicated[];
    type: string;
    error: string;
    showDialog: (messages: string[]) => void;
    setIsLoading: (value: boolean) => void;
    getRecipeErrors: () => void;
    setSelectedRecipe: (_id: string) => void;
}

export const DuplicatedRows = ({ recipes, type, error, showDialog, setIsLoading, getRecipeErrors, setSelectedRecipe }: Props) => {

    const dispatch = useDispatch();

    const fixIssue = async (recipe: Duplicated) => {
        setIsLoading(true);
        const resp = await dispatch(fixRemoveDuplicatedTags(recipe._id, recipe.tagType, recipe.tag));
        if (isRight(resp)) {
            getRecipeErrors();
        } else {
            setIsLoading(false);
        }
    }


    const render = (item: Duplicated) => {
        return (
            <TableRow key={item._id}>
                <TableCell component="th" scope="row">
                    {type}
                </TableCell>
                <TableCell
                    component="th"
                    scope="row"
                    style={{ cursor: 'pointer'}}
                    onClick={e => setSelectedRecipe(item._id)}
                >
                    <RecipeName>{item.name}</RecipeName>
                </TableCell>
                <TableCell component="th" scope="row">
                    <RecipeEnvironment environment={item.status} />
                </TableCell>
                <TableCell component="th" scope="row">
                    <InfoCell onClick={() => showDialog([item.messages])}>
                        <SearchIcon style={{marginRight: '10px'}}/>
                        <span>{error}</span>
                    </InfoCell>
                </TableCell>
                <TableCell component="th" scope="row">
                    <FixButton onClick={() => fixIssue(item)} />
                </TableCell>
            </TableRow>
        )
    }

    return (
        <>
            {
                recipes && recipes.length > 0 &&
                recipes.map( render )
            }
        </>
    )
}

const InfoCell = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RecipeName = styled.span`
  color: blue;
  text-decoration: underline;
`;

import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { updateCopyRecipe } from "../../store/actions/CopyRecipeAction";
import {  RecipeResponse, RecipeResponseCodec } from "../types/CategoriesResponseCodec";

export const getRecipeCopy = (_id: string): ServiceCallAction<RecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/${_id}`,
	serviceKey: "getRecipes",
	responseDecoder: RecipeResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateCopyRecipe
});
import React from "react";
import styled from "styled-components";

interface Props {
    environment: number;
}

export const RecipeEnvironment = ({ environment }: Props) => {

    if (environment === 1) {
        return (
            <LiveButton>
                <WhiteText>Live</WhiteText>
            </LiveButton>
        )
    }

    if (environment === 3) {
        return (
            <DraftButton>
                <BlackText>Draft</BlackText>
            </DraftButton>
        )
    }


    return (
        <ArchivedButton>
            <WhiteText>Archived</WhiteText>
        </ArchivedButton>
    )

}


const DraftButton = styled.div`
  background-color: #fbe616;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
`;

const ArchivedButton = styled.div`
  background-color: #797979;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
`;

const LiveButton = styled.div`
  background-color: #137d01;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
`;

const WhiteText = styled.span`
  color: white;
  font-weight: bold;
  text-transform: capitalize;
`;

const BlackText = styled.span`
  color: black;
  font-weight: bold;
  text-transform: capitalize;
`;


// const ArchivedButtonStyle = withStyles((theme: Theme) => ({
//     root: {
//         backgroundColor: '#797979',
//         '&:hover': {
//             backgroundColor: '#676666',
//         },
//     },
// }))(Button);
//
// const LiveButtonStyle = withStyles((theme: Theme) => ({
//     root: {
//         backgroundColor: '#137d01',
//         '&:hover': {
//             backgroundColor: '#106901',
//         },
//     },
// }))(Button);
//
// const DraftButtonStyle = withStyles((theme: Theme) => ({
//     root: {
//         backgroundColor: '#fbe616',
//         '&:hover': {
//             backgroundColor: '#e6d314',
//         },
//     },
// }))(Button);

import { ImportRecipesActions } from "../reducers/ImportRecipesReducer";
import { ImportRecipe } from "../../model/ImportRecipe";

export const ImportRecipesAction = (importRecipe: ImportRecipe): ImportRecipesActions => ({
	type: "IMPORT_RECIPES",
	importRecipe
});

export const UpdateImportRecipesAction = (importRecipe: ImportRecipe): ImportRecipesActions => ({
	type: "UPDATE_IMPORT_RECIPES",
	importRecipe
});

export const clearImportRecipesAction = (): ImportRecipesActions => ({
	type: "CLEAR_IMPORT_RECIPES"
});

export const ErrorImportRecipesAction = (): ImportRecipesActions => ({
	type: "ERROR_IMPORT_RECIPES"
});
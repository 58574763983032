import {useEffect, useRef, useState} from "react";
import {ContentModel} from "../../model/ContentModel";
import {useDispatch, useSelector} from "react-redux";
import {_getContentModel} from "../../services/actions/ContentModelService";
import {CONTENT_MODEL, MIME_MP4, MIME_PNG, SPACE} from "../../services/types/util/Constants";
import {isRight} from "fp-ts/These";
import {FullFile} from "../../model/FullMediaComponents";
import {
    _createContent, _publishContentInLive,
    _replaceContent,
    createContent, getContent,
    publishContentInLive,
    replaceContent
} from "../../services/actions/ContentService";
import {FullMedia} from "../../model/FullMedia";
import {updateFullMedia} from "../../store/actions/FullMediaAction";
import {
    addFullFileDataAction,
    addPreviewDataAction,
    addThumbnailDataAction
} from "../../store/actions/FullMediaDataActions";
import {canAddPreviewAndThumbnailAction} from "../../store/actions/FullMediaUploadingActions";

export const useFullMedia = () => {

    const dispatch = useDispatch();
    const [ contentModel, setContentModel ] = useState<ContentModel>();
    const { fullFile, thumbnail, preview } = useSelector(state => state.transient.fullMediaDataReducer);
    const { contentId } = useSelector(state => state.transient.fullMediaUploadingReducer);
    const [ name, setName ] = useState('');
    const [ publishErrors, setPublishErrors ] = useState<string[]>([]);
    const [ saving, setSaving ] = useState(false);
    const [ content, setContent]  = useState<FullMedia>({} as FullMedia);
    const [ showError, setShowError] = useState(false);
    const existingContent = useRef(false);
    const newContentId = useRef('');
    const [ change, setChange ] = useState(false);
    const firstLoad = useRef(true);

    const loadPublishErrors = () => {
        let description: string[] = ["To publish the full media, you need to add:"];
        if (!name) {
            description.push("- Name Full Media");
        }
        if (!fullFile?.id) {
            description.push( "- Full Media File");
        }
        setPublishErrors(description);
    }

    const loadFullFileStructure = (data: any): FullFile => {
        return {
            id: data.value,
            url: data.image && data.image.url ? data.image.url : '',
            name: data.image && data.image.name ? data.image.name : '',
            mimeType: data.image && data.image.mimeType ? data.image.mimeType : ''
        };
    }

    const addAutogenerateOptions = (data: any) => {
        debugger;
        if (!data.image || (data.image && !data.image.mimeType)) {
            return;
        }

        const isMP4 = data.image.mimeType === MIME_MP4;
        const isPNG = data.image.mimeType === MIME_PNG;

        const canAddThumbnail =  isPNG || isMP4;
        const canAddThumbnailFromImage = isPNG;
        const canAddPreview = isMP4;

        dispatch(canAddPreviewAndThumbnailAction(canAddThumbnail, canAddThumbnailFromImage, canAddPreview));
    }

    const loadContent = async (id: string) => {
        const resp = await dispatch(getContent(id, SPACE));
        if (!isRight(resp)) {
            return;
        }
        setContent(resp.right.content);
        setName(resp.right.content.name);
        resp.right.content.elements.forEach(el => {
            if (!el.data.value) {
                return;
            }
            switch (el.name) {
                case "Full Media":
                    addAutogenerateOptions(el.data);
                    dispatch(addFullFileDataAction( loadFullFileStructure(el.data)));
                    break;
                case "Preview":
                    dispatch(addPreviewDataAction( loadFullFileStructure(el.data)));
                    break;
                case "Thumbnail":
                    dispatch(addThumbnailDataAction( loadFullFileStructure(el.data)));
                    break
            }
        });
    }

    const loadContentModel = async () => {

        const resp = await dispatch(_getContentModel(CONTENT_MODEL, SPACE));
        if (!isRight(resp)) {
            return;
        }
        setContentModel(resp.right);

    }

    const generateElement = (field: any, result?: FullFile | null) => {
        return {
            name: field.name,
            datatype: field.datatype,
            data: {
                value: result && result.id ? result.id : '',
                image: {
                    url: result && result.url ? result.url : '',
                    name: result && result.name ? result.name : '',
                    mimeType: result && result.mimeType ? result.mimeType : ''
                }
            },
            config: field.config
        };
    }

    const generateStatusField = (field: any) => {
        return {
            name: field.name,
            datatype: field.datatype,
            data: {
                value: existingContent.current ? 'Updated' : 'Draft',
            },
            config: field.config
        };
    }

    const getFullMedia = () => {
        if (contentModel) {
            let elements: any[] = [];
            contentModel.fields.forEach(field => {
                switch (field.name) {
                    case 'Full Media':
                        elements.push(generateElement(field, fullFile));
                        break;
                    case 'Thumbnail':
                        elements.push(generateElement(field, thumbnail));
                        break;
                    case 'Preview':
                        elements.push(generateElement(field, preview));
                        break;
                    case 'Status':
                        elements.push(generateStatusField(field));
                        break;
                    default:
                        break
                }
            });

            return {
                name: name,
                slug: name.split(' ').join('-'),
                contentModel_id: contentModel?._id,
                status: "Draft",
                elements: elements
            }

        }

    }

    const loadError = () => {
        setShowError(true);
        loadPublishErrors();
    }

    const closeError = () => {
        setShowError(false);
        setPublishErrors([]);
    }

    const isValid = (): boolean => {
        const nameValid = !!name;
        const fullFileValid = !!fullFile?.id;

        return nameValid && fullFileValid;
    }

    const createFullMedia = async () => {

        setSaving(true);
        const resp = await dispatch(_createContent(getFullMedia()));
        setSaving(false);

        if (!isRight(resp)) {
            return;
        }

        setContent(resp.right.content);
        newContentId.current = resp.right.content._id;

    }

    const replaceFullMedia = async () => {

        setSaving(true);
        const resp = await dispatch(_replaceContent(content._id, getFullMedia()));
        setSaving(false);

        if (!isRight(resp)) {
            return;
        }

        setContent(resp.right.content);
        newContentId.current = resp.right.content._id;

    }

    const saveInLiveFullMedia = async () => {

        setSaving(true);
        await dispatch(_publishContentInLive(newContentId.current));
        setSaving(false);

    }

    const publishLive = async (): Promise<boolean> => {
        if (!isValid()) {
            loadError();
            return false;
        }

        if (content._id) {

            await replaceFullMedia();
            await saveInLiveFullMedia();

        } else {
            await createFullMedia();
            await saveInLiveFullMedia();
        }

        return true;
    }

    const publish = async (): Promise<boolean> => {
        if (!isValid()) {
            loadError();
            return false;
        }

        if (content._id) {

            await replaceFullMedia();

        } else {

            await createFullMedia();

        }

        return true;
    }

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
        } else {
            setChange(true);
        }
    }, [fullFile, thumbnail, preview, name])

    useEffect(() => {
        loadContentModel();
    }, []);

    useEffect(() => {
        if (contentId) {
            loadContent(contentId);
        }
    }, [])

    return {
        closeError,
        showError,
        saving,
        contentModel,
        content,
        name,
        setName,
        publishErrors,
        publish,
        publishLive,
        change
    }

}

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../services/actions/AuthService';
import styled from "styled-components";
import MenuMaterial, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';
import { withStyles } from '@material-ui/styles';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export const Menu = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.persistent.auth);

    const onClick = () => {
        dispatch(logOut());
    }
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const renderLogout = () => {
      return (
        <div>
              <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <PersonIcon />
                <UserName>
                  {user}
                </UserName>
              </Button>
              <StyledMenu
                id="menu-appbar"
                anchorEl={anchorEl} 
                keepMounted
                open={open}
                onClose={handleClose}
                style={{zIndex: 10001}}
              >
                <MenuItem onClick={onClick}>
                  <ExitToAppIcon />
                  <UserName>
                    Log out
                  </UserName>
                </MenuItem>
              </StyledMenu>
            </div>
        
      );
    }

    return (
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
                NAM TSP Recipes
            </Typography>
            {renderLogout()}
          </Toolbar>
        </AppBar>
      </div>
    );
};

const UserName = styled.span`
    padding-left: 10px;
`
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <MenuMaterial
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {PreviewOptionsModal} from "./PreviewOptionsModal";

interface Props {
    setAutogenerate: (value: { autogenerate: boolean, startTime: string, duration: number }) => void
}

export const AutogeneratePreviewButton = ({ setAutogenerate } : Props) => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ startTime, setStartTime ] = useState('00:00:00.000');
    const [ duration, setDuration ] = useState(1);

    const autogenerateFormVideo = (generate: boolean) => {
        setOpenModal(false);
        if (!generate) {
            return;
        }
        setAutogenerate({autogenerate: true, startTime: startTime, duration: duration});
    }

    return (
        <>
            <Button
                size="large"
                variant="outlined"
                color="primary"
                component="span"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => setOpenModal(true)}
            >
                UPLOAD PREVIEW FROM FULL MEDIA
            </Button>

            <PreviewOptionsModal
                open={openModal}
                handleClose={autogenerateFormVideo}
                startTime={startTime}
                setStartTime={setStartTime}
                duration={duration}
                setDuration={setDuration}
            />
        </>
    )
}

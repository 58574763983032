import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {_loadThumbnailFullMedia} from "../../../../services/actions/FullMediaService";
import {isRight} from "fp-ts/These";
import {addThumbnailDataAction} from "../../../../store/actions/FullMediaDataActions";
import {LinearProgress} from "@material-ui/core";
import styled from "styled-components";

interface Props {
    handleError: () => void
}

export const ProgressThumbnailFromImage = ({ handleError }: Props) => {

    const dispatch = useDispatch();
    const { fullFile }  = useSelector(state => state.transient.fullMediaDataReducer);

    const loadThumbnailFromImage = async (id: string) => {

        const resp = await dispatch(_loadThumbnailFullMedia(id));

        if (!isRight(resp)) {
            handleError();
            return;
        }

        dispatch(addThumbnailDataAction(resp.right));

    }

    useEffect(() => {
        if (fullFile) {
            loadThumbnailFromImage(fullFile.id);
        }
    }, [fullFile])


    return (
        <>
            <PercentText>Generating...</PercentText>
            <LinearProgress />
        </>
    )

}

const PercentText = styled.span`
    margin: 10px 0;
    font-weight: bold;
`;

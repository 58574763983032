import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import validate from '../Validate';
import { createMuiTheme, createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import { blue, green, yellow } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RecipeDetail } from '../../../../model/Recipe';
import RecipeDetailForm from '../../Recipe/RecipeDetailForm';
import BasicDialog from '../../basicComponents/BasicDialog';

interface CustomProps {
    previousPage: () => void;
    saveAsLive: () => void;
    submittingForm: boolean;
};

const RecipeCreateFifthStep: any = (props: any) => {
    const { handleSubmit, pristine, previousPage, saveAsLive, submittingForm } = props;
    const [formData, setFormData] = useState<any>({});
    const classes = useStyles();
    const formState = useSelector(state => state.form);
    const Images = useSelector(state => state.transient.fileImages);
    const { serveStrategies, mixers, glasswares, complexities, drinkTypes, occasions, brands, variants } = useSelector(state => state.transient);
    const [ recipeDetail, setRecipeDetail ] = useState<RecipeDetail | null>(null);
    const { newRecipe, error } = useSelector(state => state.transient.newRecipe);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const ingredients = formData.ingredients?.map((ing: {description: string, id: string}) =>{
            return {ingredient: ing.description};
        }); 
        const recipeData: RecipeDetail = {
            _id: newRecipe?._id,
            enviroment: newRecipe?.enviroment,
            name: formData.recipeName,
            uuid: newRecipe?.uuid,
            status: newRecipe?.status,
            drinkTypes: formData.drinkTypes || [],
            newDrinkTypes: formData.newdrinkTypes || [],
            occasions: formData.occassions || [],
            newOccasions: formData.newoccassions || [],
            mixers: formData.mixers || [],
            newMixers: formData.newmixers || [],
            glassware: formData.glassware || [],
            newGlassware: formData.newglassware || [],
            foodPairing: formData.foodPairings || [],
            complexity: formData.complexity,
            preparationSteps: formData.preparationSteps,
            menuCopy: formData.menuCopy,
            ingredients: ingredients || [],
            servingSize: formData.servingSize,
            serveStrategy: formData.servinStrategy,
            secondaryVariant: formData.secondaryVariant,
            secondaryBrand: formData.secondaryBrand,
            primaryVariant: formData.primaryVariant,
            brand: formData.brand,
            lifeStyle: {
                fileImage: Images.lifeStyle,
                error: Images.errorLifeStyle
            },
            drinkShot: {
                fileImage: Images.drinkShot,
                error: Images.errorDrinkshot
            }
        };
        setRecipeDetail(recipeData);
    }, [ Images, formData, newRecipe]);

    const validLive = (): boolean => {
        const lifeStyleValid = !!Images.lifeStyle?.janusFile && !Images.errorLifeStyle;
        const drinkShotValid = !!Images.drinkShot?.janusFile && !Images.errorDrinkshot;
        const lifeStyleOK = Images.lifeStyle && Images.lifeStyle.janusFile ? Images.lifeStyle.janusFile.broken !== true : true;
        const drinkShotOK = Images.drinkShot && Images.drinkShot.janusFile ? Images.drinkShot.janusFile.broken !== true : true;
        const servingSizeValid = !!formData.servingSize;
        const validImage = (lifeStyleValid && lifeStyleOK) || (drinkShotValid && drinkShotOK);

        return validImage && servingSizeValid;
    }

    const publish = () => {
        const valid = validLive();
        if (valid) {
            saveAsLive();
        } else {
            setOpenDialog(true);
        }
    }

    useEffect(()=> {
        const form = formState && formState.createRecipe && formState.createRecipe.values ? formState.createRecipe.values : {};
        setFormData(form);
    }, [formState]);


    const getDescription = () => {
        let description: string[] = ["To publish the recipe as alive, you need to add:"];
        const lifeStyleValid = !!Images.lifeStyle?.janusFile && !Images.errorLifeStyle;
        const drinkShotValid = !!Images.drinkShot?.janusFile && !Images.errorDrinkshot;
        const lifeStyleOK = Images.lifeStyle && Images.lifeStyle.janusFile ? Images.lifeStyle.janusFile.broken !== true : true;
        const drinkShotOK = Images.drinkShot && Images.drinkShot.janusFile ? Images.drinkShot.janusFile.broken !== true : true;
        const servingSizeValid = !!formData.servingSize;
        const validImage = (lifeStyleValid && lifeStyleOK) || (drinkShotValid && drinkShotOK);
        if (!validImage) {
            description.push("- Life style asset or Drink shot asset");
        }
        if (!servingSizeValid) {
            description.push( "- Serving Size");
        }
        return description;
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>

                {recipeDetail ? <RecipeDetailForm showHeader={false} recipeInformation={recipeDetail} canPublish={false}/> : null}

                <BasicDialog
                    open={openDialog}
                    handleClose={() => setOpenDialog(false)} 
                    okButton="OK" 
                    cancelButton={null} 
                    description={getDescription()}
                />

                <Box pt={3}>
                    <Divider />
                </Box>

                <Box display="flex" justifyContent="space-between" pt={5}>

                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowBackIosIcon />}
                        onClick={previousPage}
                    >
                        Previous
                    </Button>
                

                    <Box display="flex">

                        <ThemeProvider theme={outerTheme}>

                            <div className={classes.wrapper}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.buttonWhite}
                                    endIcon={<ArrowForwardIosIcon />}
                                    disabled={submittingForm}
                                >
                                    SAVE AS DRAFT
                                </Button>
                                {submittingForm && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                            <div className={classes.wrapper}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={publish}
                                    endIcon={<ArrowForwardIosIcon />}
                                    disabled={submittingForm}
                                >
                                    PUBLISH LIVE
                                </Button>
                                {submittingForm && validLive() && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>

                        </ThemeProvider>

                    </Box>

                </Box>

            </form>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: blue[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    buttonWhite: {
        marginRight: theme.spacing(1),
    }
  }),
);

const outerTheme = createMuiTheme({
    palette: {
        primary: {
            main: yellow['A700'],
        },
        secondary: {
            main: green['A700'],
        }
    },
});

export default reduxForm<{}, CustomProps>({
    form: 'createRecipe',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(RecipeCreateFifthStep);

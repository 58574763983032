import React from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from '../Validate';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { RecipeName, RecipeTitle } from '../RecipeTitles';
import { Divider } from '@material-ui/core';
import { renderTextField } from '../../basicComponents/LocalFields';
import {TextEditor} from '../../basicComponents/TextEditor';

const theme = createMuiTheme({
    palette: {
        primary: blue,
    },
});

const defaultProps = {
    borderLeft: "8px solid #bfbfbfcc",
};

interface CustomProps {
    previousPage: () => void;
};

const RecipeCreateThirdStep = (props: any) => {

    const { handleSubmit, previousPage } = props;

    return (
        <div>
             <form onSubmit={handleSubmit}>

                <RecipeName />

                <RecipeTitle title={"Preparation Steps"} />

                <Box mt={5}>
                    <Box mb={1} pt={2} pb={2} pl={3} {...defaultProps}>
                        <Field
                            name="preparationSteps"
                            component={renderTextField}
                            label="Preparation Steps"
                            fullWidth
                            multiline
                            rows={6}
                            type="text"
                            variant="outlined"
                        />
                    </Box>
                </Box>

                <RecipeTitle title={"Menu Copy"} />

                <Box mt={5}>
                    <Box mb={1} pt={2} pb={2} pl={3} {...defaultProps}>
                        <Field 
                            name="menuCopy"
                            component={TextEditor}
                        />
                    </Box>
                </Box>

                <Box pt={3}>
                    <Divider />
                </Box>

                <Box display="flex" justifyContent="space-between" pt={5}>
                    <ThemeProvider theme={theme}>

                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            startIcon={<ArrowBackIosIcon />}
                            onClick={previousPage}
                        >
                            Previous
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                        >
                            Next: Drink Types & Tags
                        </Button>

                    </ThemeProvider>
                </Box>

            </form>
        </div>
    );
};

export default reduxForm<{}, CustomProps>({
    form: 'createRecipe',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(RecipeCreateThirdStep);

import {ContentModel} from "../../model/ContentModel";
import {Reducer} from "redux";

export interface ContentModelState {
  contentModel?: ContentModel
}

const defaultState: ContentModelState = {
  contentModel: undefined
};

export const ContentModelReducer: Reducer<ContentModelState, ContentModelActions> = (
  state: ContentModelState = defaultState,
  action: ContentModelActions
) => {
  switch (action.type) {
    case "LOAD_CONTENT_MODEL":
      return {contentModel: action.contentModel};
    case "CLEAR_CONTENT_MODEL":
      return defaultState;
    default:
      return state;
  }
};

export type ContentModelActions =
  | {
  type: "LOAD_CONTENT_MODEL";
  contentModel: ContentModel;
} | {
  type: "CLEAR_CONTENT_MODEL"
};

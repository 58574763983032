import React, {useState} from 'react';
import { Editor } from "@tinymce/tinymce-react";


export const TextEditor = ({input}: any) => {
    const tinyKey = process.env.REACT_APP_TINY_KEY ? process.env.REACT_APP_TINY_KEY : '';
    const [state, setState] = useState();
    const handleChange = (content: any, editor: any) => {
        setState(content);
    }
    const handleSubmit = (event: any) => {
        event.preventDefault();
    }
	return (
            <div>
                <Editor
                    apiKey={tinyKey}
                    init={{
                        height: 250,
                        menubar: false
                    }}
                    value={input.value}
                    onEditorChange={e => input.onChange(e)}
                    
                />
                </div>
            
        );
}


import React, {FC, useEffect, useRef, useState} from 'react';
import {RecipeErrors, RecipeErrorsParams, VariantsAreNotInBrand, WarningMessage} from "../../../../model/RecipeErrors";
import { isRight } from "fp-ts/These";
import { getRecipeError } from "../../../../services/actions/RecipeIssuesService";
import { useDispatch } from "react-redux";
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {RecipesWithExcludedVariantsAndBrandsRows} from "./RecipesWithExcludedVariantsAndBrandsRows";
import {VariantsAreNotInBrandRows} from "./variantsAreNotInBrandsRows";
import {RecipeDataRows} from "./RecipeDataRows";
import {DuplicatedRows} from "./DuplicatedRows";
import {DuplicatedNameRows} from "./DuplicatedNameRows";
import TablePaginationActions from "../../basicComponents/TablePaginationActions";
import {Button, TablePagination} from "@material-ui/core";
import BasicDialog from "../../basicComponents/BasicDialog";
import {FilterErrorsOptions} from "./FilterErrorsOptions";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import styled from "styled-components";

const recipeErrorsDefault: RecipeErrorsParams = {
    findVariantsAreNotInBrands: true,
    findRecipesWithExcludedVariantsAndBrands: true,
    findRecipesWithoutThumbnail: true,
    findRecipesWithoutVariants: true,
    findRecipesWithoutBrands: true,
    findDuplicatedTags: true,
    findDuplicatedNames: true,
    environments: 'live|draft'
}

interface Props {
    setIsLoading: (value: boolean) => void;
    setSelectedRecipe: (_id: string) => void;
}

export const RecipeErroInformation = ({setIsLoading, setSelectedRecipe}: Props) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const [ recipeErrors, SetRecipeErrors ] = useState<RecipeErrors>({} as RecipeErrors);
    const [ recipeErrorsParams, setRecipeErrorsParams ] = useState<RecipeErrorsParams>(recipeErrorsDefault);
    const [ warningMessage, setWarningMessage ] = useState<WarningMessage>({showWaring: false, messages: [], callback: () => {}});
    const [ showWarningMessage, setShowWarningMessage ] = useState(false);
    const [ messages, setMessages ] = useState<string[]>([]);
    const [ openDialog, setOpenDialog ] = useState(false);
    const [ showFilterOptions, setShowFiltersOptions] = useState(false);
    const page = useRef(1);
    const limit = useRef(25);
    const count = useRef(1);

    const closeWarning = (value: boolean) => {
        setShowWarningMessage(false);
        if (value) {
            warningMessage.callback();
        }
        setWarningMessage({showWaring: false, messages: [], callback: () => {}})
    }

    const showDialog = (messages: string[]) => {
        setMessages(messages.map(msg => "- " + msg));
        setOpenDialog(true);
    }

    const getRecipeErrors = async () => {
        setIsLoading(true);
        const resp = await dispatch(getRecipeError(recipeErrorsParams, page.current, limit.current));
        if (isRight(resp)) {
            SetRecipeErrors(resp.right.data);
            page.current = resp.right.page;
            count.current = resp.right.totalcount;
        }
        setIsLoading(false);
    }

    const changePage = (event: unknown, newPage: number) => {
        page.current = newPage +1;
        getRecipeErrors();
    }

    const changeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        limit.current = parseInt(event.target.value);
        getRecipeErrors();
    }

    const selectRecipe = (_id: string) => {
        setSelectedRecipe(_id);
    }

    useEffect(() => {
        if (warningMessage.showWaring) {
            setShowWarningMessage(true);
        }
    }, [warningMessage]);

    useEffect(() => {
        page.current = 1;
        getRecipeErrors();
    }, [recipeErrorsParams]);

    useEffect(() => {
        getRecipeErrors();
    }, []);

    return (
        <div>
            <ButtonContainer>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowFiltersOptions(true)}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                >
                    Filter
                </Button>
            </ButtonContainer>


            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Recipe Name</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Error</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <VariantsAreNotInBrandRows
                            setWarningMessage={setWarningMessage}
                            getRecipeErrors={getRecipeErrors}
                            setIsLoading={setIsLoading}
                            showDialog={showDialog}
                            recipes={recipeErrors.variantsAreNotInBrands}
                            setSelectedRecipe={selectRecipe}
                        />
                        <RecipesWithExcludedVariantsAndBrandsRows
                            setWarningMessage={setWarningMessage}
                            getRecipeErrors={getRecipeErrors}
                            setIsLoading={setIsLoading}
                            showDialog={showDialog}
                            recipes={recipeErrors.recipesWithExcludedVariantsAndBrands}
                            setSelectedRecipe={selectRecipe}
                        />
                        <RecipeDataRows
                            setWarningMessage={setWarningMessage}
                            typeError={"brand"}
                            showDialog={showDialog}
                            recipes={recipeErrors.recipesWithoutBrands}
                            type='No Brands'
                            error='The recipe has no brands loaded'
                            setSelectedRecipe={selectRecipe}
                        />
                        <RecipeDataRows
                            setWarningMessage={setWarningMessage}
                            typeError={"variant"}
                            showDialog={showDialog}
                            recipes={recipeErrors.recipesWithoutVariants}
                            type='No Variants'
                            error='The recipe has no variants loaded'
                            setSelectedRecipe={selectRecipe}
                        />
                        <RecipeDataRows
                            setWarningMessage={setWarningMessage}
                            typeError={"thumbnail"}
                            showDialog={showDialog}
                            recipes={recipeErrors.recipesWithoutThumbnail}
                            type='No Thumbnail'
                            error='The recipe has images loaded but no thumbnail'
                            setSelectedRecipe={selectRecipe}
                        />
                        <DuplicatedRows
                            getRecipeErrors={getRecipeErrors}
                            setIsLoading={setIsLoading}
                            showDialog={showDialog}
                            recipes={recipeErrors.duplicatedDrinkTypes}
                            type='Duplicated Drink Type'
                            error='The recipe has the tag Drink Type duplicated'
                            setSelectedRecipe={selectRecipe}
                        />
                        <DuplicatedRows
                            getRecipeErrors={getRecipeErrors}
                            setIsLoading={setIsLoading}
                            showDialog={showDialog}
                            recipes={recipeErrors.duplicatedOccasions}
                            type='Duplicated Occasions'
                            error='The recipe has the tag Occasions duplicated'
                            setSelectedRecipe={selectRecipe}
                        />
                        <DuplicatedRows
                            getRecipeErrors={getRecipeErrors}
                            setIsLoading={setIsLoading}
                            showDialog={showDialog}
                            recipes={recipeErrors.duplicatedMixers}
                            type='Duplicated Mixers'
                            error='The recipe has the tag Mixer duplicated'
                            setSelectedRecipe={selectRecipe}
                        />
                        <DuplicatedRows
                            getRecipeErrors={getRecipeErrors}
                            setIsLoading={setIsLoading}
                            showDialog={showDialog}
                            recipes={recipeErrors.duplicatedGlassware}
                            type='Duplicated Glassware'
                            error='The recipe has the tag Glassware duplicated'
                            setSelectedRecipe={selectRecipe}
                        />
                        <DuplicatedNameRows
                            setWarningMessage={setWarningMessage}
                            showDialog={showDialog}
                            recipes={recipeErrors.duplicatedNames}
                        />
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={ count.current }
                rowsPerPage={ limit.current }
                labelRowsPerPage={"Items per page:"}
                page={ page.current - 1}
                onChangePage={ changePage }
                onChangeRowsPerPage={ changeRowsPerPage }
                ActionsComponent={ TablePaginationActions }
            />

            <FilterErrorsOptions
                open={showFilterOptions}
                handleClose={() => setShowFiltersOptions(false)}
                setOptions={setRecipeErrorsParams}
                options={recipeErrorsParams}
            />

            <BasicDialog
                title='Issue Description'
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                okButton="OK"
                cancelButton={null}
                description={messages}
            />

            <BasicDialog
                title='Warning'
                open={showWarningMessage}
                handleClose={closeWarning}
                okButton={warningMessage.ok || "OK"}
                cancelButton="CANCEL"
                description={warningMessage.messages}
            />

        </div>
    )

}

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#f2f2f2',
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 20px;
`;

import React from 'react';
import { Switch, Route, useLocation,  useHistory } from "react-router-dom";
import { parseSearch } from '../services/types/util/parseSearch';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Auth from './components/Auth/Auth';
import RecipeCreate from './components/RecipeCreate/RecipeCreate';
import { Menu } from './Menu';
import { LoadingPage } from './LoadingPage';
import styled from "styled-components";
import { useSelector } from 'react-redux';
import SideBar from './SideBar';
import RecipeList from './components/RecipeList/RecipeList';
import Dashboard from './components/Dashboard/Dashboard';
import MediaLibrary from './components/MediaLibrary/MediaLibrary';
import FullMediaList from "./components/FullMediaList/FullMediaList";
import {_MediaCreate} from "./components/MediaCreate/_MediaCreate";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

const AuthenticatedRoutes = (props: { location: Switch["props"]["location"] }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <MenuContainer>
                <Menu />
            </MenuContainer>
            <SideBarContainer>
                <SideBar location={props.location}/>
            </SideBarContainer>


            <DataContainer className={classes.content}>
                <Switch location={props.location}>
                    <Route path='/recipe/new' component={RecipeCreate} />
                    <Route path='/recipe/list' component={RecipeList} />
                    <Route path='/dashboard' component={Dashboard} />
                    <Route path='/media-library' component={MediaLibrary} />
                    <Route path='/media/new' component={_MediaCreate} />
                    <Route path='/media/list' component={FullMediaList} />
                    <Route component={LoadingPage} />
                </Switch>
            </DataContainer>
        </div>
    );
};

const LoginRoutes = (props: { location: Switch["props"]["location"] }) => (
    <>
        <Switch location={props.location}>
            <Route path='/login' component={Auth} />
            <Route component={LoadingPage} />
        </Switch>
    </>
);

export const Routes = () => {
    const location = useLocation();
    const history = useHistory();
    const query = parseSearch(location.search);
    const { token, uid } = useSelector(state => state.persistent.auth);

    const isAuthenticated = () => {
        return token && uid;
    }

    return (
        <>
            {token && uid ?
                (
                    <AuthenticatedRoutes location={location}/>
                ) : (
                    <LoginRoutes location={location} />
                )
            }
        </>
    );

}

const MenuContainer = styled.div`
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 1250;
    width: 100%;
`;

const SideBarContainer = styled.div`
    z-index: 1202;
`;

const DataContainer = styled.div`
    margin: 0 auto;
    margin-top: 48px;
`;

import React, {useEffect} from "react";
import styled from "styled-components";
import { useUploadFullFile } from "../../../hooks/useUploadFullFile";
import {addPreviewDataAction, removeFullFileDataAction} from "../../../../store/actions/FullMediaDataActions";
import { useDispatch } from "react-redux";
import LinkIcon from "@material-ui/icons/Link";
import {IconButton, LinearProgress} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

interface Props {
    previewId: string;
    file: File;
}

export const ProgressPreviewFile = ({ previewId, file }: Props) => {

    const dispatch = useDispatch();
    const { uploadingInfo, fullFile, startUploadFile } = useUploadFullFile(file);


    useEffect(() => {
        if ( !!fullFile.id ) {
            dispatch(addPreviewDataAction(fullFile));
        }
    }, [fullFile]);

    useEffect(() => {
        if (previewId.length > 0) {
            startUploadFile(previewId);
        }
    }, [previewId])

    return (
        <div>
            <PercentText>{ uploadingInfo.percent } % { uploadingInfo.percent === 100 ? 'Finishing upload, almost there...' : ' done... ' + uploadingInfo.progress }</PercentText>
            <LinearProgress variant="determinate" value={ uploadingInfo.percent } />
        </div>
    )
}

const PercentText = styled.span`
    margin: 10px 0;
    font-weight: bold;
`;

import React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {RecipeErrorsParams} from "../../../../model/RecipeErrors";
import {useSelector} from "react-redux";

interface Props {
    open: boolean;
    handleClose: (generate: boolean) => void;
    startTime: string;
    setStartTime: (startTime: string) => void;
    duration: number,
    setDuration: (duration: number) => void
}

export const PreviewOptionsModal = ({ handleClose, open, startTime, setStartTime, duration, setDuration }: Props) => {

    const { videoMaxTime } = useSelector(state => state.transient.fullMediaUploadingReducer);

    const isValidMaxTime = () => {
        if (videoMaxTime === '00:00:00.000') {
            return true;
        }
        let [hours, minutes, secondsAndMili] = startTime.split(":");
        let [seconds, mili] = secondsAndMili.split('.');
        let [maxHours, maxMinutes, maxSecondsAndMili] = videoMaxTime.split(":");
        let [maxSeconds, maxMili] = maxSecondsAndMili.split('.');

        let invalidMaxTime = parseInt(hours) > parseInt(maxHours)
            || ( parseInt(hours) <= parseInt(maxHours) && parseInt(minutes) > parseInt(maxMinutes))
            || ( parseInt(hours) <= parseInt(maxHours) && parseInt(minutes) <= parseInt(maxMinutes) && parseInt(seconds) + 1 > parseInt(maxSeconds));

        return !invalidMaxTime;
    }

    const validStartTime = () => {
        let [hours, minutes, secondsAndMili] = startTime.split(":");
        let [seconds, mili] = secondsAndMili.split('.');

        return /([0-9][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9].[0-9][0-9][0-9]/g.test(startTime) && /^\d+$/.test(hours) && /^\d+$/.test(minutes) && /^\d+$/.test(seconds) && seconds.length === 2 && /^\d+$/.test(mili) && mili.length === 3 && isValidMaxTime();
    }

    const validDuration = () => {
        return duration > 0;
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartTime(event.target.value);
    };

    const handleChangeDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDuration(parseInt(event.target.value));
    };

    const getMaxTimeText = () => {
        if (videoMaxTime === '00:00:00.000') {
            return '';
        } else {
            return `, max time: ${videoMaxTime}`
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">Select Start Time Preview</DialogTitle>
                <DialogContent>

                    <FieldContainer>
                        <TextField
                            label="Start Time"
                            name="time"
                            placeholder="00:00:00"
                            error={!validStartTime()}
                            helperText={!validStartTime() ? "Format time: HH:MM:SS.sss" + getMaxTimeText() : ""}
                            value={startTime}
                            onChange={handleChange}
                            fullWidth
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <TextField
                            label="Duration in Seconds"
                            name="duration"
                            type="number"
                            error={!validDuration()}
                            helperText={!validDuration() ? "Invalid duration" : ""}
                            value={duration}
                            onChange={handleChangeDuration}
                            fullWidth
                        />
                    </FieldContainer>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="primary">
                        cancel
                    </Button>
                    <Button
                        onClick={() => handleClose(true)}
                        color="primary"
                        disabled={!validStartTime() || !validDuration()}
                    >
                        generate preview
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


const FieldContainer = styled.div`
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
`;


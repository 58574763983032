import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import LinkIcon from "@material-ui/icons/Link";
import { IconButton, LinearProgress } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { addFullFileDataAction, removeFullFileDataAction } from "../../../../store/actions/FullMediaDataActions";
import { useUploadFullFile } from "../../../hooks/useUploadFullFile";

interface Props {
    file: File;
}

export const ProgressFullFile = ({ file }: Props) => {

    const dispatch = useDispatch();
    const { fullMediaIdUploading }  = useSelector(state => state.transient.fullMediaUploadingReducer);
    const { uploadingInfo, fullFile, startUploadFile } = useUploadFullFile(file);


    useEffect(() => {
        if ( !!fullFile.id ) {
            dispatch(addFullFileDataAction(fullFile));
        }
    }, [fullFile]);

    useEffect(() => {
        if (fullMediaIdUploading.length > 0) {
            startUploadFile(fullMediaIdUploading);
        }
    }, [fullMediaIdUploading])

    return (
        <div>
            <PercentText>{ uploadingInfo.percent } % { uploadingInfo.percent === 100 ? 'Finishing upload, almost there...' : ' done... ' + uploadingInfo.progress }</PercentText>
            <LinearProgress variant="determinate" value={ uploadingInfo.percent } />
        </div>
    )
}

const PercentText = styled.span`
    margin: 10px 0;
    font-weight: bold;
`;

import { ElementRecipe } from "../../model/ElementRecipe";
import { FilterTagsActions } from "../reducers/filterTagsReducer";

export const updateTags = (tag: ElementRecipe, key: string): FilterTagsActions => ({
	type: "UPDATE_FILTER_TAGS",
	key,
	tag
});

export const removeTag = (tag: ElementRecipe, key: string): FilterTagsActions => ({
	type: "REMOVE_FILTER_TAG",
	key,
	tag
});

export const removeSpecificTags = (key: string, ids: string[]): FilterTagsActions => ({
	type: "REMOVE_SPECIFIC_TAG",
	key,
	ids
});

export const updateFilterName = (filterName: string): FilterTagsActions => ({
	type: "UPDATE_FILTER_NAME",
	filterName
});

export const clearAllTags = (): FilterTagsActions => ({
	type: "CLEAR_ALL_TAGS"
});
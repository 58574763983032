import {UtilsActions} from "../reducers/UtilsReducer";
import {StatusUpdateResponse} from "../../services/types/StatusRecipeCodec";

export const openModalAction = (status: boolean): UtilsActions => ({
  type: "OPEN_MODAL",
  status
});

export const redirectAction = (waiting: boolean, canRedirect: boolean, check: boolean): UtilsActions => ({
  type: "REDIRECT",
  waiting,
  canRedirect,
  check
});

export const updateStatusUtilAction = (status: StatusUpdateResponse): UtilsActions => ({
  type: "UPDATE_STATUS_UTILS",
  rejectedContentsCount: status.rejectedContentsCount
});

export const failUpdateUtilStatus = (): UtilsActions => ({
  type: "FAIL_UPDATE_STATUS_UTILS"
});

export const clearStatusUtilError = (): UtilsActions => ({
  type: "CLEAR_STATUS_ERROR_UTILS"
});

import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import { _checkFullMedia } from "../../../../services/actions/FullMediaService";
import {isRight} from "fp-ts/These";
import {addThumbnailDataAction} from "../../../../store/actions/FullMediaDataActions";
import {FullFile, FullFileData} from "../../../../model/FullMediaComponents";
import {IconButton, LinearProgress} from "@material-ui/core";
import styled from "styled-components";
import LinkIcon from "@material-ui/icons/Link";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {addErrorUploadingFullMediaAction} from "../../../../store/actions/FullMediaUploadingActions";

interface Props {
    id: string;
    handleAddFullFile: (fullFile: FullFile) => void,
    handleError: () => void
}

const UPDATE_TIME = 2000;

export const ProgressFile = ({ id, handleAddFullFile, handleError }: Props) => {

    const dispatch = useDispatch();
    const [ checkProgress, setCheckProgress ] = useState(false);
    const [ uploadingInfo, setUploadingInfo ] = useState({progress: '', percent: 0});
    const [ fullFile, setFullFile ] = useState<FullFile>({} as FullFile);
    const timerId = useRef(0);

    const runCheckProgressTimeout = () => {
        setCheckProgress(false);
        timerId.current = setTimeout(() => {
            setCheckProgress(true);
        }, UPDATE_TIME);

    }

    const getProgressFile = async () => {

        const resp = await dispatch(_checkFullMedia(id));

        if (!isRight(resp)) {
            dispatch(addErrorUploadingFullMediaAction('Error uploading file'));
            return;
        }

        setUploadingInfo({progress: resp.right.progress, percent: resp.right.percent});

        if ( resp.right.running ) {

            runCheckProgressTimeout();

        } else if ( !!resp.right.result ) {

            setFullFile(resp.right.result);
            handleAddFullFile(resp.right.result);

        }

    }

    useEffect(() => {

        if ( checkProgress ) {
            getProgressFile();
        }

    }, [ checkProgress ])

    useEffect(() => {

        if ( id.length > 0 ) {
            getProgressFile();
        }

    }, [ id ])

    return (
        <>
            <PercentText>{ uploadingInfo.percent } % { uploadingInfo.percent === 100 ? 'Finishing upload, almost there...' : ' done... ' + uploadingInfo.progress }</PercentText>
            <LinearProgress variant="determinate" value={ uploadingInfo.percent } />
        </>
    )
}

const PercentText = styled.span`
    margin: 10px 0;
    font-weight: bold;
`;

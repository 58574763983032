import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, reduxForm, submit } from 'redux-form';
import styled, {css} from "styled-components";

interface stepProps {
    selectedStep: number,
    steps: {
        header: string,
        description: string,
        id: number,
        isFirst: boolean
    }[],
    onSelectedChange: any
}

const RecipeCreateHeader = (props: stepProps) => {

    const {selectedStep, steps, onSelectedChange} = props;
    const dispatch = useDispatch();
    const formState = useSelector(state => state.form.createRecipe?.values);

    const formInvalid = () => {
        const recipeName = formState?.recipeName;
        const brand = formState?.brand;
        const primaryVariant = formState?.primaryVariant;
        const auxiliarName = formState?.auxiliarName;

        return !recipeName || !brand || !primaryVariant || recipeName === auxiliarName;
    }

    const clearIngredients = () => {
        const ingredients = formState?.ingredients;
        if (ingredients) {
            let newIngredientes = ingredients
                                    .filter((ing: {description: string, id: string}) => ing.description !== '')
                                    .map((ing: {description: string, id: string}, index: number) => {
                                        return {description: ing.description, id: index.toString()}
                                    });
            dispatch(change('createRecipe', "ingredients", newIngredientes));
        }
    }

    const nextStep = (step: number) => {
        if (selectedStep == steps[0].id && formInvalid()) {
            return dispatch(submit('createRecipe'));
        }
        clearIngredients();
        onSelectedChange(step);
    };

    const renderedSteps = steps.map(step => {
        const StepState = selectedStep === step.id ? ActiveStep : Step;
        const theme = {
            isFirst: step.isFirst
        };
        return (
            <StepState theme={theme} key={step.id} onClick={() => nextStep(step.id)}>

                        <StepText>
                            {step.header}
                        </StepText>
                        <StepText>
                            {step.description}
                        </StepText>

            </StepState>
        );
    });

    return (
            <HearderStepsContainer>
                {renderedSteps}
            </HearderStepsContainer>
    );
};

const HearderStepsContainer = styled.div`
    display: flex;
    margin-right: 20px;
`;

const StepText = styled.span`
    padding-left: 20px;
`;

const Step = styled.div`
    @media only screen and (max-width: 1500px) {
      font-size: 12px;
    }
    color: white;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    height: 80px;
    flex-grow: 1;
    border-radius: 3px;
    background-color: #0c5d8e;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
        background-color: #158cd4;
    }
    &:after {
        content: "";
        position: absolute;
        right: -39px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 40px solid #0c5d8e;
        border-top: 40px solid transparent;
        border-bottom: 40px solid transparent;
        z-index:150;
    }
    &:hover:after {
        border-left: 40px solid #158cd4;
    }
    &:before {
        ${props => !props.theme.isFirst && css`
             content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: 40px solid white;
            border-top: 40px solid transparent;
            border-bottom: 40px solid transparent;
            z-index:50;
        `}
    }
`;

const ActiveStep = styled(Step)`
    background-color: #158cd4;
    color:white;
    &:after {
        border-left: 40px solid #158cd4;
    }
`;


export default RecipeCreateHeader;

/* export default reduxForm<{}, stepProps>({
    form: 'createRecipe',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(RecipeCreateHeader); */

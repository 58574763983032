import { Box, Button, Collapse, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { ElementRecipe } from '../../../model/ElementRecipe';
import TagMenu from './TagMenu';

interface TagListMenuProps {
    tags: ElementRecipe[];
    title: string;
    tagKey: string;
}

const TagListMenu = ({tags, title, tagKey}: TagListMenuProps) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [tagsToShow, setTagsToShow] = useState<ElementRecipe[]>([]);
    const [numberOfTags, setNumberOfTags] = useState(10);

    useEffect(() => {
        setTagsToShow(tags.slice(0,numberOfTags));
    }, [tags, numberOfTags])

    return (
        <Box mt={1} mb={1}>
            <Box 
                display="flex" 
                flexDirection="row" 
                alignItems="center" 
                onClick={() => {
                    setExpanded(!expanded);
                    setNumberOfTags(10);
                }} 
                className={classes.root}
            >
                <Box pl={2}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
                <Box pl={1}>
                    <span>{title}</span>
                </Box>
            </Box>
            
            <Collapse in={expanded} timeout="auto" unmountOnExit>
               <Box display="flex" flexDirection="column" ml={3} mt={1}>
                    {tagsToShow.map(tag => {
                        return <TagMenu tag={tag} key={tag._id} tagKey={tagKey}/>
                    })}
                    {
                        numberOfTags <= tags.length && expanded ?
                            <Box mt={1} mb={1}>
                                <Button 
                                    color="primary"
                                    variant="contained"
                                    size="small" 
                                    onClick={e => setNumberOfTags(numberOfTags + 10)}
                                    className={classes.buttonCustom}
                                >
                                    + More
                                </Button>
                            </Box>
                        :
                            null
                    }
                   
               </Box>
            </Collapse>
        </Box>
    )
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: 'fit-content',
        cursor: 'pointer',
    },
    buttonCustom: {
        width: '74px',
        color: 'white',
    }
  }),
);

export default TagListMenu;
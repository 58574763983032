import * as t from "io-ts";

export const exportImportCodec = t.unknown;
export type exportImportResponse = t.OutputOf<typeof exportImportCodec>;

export const importCodec = t.strict({
    id: t.string,
    running: t.boolean,
    rows: t.array(t.strict({
        row_number: t.number,
        row_name: t.string,
        row_status_ok: t.boolean,
        row_message: t.string
    }))
})

export type importResponse = t.OutputOf<typeof importCodec>;
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from '../Validate';
import { createMuiTheme, createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { RecipeName, RecipeTitle } from '../RecipeTitles';
import TagSection from './TagSection';
import NewTag from './NewTag';
import { Divider, FormControl, Grid, InputLabel, Select } from '@material-ui/core';
import { RenderAutocompleteField, renderSelectField, renderTextField } from '../../basicComponents/LocalFields';
import { useSelector } from 'react-redux';

const theme = createMuiTheme({
    palette: {
        primary: blue,
    },
});

interface CustomProps {
    previousPage: () => void;
};

const defaultProps = {
    borderLeft: "8px solid #bfbfbfcc",
};


const RecipeCreateFourthStep = (props: any) => {
    const { handleSubmit, previousPage } = props;
    const { mixers, occasions, glasswares, complexities, drinkTypes } = useSelector(state => state.transient);

    const renderComplexity = complexities.elementRecipe.map(complexity => {
        return (
            <option key={complexity._id} value={complexity._id}>{complexity.name}</option>
        );
    });

    return (
        <div>
            <form onSubmit={handleSubmit}>
                
                <RecipeName />

                <TagSection title="Drink Types" tags={drinkTypes.elementRecipe} formName="drinkTypes" label="Add Drink Type"/>

                <TagSection title="Occassions" tags={occasions.elementRecipe} formName="occassions" label="Add Occassion"/>

                <TagSection title="Mixers" tags={mixers.elementRecipe} formName="mixers" label="Add Mixer"/>

                <TagSection title="Glassware" tags={glasswares.elementRecipe} formName="glassware" label="Add Glassware"/>

                <Box mt= {1}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>

                            <RecipeTitle title="Food Pairings" />
                            
                            <Box mt={3}>
                                <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                                    <NewTag formName="foodPairings" label="Add Food Pairing"/>
                                </Box>
                            </Box>

                        </Grid>
                        <Grid item xs={6}>

                            <RecipeTitle title="Complexity" />

                            <Box mt={3}>
                                <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                                    <Field 
                                        name="complexity"
                                        component={RenderAutocompleteField}
                                        label="Select Complexity"
                                        options={complexities.elementRecipe}
                                        customField="name"
                                    />
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </Box>            

                <Box pt={3}>
                    <Divider />
                </Box>

                <Box display="flex" justifyContent="space-between" pt={5}>
                    <ThemeProvider theme={theme}>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            startIcon={<ArrowBackIosIcon />}
                            onClick={previousPage}
                        >
                            Previous
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIosIcon />}
                        >
                            Next: Review & Submit
                        </Button>
                    </ThemeProvider>
                </Box>
               
            </form>
        </div>
    );
};


export default reduxForm<{}, CustomProps>({
    form: 'createRecipe',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(RecipeCreateFourthStep);

import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {useSelector} from "react-redux";
import {ThumbnailOptionsModal} from "./ThumbnailOptionsModal";

interface Props {
    setAutogenerate: (value: { autogenerate: boolean, fromImage: boolean, startTime: string }) => void
}

export const AutogenerateThumbnailButton = ({ setAutogenerate } : Props) => {

    const { canAddThumbnailFromImage } = useSelector(state => state.transient.fullMediaUploadingReducer);
    const [ openModal, setOpenModal ] = useState(false);
    const [ startTime, setStartTime ] = useState('00:00:00.000');

    const onPress = () => {
        debugger;
        if (canAddThumbnailFromImage) {
            setAutogenerate({autogenerate: true, fromImage: true, startTime: ''});
        } else {
            setOpenModal(true);
        }
    }

    const autogenerateFormVideo = (generate: boolean) => {
        setOpenModal(false);
        if (!generate) {
            return;
        }
        setAutogenerate({autogenerate: true, fromImage: false, startTime: startTime});
    }

    return (
        <>
            <Button
                size="large"
                variant="outlined"
                color="primary"
                component="span"
                startIcon={<AddCircleOutlineIcon />}
                onClick={onPress}
            >
                UPLOAD THUMBNAIL FROM FULL MEDIA
            </Button>

            <ThumbnailOptionsModal
                open={openModal}
                handleClose={autogenerateFormVideo}
                startTime={startTime}
                setStartTime={setStartTime}
            />
        </>
    )
}

import React, {useState} from "react";
import {
    RecipesWithExcludedVariantsAndBrand,
    VariantsAreNotInBrand,
    WarningMessage
} from "../../../../model/RecipeErrors";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {RecipeEnvironment} from "./RecipeEnvironment";
import SearchIcon from '@material-ui/icons/Search';
import styled from "styled-components";
import {FixButton} from "./FixButton";
import {useDispatch} from "react-redux";
import {fixBrandsAndVariants, getRecipeDetail} from "../../../../services/actions/RecipeIssuesService";
import {isRight} from "fp-ts/These";
import BasicDialog from "../../basicComponents/BasicDialog";
import {useHistory} from "react-router-dom";

interface Props {
    recipes: VariantsAreNotInBrand[];
    showDialog: (messages: string[]) => void;
    setIsLoading: (value: boolean) => void;
    getRecipeErrors: () => void;
    setWarningMessage: (value: WarningMessage) => void;
    setSelectedRecipe: (_id: string) => void;
}


export const VariantsAreNotInBrandRows = ({ recipes, showDialog, setIsLoading, getRecipeErrors, setWarningMessage, setSelectedRecipe }: Props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const fixIssue = (recipe: VariantsAreNotInBrand) => {
        dispatch(getRecipeDetail(recipe._id));
        history.push('/recipe/new');
    }

    const render = (item: VariantsAreNotInBrand) => {
        return (
            <TableRow key={item._id}>

                <TableCell component="th" scope="row">
                    <span>Invalid variants</span>
                </TableCell>

                <TableCell
                    component="th"
                    scope="row"
                    style={{ cursor: 'pointer'}}
                    onClick={e => setSelectedRecipe(item._id)}
                >
                    <RecipeName>{item.name}</RecipeName>
                </TableCell>

                <TableCell component="th" scope="row">
                    <RecipeEnvironment environment={item.status} />
                </TableCell>

                <TableCell component="th" scope="row">
                    <InfoCell onClick={ () => showDialog(item.messages) }>
                        <SearchIcon style={{marginRight: '10px'}}/>
                        <span>The variants in the recipe are not associated with the brand</span>
                    </InfoCell>
                </TableCell>

                <TableCell component="th" scope="row">
                    <FixButton onClick={ () => fixIssue(item) } />
                </TableCell>

            </TableRow>
        )
    }

    return (
        <>
            {
                recipes && recipes.length > 0 &&
                recipes.map( render )
            }
        </>
    )
}

const InfoCell = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RecipeName = styled.span`
  color: blue;
  text-decoration: underline;
`;

import * as t from "io-ts";

const stringOrNull = t.union([t.string, t.undefined]);

export const DCHImageCodec = t.strict({
    id: t.number,
    name: t.string,
    title: t.string,
    filesize: t.union([t.number, t.null]),
    filetype: t.union([t.string, t.null]),
    mimetype: t.union([t.string, t.null]),
    width: t.union([t.string, t.null]),
    height: t.union([t.string, t.null]),
    videowidth: t.union([t.string, t.null]),
    videoheight: t.union([t.string, t.null]),
    videoduration: t.union([t.string, t.null]),
    renditions: t.type({
        download: t.union([t.type({url: t.string}), t.null]),
        preview: t.union([t.type({url: t.string}), t.null]),
        thumbnail: t.union([t.type({url: t.string}), t.null]),
    }),
    imported: t.boolean,
    usagerights: t.union([t.string, t.null]),
    keywords: t.union([t.string, t.null]),
    enableToDownload: t.type({
        valid: t.boolean,
        error_code: t.union([t.string, t.null]),
        message: t.string
    }),
    retired: t.boolean
});

export const DCHImagesCodec = t.array(DCHImageCodec);

export const DCHImagesResponseCodec = t.strict({
    assets: DCHImagesCodec,
    totalcount: t.number,
    resultlimit: t.number,
    currentpage: t.number,
    pagecount: t.number
});

export type DCHImagesResponse = t.OutputOf<typeof DCHImagesResponseCodec>;
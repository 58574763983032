import React, { useState} from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import ErrorIcon from '@material-ui/icons/Error';
import {ListItemIcon, ListItemText, Menu, MenuItem, MenuProps} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';

interface Props {
    setOption: (options: string) => void;
}

export const FilterImportOptions = ({setOption}: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClose = (option?: string) => {
        setAnchorEl(null);
        if (option) {
            setOption(option);
        }
    };


    return (
        <div>
            <ButtonContainer>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) =>setAnchorEl(e.currentTarget)}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                >
                    Filter
                </Button>
            </ButtonContainer>
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={e => handleClose()}
            >
                <MenuItem onClick={() => handleClose('all')}>
                    <StyledMenuItem>
                        <FormatAlignJustifyIcon fontSize="small" />
                    </StyledMenuItem>
                    <ListItemText primary="All" />
                </MenuItem>
                <MenuItem onClick={() => handleClose('running')}>
                    <StyledMenuItem>
                        <AutorenewIcon fontSize="small" />
                    </StyledMenuItem>
                    <ListItemText primary="Running" />
                </MenuItem>
                <MenuItem onClick={() => handleClose('error')}>
                    <StyledMenuItem>
                        <ErrorIcon fontSize="small" />
                    </StyledMenuItem>
                    <ListItemText primary="Error" />
                </MenuItem>
                <MenuItem onClick={() => handleClose('finish')}>
                    <StyledMenuItem>
                        <CheckCircleOutlineOutlinedIcon fontSize="small" />
                    </StyledMenuItem>
                    <ListItemText primary="Finish" />
                </MenuItem>
            </StyledMenu>
        </div>
    );

}

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 20px;
`;

const StyledMenuItem = withStyles((theme) => ({
    root: {
        minWidth: '27px',
    },
}))(ListItemIcon);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

import { Reducer } from "redux";

interface ReloadRecipeFormState {
    reload: boolean;
}

const defaultState: ReloadRecipeFormState = {
    reload: false
};

export const reloadRecipeFormReducer: Reducer<ReloadRecipeFormState, RecipeFormActions> = (
    state: ReloadRecipeFormState = defaultState,
    action: RecipeFormActions
) => {
    switch (action.type) {
        case "RELOAD_FORM":
            return { reload: true};
        case "FORM_REOLADED":
            return { reload: false};
        default:
            return state;
    }
};

export type RecipeFormActions = 
| {
    type: "RELOAD_FORM";
}
| {
    type: "FORM_REOLADED";
};
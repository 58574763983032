import * as t from "io-ts";

export const AuthResponseCodec = t.strict({
    message: t.string
});

export type AuthResponse = t.OutputOf<typeof AuthResponseCodec>;

export const TokenResponseCodec = t.strict({
    token: t.string
});

export type TokenResponse = t.OutputOf<typeof TokenResponseCodec>;

export const UserInformactionResponseCodec = t.strict({
    email: t.string,
    firstName: t.string,
    lastName: t.string,
    apps: t.unknown
});

export type UserInformactionResponse = t.OutputOf<typeof UserInformactionResponseCodec>;

import { QuerySearchMedia } from "../../model/QuerySearchMedia";
import { updateMedia, updateMediaNameAction } from "../../store/actions/MediaActions";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { MediaResponse, MediaCodec, ImageResponse, ImageResponseCodec, ImageUpdateResponseCodec, ImageUpdateResponse } from "../types/ImageResponseCodec";

export const getMedia = (page: number, limit: number, params: QuerySearchMedia ): ServiceCallAction<MediaResponse> => {
	const timeStamp = new Date().getTime();
	return ({
		method: "GET",
		endpoint: `recipe/media`,
		param: {page, limit, ...params},
		serviceKey: "getMedia",
		responseDecoder: MediaCodec,
		type: "SERVICE_CALL",
		onSuccess: (data: any) => updateMedia(data, timeStamp)
	})
};

export const updateMediaName = (_id: string, name: string): ServiceCallAction<ImageUpdateResponse> => {
	return ({
		method: "PUT",
		endpoint: `media/update/${_id}`,
		body: {type: 'application/json', value: {name: name}},
		serviceKey: "updateMedia",
		responseDecoder: ImageUpdateResponseCodec,
		type: "SERVICE_CALL",
		onSuccess: updateMediaNameAction
	})
};
import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Backdrop, Box, Checkbox, CircularProgress, Snackbar, TablePagination, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { ArchivedButton, DraftButton, LiveButton } from './RecipeListButtons';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Recipe } from '../../../model/Recipe';
import IconButton from '@material-ui/core/IconButton';
import { Alert, Skeleton } from '@material-ui/lab';
import { republishAndFetchRecipes, updateStatusAndFetchRecipes } from '../../../services/actions/StatusService';
import { clearStatusError } from '../../../store/actions/StatusReducerActions';
import { SORT_ORDER, SORT_BY, SORT_BY_ELEMENT } from '../../../services/types/util/Constants';
import { getRecipeCopy } from '../../../services/actions/CopyRecipeService';
import { Link } from 'react-router-dom';
import { buildQuery } from './BuildQuery';
import TablePaginationActions from '../basicComponents/TablePaginationActions';
import styled from "styled-components";


interface RecipeTableProps {
    handleChangePage: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowsPerPage: number;
    setLoading: (loading: boolean) => void;
    loading: boolean;
    setSelectedRecipe: (_id: string) => void;
    selected: string[];
    setSelected: (_: string[]) => void;
    sortOrder: SORT_ORDER,
    sortBy: SORT_BY,
    sortByElement: SORT_BY_ELEMENT
}

const RecipeTable = ({handleChangePage, handleChangeRowsPerPage, rowsPerPage, setLoading, loading, setSelectedRecipe, selected, setSelected, sortOrder, sortBy, sortByElement}: RecipeTableProps) => {
    const classes = useStyles();
    const [rows, setRow] = useState<Recipe[]>([]);

    const isSelected = (uuid: string) => selected.indexOf(uuid) !== -1;
    const { recipes, brands, variants, serveStrategies, filterTags } = useSelector(state => state.transient);
    const { error } = useSelector(state => state.transient.statusUpdate);
    const [showError, setShowError] = useState(false);
    const dispatch = useDispatch();

    const getQuery = () => {
        return buildQuery(filterTags, sortBy, sortByElement, sortOrder);
    }

    useEffect(() => {
        setRow(recipes.contents);
        if (!recipes.firstLoad)
            setLoading(false);
    }, [recipes]);

    useEffect(() => {
        if (error){
            setLoading(false);
            setShowError(true);
            dispatch(clearStatusError());
        }
    }, [error]);

    const getBrand = (id: string) => {
        if (brands && brands.elementRecipe && brands.elementRecipe.length > 0 && id) {
            return brands.elementRecipe.find(brand => brand._id === id)?.name;
        }
        return '';
    }

    const getVariant = (id: string) => {
        if (variants && variants.elementRecipe && variants.elementRecipe.length > 0 && id) {
            return variants.elementRecipe.find(variant => variant._id === id)?.name;
        }
        return '';
    }

    const getServingStrategy = (ids: string[]) => {
        if (serveStrategies && serveStrategies.elementRecipe && serveStrategies.elementRecipe.length > 0 && ids.length > 0) {
            const options = ids.map((servinStrategy: string) => {
                const option = serveStrategies.elementRecipe.find(option => option._id === servinStrategy);
                return option?.name;
            }) || [];
            return options.join(' - ');
        }
    }

    const selectRecipe = (_id: string) => {
        setSelectedRecipe(_id);
    }

    const copyRecipe = (_id: string) => {
        dispatch(getRecipeCopy(_id));
    }

    const handleClick = (event: React.MouseEvent<unknown>, uuid: string) => {
        const selectedIndex = selected.indexOf(uuid);
        let newSelected: string[] = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, uuid);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
    
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleMoveToDraft = (uuid: string) => {
        dispatch(updateStatusAndFetchRecipes(uuid, 'Draft', recipes.currentpage, rowsPerPage, getQuery()));
        setLoading(true);
    }

    const handleArchive = (uuid: string) => {
        dispatch(updateStatusAndFetchRecipes(uuid, 'Archived', recipes.currentpage, rowsPerPage, getQuery()));
        setLoading(true);
    }

    const handlePublishNow = (uuid: string) => {
        dispatch(updateStatusAndFetchRecipes(uuid, 'Live', recipes.currentpage, rowsPerPage, getQuery()));
        setLoading(true);
    }

    const handleRepublish = (uuid: string) => {
        dispatch(republishAndFetchRecipes(uuid, recipes.currentpage, rowsPerPage, getQuery()));
        setLoading(true);
    }

    
    const renderButton = (enviroment: number, uuid: string) => {
        switch (enviroment) {
            case 3:
                return <DraftButton
                            id={uuid}
                            handleArchive={handleArchive} 
                            handlePublishNow={handlePublishNow}
                            textPublish="Publish Now"
                        />;
            case 1:
                return <LiveButton id={uuid} handleMoveToDraft={handleMoveToDraft} handleArchive={handleArchive}/>;
            default:
                return <ArchivedButton
                            id={uuid}
                            handleMoveToDraft={handleMoveToDraft} 
                            handleRepublish={handleRepublish}
                            showRepublish={true}
                        />;
        }
    }

    const getUrl = (id: string, type: string) => {
        return process.env.REACT_APP_API_URL + '/contents/stream/' + id + '?type=' + type;
    }

    return (

        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Thumb</StyledTableCell>
                            <StyledTableCell align="left">Recipe Name</StyledTableCell>
                            <StyledTableCell align="left">Brand</StyledTableCell>
                            <StyledTableCell align="left">Variant</StyledTableCell>
                            <StyledTableCell align="left">Serve Strategy</StyledTableCell>
                            <StyledTableCell align="left">Publish Status</StyledTableCell>
                            <StyledTableCell padding="checkbox">
                                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < rows.length}
                                        checked={rows.length > 0 && selected.length === rows.length}
                                        onChange={handleSelectAllClick}
                                        inputProps={{ 'aria-label': 'select all desserts' }}
                                    />
                                </Box>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const type = row.thumbnail && row.thumbnail.url ? 'thumbnail' : row.thumbnail_Drinkshot && row.thumbnail_Drinkshot.url ? 'thumbnail_Drinkshot' : null;
                            const src = type ? getUrl(row._id, type) : '';
                                // const src = row.thumbnail && row.thumbnail.url ?
                                //             row.thumbnail.url
                                //         :
                                //             row.thumbnail_Drinkshot && row.thumbnail_Drinkshot.url ?
                                //             row.thumbnail_Drinkshot.url
                                //         : '';
                            return (
                                <TableRow 
                                    key={row._id}
                                >
                                    <TableCell align="left" style={{width: "6%"}}>
                                        <Avatar 
                                            variant="square" 
                                            src={src} 
                                        >  
                                            <img
                                                className="MuiAvatar-img"
                                                src="/assets/extensions/_blank.png"
                                            />
                                        </Avatar>
                                    </TableCell>
                                    <TableCell 
                                        align="left" 
                                        style={{width: "19%", cursor: 'pointer'}} 
                                        onClick={e => selectRecipe(row._id)}
                                    >
                                        <RecipeName>
                                            {row.name}
                                        </RecipeName>
                                    </TableCell>
                                    <TableCell align="left" style={{width: "17%"}}>{getBrand(row.brand)}</TableCell>
                                    <TableCell align="left" style={{width: "17%"}}>{getVariant(row.primaryVariant)}</TableCell>
                                    <TableCell align="left" style={{width: "17%"}}>{getServingStrategy(row.serveStrategy)}</TableCell>
                                    <TableCell align="left" style={{width: "17%"}}>
                                        {renderButton(row.enviroment, row.uuid)}
                                    </TableCell>
                                    <TableCell 
                                        padding="checkbox"
                                        style={{width: "7%"}}
                                    >
                                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                                            <Tooltip title="Copy" placement="left-end">
                                                <Link 
                                                    onClick={e => copyRecipe(row._id)}
                                                    to={{
                                                        pathname: '/recipe/new',
                                                        state: { copyRecipe: true}
                                                    }}
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <IconButton >
                                                        <AddToPhotosIcon />
                                                    </IconButton>
                                                </Link>
                                            </Tooltip>
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                onClick={(event) => handleClick(event, row._id)}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={recipes.totalcount}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={"Items per page:"}
                page={recipes.currentpage - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
            <Snackbar open={error} autoHideDuration={3000} onClose={() => setShowError(false)}>
                <Alert onClose={() => setShowError(false)} severity="error">
                    Error updating recipe status!
                </Alert>
            </Snackbar>
        </div>
    )
};


const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#f2f2f2',
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    table: {
        minWidth: 650,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  }),
);

const RecipeName = styled.span`
  color: blue;
  text-decoration: underline;
`;

export default RecipeTable;

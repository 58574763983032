import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ImageIcon from '@material-ui/icons/Image';
import RecipeImage from './RecipeImage';
import { FileImage } from '../../../../model/FileImage';
import { useDispatch, useSelector } from 'react-redux';
import { FileImageState } from '../../../../store/reducers/fileReducer';
import { removeDrinkShot, removeLifeStyle, updateDrinkShot, updateLifeStyle } from '../../../../store/actions/fileActions';
import UploadDCHImage from './UploadDCHImage';
import { postImage } from '../../../../services/actions/ImagesService';
import { DCHImages } from '../../../../model/DCHImages';
import { getJanusImage, importImage } from '../../../../services/actions/DCHImagesService';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '5px',
            marginBottom: '5px',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
    }),
);

interface UploadImageProps {
    change: any,
    formKey: "drinkShot" | "lifeStyle",
    formId: "drinkShotId" | "lifeStyleId",
    id?: string
};

const UploadImage = ({change, formKey, formId, id}: UploadImageProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { admin } = useSelector(state => state.persistent.auth);
    const fileImage : FileImageState = useSelector(state => state.transient.fileImages);


    const setImage = (image: FileImage) => {
        if (formKey === 'drinkShot') {
            dispatch(updateDrinkShot(image));
        } else {
            dispatch(updateLifeStyle(image));
        }
    }

    const onChange = (e: any) => {

        var data = new FormData();
        data.append("file", e.target.files[0]);
        if (formKey === "drinkShot") {
            data.append("maxSize", "9");
        } else {
            data.append("maxSize", "19");
        }

        dispatch(postImage(data, formKey));
        const myFile: FileImage = {
            file: e.target.files[0],
            DCHImage: null,
            janusFile: null
        }
        setImage(myFile);
    }

    const onDeleteFile = () => {
        change(formKey, false);
        if (formKey === 'drinkShot') {
            dispatch(removeDrinkShot());
        } else {
            dispatch(removeLifeStyle());
        }
    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (image: DCHImages | null) => {
        if (image) {
            const myFile: FileImage = {
                file: null,
                DCHImage: image,
                janusFile: null
            };
            setImage(myFile);
            
            let maxSize = '';
            if (formKey === "drinkShot") {
                maxSize = "9";
            } else {
                maxSize = "19";
            }
            dispatch(importImage(image.id, formKey, maxSize));
        }
        setOpen(false);
    };

    const renderedButtons = (type: "drinkShot" | "lifeStyle") => {

        if (fileImage && fileImage.hasOwnProperty(formKey) && fileImage[formKey]) {
            return <RecipeImage 
                        file={fileImage[formKey]} 
                        deleteFile={onDeleteFile} 
                        editFile={handleClickOpen} 
                        showButtons={true} 
                        error={formKey == 'drinkShot' ? fileImage.errorDrinkshot : fileImage.errorLifeStyle}
                        type={type}
                        id={id}
                    />
        }
        
        return (
            <div className={classes.root}>
                {
                    admin ? 
                        <>
                            <input
                                accept="image/*|video/*"
                                id={formKey}
                                className={classes.input}
                                type="file"
                                onChange={onChange}
                            />
                            <label htmlFor={formKey}>
                                <Button 
                                    size="large" 
                                    variant="outlined" 
                                    color="primary" 
                                    component="span" 
                                    startIcon={<AddCircleOutlineIcon />}
                                >
                                    Upload New Image
                                </Button>
                            </label>
                        </>
                    : 
                        null
                }
                <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<ImageIcon />}
                    onClick={handleClickOpen}
                >
                    Search by DCH ID
                </Button>
            </div>
        );
    }

    return (
        <div>
            {renderedButtons(formKey)}
            <UploadDCHImage open={open} handleClickOpen={handleClickOpen} handleClose={handleClose}/>
        </div>
    );
};

export default UploadImage;

import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { RecipeTitle } from '../RecipeTitles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SelectTags from './SelectTags';
import { Collapse } from '@material-ui/core';
import { ElementRecipe } from '../../../../model/ElementRecipe';

interface TagSectionProps {
    title: string;
    tags: ElementRecipe[];
    formName: string;
    label: string;
};

const TagSection = ({title, tags, formName, label}: TagSectionProps) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    return (
        <div>
            <Box display="flex" flexDirection="row" alignItems="center" onClick={() => setExpanded(!expanded)} className={classes.root}>
                <RecipeTitle title={title} />
                <Box mt={1} pl={2}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>    
            </Box>
            
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box mt={1}>
                    <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                        <SelectTags tags={tags} name={formName} label={label}/>
                    </Box>
                </Box>
            </Collapse>
        </div>
    )
};

const defaultProps = {
    borderLeft: "8px solid #bfbfbfcc",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: 'fit-content',
        cursor: 'pointer',
    },
  }),
);

export default TagSection;
import * as t from "io-ts";
import { ImageCodec } from "./ImageResponseCodec";

export const RecipeCodec = t.strict({
    _id: t.string,
    enviroment: t.number,
    name: t.string,
    contentModel_id: t.string,
    author: t.string,
    updatedAt: t.strict({
        title: t.string,
        textToShow: t.string,
        raw: t.string,
    }),
    uuid: t.string,
    status: t.string,
    version: t.number,
    uploadDate: t.string,
    drinkTypes: t.array(t.string),
    occasions: t.array(t.string),
    mixers: t.array(t.string),
    glassware: t.array(t.string),
    foodPairing: t.array(t.string),
    complexity: t.string,
    preparationSteps: t.string,
    menuCopy: t.string,
    ingredients: t.array(t.type({
        ingredient: t.union([t.string, t.undefined])
    })),
    servingSize: t.string,
    serveStrategy: t.array(t.string),
    secondaryVariant: t.string,
    secondaryBrand: t.string,
    primaryVariant: t.string,
    brand: t.string,
    thumbnail: t.strict({
        url: t.union([t.string, t.null])
    }),
    thumbnail_Drinkshot: t.strict({
        url: t.union([t.string, t.null])
    }),
    lifeStyle: t.strict({
        url: t.union([t.string, t.null]),
        asset: t.union([t.string, t.null]),
        media: t.union([ImageCodec, t.null])
    }),
    drinkShot: t.strict({
        url: t.union([t.string, t.null]),
        asset: t.union([t.string, t.null]),
        media: t.union([ImageCodec, t.null])
    }),
});

export const RecipesCodec = t.strict({
    contents: t.array(RecipeCodec),
    resultlimit: t.number,
    currentpage: t.number,
    totalcount: t.number,
    pagecount: t.number
});

export const RecipeResponseCodec = t.strict({
    content: RecipeCodec
})

export type RecipeResponse = t.OutputOf<typeof RecipeResponseCodec>;
export type RecipesResponse = t.OutputOf<typeof RecipesCodec>;

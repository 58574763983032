import {Reducer} from "redux";

export interface SegmentFileState {
  segmentFinished: boolean;
  segment: number;
  percent: number;
  progress: string;
}

const defaultState: SegmentFileState = {
  segmentFinished: false,
  segment: 0,
  percent: 0,
  progress: ''
};

export const segmentFileReducer: Reducer<SegmentFileState, SegmentFileActions> = (
  state: SegmentFileState = defaultState,
  action: SegmentFileActions
) => {
  switch (action.type) {
    case "UPDATE_SEGMENT_STATUS":
      return {segmentFinished: action.segmentFinished, segment: action.segment, percent: action.percent, progress: action.progress};
    case "RESTART_SEGMENT_STATUS":
      return {...state, segmentFinished: false};
    case "CLEAR":
      return {segment: 0, percent: 0, progress: '', segmentFinished: false};
    default:
      return state;
  }
};

export type SegmentFileActions =
  | {
  type: "UPDATE_SEGMENT_STATUS";
  segmentFinished: boolean;
  segment: number;
  percent: number;
  progress: string;
} | {
  type: "RESTART_SEGMENT_STATUS"
} | {
  type: "CLEAR"
};

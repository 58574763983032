import React from "react";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FullMediaPanel from "../FullMediaPanel/FullMediaPanel";
import {CHUNK_SIZE} from "../../../../services/types/util/Constants";
import {FullFileData} from "../../../../model/FullMediaComponents";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {postFullMedia} from "../../../../services/actions/FullMediaService";
import {useDispatch} from "react-redux";
import {addErrorUploadingFullMediaAction} from "../../../../store/actions/FullMediaUploadingActions";

interface Props {
    setFullFileData: (data: FullFileData) => void;
    setFile: (file: File) => void;
}

const prohibitedExtensions = ['image/vnd.adobe.photoshop', 'application/vnd.adobe.photoshop']

export const PreviewFileButton = ({ setFullFileData, setFile }: Props) => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const canUploadFileType = (extension: string, type: string): boolean => {
        return extension !== 'psd' && !prohibitedExtensions.includes(type);
    }

    const onChange = (e: any) => {

        const extension = e.target.files[0].name.split('.').pop().toLowerCase();
        const type = e.target.files[0].type;

        if ( !canUploadFileType(extension, type) ) {
            dispatch(addErrorUploadingFullMediaAction('PSD files aren\'t valid'));
            return;
        }

        const file = e.target.files[0];

        const totalParts = file.size % CHUNK_SIZE === 0 ? file.size / CHUNK_SIZE : Math.floor(file.size / CHUNK_SIZE) + 1;

        let body: FullFileData = {
            contentType: file.type,
            filename: file.name,
            totalParts: totalParts,
            fileSize: file.size,
            previewStart: undefined,
            previewDuration: undefined,
            thumbnail: false,
            thumbnailFromImage: false,
        }

        setFullFileData(body);
        setFile(file);

    }

    return (
        <div>
            <input
                accept="*"
                className={classes.input}
                id='preview'
                type="file"
                onChange={onChange}
            />
            <label htmlFor='preview'>
                <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    component="span"
                    startIcon={<AddCircleOutlineIcon />}
                >
                    UPLOAD PREVIEW
                </Button>
            </label>
        </div>
    );

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '5px',
            marginBottom: '5px',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
    }),
);

import { updateFilterTagVariantsStatus } from "../../store/actions/VariantTagsActions";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import { ElementsRecipeCodecResponseCodec, ElementsRecipeResponse } from "../types/ElementsRecipeResponseCodec";

export const getVariantFromBrands = (ids: string): ServiceCallAction<ElementsRecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/variants-from-brands`,
    param: {ids},
	serviceKey: "getVariantFromBrands",
	responseDecoder: ElementsRecipeCodecResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateFilterTagVariantsStatus
});
import React, {useEffect, useState} from 'react';
import {SORT_BY, SORT_BY_ELEMENT, SORT_ORDER} from "../../../services/types/util/Constants";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import styled from "styled-components";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {Backdrop, Box, Checkbox, CircularProgress, Snackbar, TablePagination, Tooltip} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import {Link} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import TablePaginationActions from "../basicComponents/TablePaginationActions";
import {Alert} from "@material-ui/lab";
import {Recipe} from "../../../model/Recipe";
import {FullMedia} from "../../../model/FullMedia";
import {useDispatch, useSelector} from "react-redux";
import {ArchivedButton, DraftButton, LiveButton} from "../RecipeList/RecipeListButtons";
import {changeContentStatus} from "../../../services/actions/ContentService";

interface RecipeTableProps {
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fetchFullMedia: (page: number) => void;
  setChangStatus: (_: boolean) => void;
  rowsPerPage: number;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  setSelectedMedia: (media: FullMedia) => void;
  selected: string[];
  setSelected: (_: string[]) => void;
  // sortOrder: SORT_ORDER,
  // sortBy: SORT_BY,
  // sortByElement: SORT_BY_ELEMENT
}

const FullMediaTable = ({handleChangePage, handleChangeRowsPerPage, fetchFullMedia, setChangStatus, rowsPerPage, setLoading, loading, setSelectedMedia, selected, setSelected, /*sortOrder, sortBy, sortByElement*/}: RecipeTableProps) => {
  const classes = useStyles();
  const [rows, setRow] = useState<FullMedia[]>([]);
  const isSelected = (_id: string) => selected.indexOf(_id) !== -1;
  const { fullMediaList } = useSelector(state => state.transient);
  const dispatch = useDispatch();

  useEffect(() => {
    setRow(fullMediaList.contents);
    setLoading(false);
  }, [fullMediaList]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const selectMedia = (row: FullMedia) => {
    setSelectedMedia(row);
  }

  const handleClick = (event: React.MouseEvent<unknown>, _id: string) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleMoveToDraft = (_id: string) => {
    dispatch(changeContentStatus(_id, 'Draft'));
    setChangStatus(true);
  }

  const handleArchive = (_id: string) => {
    dispatch(changeContentStatus(_id, 'Archived'));
    setChangStatus(true);
  }

  const handlePublishNow = (_id: string) => {
    dispatch(changeContentStatus(_id, 'Live'));
    setChangStatus(true);
  }

  const renderButton = (enviroment: string, _id: string) => {
    switch (enviroment) {
      case 'Draft':
        return <DraftButton
          id={_id}
          handleArchive={handleArchive}
          handlePublishNow={handlePublishNow}
          textPublish="Publish Now"
        />;
      case 'Live':
        return <LiveButton id={_id} handleMoveToDraft={handleMoveToDraft} handleArchive={handleArchive} hideMoveToDraft={true}/>;
      default:
        return <ArchivedButton
          id={_id}
          handleMoveToDraft={handleMoveToDraft}
          showRepublish={false}
        />;
    }
  }

  return (

    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Full Media Name</StyledTableCell>
              {/*<StyledTableCell align="left">Full Media Link</StyledTableCell>*/}
              <StyledTableCell align="left">Environment</StyledTableCell>
              <StyledTableCell padding="checkbox">
                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row._id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  key={row._id}
                >
                  <TableCell
                    align="left"
                    style={{width: "47%", cursor: 'pointer'}}
                    onClick={e => selectMedia(row)}
                  >
                    <RecipeName>
                      {row.name}
                    </RecipeName>
                  </TableCell>
                  <TableCell align="left" style={{width: "17%"}}>
                    {renderButton(row.status, row._id)}
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    style={{width: "7%"}}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        onClick={(event) => handleClick(event, row._id)}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={fullMediaList.totalcount}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={"Items per page:"}
        page={fullMediaList.currentpage - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  )
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#f2f2f2',
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    table: {
      minWidth: 650,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

const RecipeName = styled.span`
  color: blue;
  text-decoration: underline;
`;

export default FullMediaTable;

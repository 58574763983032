import { updateNewRecipe, updateRecipes, updateRecipe as updateRecipeReducer, failNewRecipe, failRecipe } from "../../store/actions/recipeActions";
import { createRecipeAction } from "../../store/actions/CreateRecipeActions";
import { ServiceCallAction } from "../../store/middleware/serviceCallMiddleware";
import {  RecipeResponse, RecipesResponse, RecipesCodec, RecipeResponseCodec } from "../types/CategoriesResponseCodec";
import { JSONObject } from "../types/util/BasicTypes";
import { QuerySearchRecipe } from "../../model/QuerySearchRecipes";

export const getRecipe = (_id: string): ServiceCallAction<RecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/${_id}`,
	serviceKey: "getRecipe",
	responseDecoder: RecipeResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateRecipeReducer,
	onFailure: failRecipe
});

export const getRecipes = (page: number, limit: number, params: QuerySearchRecipe ): ServiceCallAction<RecipesResponse> => {
	const timeStamp = new Date().getTime();
	return ({
		method: "GET",
		endpoint: `recipe/retrieve`,
		param: {page, limit, ...params},
		serviceKey: "getRecipes",
		responseDecoder: RecipesCodec,
		type: "SERVICE_CALL",
		onSuccess: (data: any) => updateRecipes(data, timeStamp)
	})
};

export const createRecipe = (body: JSONObject): ServiceCallAction<RecipeResponse> => ({
	method: "POST",
	endpoint: `recipe/create`,
	body: {type: 'application/json', value: body},
	serviceKey: "createRecipe",
	responseDecoder: RecipeResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateNewRecipe,
	auxiliarAction: createRecipeAction,
	onFailure: failNewRecipe
});

export const updateRecipe = (body: JSONObject, id: string): ServiceCallAction<RecipeResponse> => ({
	method: "PUT",
	endpoint: `recipe/update/${id}`,
	body: {type: 'application/json', value: body},
	serviceKey: "updateRecipe",
	responseDecoder: RecipeResponseCodec,
	type: "SERVICE_CALL",
	onSuccess: updateNewRecipe,
	auxiliarAction: createRecipeAction,
	onFailure: failNewRecipe
});

export const getPrevRecipeVersion = (uuid: string): ServiceCallAction<RecipeResponse> => ({
	method: "GET",
	endpoint: `recipe/retrieve/prev-version/${uuid}`,
	serviceKey: "getPrevRecipeVersion",
	responseDecoder: RecipeResponseCodec,
	type: "SERVICE_CALL",
});


import { Reducer } from "redux";

export interface LoginState {
    response: boolean;
};

const defaultLoginState: LoginState = {
    response: false
};

export interface AuthState {
    token: string | null;
    uid: string | null;
    user: string | null;
    admin: boolean;
    error: boolean
};

const defaultState: AuthState = {
    token: null,
    uid: null,
    user: null,
    admin: false,
    error: false
};

export const LogingReducer: Reducer<LoginState, LoginActions> = (
    state: LoginState = defaultLoginState,
    action: LoginActions
) => {
    switch (action.type) {
        case "SEND_EMAIL":
            return {response: true};
        default:
            return state;
    }
};

export const AuthReducer: Reducer<AuthState, AuthActions> = (
    state: AuthState = defaultState,
    action: AuthActions
) => {
    switch (action.type) {
        case "UPDATE_TOKEN":
            return {...state, token: action.token};
        case "UPDATE_UID":
            return {...state, uid: action.uid, user: action.user, admin: action.admin};
        case "FAIL_LOGIN":
            return defaultState;
        case "CLEAR_LOGIN":
            return defaultState;
        default:
            return state;
    }
};

export type LoginActions = 
    | {
        type: "SEND_EMAIL";
    };

export type AuthActions = 
    | {
        type: "UPDATE_TOKEN";
        token: string;
    }
    | {
        type: "UPDATE_UID";
        uid: string;
        user: string;
        admin: boolean;
    }
    | {
        type: "FAIL_LOGIN";
    }
    | {
        type: "CLEAR_LOGIN"
    };
import { Reducer } from "redux";
import { JanusFile } from "../../model/FileImage";

export interface MediaState {
    contents: JanusFile[];
    resultlimit: number;
    currentpage: number;
    totalcount: number;
    pagecount: number;
    firstLoad?: boolean;
    timeStamp: number;
}

const defaultState: MediaState = {
    contents: [],
    resultlimit: 0,
    currentpage: 1,
    totalcount: 0,
    pagecount: 0,
    firstLoad: true,
    timeStamp: 0
};

export const mediaReducer: Reducer<MediaState, MediaActions> = (
    state: MediaState = defaultState,
    action: MediaActions
) => {
    switch (action.type) {
        case "UPDATE_MEDIA":
            if (action.timeStamp > state.timeStamp) {
                return {...action.media, timeStamp: action.timeStamp, firstLoad: false};
            } else {
                return state;
            }
        case "UPDATE_MEDIA_NAME":
            let contents = [...state.contents];
            contents.forEach(ele => {
                if (ele._id === action.media._id) {
                    ele.name = action.media.name;
                }
            })
            return {...state, contents: contents};
        case "CLEAR_MEDIA":
            return defaultState;
        default:
            return state;
    }
};

export type MediaActions = 
    | {
        type: "UPDATE_MEDIA";
        media: MediaState;
        timeStamp: number;
    }
    | {
        type: "UPDATE_MEDIA_NAME";
        media: JanusFile;
    }
    | {
        type: "CLEAR_MEDIA";
    };

import { Action, AnyAction, Dispatch, MiddlewareAPI } from "redux";
import * as t from "io-ts";
import { StoreContent } from "../store";
import { Either, isRight, left, right } from "fp-ts/lib/Either";
import { ResponseContainerCodec } from "../../services/types/ResponseContainerCodec";
import {HTTPMethod, JSONObject, JSONValue} from "../../services/types/util/BasicTypes";
import { printError } from "../../services/types/util/printError";
import { clearLogin } from "../actions/AuthActions";
import { isLeft } from "fp-ts/lib/These";

export interface ServiceCallAction<T> extends Action {
	method: HTTPMethod;
	endpoint: string;

	body?: {type: 'application/json', value: JSONObject} | {type: 'multipart', value: FormData};
	param?: Record<string, string | number | boolean | undefined>;
	header?: Record<string, string | number | null>;

	serviceKey: string;
	onStart?: () => Action;
	responseDecoder: t.Decoder<any, T>;
	onSuccess?: (result: T) => Action;
	auxiliarAction?: (result: T) => Action;
	onDownloadFile?: (result: Blob) => void;
	onFailure?: (error: ServiceCallError) => Action;
	blob?: boolean;
}

export type ServiceCallError =
	| { type: "FETCH_ERROR"; error: any }
	| { type: "PARSE_ERROR"; error: string }
	| { type: "SERVER_ERROR"; errorMessage: string }
	| { type: "SERVER_ERRORS"; errorMessages: string[] }
	| { type: "SERVER_UNSUCCESS_REQUEST" };

const sleep = async (time: number) => new Promise(resolve => setTimeout(resolve, time));

async function downloadFile<E, T>(
	apiHost: string,
	token: string | null,
	uid: string | null,
	appid: string | null,
	action: ServiceCallAction<T>
): Promise<Either<ServiceCallError, { file: Blob }>> {
	try {
		const requestHeaders = new Headers();
		if (!action.body || action.body.type === 'application/json') {
			requestHeaders.set("Content-Type", "application/json");
		}
		if (token) {
			requestHeaders.set("token", token);
		}
		if (uid) {
			requestHeaders.set("uid", uid);
		}
		if (appid) {
			requestHeaders.set("appid", appid);
		}

		Object.entries(action.header || {})
            .map(([name, value]) => {
				if (value)
					requestHeaders.set(name, value.toString())
			});

		const body = action.body ? action.body.type === "multipart" ? action.body.value : JSON.stringify(action.body.value) : undefined

		let request: RequestInit = {
			headers: requestHeaders,
			method: action.method,
			body: body
		};

		const response = await (async () => {
			const params = Object.entries(action.param || {})
				.filter(([name, value]) => value != undefined)
                .map(([name, value]) => `${name}=${value}`)
                .join("&");
            const uri = params
                ? `${apiHost}/${action.endpoint}?${params}`
                : `${apiHost}/${action.endpoint}`;
            const rawResponse = await fetch(uri, request);
            const rawData = await rawResponse.blob();
            return rawData;
		})();

		return right({ file: response });

	} catch (error) {
		return left({
			type: "FETCH_ERROR",
			error
		});
	}
}

async function performServiceCall<E, T>(
	apiHost: string,
	token: string | null,
	uid: string | null,
	appid: string | null,
	action: ServiceCallAction<T>
): Promise<Either<ServiceCallError, { parsed: T }>> {
	try {
		const requestHeaders = new Headers();
		if (!action.body || action.body.type === 'application/json') {
			requestHeaders.set("Content-Type", "application/json");
		}
		if (token) {
			requestHeaders.set("token", token);
		}
		if (uid) {
			requestHeaders.set("uid", uid);
		}
		if (appid) {
			requestHeaders.set("appid", appid);
		}

		Object.entries(action.header || {})
            .map(([name, value]) => {
				if (value)
					requestHeaders.set(name, value.toString())
			});

		const body = action.body ? action.body.type === "multipart" ? action.body.value : JSON.stringify(action.body.value) : undefined

		let request: RequestInit = {
			headers: requestHeaders,
			method: action.method,
			body: body
		};

		const response = await (async () => {
			const params = Object.entries(action.param || {})
				.filter(([name, value]) => value != undefined)
                .map(([name, value]) => `${name}=${value}`)
                .join("&");
            const uri = params
                ? `${apiHost}/${action.endpoint}?${params}`
                : `${apiHost}/${action.endpoint}`;
            const rawResponse = await fetch(uri, request);
            const rawData = await rawResponse.json();
            return ResponseContainerCodec.decode(rawData);
		})();


		if (!isRight(response)) {
			return left({
				type: "PARSE_ERROR",
				error: printError(response.left)
			});
		}

		if ("error" in response.right) {
			return left({
				type: "SERVER_ERROR",
				errorMessage: response.right.error.message
			});
		} else if (response.right.status !== 'success') {
			return left({
				type: "SERVER_UNSUCCESS_REQUEST"
			});
		}

		const parsed = action.responseDecoder.decode(response.right.data);
		if (!isRight(parsed)) {
			return left({
				type: "PARSE_ERROR",
				error: printError(parsed.left),
				data: response.right.data
			});
		}

		return right({ parsed: parsed.right });
	} catch (error) {
		return left({
			type: "FETCH_ERROR",
			error
		});
	}
}

const callEnd = <T>(action: ServiceCallAction<T>, store: MiddlewareAPI<Dispatch, StoreContent>) => {
	store.dispatch({
		type: "SERVICE_CALL_START",
		serviceKey: action.serviceKey
	});
}

const handlerLeft = <T>(action: ServiceCallAction<T>, result: Either<ServiceCallError, { parsed: T } | {file: Blob}>, store: MiddlewareAPI<Dispatch, StoreContent>)
:  Either<ServiceCallError, { parsed: T } | {file: Blob}> => {
	if (isLeft(result))	{
		if (process.env.NODE_ENV !== "production") {
			console.log(result.left);
		}
		if (action.onFailure) {
			store.dispatch(action.onFailure(result.left));
		}
		if (result.left && result.left.type === 'SERVER_ERROR' && (result.left.errorMessage === 'Invalid token.' || result.left.errorMessage === 'Token expired.')) {
			store.dispatch(clearLogin());
		}
		return result;
	}
	return result;
}

export const serviceCallMiddleware = (store: MiddlewareAPI<Dispatch, StoreContent>) => (
	next: Dispatch<AnyAction>
) => async <T>(action: ServiceCallAction<T>) => {

	if (action.type !== "SERVICE_CALL" && action.type !== "SERVICE_CALL_AUTH") {
		return next(action as any);
	}

	if (action.onStart) {
		store.dispatch(action.onStart());
	}
	store.dispatch({
		type: "SERVICE_CALL_START",
		serviceKey: action.serviceKey
	});


	const api_url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';
	const sso_url = process.env.REACT_APP_API_URL_SSO ? process.env.REACT_APP_API_URL_SSO : '';

	const token = action.type === "SERVICE_CALL_AUTH" ? null : store.getState().persistent.auth.token;
	const uid = action.type === "SERVICE_CALL_AUTH" ? null : store.getState().persistent.auth.uid;
	const appid = action.type === "SERVICE_CALL_AUTH" ? null : 'nam-tsp';
	const server = action.type === "SERVICE_CALL_AUTH" ? sso_url : api_url;

	if (action.blob) {

		const result = await downloadFile(
			server,
			token,
			uid,
			appid,
			action
		);

		callEnd(action, store);

		if (isRight(result)) {
			if (action.onDownloadFile) {
				action.onDownloadFile(result.right.file);
			}
			return right(result.right.file);
		} else {
			return handlerLeft(action, result, store);
		}

	} else {

		const result = await performServiceCall(
			server,
			token,
			uid,
			appid,
			action
		);

		callEnd(action, store);

		if (isRight(result)) {
			if (action.onSuccess) {
				store.dispatch(action.onSuccess(result.right.parsed));
			}
			if (action.auxiliarAction) {
				store.dispatch(action.auxiliarAction(result.right.parsed));
			}
			return right(result.right.parsed);
		} else {
			return handlerLeft(action, result, store);
		}

	}
};

// Overload to add type info to dispatch calls
declare module "redux" {
	export interface Dispatch<A extends Action> {
		<T>(action: ServiceCallAction<T>): Promise<Either<ServiceCallError, T>>;
	}
}

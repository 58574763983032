import { Reducer } from "redux";

interface UtilsState {
  openModal: boolean;
  redirect: {
    waiting: boolean,
    canRedirect: boolean,
    check: boolean
  };
  statusUpdate: {
    rejectedContentsCount: number;
    error: boolean;
  }
}

const defaultUtilsState: UtilsState = {
  openModal: false,
  redirect: {
    waiting: false,
    canRedirect: false,
    check: false
  },
  statusUpdate: {
    rejectedContentsCount: 0,
    error: false
  }
};

export const utilsReducer: Reducer<UtilsState, UtilsActions> = (
  state: UtilsState = defaultUtilsState,
  action: UtilsActions
) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return {...state, openModal: action.status};
    case "UPDATE_STATUS_UTILS":
      return {...state, statusUpdate: {rejectedContentsCount: action.rejectedContentsCount, error: false}};
    case "FAIL_UPDATE_STATUS_UTILS":
      return {...state, statusUpdate: {rejectedContentsCount: 0, error: true}};
    case "CLEAR_STATUS_ERROR_UTILS":
      return {...state, statusUpdate: {rejectedContentsCount: 0, error: false}};
    case "REDIRECT":
      return {...state, redirect: {waiting: action.waiting, canRedirect: action.canRedirect, check: action.check}};
    default:
      return state;
  }
};

export type UtilsActions =
  | {
  type: "OPEN_MODAL",
  status: boolean;
} | {
  type: "REDIRECT",
  waiting: boolean,
  canRedirect: boolean,
  check: boolean
} | {
  type: "UPDATE_STATUS_UTILS",
  rejectedContentsCount: number
} | {
  type: "FAIL_UPDATE_STATUS_UTILS"
} | {
  type: "CLEAR_STATUS_ERROR_UTILS"
};

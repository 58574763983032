import * as t from "io-ts";
import {DataImportRecipeResult} from "../../model/ImportResults";

const RowCodec = t.strict({
    row_number:    t.number,
    row_name:      t.string,
    row_status_ok: t.boolean,
    row_message:   t.string
});

const ResultCodec = t.strict({
    rows: t.array(RowCodec)
})

const OptionsCodec = t.strict({
    progress: t.string
})

const DataImportRecipeResultCodec = t.strict({
    _id:            t.string,
    source:         t.string,
    createdByUser:  t.union([t.string, t.undefined]),
    createdAt:      t.union([t.string, t.undefined]),
    running:        t.boolean,
    options:        OptionsCodec,
    result:         t.union([ResultCodec, t.undefined])
})

export const ImportRecipeResultCodec = t.strict({
    page:  t.number,
    limit: t.number,
    total: t.number,
    data:  t.array(DataImportRecipeResultCodec)
})


export type ImportRecipeResultResponse = t.OutputOf<typeof ImportRecipeResultCodec>;

import React, {useCallback} from 'react';
import { Box } from '@material-ui/core';
import styled from "styled-components";
import TargetBox from "./TargetBox";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DropTargetMonitor } from 'react-dnd'

function MenuIcon(props: any) {
    return (
        <div>
            <img src={props.src} style={props.style}/>
        </div>
    );
}

interface UploadFileProps {
    setFile: (file: File) => void;
}

const UploadFile = ({setFile}: UploadFileProps) => {

    const onChange = (e: any) => {
        setFile(e.target.files[0]);
    }

    const handleFileDrop = useCallback(
        (item: any, monitor: DropTargetMonitor) => {
            if (monitor) {
                const file = monitor.getItem().files[0];
                const validTypes = ["text/csv", "text/plain", "application/json", "text/plain", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
                if (validTypes.indexOf(file.type) !== -1) {
                    setFile(file)
                }
            }
        },
        [],
    )

    const renderImportFile = () => {
        return (
            <div style={{width: "100%"}}>
                <input
                    accept="text/csv, text/plain, application/json, text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    id="importFile"
                    style={{display: 'none'}}
                    type="file"
                    onChange={onChange}
                />
                <label htmlFor="importFile">
                    <DropZone style={{fontFamily: 'sans-serif'}}>
                        <Box display="flex" flexDirection="row" justifyContent="center">
                            <MenuIcon src={"/assets/icons/csv.svg"} style={{ height: '43px', marginLeft: '-5px', paddingRight: "40px"}}/>
                            <MenuIcon src={"/assets/icons/excel.svg"} style={{ height: '43px', paddingRight: "40px"}}/>
                            <MenuIcon src={"/assets/icons/json.svg"} style={{ height: '43px'}}/>
                        </Box>
                        <div style={{textAlign: "center", marginTop: "20px", fontSize: "16px"}}>
                            Select a CSV or TXT file from disk or drag & drop here to upload
                        </div>
                        <div style={{textAlign: 'center', marginTop: '10px', fontSize: '12px'}}>
                            ( Max DCH IDs: 100, Max File Size: 5 MB )
                        </div>
                    </DropZone>
                </label>
            </div>
        );
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <DndProvider backend={HTML5Backend}>
                <TargetBox onDrop={handleFileDrop}>
                    {renderImportFile()}
                </TargetBox>
                
            </DndProvider>
        </Box>
    );
};

const DropZone = styled.div`
    margin: 0;
    display: flex;
    min-height: 200px;
    border: dotted 3px lightgray;
    background-color: #f5f5f5;
    cursor: pointer;
    font-family: 'sans-serif';
    flex-direction: column;
    width: 100%;
    justify-content: center;
`;


export default UploadFile;
import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, createStyles, makeStyles, Theme} from "@material-ui/core";
import {RecipeFirstTitle} from "../RecipeCreate/RecipeTitles";
import {getContentModel} from "../../../services/actions/ContentModelService";
import {useDispatch, useSelector} from "react-redux";
import {exportFullMedia, getFullMedias} from "../../../services/actions/FullMediaListService";
import FullMediaTable from "./FullMediaTable";
import {clearFullMediasList} from "../../../store/actions/FullMediaListAction";
import {FullMedia} from "../../../model/FullMedia";
import {updateFullMedia} from "../../../store/actions/FullMediaAction";
import {updateContent} from "../../../store/actions/ContentAction";
import {useHistory} from "react-router-dom";
import RecipeActions from "../basicComponents/RecipeActions";
import styled from "styled-components";
import {FIELD_DELIMITER, FILE_TYPE} from "../../../services/types/util/Constants";
import {exportRecipes} from "../../../services/actions/ImportExportService";
import {AlertMessage} from "../basicComponents/AlertMessage";
import {changeBulkContentStatus, changeContentStatus} from "../../../services/actions/ContentService";
import RecipeListFilter from "../RecipeList/RecipeListFilter";
import {clearStatusError} from "../../../store/actions/StatusReducerActions";
import {clearStatusUtilError} from "../../../store/actions/UtilsAction";
import {addContentIdFullMediaUploading} from "../../../store/actions/FullMediaUploadingActions";

const CONTENT_MODEL = 'Full Media';
const SPACE = 'nam-tsp';

const FullMediaList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contentModel }  = useSelector(state => state.transient.contentModel);
  const { fullMediaList, utils } = useSelector(state => state.transient);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [ selected, setSelected ] = useState<string[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedMedia, setSelectedMedia] = React.useState<FullMedia>();
  const [ openError, setOpenError ] = useState<boolean>(false);
  const [ messageStatusUpadate, setMessageStatusUpadate] = useState<{message: string, open: boolean}>({message: '', open: false});
  const [ changeStatus, setChangeStatus] = useState(false);

  const loadFullMediaStructure = (data: any, origin: string) => {
    return {
      id: '',
      running: false,
      percent: 100,
      progress: '',
      result: {
        id: data.value,
        url: data.image && data.image.url ? data.image.url : '',
        name: data.image && data.image.name ? data.image.name : '',
        mimeType: data.image && data.image.mimeType ? data.image.mimeType : ''
      },
      origin: origin
    };
  }

  const getStatus = (status: string): "Draft" | "Live" => {
    switch (status) {
      case "Draft":
        return "Draft"
      default:
        return "Live"
    }
  }

  useEffect(() => {
    if (selectedMedia) {
      // history.push({pathname: '/media/new', search: `?contentId=${selectedMedia._id}`});
      dispatch(addContentIdFullMediaUploading(selectedMedia._id))
      history.push('/media/new');
    }
  }, [selectedMedia])

  const fetchFullMedia = (page: number) => {
    if (contentModel?._id) {
      dispatch(getFullMedias(page, rowsPerPage, contentModel._id));
      setSelected([]);
      setLoading(true);
    }
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (contentModel?._id) {
      setRowsPerPage(parseInt(event.target.value));
      dispatch(getFullMedias(1, parseInt(event.target.value), contentModel._id));
      setSelected([]);
      setLoading(true);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (contentModel?._id) {
      dispatch(getFullMedias(newPage +1, rowsPerPage, contentModel._id));
      setSelected([]);
      setLoading(true);
    }
  };

  useEffect(() => {
    if (contentModel?._id) {
      dispatch(getFullMedias(1, 25, contentModel._id));
    }
  }, [contentModel]);

  useEffect(() => {
    if (changeStatus) {
      setChangeStatus(false);
      fetchFullMedia(fullMediaList.currentpage);
      if (utils.statusUpdate.rejectedContentsCount > 0) {
        setMessageStatusUpadate({
          message: `${utils.statusUpdate.rejectedContentsCount} Recipes rejected`,
          open: true
        });
      }
      dispatch(clearStatusUtilError());
    }
  }, [utils.statusUpdate]);

  useEffect(() => {
    dispatch(getContentModel(CONTENT_MODEL, SPACE));
    setLoading(true);
    return () => {
      dispatch(clearFullMediasList());
    }
  }, [])


  const handleExport = (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => {
    if (selected.length === 0) {
      setOpenError(true);
      return;
    }
    if (contentModel?._id) {
      let contentModelId = contentModel._id;
      return  dispatch(exportFullMedia(selected, contentModelId, file_type, field_delimiter));
    }

  }

  const hadleBulkChangeStatus = (newStatus: string) => {
    if (!contentModel?._id) {
      return;
    }
    let body = {
      "uuids": selected,
      "newStatus": newStatus,
      "allContents": false,
      "filter": {
        "contentModel_id": contentModel._id,
        "status": null,
        "createdByUser": null,
        "name": "",
        "slug": "",
        "elementName": "",
        "elementValue": "",
        "appid": "nam-tsp"
      }
    }
    dispatch(changeBulkContentStatus(body));
    setChangeStatus(true);
  }

  return (
    <Card>
      <CardContent>
        <RecipeFirstTitle title={"Full Media List"} />
        <ActionsContainer>
          <RecipeActions
            showAdminOptions={true}
            showExportOptions={true}
            showImportOptions={false}
            showExportAllOptions={false}
            showPublishOption={true}
            handlerPublish={() => hadleBulkChangeStatus('Live')}
            handlerMoveToDraft={() => hadleBulkChangeStatus('Draft')}
            handlerArchiveSelected={() => hadleBulkChangeStatus('Archived')}
            handleExportRecipes={handleExport}
          />
        </ActionsContainer>
        <FullMediaTable
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          fetchFullMedia={fetchFullMedia}
          setChangStatus={setChangeStatus}
          rowsPerPage={rowsPerPage}
          loading={loading}
          setLoading={setLoading}
          setSelectedMedia={setSelectedMedia}
          selected={selected}
          setSelected={setSelected}
        />
        <AlertMessage text="You must select a full media" open={openError} type="error" setOpen={setOpenError}/>
        <AlertMessage
          text={messageStatusUpadate.message}
          open={messageStatusUpadate.open}
          type='error'
          setOpen={() => setMessageStatusUpadate({message: '', open: false})}
        />
      </CardContent>
    </Card>
  )
}

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  //padding-right: 20px;
  margin: 20px 0;
`;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

export default FullMediaList;

import { Backdrop, Box, CircularProgress, createStyles, Divider, Grid, makeStyles, Theme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadRecipeDetail, RecipeDetail } from '../../../model/Recipe';
import styled from "styled-components";
import RecipeImage from '../basicComponents/uploadImage/RecipeImage';
import { FileImage } from '../../../model/FileImage';
import { RecipeTitle } from '../RecipeCreate/RecipeTitles';
import Tags from './Tags';
import RecipeActions from '../basicComponents/RecipeActions';
import { ArchivedButton, DraftButton, LiveButton } from '../RecipeList/RecipeListButtons';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { republish, updateStatus } from '../../../services/actions/StatusService';
import { clearErrorRecipe } from '../../../store/actions/recipeActions';
import {FILE_TYPE, FIELD_DELIMITER, LifeStyle, DrinkShot} from '../../../services/types/util/Constants';
import { exportRecipes } from '../../../services/actions/ImportExportService';
import { getRecipeDetail } from '../../../services/actions/RecipeIssuesService';
import { useHistory } from 'react-router-dom';
import { getBrands, getComplexities, getDrinkTypes, getGlasswares, getMixers, getOccasions, getServeStrategy, getVariants } from '../../../services/actions/ElementsRecipeService';
import { AlertMessage } from '../basicComponents/AlertMessage';
import { restoreRecipe } from '../../../services/actions/RestoreService';
import { clearRestoreAction } from '../../../store/actions/RestoreActions';
import BasicDialog from '../basicComponents/BasicDialog';
import { clearStatusError } from '../../../store/actions/StatusReducerActions';


interface RecipeDetailProps {
    previousVersion?: boolean;
    showHeader: boolean;
    recipeInformation: RecipeDetail,
    goBack?: () => void,
    canPublish: boolean,
    setShowPreviousVersion?: (_: boolean) => void,
}

const RecipeDetailForm = ({previousVersion, showHeader, recipeInformation, goBack, canPublish, setShowPreviousVersion}: RecipeDetailProps) => {
    const classes = useStyles();
    const { serveStrategies, mixers, glasswares, complexities, drinkTypes, occasions, brands, variants, recipe, restore, statusUpdate } = useSelector(state => state.transient);
    const dispatch = useDispatch();
    const [recipeDetail, setRecipeDetail] = useState<RecipeDetail>(recipeInformation);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [restoreOptions, setRestoreOptions] = useState<{type: 'error' | 'success', message: string, open: boolean}>({type: 'success', message: '', open: false});
    const [checkRestore, setCheckRestore] = useState(false);

    useEffect(()=> {
        dispatch(getBrands());
        dispatch(getVariants());
        dispatch(getServeStrategy());
        dispatch(getMixers());
        dispatch(getOccasions());
        dispatch(getGlasswares());
        dispatch(getComplexities());
        dispatch(getDrinkTypes());
        return () => {
            setRestoreOptions({type: 'success', message: '', open: false});
            dispatch(clearRestoreAction());
        }
    }, []);

    useEffect(() => {
        setLoading(false);
        if (statusUpdate.error) {
            setLoading(false);
        } else if (statusUpdate.rejectedContentsCount > 0) {
            setRestoreOptions({
                type: 'error',
                message: 'Update rejected, check the mandatory fields to save as live',
                open: true
            });
        }
        dispatch(clearStatusError());
    }, [statusUpdate])

    useEffect(() => {
        if (recipe.recipe) {
            const recipeData: RecipeDetail = LoadRecipeDetail(recipe.recipe);
            setRecipeDetail(recipeData);
            setLoading(false);
        }
        if (recipe.error) {
            setLoading(false);
            dispatch(clearErrorRecipe());
        }
    }, [recipe])

    useEffect(() => {
        setRecipeDetail(recipeInformation);
    }, [recipeInformation])

    useEffect(() => {
        if (restore.recipe) {
            setLoading(false);
            setRecipeDetail(LoadRecipeDetail(restore.recipe));
            if (restore.restored) {
                setRestoreOptions({type: 'success', message: restore.message, open: true});
            } else {
                setRestoreOptions({type: 'error', message: restore.message, open: true});
            }
        }
        if (restore.error) {
            setLoading(false);
        }
    }, [restore]);


    const getServingStrategy = () => {
        if (recipeDetail.serveStrategy) {
            const options = recipeDetail.serveStrategy.map((servinStrategy: string) => {
                const option = serveStrategies.elementRecipe.find(option => option._id === servinStrategy);
                return option?.name;
            }) || [];
            return <Text>{options.join(' - ')}</Text>
        }
    }

    const getIngredients = () => {
        if (recipeDetail.ingredients) {
            return recipeDetail.ingredients.map((value: {ingredient: string | undefined}, index: number) => {
                return (
                    <Grid item xs={12} key={index}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <StepNumber>{ index + 1 }</StepNumber>
                            <Ingredient>{value.ingredient}</Ingredient>
                        </Box>
                    </Grid>
                );
            });
        }
        return <Text>None</Text>;
    }

    const renderComplexity = () => {
        if (recipeDetail.complexity) {
            const complexity = complexities.elementRecipe.find(complexity => complexity._id === recipeDetail.complexity);
            return <Text>{complexity?.name}</Text>;
        }
        return <Text>None</Text>;
    }

    const renderImage = (image: FileImage | null, error: string | null, type: LifeStyle | DrinkShot | null) => {
        if (image && (image.DCHImage || image?.file || image?.janusFile)) {
            return <RecipeImage file={image} deleteFile={()=> {}} editFile={()=> {}} showButtons={false} error={error} type={type} id={recipeDetail._id} />;
        }
        return <Text>None</Text>;
    }

    const renderPreparationSteps = () => {
        if (recipeDetail.preparationSteps) {
            return <TextWithBreakLine>{recipeDetail.preparationSteps}</TextWithBreakLine>;
        }
        return <Text>None</Text>;
    }

    const renderMenuCopy = () => {
        if (recipeDetail.menuCopy) {
            return <span dangerouslySetInnerHTML={{ __html: recipeDetail.menuCopy }}></span>;
        }
        return <Text>None</Text>;
    }

    const renderBrandName = (brandId: string | undefined) => {
        if (brandId && brands && brands.elementRecipe && brands.elementRecipe.length > 0) {
            const brand = brands.elementRecipe.find(brand => brand._id === brandId);
            return <Text>{brand?.name}</Text>
        }
        return null;
    }

    const renderVariantName = (variantId: string | undefined) => {

        if (variantId && variants && variants.elementRecipe && variants.elementRecipe.length > 0) {
            const variant = variants.elementRecipe.find(variant => variant._id === variantId);
            return <Text>{variant?.name}</Text>
        }
        return null;
    }


    const handlerRetore = (restore: boolean) => {
        setCheckRestore(false);
        if (recipeDetail._id && restore) {
            setLoading(true);
            dispatch(restoreRecipe(recipeDetail._id));
        }
    }

    const handleMoveToDraft = (uuid: string) => {
        if (recipeDetail._id) {
            dispatch(updateStatus(uuid, 'Draft', recipeDetail._id));
            setLoading(true);
        }
    }

    const handleArchive = (uuid: string) => {
        if (recipeDetail._id) {
            dispatch(updateStatus(uuid, 'Archived', recipeDetail._id));
            setLoading(true);
        }
    }

    const handleEdit = (uuid: string) => {
        if (recipeDetail._id) {
            dispatch(getRecipeDetail(recipeDetail._id));
            history.push('/recipe/new');
        }
    }

    const handlePublishNow = (uuid: string) => {
        if (recipeDetail._id) {
            dispatch(updateStatus(uuid, 'Live', recipeDetail._id));
            setLoading(true);
        }
    }

    const handleFixIssue = (uuid: string) => {
        if (recipeDetail._id) {
            dispatch(getRecipeDetail(recipeDetail._id));
            history.push('/recipe/new');
        }
    }

    const handleRepublish = (uuid: string) => {
        if (recipeDetail._id) {
            dispatch(republish(uuid, recipeDetail._id));
            setLoading(true);
        }

    }

    const handleExportRecipes = (file_type: FILE_TYPE, field_delimiter?: FIELD_DELIMITER) => {
        if (recipeDetail.contentModel_id && recipeDetail._id) {
            const contentModelId = recipeDetail.contentModel_id;
            const selected = [recipeDetail._id];
            return  dispatch(exportRecipes(selected, contentModelId, file_type, field_delimiter));
        }
    }

    const renderButton = (enviroment: number | undefined, uuid: string | undefined) => {
        if (uuid) {
            switch (enviroment) {
                case 3:
                    const publishMethod = canPublish ? handlePublishNow : handleFixIssue;
                    const textPublish = canPublish ? "Publish Now" : "Fix Issue";
                    return <DraftButton
                                setShowPreviousVersion={setShowPreviousVersion}
                                id={uuid}
                                handleArchive={handleArchive}
                                handlePublishNow={publishMethod}
                                handleEdit={handleEdit}
                                handlerRetore={() => setCheckRestore(true)}
                                textPublish={textPublish}
                                showCompleteMenu={true}
                                statusVersion={{
                                    version: recipeDetail.version,
                                    date: recipeDetail.uploadDate
                                }}
                            />;
                case 1:
                    return <LiveButton
                                id={uuid}
                                handleMoveToDraft={handleMoveToDraft}
                                handleArchive={handleArchive}
                                showCompleteMenu={true}
                                statusVersion={{
                                    version: recipeDetail.version,
                                    date: recipeDetail.uploadDate
                                }}
                            />;
                default:
                    return <ArchivedButton
                                id={uuid}
                                handleMoveToDraft={handleMoveToDraft}
                                handleRepublish={handleRepublish}
                                showRepublish={canPublish}
                                showCompleteMenu={true}
                                statusVersion={{
                                    version: recipeDetail.version,
                                    date: recipeDetail.uploadDate
                                }}
                            />;
            }
        }
    }

    const close = () => {
        if (goBack) {
            dispatch(clearRestoreAction());
            goBack();
        }
    }

    const renderHeader = () => {
        return (
            <div>
                <Box pt={2} pb={2}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <RecipeHeaderTitle>Recipe: {recipeDetail.name}</RecipeHeaderTitle>
                        <IconButton aria-label="close" onClick={close}>
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" pt={3}>
                        {renderButton(recipeDetail.enviroment, recipeDetail.uuid)}
                        <UpdatedAt>{recipeDetail.updatedAt?.textToShow} by {recipeDetail.author}</UpdatedAt>
                        <Separator />
                        <RecipeActions
                            showAdminOptions={false}
                            showExportOptions={true}
                            showImportOptions={false}
                            handleExportRecipes={handleExportRecipes}
                        />
                    </Box>
                </Box>
                <Divider />
            </div>
        );
    }

    const renderSimpleHeader = () => {
        return (
            <div>
                <Box pt={2} pb={2}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <RecipeHeaderTitle>Recipe: {recipeDetail.name} - Previous version</RecipeHeaderTitle>
                        <IconButton aria-label="close" onClick={close}>
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" pt={3}>
                        <UpdatedAt>{recipeDetail.updatedAt?.textToShow} by {recipeDetail.author}</UpdatedAt>
                    </Box>
                </Box>
                <Divider />
            </div>
        );
    }

    return (
        <div>

            {
                showHeader ?
                    (renderHeader())
                :
                    null
            }

            {
                previousVersion ?
                    (renderSimpleHeader())
                :
                    null
            }
            <RecipeTitle title="Recipe Name, Variants & Serving Strategy" />

                <Box mt={3}>
                    <Box mb={1} pt={2} pb={2} pl={3} {...defaultProps}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <SubTitle>Brand</SubTitle>
                                {renderBrandName(recipeDetail.brand)}
                            </Grid>
                            <Grid item xs={6}>
                                <SubTitle>Secondary Brand</SubTitle>
                                {renderBrandName(recipeDetail.secondaryBrand)}
                            </Grid>
                            <Grid item xs={6}>
                                <SubTitle>Primary Variant</SubTitle>
                                {renderVariantName(recipeDetail.primaryVariant)}
                            </Grid>
                            <Grid item xs={6}>
                                <SubTitle>Secondary Variant</SubTitle>
                                {renderVariantName(recipeDetail.secondaryVariant)}
                            </Grid>
                            <Grid item xs={12}>
                                <SubTitle>Recipe Name</SubTitle>
                                <Text>{recipeDetail.name}</Text>
                            </Grid>
                            <Grid item xs={12}>
                                <SubTitle>Serving Strategy</SubTitle>
                                {getServingStrategy()}
                            </Grid>
                            <Grid item xs={12}>
                                <SubTitle>Serving Size</SubTitle>
                                <Text>{recipeDetail.servingSize}</Text>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <RecipeTitle title="Media Selection" />

                <Box mt={3}>
                    <Box mb={1} pt={2} pb={2} pl={3} {...defaultProps}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SubTitle>Drink Image</SubTitle>
                                {renderImage(recipeDetail.drinkShot.fileImage, recipeDetail.drinkShot.error, 'drinkShot')}
                            </Grid>
                            <Grid item xs={12}>
                                <SubTitle>Lifestyle Image</SubTitle>
                                {renderImage(recipeDetail.lifeStyle.fileImage, recipeDetail.lifeStyle.error, 'lifeStyle')}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <RecipeTitle title="Ingredients" />

                <Box mt={3}>
                    <Box mb={1} pt={2} pb={2} pl={3} {...defaultProps}>
                        <Grid container spacing={2}>
                            {getIngredients()}
                        </Grid>
                    </Box>
                </Box>

                <RecipeTitle title="Preparation Steps" />

                <Box mt={3}>
                    <Box mb={1} pt={2} pb={2} pl={3} {...defaultProps}>
                        <Grid container spacing={2}>
                            {renderPreparationSteps()}
                        </Grid>
                    </Box>
                </Box>

                <RecipeTitle title="Menu Copy" />

                <Box mt={3}>
                    <Box mb={1} pt={2} pb={2} pl={3} {...defaultProps}>
                        <Grid container spacing={2}>
                            {renderMenuCopy()}
                        </Grid>
                    </Box>
                </Box>

                <RecipeTitle title="Drink Types" />

                <Box mt={3}>
                    <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                        <Tags tags={recipeDetail.drinkTypes || []} allTags={drinkTypes.elementRecipe} customTags={recipeDetail.newDrinkTypes || []} width={2}/>
                    </Box>
                </Box>

                <RecipeTitle title="Occassions" />

                <Box mt={3}>
                    <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                        <Tags tags={recipeDetail.occasions || []} allTags={occasions.elementRecipe} customTags={recipeDetail.newOccasions || []} width={2}/>
                    </Box>
                </Box>

                <Box mt= {1}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>

                            <RecipeTitle title="Mixers" />

                            <Box mt={3}>
                                <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                                    <Tags tags={recipeDetail.mixers || []} allTags={mixers.elementRecipe} customTags={recipeDetail.newMixers || []}/>
                                </Box>
                            </Box>

                        </Grid>
                        <Grid item xs={6}>

                            <RecipeTitle title="Food Pairings" />

                            <Box mt={3}>
                                <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                                    <Tags tags={[]} allTags={[]} customTags={recipeDetail.foodPairing || []}/>
                                </Box>
                            </Box>

                        </Grid>
                        <Grid item xs={6}>

                            <RecipeTitle title="Glassware" />

                            <Box mt={3}>
                                <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                                    <Tags tags={recipeDetail.glassware || []} allTags={glasswares.elementRecipe} customTags={recipeDetail.newGlassware || []}/>
                                </Box>
                            </Box>

                        </Grid>
                        <Grid item xs={6}>

                            <RecipeTitle title="Complexity" />

                            <Box mt={3}>
                                <Box mb={1} pt={1} pb={1} pl={3} {...defaultProps}>
                                    {renderComplexity()}
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </Box>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <BasicDialog
                    open={checkRestore}
                    handleClose={handlerRetore}
                    okButton="restore"
                    cancelButton="cancel"
                    description={["Are you sure to retore this recipe?"]}
                />
                <AlertMessage
                    text={restoreOptions.message}
                    open={restoreOptions.open}
                    type={restoreOptions.type}
                    setOpen={() => setRestoreOptions({type: 'success', message: '', open: false})}
                />
        </div>
    )
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: blue[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    buttonWhite: {
        marginRight: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
  }),
);

const defaultProps = {
    borderLeft: "8px solid #bfbfbfcc",
};

const StepNumber = styled.div`
    margin-right: 10px;
    
    padding: 8px;
`;

const SubTitle = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Text = styled.span`
    color: #a2a2a2;
    font-size: 18px;
    padding-right: 5px;
    font-family: sans-serif;
`;

const TextWithBreakLine = styled(Text)`
    white-space: break-spaces;
    max-width: 1200px;
`

const Ingredient = styled.span`
    margin-left: 11px;
    width: 50%;
    border-bottom: 1px solid black;
    padding-bottom: 8px;
`;

const Tag = styled.span`
    background-color: #0c5d8e;
    color: white;
    padding: 10px 29px 10px 10px;
    border-radius: 9px;
    display: -webkit-inline-box;
`;

const RecipeHeaderTitle = styled.span`
    font-size: 40px;
    font-weight: lighter;
`;
const Separator = styled.span`
    flex: 1 1 auto;
`;

const UpdatedAt = styled.p`
    margin-left: 15px;
    color: #858383de;
`;

export default RecipeDetailForm;

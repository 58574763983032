import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    autogeneratePreviewFromFullMedia,
    autogenerateThumbnailFromFullMedia,
    checkProgressAutogeneratedFullMedia
} from "../../../../services/actions/FullMediaService";
import {isRight} from "fp-ts/These";
import {addPreviewDataAction, addThumbnailDataAction} from "../../../../store/actions/FullMediaDataActions";
import {LinearProgress} from "@material-ui/core";
import styled from "styled-components";
import {addErrorUploadingFullMediaAction} from "../../../../store/actions/FullMediaUploadingActions";

const UPDATE_TIME = 2000;

interface Props {
    startTime: string;
    duration: number;
    handleError: () => void;
}

export const ProgressAutogeneratePreview = ({ startTime, duration, handleError } : Props) => {

    const dispatch = useDispatch();
    const { fullFile }  = useSelector(state => state.transient.fullMediaDataReducer);
    const [ checkFile, setCheckFile ] = useState(false);
    const timerId = useRef(0);
    const processId = useRef('');

    const runCheckProgressTimeout = () => {
        setCheckFile(false);
        timerId.current = setTimeout(() => {
            setCheckFile(true);
        }, UPDATE_TIME);
    }

    const loadError = () => {
        dispatch(addErrorUploadingFullMediaAction('Error uploading file'));
        handleError();
    }

    const checkProgress = async () => {
        const resp = await dispatch(checkProgressAutogeneratedFullMedia(processId.current, 'preview'));

        if (!isRight(resp)) {
            loadError();
            return;
        }

        if ( !resp.right.running && !resp.right.result) {
            loadError();
            return;
        }

        if ( !resp.right.running && resp.right.result) {
            dispatch(addPreviewDataAction(resp.right.result));
        } else {
            runCheckProgressTimeout();
        }

    }

    useEffect(() => {

        if ( checkFile ) {
            checkProgress();
        }

    }, [checkFile])

    const autogeneratePreview = async (id: string) => {

        const resp = await dispatch(autogeneratePreviewFromFullMedia(id, startTime, duration.toString()));

        if (!isRight(resp)) {
            return;
        }

        processId.current = resp.right.id;
        checkProgress();

    }

    useEffect(() => {

        if (fullFile) {
            autogeneratePreview(fullFile.id);
        }

    }, [fullFile])

    return (
        <>
            <PercentText>Generating...</PercentText>
            <LinearProgress />
        </>
    )
}


const PercentText = styled.span`
    margin: 10px 0;
    font-weight: bold;
`;

import React from 'react';
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from '@material-ui/core';
import { makeStyles, withStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { green, yellow } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PublishIcon from '@material-ui/icons/Publish';
import ReplayIcon from '@material-ui/icons/Replay';
import SearchIcon from "@material-ui/icons/Search";

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const daysNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const parseDate = (date?: string) => {
    if (!date) {
        return '';
    }
    let uploadDate = new Date(date);
    let month = monthNames[uploadDate.getMonth()];
    let day = daysNames[uploadDate.getDay()];
    let hours = uploadDate.getHours();
    let minutes = uploadDate.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minutesStr = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutesStr + ' ' + ampm;

    return day + ', ' + month + ' ' + uploadDate.getDate() + ', ' + uploadDate.getFullYear() + ', ' + strTime;
}

const getVersionHeader = (statusVersion: StatusVersion) => {
    const menuItems = [];
    menuItems.push(
        <MenuItem disabled dense style={{opacity: "1"}} key="1">
            <ListItemText primary="Versions" />
        </MenuItem>
    )
    menuItems.push(
        <Divider key="2"/>
    )
    menuItems.push(
        <MenuItem disabled dense style={{opacity: "1"}} key="3">
            <ListItemText primary={`Version: ${statusVersion.version}`} />
        </MenuItem>
    )
    menuItems.push(
        <MenuItem disabled dense key="4">
            <ListItemText primary={parseDate(statusVersion.date)} />
        </MenuItem>
    );
    return menuItems;
}

interface StatusVersion {
    version?: number;
    date?: string;
};

interface DraftButtonProps {
    id: string;
    handlePublishNow: (id: string) => void;
    handleArchive: (id: string) => void;
    handleEdit?: (id: string) => void;
    handlerRetore?: (id: string) => void;
    textPublish: string;
    showCompleteMenu?: boolean;
    statusVersion?: StatusVersion;
    setShowPreviousVersion?: (_: boolean) => void
}

export const DraftButton = ({id, handlePublishNow, handleArchive, handleEdit, handlerRetore, textPublish, showCompleteMenu, statusVersion, setShowPreviousVersion}: DraftButtonProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const publishNow = () => {
        handlePublishNow(id);
        handleClose();
    }

    const archive = () => {
        handleArchive(id);
        handleClose();
    }

    const edit = () => {
        if (handleEdit) {
            handleEdit(id);
            handleClose();
        }
    }

    const restore = () => {
        if (handlerRetore) {
            handlerRetore(id);
            handleClose();
        }
    }

    return (
        <div>
            <DraftButtonStyle
                aria-controls="draft-button"
                aria-haspopup="true"
                variant="contained"
                style={{textTransform: 'none'}}
                endIcon={<ArrowDropDownIcon />}
                size="small"
                color="primary"
                className={classes.draftButton}
                onClick={handleClick}
            >
                Draft
            </DraftButtonStyle>
            <StyledMenu
                id="draft-button"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    showCompleteMenu && statusVersion?
                        getVersionHeader(statusVersion)
                    :
                        null
                }
                <MenuItem onClick={publishNow}>
                    {
                        showCompleteMenu ?
                            <StyledMenuItem>
                                <PublishIcon fontSize="small" />
                            </StyledMenuItem>
                        :
                            null
                    }
                    <ListItemText primary={textPublish} />
                </MenuItem>
                {
                    showCompleteMenu ?
                        <MenuItem onClick={edit}>
                            <StyledMenuItem>
                                <EditIcon fontSize="small" />
                            </StyledMenuItem>
                            <ListItemText primary="Edit" />
                        </MenuItem>
                    :
                        null
                }
                <MenuItem onClick={archive}>
                    {
                        showCompleteMenu ?
                            <StyledMenuItem>
                                <RemoveCircleIcon fontSize="small" />
                            </StyledMenuItem>
                        :
                            null
                    }
                    <ListItemText primary="Archive" />
                </MenuItem>
                {
                    showCompleteMenu ?
                        <MenuItem onClick={restore}>
                            <StyledMenuItem>
                                <ReplayIcon fontSize="small" />
                            </StyledMenuItem>
                            <ListItemText primary="Restore Previous Version" />
                        </MenuItem>
                    :
                        null
                }
                {
                    setShowPreviousVersion ?
                        <MenuItem onClick={() => {
                            setShowPreviousVersion(true);
                            handleClose();
                        }}>
                            <StyledMenuItem>
                                <SearchIcon fontSize="small" />
                            </StyledMenuItem>
                            <ListItemText primary="Previous Version" />
                        </MenuItem>
                    :
                        null
                }

            </StyledMenu>
        </div>

    )
}

interface LiveButtonProps {
    id: string;
    handleMoveToDraft: (id: string) => void;
    handleArchive: (id: string) => void;
    hideMoveToDraft?: boolean;
    showCompleteMenu?: boolean;
    statusVersion?: StatusVersion;
}

export const LiveButton = ({id, handleMoveToDraft, handleArchive, hideMoveToDraft, showCompleteMenu, statusVersion}: LiveButtonProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const moveToDraft = () => {
        handleMoveToDraft(id);
        handleClose();
    }

    const archive = () => {
        handleArchive(id);
        handleClose();
    }

    return (
        <div>
            <LiveButtonStyle
                aria-controls="live-button"
                aria-haspopup="true"
                variant="contained"
                style={{textTransform: 'none'}}
                endIcon={<ArrowDropDownIcon style={{color: 'white'}}/>}
                size="small"
                color="secondary"
                className={classes.button}
                onClick={handleClick}
            >
                Live
            </LiveButtonStyle>
            <StyledMenu
                id="live-button"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    showCompleteMenu && statusVersion?
                        getVersionHeader(statusVersion)
                    :
                        null
                }
                {
                    hideMoveToDraft ?
                      null
                    :
                      <MenuItem onClick={moveToDraft}>
                          {
                              showCompleteMenu ?
                                <StyledMenuItem>
                                    <PublishIcon fontSize="small" />
                                </StyledMenuItem>
                                :
                                null
                          }
                          <ListItemText primary="Move to Draft" />
                      </MenuItem>
                }

                <MenuItem onClick={archive}>
                    {
                        showCompleteMenu ?
                            <StyledMenuItem>
                                <RemoveCircleIcon fontSize="small" />
                            </StyledMenuItem>
                        :
                            null
                    }
                    <ListItemText primary="Archive" />
                </MenuItem>
            </StyledMenu>
        </div>

    )
}

interface ArchivedButtonProps {
    id: string;
    handleMoveToDraft: (id: string) => void;
    handleRepublish?: (id: string) => void;
    showRepublish: boolean;
    showCompleteMenu?: boolean;
    statusVersion?: StatusVersion;
}

export const ArchivedButton = ({id, handleMoveToDraft, handleRepublish, showRepublish, showCompleteMenu, statusVersion}: ArchivedButtonProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const moveToDraft = () => {
        handleMoveToDraft(id);
        handleClose();
    }

    const republish = () => {
        if (handleRepublish) {
            handleRepublish(id);
        }
        handleClose();
    }

    return (
        <div>
            <ArchivedButtonStyle
                    aria-controls="archived-button"
                    aria-haspopup="true"
                    variant="contained"
                    style={{textTransform: 'none'}}
                    endIcon={<ArrowDropDownIcon style={{color: 'white'}}/>}
                    size="small"
                    className={classes.button}
                    onClick={handleClick}
                >
                    Archived
            </ArchivedButtonStyle>
            <StyledMenu
                id="archived-button"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    showCompleteMenu && statusVersion?
                        getVersionHeader(statusVersion)
                    :
                        null
                }
                <MenuItem onClick={moveToDraft}>
                    {
                        showCompleteMenu ?
                            <StyledMenuItem>
                                <EditIcon fontSize="small" />
                            </StyledMenuItem>
                        :
                            null
                    }
                    <ListItemText primary="Move to Draft" />
                </MenuItem>
                {
                    showRepublish ?
                        <MenuItem onClick={republish}>
                            {
                                showCompleteMenu ?
                                    <StyledMenuItem>
                                        <PublishIcon fontSize="small" />
                                    </StyledMenuItem>
                                :
                                    null
                            }
                            <ListItemText primary="Republish" />
                        </MenuItem>
                    :
                        null
                }
            </StyledMenu>
        </div>

    )
}


const theme = createMuiTheme({
    palette: {
        primary: {
            main: yellow['A700'],
        },
        secondary: {
            main: green['A700'],
        }
    },
});

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    button: {
        width: '100px',
        color: 'white'
    },
    draftButton: {
        width: '100px',
        color: 'black'
    }
});

const ArchivedButtonStyle = withStyles((theme: Theme) => ({
    root: {
      backgroundColor: '#797979',
      '&:hover': {
        backgroundColor: '#676666',
      },
    },
}))(Button);

const LiveButtonStyle = withStyles((theme: Theme) => ({
    root: {
      backgroundColor: '#137d01',
      '&:hover': {
        backgroundColor: '#106901',
      },
    },
}))(Button);

const DraftButtonStyle = withStyles((theme: Theme) => ({
    root: {
      backgroundColor: '#fbe616',
      '&:hover': {
        backgroundColor: '#e6d314',
      },
    },
}))(Button);

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props: MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
    }}
    transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
    }}

    {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        minWidth: '27px',
    },
}))(ListItemIcon);

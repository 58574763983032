import {ServiceCallAction} from "../../store/middleware/serviceCallMiddleware";
import {
  failUpdateFullMedia,
  updateFullMedia,
  // updateFullMediaWithThumbnailAndPreview,
  updatePreviewAndThumbnailFullMedia, updateThumbnailFullMedia
} from "../../store/actions/FullMediaAction";
import {
  CheckPreviewAndThumbnailResponse,
  CheckPreviewAndThumbnailResponseCodec, CheckThumbnailResponseCodec, CheckThumbnailResponseResponse,
  FullMediaResponse,
  FullMediaResponseCodec
} from "../types/FullMediaResponseCodec";
import {JSONObject} from "../types/util/BasicTypes";
import {SegmentFileResponse, SegmentFileResponseCodec, anyResponse, anyCodec} from "../types/SegmentFileResponseCodec";
import {updateSegmentStatus} from "../../store/actions/SegmentFileAction";
import {FullFileData} from "../../model/FullMediaComponents";


export const postFullMedia = (data: FormData, origin: 'fullMedia' | 'thumbnail' | 'preview'): ServiceCallAction<FullMediaResponse> => ({
  method: "POST",
  endpoint: `media/create/full`,
  serviceKey: "postFullMedia",
  body: {type: 'multipart', value: data},
  responseDecoder: FullMediaResponseCodec,
  type: "SERVICE_CALL",
  onSuccess: (data: FullMediaResponse) => updateFullMedia(data, origin),
  onFailure: (data: any) => failUpdateFullMedia(origin)
});

export const _postFullMedia = (data: FormData): ServiceCallAction<FullMediaResponse> => ({
  method: "POST",
  endpoint: `media/create/full`,
  serviceKey: "postFullMedia",
  body: {type: 'multipart', value: data},
  responseDecoder: FullMediaResponseCodec,
  type: "SERVICE_CALL",
});

export const postFullMediaMultiPart = (data: JSONObject): ServiceCallAction<FullMediaResponse> => ({
  method: "POST",
  endpoint: `media/start/full-uploading`,
  serviceKey: "postFullMediaMultiPart",
  body: {type: 'application/json', value: {...data}},
  responseDecoder: FullMediaResponseCodec,
  type: "SERVICE_CALL",
  onSuccess: (data: FullMediaResponse) => updateFullMedia(data, 'fullMedia'),
  onFailure: (data: any) => failUpdateFullMedia('fullMedia')
});

export const _postFullMediaMultiPart = (data: FullFileData): ServiceCallAction<FullMediaResponse> => ({
  method: "POST",
  endpoint: `media/start/full-uploading`,
  serviceKey: "postFullMediaMultiPart",
  body: {type: 'application/json', value: {...data}},
  responseDecoder: FullMediaResponseCodec,
  type: "SERVICE_CALL",
});

export const postFullMediaSegment = (data: FormData, id: String, segmentId: number): ServiceCallAction<anyResponse> => ({
  method: "POST",
  endpoint: `media/segment/${segmentId}/from/${id}`,
  serviceKey: "postFullMediaSegment",
  body: {type: 'multipart', value: data},
  responseDecoder: anyCodec,
  type: "SERVICE_CALL"
});

export const checkSegment = (id: String, segmentId: string): ServiceCallAction<SegmentFileResponse> => ({
  method: "GET",
  endpoint: `media/check/segment/${segmentId}/from/${id}`,
  serviceKey: "checkSegment",
  responseDecoder: SegmentFileResponseCodec,
  type: "SERVICE_CALL",
  onSuccess: updateSegmentStatus,
  // onFailure: (data: any) => failUpdateFullMedia('fullMedia')
});

export const _checkSegment = (id: String, segmentId: string): ServiceCallAction<SegmentFileResponse> => ({
  method: "GET",
  endpoint: `media/check/segment/${segmentId}/from/${id}`,
  serviceKey: "checkSegment",
  responseDecoder: SegmentFileResponseCodec,
  type: "SERVICE_CALL",
});

// export const postFullMediaWithThumbnailAndPreview = (data: FormData, preview: boolean, thumbnail: boolean): ServiceCallAction<FullMediaResponse> => ({
//   method: "POST",
//   endpoint: `media/create/full`,
//   serviceKey: "postFullMedia",
//   body: {type: 'multipart', value: data},
//   responseDecoder: FullMediaResponseCodec,
//   type: "SERVICE_CALL",
//   onSuccess: (data: FullMediaResponse) => updateFullMediaWithThumbnailAndPreview(data, preview, thumbnail)
// });

export const checkFullMedia = (id: string, origin: 'fullMedia' | 'thumbnail' | 'preview'): ServiceCallAction<FullMediaResponse> => ({
  method: "GET",
  endpoint: `media/status/${id}`,
  serviceKey: "checkFullMedia",
  responseDecoder: FullMediaResponseCodec,
  type: "SERVICE_CALL",
  onSuccess: (data: FullMediaResponse) => updateFullMedia(data, origin),
  onFailure: (data: any) => failUpdateFullMedia(origin)
});

export const _checkFullMedia = (id: string): ServiceCallAction<FullMediaResponse> => ({
  method: "GET",
  endpoint: `media/status/${id}`,
  serviceKey: "checkFullMedia",
  responseDecoder: FullMediaResponseCodec,
  type: "SERVICE_CALL",
});

export const checkPreviewAndThumbnail = (id: string): ServiceCallAction<CheckPreviewAndThumbnailResponse> => ({
  method: "GET",
  endpoint: `media/retrieve/preview/${id}`,
  serviceKey: "checkFullMedia",
  responseDecoder: CheckPreviewAndThumbnailResponseCodec,
  type: "SERVICE_CALL",
  onSuccess: updatePreviewAndThumbnailFullMedia,
  onFailure: (data: any) => failUpdateFullMedia('fullMedia')
});

export const _checkPreviewAndThumbnail = (id: string): ServiceCallAction<CheckPreviewAndThumbnailResponse> => ({
  method: "GET",
  endpoint: `media/retrieve/preview/${id}`,
  serviceKey: "checkFullMedia",
  responseDecoder: CheckPreviewAndThumbnailResponseCodec,
  type: "SERVICE_CALL",
});

export const loadThumbnailFullMedia = (id: string): ServiceCallAction<CheckThumbnailResponseResponse> => ({
  method: "GET",
  endpoint: `media/thumbnail/${id}`,
  serviceKey: "checkFullMedia",
  responseDecoder: CheckThumbnailResponseCodec,
  type: "SERVICE_CALL",
  onSuccess: updateThumbnailFullMedia,
  onFailure: (data: any) => failUpdateFullMedia('fullMedia')
});

export const _loadThumbnailFullMedia = (id: string): ServiceCallAction<CheckThumbnailResponseResponse> => ({
  method: "GET",
  endpoint: `media/thumbnail/${id}`,
  serviceKey: "_loadThumbnailFullMedia",
  responseDecoder: CheckThumbnailResponseCodec,
  type: "SERVICE_CALL",
});

export const autogenerateThumbnailFromFullMedia = (id: string, startTime: string): ServiceCallAction<FullMediaResponse> => ({
  method: "POST",
  endpoint: `media/create/thumbnail/`,
  body: {type: 'application/json', value: {id, startTime}},
  serviceKey: "autogenerateThumbnailFromFullMedia",
  responseDecoder: FullMediaResponseCodec,
  type: "SERVICE_CALL",
});

export const autogeneratePreviewFromFullMedia = (id: string, startTime: string, duration: string): ServiceCallAction<FullMediaResponse> => ({
  method: "POST",
  endpoint: `media/create/preview/`,
  body: {type: 'application/json', value: {id, startTime, duration}},
  serviceKey: "autogenerateThumbnailFromFullMedia",
  responseDecoder: FullMediaResponseCodec,
  type: "SERVICE_CALL",
});

export const autogenerateThumbnailFromImageFullMedia = (id: string): ServiceCallAction<FullMediaResponse> => ({
  method: "POST",
  endpoint: `media/create/thumbnail-from-image/`,
  body: {type: 'application/json', value: {id}},
  serviceKey: "autogenerateThumbnailFromImageFullMedia",
  responseDecoder: FullMediaResponseCodec,
  type: "SERVICE_CALL",
});

export const checkProgressAutogeneratedFullMedia = (id: string, type: 'preview' | 'thumbnail'): ServiceCallAction<FullMediaResponse> => ({
  method: "GET",
  endpoint: `media/autogenerate/fullMedia/${type}`,
  param: {id},
  serviceKey: "checkProgressAutogeneratedFullMedia",
  responseDecoder: FullMediaResponseCodec,
  type: "SERVICE_CALL",
});


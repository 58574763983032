import * as t from "io-ts";

export const FullMediaCodec = t.strict({
  _id: t.string,
  name: t.string,
  slug: t.string,
  status: t.string,
  uuid: t.string,
  version: t.number,
  createdByUser: t.string,
  author: t.string,
  createdAt: t.string,
  updatedAt: t.strict({
    title: t.string,
    textToShow: t.string,
    raw: t.string
  }),
  elements: t.array(t.strict({
    _id: t.string,
    name: t.string,
    datatype: t.string,
    data: t.strict({
      value: t.unknown,
      image: t.union([
        t.null,
        t.undefined,
        t.strict({
          url: t.string,
          name: t.string
        })
      ])
    })
  }))
});

export const FullMediaListCodec = t.strict({
  contents: t.array(FullMediaCodec),
  resultlimit: t.number,
  currentpage: t.number,
  totalcount: t.number,
  pagecount: t.number
})

export type FullMediaListResponse = t.OutputOf<typeof FullMediaListCodec>;

import * as t from "io-ts";

export const ContentModelCodec = t.strict({
  _id: t.string,
  name: t.string,
  fields: t.array(t.strict({
    _id: t.string,
    name: t.string,
    datatype: t.string,
    visibleInList: t.boolean,
    config: t.strict({
      default: t.unknown
    }),
    visibleInPrivateList: t.boolean
  }))
});

export type ContentModelResponse = t.OutputOf<typeof ContentModelCodec>;

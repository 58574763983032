import { Recipe } from "../../model/Recipe";
import { RestoreActions } from "../reducers/RestoreReducer";

export const restoreRecipeAction = (restoredRecipe: {recipe: Recipe, message: string, restored: boolean}): RestoreActions => ({
    type: "UPDATE_RESTORE",
    recipe: restoredRecipe.recipe,
    message: restoredRecipe.message,
    restored: restoredRecipe.restored
});

export const clearRestoreAction = (): RestoreActions => ({
	type: "CLEAR_RESTORE"
});

export const failRestoreAction = (): RestoreActions => ({
	type: "FAIL_RESTORE"
});
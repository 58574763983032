import { Box, Checkbox, FormControlLabel, Grid, IconButton, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { ElementRecipe } from '../../../../model/ElementRecipe';

interface SelectTagsProps {
    tags: ElementRecipe[];
    name: string;
    label: string;
};

/* interface renderedSelectTags {
    fields: any;
    meta: any;
    description: string;
    setDescription: (description: string) => void;
    tagsSelected: {
        id: number;
        name: string;
    }[];
    tagName: string;
}
 */
const renderNewCheckbox = ({ input}: any) => {
    return (
        <Grid item xs={2}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={input.value.checked}
                        onChange={(event) => {
                            const newValue = {
                                name: input.value.name,
                                checked: event.target.checked
                            };
                            return input.onChange(newValue);
                        }}
                    />
                }
                label={input.value.name}
            />
        </Grid>
    )
}

const renderCheckbox = ({ tags, input}: any) => {

    return tags.map((tag: any, index: number) => {
        return (
            <Grid item xs={2} key={tag._id}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={input.value.indexOf(tag._id) !== -1}
                            onChange={(event) => {
                                const newValue = [...input.value] || [];
                                if (event.target.checked) {
                                    newValue.push(tag._id);
                                } else {
                                    newValue.splice(newValue.indexOf(tag._id), 1);
                                }

                                return input.onChange(newValue);
                            }}
                        />
                    }
                    label={tag.name}
                />
            </Grid>
        )
    });

};

const renderCheckboxGroup = ({ fields, meta: { error }, description, setDescription, tags, tagName, addLabel}: any) => {
    const addNewTag = (fields: any[]) => {
        if (description.length > 0) {
            fields.push({name: description, checked: true});
            setDescription('');
        }
    };

    return (
        <Grid container item xs={12} spacing={3}>

            <Grid item xs={12}>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" flexDirection="row">
                        <TextField
                            id="ingredient-description"
                            label={addLabel}
                            color="primary"
                            value={description}
                            fullWidth
                            onChange={e => setDescription(e.target.value)}
                        />
                        <IconButton aria-label="add" color="primary" onClick={() => addNewTag(fields)}>
                            <AddCircleOutlineIcon fontSize="large" />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>

            <Field name={tagName} component={renderCheckbox} tags={tags} /> 
            
            {fields.map((newTag: any, index: number) => (
                <Field
                    key={`${tagName}-${index}`}
                    name={newTag}
                    component={renderNewCheckbox}
                />
            ))}

        </Grid>
    )
}

const SelectTags = ({tags, name, label}: SelectTagsProps) => {
    const [description, setDescription] = useState('');

    return (
        <div style={{width: "100%"}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FieldArray name={`new${name}`} component={renderCheckboxGroup} description={description} setDescription={setDescription} tags={tags} tagName={name} addLabel= {label}/>
                </Grid>
            </Grid>
        </div>
    );
}

export default SelectTags;